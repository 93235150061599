import {makeStyles, Theme} from '@material-ui/core';
import {colors} from '@valmet-iop/ui-common';
import {getLayoutStyles} from '../styles';

export const useCalendarStyles = makeStyles<Theme>(theme => ({
  '@global': {
    'body .rbc-current-time-indicator': {
      background: theme.palette.error.main,
    },
    'body .rbc-time-gutter .rbc-time-slot': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: theme.spacing(6.25),
    },
    'body .rbc-off-range': {
      color: theme.palette.secondary.main,
    },
    'body .rbc-timeslot-group:nth-child(odd)': {
      background: theme.palette.primary.contrastText,
    },
    'body .rbc-time-header': {
      marginRight: '16px !important',
    },
    'body .rbc-timeslot-group:nth-child(even)': {
      background: theme.palette.secondary.light,
    },
    'body .rbc-allday-cell': (props: {isWeekView: boolean}) => ({
      display: props.isWeekView ? 'none' : '',
    }),
    'body .rbc-time-view .rbc-header': {
      borderBottom: 'none',
      height: theme.spacing(5),
    },
    'body .rbc-time-content': {
      borderTop: 'none',
    },
    'body .rbc-time-slot': {
      borderTop: 'none !important',
    },
    'body .rbc-today': {
      background: 'none',
    },
    'body .rbc-time-view .rbc-header .rbc-button-link': {
      height: '100%',
    },
    'body .rbc-time-view .rbc-header .rbc-button-link div': {
      display: 'flex',
      gap: '8px',
    },
    'body .rbc-time-view .rbc-header .rbc-button-link div:first-child': {
      fontWeight: 'bold',
    },
    'body .rbc-calendar': {
      color: theme.palette.primary.main,
      height: '775px',
    },
    'body .rbc-month-view': {
      border: 'none',
      borderBottom: `1px solid ${theme.palette.secondary.dark}`,
    },
    'body .rbc-month-row ': {
      borderRight: `1px solid ${theme.palette.secondary.dark}`,
      borderLeft: `1px solid ${theme.palette.secondary.dark}`,
    },
    'body .rbc-month-view .rbc-month-header .rbc-header': {
      border: 'none',
      borderBottom: `1px solid ${theme.palette.secondary.dark}`,
      height: theme.spacing(5),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    'body .rbc-show-more': {
      fontWeight: 'normal',
      color: colors.grey7,
      fontSize: '95%',
      paddingLeft: theme.spacing(1.6),
      display: 'flex',
      alignItems: 'center',
    },
    'body .rbc-day-slot .rbc-event': {
      border: 'none',
    },
    'body .rbc-row-segment .rbc-event-content': {
      whiteSpace: 'normal',
    },
    'body .rbc-month-row .rbc-event-content': {
      height: '40px',
    },

    ...getLayoutStyles(theme),
  },
  monthWeek: {
    marginTop: theme.spacing(0.25),
    marginLeft: theme.spacing(1),
    color: theme.palette.secondary.main,
  },
  monthDayToday: {
    marginTop: theme.spacing(1),
    border: `1px solid ${theme.palette.info.main}`,
    borderRadius: theme.shape.borderRadius,
    padding: `${theme.spacing(0.625)}px ${theme.spacing(1)}px`,
    marginBottom: theme.spacing(0.625),
  },
  monthDay: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(0.625),
    marginBottom: theme.spacing(0.625),
  },
  headerWeekDay: {
    color: theme.palette.secondary.main,
  },
  headerWeekDayToday: {
    border: `1px solid ${theme.palette.info.main}`,
    padding: `${theme.spacing(0.625)}px ${theme.spacing(1)}px`,
    borderRadius: theme.shape.borderRadius,
  },
}));
const getCommonEventStyles = (theme: Theme, color: string) => {
  return {
    borderRadius: `${theme.spacing(0.25)}px`,
    boxShadow: `0px 1px 2px rgba(38, 50, 56, 0.24)`,
    backgroundColor: colors.white,
    color,
    borderLeft: `${theme.spacing(0.25)}px solid ${color}`,
    marginBottom: '2px',
  };
};
// we have to apply styles instead of classname for events, otherwise we have to put important everywhere for styles
export const getEventStyles = (theme: Theme) => {
  return {
    maintenance: {
      ...getCommonEventStyles(theme, colors.yellow1),
    },
    reservation: {
      ...getCommonEventStyles(theme, colors.blue2),
    },
    notVisible: {
      ...getCommonEventStyles(theme, colors.grey5),
      backgroundColor: colors.grey13,
    },
    completed: {
      ...getCommonEventStyles(theme, colors.green1),
    },
  };
};
