import {DialogBase} from '@valmet-iop/ui-common';
import React from 'react';
import {useTranslation} from 'react-i18next';

interface Props {
  isOpen: boolean;
  onResult: (confirmed: boolean) => void;
}

export const DeleteConfirmDialog = (props: Props) => {
  const {t} = useTranslation();

  const onClose = () => {
    props.onResult(false);
  };
  const onConfirm = () => {
    props.onResult(true);
  };

  return (
    <DialogBase
      headerText={t('forms.deleteConfirmDialog.header')}
      isOpen={props.isOpen}
      onClose={onClose}
      buttons={[
        {
          text: t('forms.deleteConfirmDialog.cancel'),
          type: 'default',
          onClick: onClose,
        },
        {
          text: t('forms.deleteConfirmDialog.apply'),
          type: 'ghost',
          onClick: onConfirm,
        },
      ]}
    />
  );
};

export interface DialogButton {
  text: string;
  type: 'default' | 'ghost' | 'primary' | 'text';
  onClick: () => void;
}

interface IConfirmDialog extends Props {
  text: string;
  buttons: ({
    onClose,
    onConfirm,
  }: {
    onClose: () => void;
    onConfirm: () => void;
  }) => DialogButton[];
}
export const ConfirmDialog = (props: IConfirmDialog) => {
  const {t} = useTranslation();

  const onClose = () => {
    props.onResult(false);
  };
  const onConfirm = () => {
    props.onResult(true);
  };

  return (
    <DialogBase
      headerText={t(props.text)}
      isOpen={props.isOpen}
      onClose={onClose}
      buttons={props.buttons({onClose, onConfirm})}
    />
  );
};

export const DialogDefaultYesNoButtons = ({
  onClose,
  onConfirm,
}: {
  onClose: () => void;
  onConfirm: () => void;
}): DialogButton[] => {
  const {t} = useTranslation();
  return [
    {
      text: t('forms.confirmDialog.no'),
      type: 'default',
      onClick: onClose,
    },
    {
      text: t('forms.confirmDialog.yes'),
      type: 'ghost',
      onClick: onConfirm,
    },
  ];
};
