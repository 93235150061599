import {LoadingScreen} from '@valmet-iop/ui-common';
import {
  Nullable,
  TPermissionAliasTypes,
  usePermission,
} from 'context/permission-context';
import * as React from 'react';
import {QueryStatus} from 'react-query';
import {ICreatePath, IMainPath, IReadPath, IUpdatePath} from 'utils/router';
import {ItemProvider, Modes} from '../context/item-context';
import {RedirectProvider} from '../context/redirect-context';

export interface IPage {
  list: React.ReactNode;
  edit?: React.ReactNode;
  read?: React.ReactNode;
  mode?: Modes;
  createPath: ICreatePath;
  readPath: IReadPath;
  updatePath: IUpdatePath;
  mainPath: IMainPath;
  permissionAlias?: Nullable<TPermissionAliasTypes[] | TPermissionAliasTypes>;
}

export function Page({
  list,
  read,
  edit,
  mode,
  createPath,
  readPath,
  mainPath,
  updatePath,
  permissionAlias,
}: IPage) {
  const {setPagePermissionAlias} = usePermission();
  React.useEffect(() => {
    permissionAlias && setPagePermissionAlias(permissionAlias);
    return () => {
      setPagePermissionAlias(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <RedirectProvider
      createPath={createPath}
      readPath={readPath}
      updatePath={updatePath}
      mainPath={mainPath}
    >
      <>{list}</>
      <ItemProvider mode={mode}>
        {(mode === Modes.CREATE || mode === Modes.UPDATE) && <>{edit}</>}
        {mode === Modes.READ && <>{read}</>}
      </ItemProvider>
    </RedirectProvider>
  );
}

export function SeparatedPage({
  list,
  read,
  edit,
  mode,
  createPath,
  readPath,
  mainPath,
  updatePath,
  permissionAlias,
}: IPage) {
  const {setPagePermissionAlias} = usePermission();
  React.useEffect(() => {
    permissionAlias && setPagePermissionAlias(permissionAlias);
    return () => {
      setPagePermissionAlias(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <RedirectProvider
      createPath={createPath}
      readPath={readPath}
      updatePath={updatePath}
      mainPath={mainPath}
    >
      <ItemProvider mode={mode}>
        {!mode && <>{list}</>}
        {(mode === Modes.CREATE || mode === Modes.UPDATE) && <>{edit}</>}
        {mode === Modes.READ && <>{read}</>}
      </ItemProvider>
    </RedirectProvider>
  );
}
interface ILayoutStatus {
  children?: React.ReactNode;
  status: QueryStatus;
  onErrorRender?: () => React.ReactNode;
}
// export function LayoutStatus({status, children, onErrorRender}: ILayoutStatus) {
//   return (
//     <>
//       {status === 'loading' ? (
//         <LoadingScreen />
//       ) : status === 'success' || status === 'idle' ? (
//         children
//       ) : (
//         <>{onErrorRender ? onErrorRender() : <h1>Error</h1>}</>
//       )}
//     </>
//   );
// }

export function LayoutStatus({status, children, onErrorRender}: ILayoutStatus) {
  return (
    <>
      {status === 'loading' && <LoadingScreen />}

      <div hidden={!(status === 'success' || status === 'idle')}>
        {children}
      </div>

      {status === 'error' && (
        <>{onErrorRender ? onErrorRender() : <h1>Error</h1>} </>
      )}
    </>
  );
}
