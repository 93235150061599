import React from 'react';
import {QueryClient, QueryClientProvider} from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: (failedCount, error) => {
        if ((error as any)?.name === 'acquire_token_failed') {
          // Token acquire failures should not be retried,
          // it isn't likely to be a transient fault
          return false;
        }

        const status = (error as any)?.response?.status;
        if (status === 403 || status === 404) {
          // If the API says the request is not allowed for this user, don't retry
          // Also if the entity was not found/entities are not available, don't retry
          return false;
        }

        return failedCount < 3;
      },
    },
  },
});

export default (WrappedComponent: any) => {
  const ReactQueryClientHoc = (props: any) => {
    return (
      <QueryClientProvider client={queryClient}>
        <WrappedComponent {...props} />
      </QueryClientProvider>
    );
  };

  return ReactQueryClientHoc;
};
