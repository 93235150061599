import React from 'react';
import {Card, CardContent, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

const AccessDenied = () => {
  const {t} = useTranslation();

  return (
    <Card>
      <CardContent>
        <Typography variant="h2" gutterBottom>
          {t('accessDenied.header')}
        </Typography>
        <Typography gutterBottom>{t('accessDenied.body')}</Typography>
      </CardContent>
    </Card>
  );
};

export default AccessDenied;
