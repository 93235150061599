import {
  GetSiteParametersOutputDTO,
  GetCompanyParametersOutputDTO,
} from '../api/generated/iop';

export const KnownSiteParameterIds = {
  TruckAssetFieldEnabled: 'Reservations.Fields.TruckAsset.Enabled',
  OrderNoFieldGenerated: 'Reservations.Fields.OrderNumber.Generated',
};

export const KnownCompanyParameterIds = {
  ReferenceOrderNumberFieldEnabled:
    'Reservations.Fields.ReferenceOrderNumber.Enabled',
};

export interface SiteParameters {
  values: Record<string, ParameterValue>;
}

export interface CompanyParameters {
  values: Record<string, ParameterValue>;
}

export type ParameterValue = boolean | number | string;

type TypedParameterOutputDTO =
  | {dataType: 'Toggle'; value: 'true' | 'false'}
  | {dataType: 'Numeric'; value: string}
  | {dataType: 'String'; value: string};

function getParameterValue(dto: {
  dataType: string;
  value: string;
}): ParameterValue {
  if (dto.dataType === 'Toggle') {
    return dto.value === 'true';
  }

  if (dto.dataType === 'Numeric') {
    return Number(dto.value);
  }

  if (dto.dataType === 'String') {
    return dto.value;
  }

  throw new Error(`Parameter with type ${dto.dataType} could not be parsed`);
}

export function getSiteParametersFromOutput(
  dto: GetSiteParametersOutputDTO,
): SiteParameters {
  const result: SiteParameters = {values: {}};

  const paramIds = Object.keys(dto.parameters);
  for (const paramId of paramIds) {
    const param = dto.parameters[paramId] as TypedParameterOutputDTO;
    const value = getParameterValue(param);
    result.values[paramId] = value;
  }

  return result;
}

export function getCompanyParametersFromOutput(
  dto: GetCompanyParametersOutputDTO,
): CompanyParameters {
  const result: CompanyParameters = {values: {}};

  const paramIds = Object.keys(dto.parameters);
  for (const paramId of paramIds) {
    const param = dto.parameters[paramId] as TypedParameterOutputDTO;
    const value = getParameterValue(param);
    result.values[paramId] = value;
  }

  return result;
}

export function getBooleanValueFromParams(
  parameters: SiteParameters | CompanyParameters,
  paramId: string,
  defaultValue: boolean,
): boolean {
  const parameter = parameters.values[paramId];
  if (parameter === undefined) {
    return defaultValue;
  }

  if (typeof parameter === 'boolean') {
    return parameter;
  }

  throw new Error(`Parameter ${paramId} is not a boolean`);
}
