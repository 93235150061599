import {useLocation} from 'react-router-dom';
import {useItem} from 'components/Common/Entity/context/item-context';
import {
  useReservationsSites,
  useReservationsLoadingPlaces,
} from 'components/Hooks/queries/useReservations';
import React from 'react';
import {Node} from 'react-checkbox-tree';
import {useTranslation} from 'react-i18next';
import {mergedStatus} from '../hooks/useItemData';
import {generateSchedulingPath} from '../routes';
import {usePageView} from './pageView-context';

const ROOT_NODE_ID = 'ROOT_NODE_ID';

interface IContext {
  data: Node;
  expanded: string[];
  checked: string[];
  checkedEquipmentsSites: string[];
  checkedEquipments: string[];
  setChecked: (checked: any) => void;
  setExpanded: (expanded: any) => void;
}

export function useTree(): IContext {
  const context = React.useContext<IContext>(TreeContext);
  if (!context) {
    throw new Error(`useTree must be used within a TreeProvider`);
  }
  return context;
}

const TreeContext = React.createContext<IContext>({
  data: {label: '', value: ''},
  expanded: [],
  checked: [],
  setChecked: () => {},
  setExpanded: () => {},
  checkedEquipmentsSites: [],
  checkedEquipments: [],
});

interface IProvider {
  children: React.ReactNode;
}

export function TreeProvider(props: IProvider) {
  const {t} = useTranslation();

  const location = useLocation();
  const isEnabled = location.pathname.includes(generateSchedulingPath());

  const {status: reservationsSitesStatus, data: reservationsSites} =
    useReservationsSites({enabled: isEnabled});
  const {status: equipmentsStatus, data: equipments} =
    useReservationsLoadingPlaces({enabled: isEnabled});
  const {isSelectedPageViewCalendar, isSelectedPageViewTimeline} =
    usePageView();
  const {isDefault} = useItem();

  const [checked, setChecked] = React.useState<string[]>([]);
  const [expanded, setExpanded] = React.useState<string[]>([]);
  const queriesStatus = mergedStatus([
    reservationsSitesStatus,
    equipmentsStatus,
  ]);
  React.useEffect(() => {
    if (queriesStatus === 'success') {
      setExpanded(
        reservationsSites?.reduce(
          (acc, curr) => {
            return [...acc, curr.siteId ?? ''];
          },
          [ROOT_NODE_ID],
        ) || [],
      );
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queriesStatus]);
  React.useEffect(() => {
    if (
      queriesStatus === 'success' &&
      (isDefault || !checked.length) &&
      !isSelectedPageViewCalendar
    ) {
      setChecked(
        equipments?.map(({equipmentId}) => equipmentId as string) || [],
      );
    } else if (queriesStatus === 'success' && isSelectedPageViewCalendar) {
      const siteId = equipments?.[0]?.siteId as string;
      const checkedEquipmentIds =
        (
          equipments?.filter(equipment => {
            return equipment.siteId === siteId;
          }) || []
        ).map(({equipmentId}) => equipmentId as string) || [];

      setChecked(checkedEquipmentIds);
    } else if (queriesStatus === 'success' && isSelectedPageViewTimeline) {
      setChecked(
        equipments?.map(({equipmentId}) => equipmentId as string) || [],
      );
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queriesStatus, isSelectedPageViewCalendar, isSelectedPageViewTimeline]);
  const value = {
    checkedEquipmentsSites: (() => {
      if (!equipments) {
        return [];
      }
      return equipments.reduce((acc: string[], {equipmentId, siteId}) => {
        if (
          !acc.includes(siteId ?? '') &&
          checked.some(id => equipmentId === id)
        ) {
          return [...acc, siteId ?? ''];
        }
        return acc;
      }, []);
    })(),
    checkedEquipments: (() => {
      if (!equipments) {
        return [];
      }
      return equipments.reduce((acc: string[], {equipmentId}) => {
        if (
          !acc.includes(equipmentId ?? '') &&
          checked.some(id => equipmentId === id)
        ) {
          return [...acc, equipmentId ?? ''];
        }
        return acc;
      }, []);
    })(),
    checked,
    setChecked,
    data: reservationsSites?.reduce(
      (acc, curr) => {
        const labelSite = t('scheduling:headers.siteBays', {
          siteName: curr.displayName,
        });
        const valueSite = curr.siteId ?? '';

        const childrenSite = equipments
          ?.filter(({siteId}) => {
            return curr.siteId === siteId;
          })
          .map(({displayName, equipmentId}) => {
            return {
              value: equipmentId ?? '',
              label: displayName ?? '',
            };
          });
        return {
          ...acc,
          children: [
            ...(acc.children ?? []),
            {
              value: valueSite,
              label: labelSite,
              children: childrenSite,
            },
          ],
        };
      },
      {
        value: ROOT_NODE_ID,
        label: t('scheduling:headers.allSites'),
      } as Node,
    ) as Node,
    expanded,
    setExpanded,
  };

  return <TreeContext.Provider value={value} {...props} />;
}
