import {Redirect} from 'react-router-dom';
import React from 'react';
import {useSelector} from 'react-redux';
import {IState} from '@valmet-iop/ui-common';

const AadCallback = () => {
  // Wait for user name to become available
  const userName = useSelector<IState, string>(s => s.user.userName);
  if (!userName) {
    return null;
  }

  const localUrl = window.sessionStorage.getItem('local-url') || '/';
  window.sessionStorage.removeItem('local-url');
  return <Redirect to={localUrl} />;
};

export default AadCallback;
