import * as React from 'react';
import Timeline, {
  CustomMarker,
  DateHeader,
  SidebarHeader,
  TimelineHeaders,
  TimelineMarkers,
} from 'react-calendar-timeline';
// make sure you include the timeline stylesheet or the timeline will not be styled
import 'react-calendar-timeline/lib/Timeline.css';
import {
  addHours,
  isToday,
  isSameDay,
  isBefore,
  format,
  addMinutes,
  isTomorrow,
  isSameHour,
} from 'date-fns';
import {Box, Typography, useTheme} from '@material-ui/core';

import {Toolbar} from '../CalendarView/Parts';
import {
  IItem,
  SLOT_ID,
  useCalendar,
  useTimeline,
} from 'components/Pages/Scheduling/context/calendar-context';
import {useRedirect} from 'components/Common/Entity/context/redirect-context';
import {getTimelineEventStyles, useTimeLineStyles} from './styles';
import {useCalendarStyles} from '../CalendarView/styles';
import {useTranslation} from 'react-i18next';
import {extractTime} from 'components/Pages/Scheduling/timeUtils';
import {LayoutView} from '../utils';
import {
  useCalendarTimelineReservations,
  isReservationNotVisible,
  TimelineGroupType,
  useTimelineGroups,
} from 'components/Pages/Scheduling/hooks/useCalendarTimelineReservations';
import {LoadingScreen} from '@valmet-iop/ui-common';

export function TimeView() {
  const {
    setSelectedView,
    selectedView,
    screenDate,
    setScreenDate,
    isSelectedViewDay,
    isSelectedViewMonth,
    setSelectedSlot: setSelectedSlotCalendar,
  } = useCalendar();
  const {
    defaultTimeRange: {start, end},
    moveToToday,
    moveToNext,
    moveToPrev,
    maxZoom,
    minZoom,
    zoomInToDayView,
    generateSlotByTime,
    isOverlapped,
    overlapped,
    selectedSlot,
    setSelectedSlot,
  } = useTimeline();

  useTimeLineStyles();
  const {data: groups} = useTimelineGroups();
  const {normalizedFormatTimeline: items, status} =
    useCalendarTimelineReservations(screenDate);
  const theme = useTheme();
  const {t} = useTranslation();
  const {toRead, toCreate} = useRedirect();
  return (
    // For some reason the layoutStatusView causes some problems with the initial load of the component. So it was removed and it's functionality was moved to the component.
    <>
      {status === 'loading' ? (
        <LoadingScreen />
      ) : (
        (status === 'success' || status === 'idle') && (
          <LayoutView>
            {!!groups.length ? (
              <>
                <Toolbar
                  onNavigate={(navigation: any) => {
                    if (navigation === 'TODAY') {
                      moveToToday();
                    } else if (navigation === 'NEXT') {
                      moveToNext();
                    } else {
                      moveToPrev();
                    }
                  }}
                  onView={(view: any) => {
                    setSelectedView(view);
                  }}
                  date={addHours(start, 1)}
                  view={selectedView}
                />
                <Timeline
                  groups={groups}
                  items={selectedSlot ? [...items, selectedSlot] : items}
                  defaultTimeStart={start}
                  defaultTimeEnd={end}
                  maxZoom={maxZoom}
                  minZoom={minZoom}
                  onItemSelect={itemId => {
                    if (
                      isReservationNotVisible({reservationId: itemId as string})
                    ) {
                      return;
                    }
                    if (itemId !== SLOT_ID) {
                      setSelectedSlot(null);
                      toRead(itemId.toString());
                    } else {
                      toCreate();
                    }
                  }}
                  groupRenderer={({group}) => {
                    return (
                      <div
                        style={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        }}
                        title={group.title}
                      >
                        {group.type === TimelineGroupType.Bay ? (
                          <span style={{paddingLeft: '10px'}}>
                            {group.title}
                          </span>
                        ) : (
                          <span style={{fontWeight: 'bold'}}>
                            {group.title}
                          </span>
                        )}
                      </div>
                    );
                  }}
                  itemRenderer={({item, getItemProps}) => {
                    const style = getTimelineEventStyles(item, theme) || {};
                    return (
                      <div
                        {...getItemProps({style})}
                        title={
                          item.id !== SLOT_ID
                            ? `${extractTime(item.start_time)} - ${extractTime(
                                item.end_time,
                              )} : ${item.title as string}`
                            : undefined
                        }
                      >
                        {item.title as string}
                      </div>
                    );
                  }}
                  onCanvasClick={(groupId, time, e) => {
                    const isPastClicked = isBefore(new Date(time), new Date());
                    if (isPastClicked) {
                      setSelectedSlot(null);
                      setSelectedSlotCalendar(new Date());
                      return;
                    }
                    let slot = generateSlotByTime({time, groupId});

                    if (
                      isSameDay(new Date(), new Date(time)) ||
                      isOverlapped(items, slot)
                    ) {
                      if (!isSelectedViewDay) {
                        setSelectedSlot(null);
                        setSelectedSlotCalendar(slot.start_time);
                        setScreenDate(slot.start_time);
                        zoomInToDayView(slot);
                        return;
                      }
                    }

                    if (!isOverlapped(items, slot)) {
                      if (isSelectedViewDay) {
                        if (isSameHour(new Date(), new Date(time))) {
                          slot = generateSlotByTime({
                            time: addMinutes(new Date(), 5).getTime(),
                            groupId,
                            startTime: addMinutes(new Date(), 5),
                            endTime: addHours(new Date(), 1),
                          });
                        }

                        const overlappedDayAfter = overlapped(items, slot);
                        if (overlappedDayAfter.length) {
                          const latest =
                            overlappedDayAfter[overlappedDayAfter.length - 1];
                          if (isBefore(slot.start_time, latest.start_time)) {
                            return;
                          } else {
                            slot = generateSlotByTime({
                              time,
                              groupId,
                              startTime: latest.end_time,
                              endTime: addHours(new Date(latest.end_time), 1),
                            });

                            const tomorrowDays = overlapped(
                              items,
                              slot,
                              false,
                              (ev: IItem) =>
                                isTomorrow(new Date(ev.start_time)),
                            );
                            if (tomorrowDays.length) {
                              return;
                            }
                          }
                        }
                      }
                      setSelectedSlotCalendar(slot.start_time);
                      setSelectedSlot(slot);
                    }
                  }}
                  visibleTimeStart={start.getTime()}
                  visibleTimeEnd={end.getTime()}
                >
                  <TimelineHeaders style={{background: 'white'}}>
                    <SidebarHeader>
                      {({getRootProps}) => {
                        const {style} = getRootProps();
                        const weekStart = `W${format(
                          addMinutes(start, 5),
                          'I',
                        )}`;
                        const range = isSelectedViewMonth
                          ? `${weekStart} - W${format(end, 'I')}`
                          : weekStart;

                        return (
                          <Box
                            display="flex"
                            justifyContent="flex-start"
                            alignItems="center"
                            pl={`${theme.spacing(1)}px`}
                            style={{
                              ...style,
                              background: theme.palette.primary.contrastText,
                              borderTop: `1px solid ${theme.palette.secondary.dark}`,
                              borderLeft: `1px solid ${theme.palette.secondary.dark}`,
                            }}
                          >
                            <b>{range}</b>
                          </Box>
                        );
                      }}
                    </SidebarHeader>
                    <StyledTimeHeader />
                  </TimelineHeaders>
                  <TimelineMarkers>
                    <StyledMarker />
                  </TimelineMarkers>
                </Timeline>
              </>
            ) : (
              <Typography variant="h2">
                {t('scheduling:headers.noSelectedLoadingPlaces')}
              </Typography>
            )}
          </LayoutView>
        )
      )}
    </>
  );
}

const CurrentDay = ({children}: {children: React.ReactNode}) => {
  const classes = useCalendarStyles();
  return <Box className={classes.headerWeekDayToday}>{children}</Box>;
};

function StyledMarker() {
  const theme = useTheme();
  return (
    <CustomMarker date={new Date().getTime() / 1000}>
      {({styles}) => (
        <Box
          style={{
            ...styles,
            background: theme.palette.error.main,
            width: '1px',
            zIndex: 1000,
          }}
        ></Box>
      )}
    </CustomMarker>
  );
}

function StyledTimeHeader() {
  const theme = useTheme();
  const {isSelectedViewWeek, isSelectedViewDay, isSelectedViewMonth} =
    useCalendar();

  let labelFormat = isSelectedViewDay ? 'HH' : 'ddd';
  if (isSelectedViewMonth) {
    labelFormat = 'DD';
  }
  return (
    <DateHeader
      height={50}
      labelFormat={labelFormat}
      intervalRenderer={({getIntervalProps, intervalContext}: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
        const {style, ...other} = getIntervalProps();

        const dayNum = format(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          new Date(intervalContext.interval.startTime),
          'd',
        );

        return (
          <Box
            {...other}
            {...style}
            bg={theme.palette.primary.contrastText}
            borderRight={`1px solid ${theme.palette.secondary.dark}`}
            height="100%"
            display="flex"
            justifyContent={isSelectedViewWeek ? 'space-between' : 'center'}
            alignItems="center"
            p={1}
          >
            {isSelectedViewMonth ? (
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              isToday(new Date(intervalContext.interval.startTime)) ? (
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                <b>{intervalContext.intervalText}</b>
              ) : (
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                <span>{intervalContext.intervalText}</span>
              )
            ) : (
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              <b>{intervalContext.intervalText}</b>
            )}
            {isSelectedViewWeek && (
              <Box color={theme.palette.secondary.main}>
                {/*eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                {isToday(new Date(intervalContext.interval.startTime)) &&
                isSelectedViewWeek ? (
                  <CurrentDay>{dayNum}</CurrentDay>
                ) : (
                  <>{dayNum}</>
                )}
              </Box>
            )}
          </Box>
        );
      }}
    />
  );
}
