import * as Yup from 'yup';
import i18n from 'i18n';
import {differenceInMinutes, isBefore, isEqual} from 'date-fns';
import {ReservationType} from 'api/generated/iop';

Yup.addMethod(Yup.string, 'integer', function () {
  const that = this as any;
  return that.matches(/^-?\d+$/, 'fieldErrors.digitsOnly');
});
Yup.addMethod(Yup.string, 'numeric', function () {
  const that = this as any;
  return that.matches(/^-?\d+\.?\d*$/, 'fieldErrors.numericOnly');
});
const MAX_AMOUNT = 100000;
const MIN_AMOUNT = 0;
const MAX_LENGTH_TITLE = 200;
const MAX_LENGTH_COMMENTS = 500;
const MAX_LENGTH_REFERENCEORDERNUMBER = 128;
const MIN_ORDER = 0;
const MAX_ORDER = 9999999;

const commonSchema = {
  loadingTimeStart: Yup.date()
    .min(new Date(), 'fieldErrors.noPast')
    .required('fieldErrors.required')
    .nullable()
    .typeError('fieldErrors.invalidDate'),
  loadingTimeEnd: Yup.date()
    .required('fieldErrors.required')
    .nullable()
    .typeError('fieldErrors.invalidDate')
    .test('moreThanStart', i18n.t('fieldErrors.beforeStart'), function (value) {
      const {loadingTimeStart} = this.parent;
      if (!loadingTimeStart || !value) {
        return true;
      }
      return !isBefore(value, loadingTimeStart as Date);
    })
    .test(
      'nullIntervalLength',
      i18n.t('fieldErrors.nullIntervalLength'),
      function (value) {
        const {loadingTimeStart} = this.parent;
        if (!loadingTimeStart || !value) {
          return true;
        }
        return !isEqual(value, loadingTimeStart as Date);
      },
    ),
  site: Yup.object()
    .noUnknown(undefined, 'fieldErrors.required')
    .nullable()
    .required('fieldErrors.required'),
  loadingPlace: Yup.object()
    .noUnknown(undefined, 'fieldErrors.required')
    .nullable()
    .required('fieldErrors.required'),
  comments: Yup.string()
    .max(
      MAX_LENGTH_COMMENTS,
      i18n.t('fieldErrors.maxLength', {length: MAX_LENGTH_COMMENTS}),
    )
    .notRequired(),
};

const commonReservationSchema = {
  customer: Yup.object()
    .noUnknown(undefined, 'fieldErrors.required')
    .nullable()
    .required('fieldErrors.required'),
  logisticOperator: Yup.object()
    .noUnknown(undefined, 'fieldErrors.required')
    .nullable()
    .required('fieldErrors.required'),
  product: Yup.object()
    .noUnknown(undefined, 'fieldErrors.required')
    .nullable()
    .required('fieldErrors.required'),
  type: Yup.object()
    .noUnknown(undefined, 'fieldErrors.required')
    .nullable()
    .required('fieldErrors.required'),
  orderNo: (Yup.string() as any)
    .required('fieldErrors.required')
    .integer()
    .test(
      'maxOrder',
      i18n.t('fieldErrors.minMax', {min: MIN_ORDER, max: MAX_ORDER}),
      (val: string) => +val <= MAX_ORDER && +val >= 0,
    ),
  destination: Yup.object()
    .noUnknown(undefined, 'fieldErrors.required')
    .nullable()
    .required('fieldErrors.required'),
  amount: (Yup.string() as any)
    .required('fieldErrors.required')
    .numeric()
    .test(
      'requiredSelect',
      i18n.t('fieldErrors.minMax', {min: MIN_AMOUNT, max: MAX_AMOUNT}),
      (val: string) => +val <= MAX_AMOUNT && +val >= 0,
    )
    .transform((_: any, value: string) => {
      if (!value) {
        return `${value}`;
      }
      if (`${value}`.includes('.')) {
        return `${value}`;
      }

      return `${value}`.replace(/,/, '.');
    }),
  truckAsset: Yup.object().optional().nullable(),
  referenceOrderNumber: Yup.string().optional().nullable(),
};

function loadingTimeEnd() {
  return {
    loadingTimeEnd: Yup.date()
      .required('fieldErrors.required')
      .nullable()
      .typeError('fieldErrors.invalidDate')
      .test(
        'moreThanStart',
        i18n.t('fieldErrors.beforeStart'),
        function (value) {
          const {loadingTimeStart} = this.parent;
          if (!loadingTimeStart || !value) {
            return true;
          }
          return !isBefore(value, loadingTimeStart as Date);
        },
      )
      .test(
        'nullIntervalLength',
        i18n.t('fieldErrors.nullIntervalLength'),
        function (value) {
          const {loadingTimeStart} = this.parent;
          if (!loadingTimeStart || !value) {
            return true;
          }
          return !isEqual(value, loadingTimeStart as Date);
        },
      )
      .test(
        'tooShortInterval',
        // This error message will never be shown.
        // The actual error message is created in the function below.
        'Reservation duration is too short',
        function (value) {
          const from = (this as any).from;
          if (!from || from.length === 0 || !from[0]?.value) {
            return true;
          }

          // We get the current values from the form
          // as well as the lookup objects for minimum duration.
          // We then try to get the minimum duration if it is available
          // and validate the current value.

          const {
            loadingTimeStart,
            type,
            site,
            loadingPlace,
            siteReservationDurationLookup,
            equipmentReservationDurationLookup,
          } = from[0].value;

          if (!loadingTimeStart || !value || type === null) {
            return true;
          }

          const typeValue = type.value as ReservationType;
          if (
            typeValue !== ReservationType.ReservationLoading &&
            typeValue !== ReservationType.ReservationUnloading
          ) {
            return true;
          }

          let minimumReservationDurationMinutes: number | null = null;

          if (loadingPlace !== null) {
            if (typeValue === ReservationType.ReservationLoading) {
              minimumReservationDurationMinutes =
                equipmentReservationDurationLookup[loadingPlace.value]
                  ?.minimumLoadingMinutes ?? null;
            } else if (typeValue === ReservationType.ReservationUnloading) {
              minimumReservationDurationMinutes =
                equipmentReservationDurationLookup[loadingPlace.value]
                  ?.minimumUnloadingMinutes ?? null;
            }
          }

          if (minimumReservationDurationMinutes === null && site !== null) {
            if (typeValue === ReservationType.ReservationLoading) {
              minimumReservationDurationMinutes =
                siteReservationDurationLookup[site.value]
                  ?.minimumLoadingMinutes ?? null;
            } else if (typeValue === ReservationType.ReservationUnloading) {
              minimumReservationDurationMinutes =
                siteReservationDurationLookup[site.value]
                  ?.minimumUnloadingMinutes ?? null;
            }
          }

          if (minimumReservationDurationMinutes === null) {
            return true;
          }

          const isValid =
            differenceInMinutes(value, loadingTimeStart as Date) >=
            minimumReservationDurationMinutes;
          if (!isValid) {
            // We create the actual error message here since we didn't know the minimum duration before.
            return this.createError({
              message: i18n.t('fieldErrors.tooShortInterval', {
                length: minimumReservationDurationMinutes,
              }),
            });
          }

          return true;
        },
      ),
  };
}

export enum ReservationFormType {
  Create,
  FullUpdate,
}
export function getReservationSchema(options: {
  type: ReservationFormType;
  truckAssetFieldEnabled: boolean;
  referenceOrderNumberFieldEnabled: boolean;
  orderNoFieldGenerated: boolean;
}) {
  const objectShape = {
    ...commonReservationSchema,
    ...commonSchema,
    ...loadingTimeEnd(),
  };

  if (options.type === ReservationFormType.FullUpdate) {
    objectShape['loadingTimeStart'] = Yup.date().required(
      'fieldErrors.required',
    );
  }

  if (options.truckAssetFieldEnabled) {
    objectShape['truckAsset'] = Yup.object()
      .noUnknown(undefined, 'fieldErrors.required')
      .nullable()
      .required('fieldErrors.required');
  }

  if (options.referenceOrderNumberFieldEnabled) {
    objectShape['referenceOrderNumber'] = Yup.string()
      .trim()
      .max(
        MAX_LENGTH_REFERENCEORDERNUMBER,
        i18n.t('fieldErrors.maxLength', {
          length: MAX_LENGTH_REFERENCEORDERNUMBER,
        }),
      );
  }

  if (options.orderNoFieldGenerated) {
    objectShape['orderNo'] = (Yup.string() as any).notRequired();
  }

  return Yup.object().shape(objectShape);
}

export function getReservationSchemaOnPastRestricted() {
  const schema = {
    destination: Yup.object()
      .noUnknown(undefined, 'fieldErrors.required')
      .nullable()
      .required('fieldErrors.required'),
    loadingTimeStart: Yup.date()
      .required('fieldErrors.required')
      .nullable()
      .typeError('fieldErrors.invalidDate'),
  };

  return Yup.object().shape({
    ...schema,
    ...loadingTimeEnd(),
  });
}

const commonMaintenanceSchema = {
  displayName: Yup.string()
    .trim()
    .max(
      MAX_LENGTH_TITLE,
      i18n.t('fieldErrors.maxLength', {length: MAX_LENGTH_TITLE}),
    )
    .required('fieldErrors.required'),
};
export const validationMaintenanceSchema = Yup.object().shape({
  ...commonMaintenanceSchema,
  ...commonSchema,
});
export const validationMaintenanceInPastSchema = Yup.object().shape({
  ...commonMaintenanceSchema,
  ...commonSchema,
  loadingTimeStart: Yup.date().required('fieldErrors.required'),
});
