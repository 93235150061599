import {errorNotification, IState} from '@valmet-iop/ui-common';
import {UserInformationOutputDTO} from 'api/generated/iop';
import {iopApp} from 'api/iopApp';
import config from 'configs/appConfig';
import {useTranslation} from 'react-i18next';
import {useQuery, UseQueryOptions} from 'react-query';
import {shallowEqual, useSelector} from 'react-redux';
import {getIsAppInitialized} from 'redux/reducers';
import {optionsGet} from 'utils/query';

export function useUsersMe() {
  const {t} = useTranslation();
  const applicationReady = useSelector<IState, boolean>(
    getIsAppInitialized,
    shallowEqual,
  );
  return useQuery<UserInformationOutputDTO>(
    'usersMe',
    async () => {
      const {data} = await iopApp.usersMeApi.apiUsersMeGet(config.api.version);
      return data;
    },
    {
      ...optionsGet,
      enabled: applicationReady,
      onSuccess: ({permissions}) => {
        if (permissions?.some(({id}) => id === 'App.Administrate')) {
          return;
        }
        if (permissions?.some(({id}) => id?.startsWith('AssetTracking.'))) {
          return;
        }
        if (permissions?.some(({scopes}) => !scopes?.length)) {
          errorNotification(t('scheduling:notifications.scopesNotFound'));
        }
      },
    } as UseQueryOptions<UserInformationOutputDTO>,
  );
}
