import {createTheme, makeStyles, Theme} from '@material-ui/core';
import {colors} from '@valmet-iop/ui-common';

export const getLayoutStyles = (theme: Theme) => {
  return {
    'body .scheduling_layout .react-datepicker': {
      border: 'none',
    },
    'body .scheduling_layout .react-datepicker__current-month': {
      color: theme.palette.primary.main,
      fontWeight: 'normal',
    },
    'body .scheduling_layout .react-datepicker__day-name': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
    'body .scheduling_layout .react-datepicker__day-names': {
      marginTop: theme.spacing(2),
    },
    'body .scheduling_layout .react-datepicker__month': {
      marginTop: theme.spacing(2),
    },
    'body .scheduling_layout .react-datepicker__day--outside-month': {
      color: `${theme.palette.secondary.main} !important`,
    },
    'body .scheduling_layout .react-datepicker__day--today': {
      background: theme.palette.primary.contrastText,
      border: `1px solid ${theme.palette.info.main}`,
      borderRadius: theme.shape.borderRadius,
      '&:hover': {
        background: theme.palette.primary.contrastText,
      },
    },
    'body .scheduling_layout .react-datepicker__day--selected.react-datepicker__day--today':
      {
        background: theme.palette.info.main,
        border: `1px solid ${theme.palette.info.main}`,
        borderRadius: theme.shape.borderRadius,
        color: colors.white,
      },
    'body .scheduling_layout .react-datepicker__day.react-datepicker__day--selected':
      {
        color: colors.white,
        background: theme.palette.info.main,
      },
    'body .scheduling_layout .react-datepicker__day--keyboard-selected': {
      background: theme.palette.primary.contrastText,
    },
    'body .scheduling_layout .react-datepicker__day': {
      color: theme.palette.primary.main,
    },
    'body .scheduling_layout .react-datepicker__header': {
      background: 'transparent',
      borderColor: theme.palette.secondary.light,
    },
    'body .scheduling_layout .react-datepicker__navigation--previous, body .scheduling_layout .react-datepicker__navigation--next':
      {
        borderColor: 'transparent',
        '&:hover': {
          borderColor: 'transparent',
        },
        '&:focus': {
          outline: 0,
        },
      },
    'body .rct-node-icon': {
      padding: 0,
    },
    'body .react-checkbox-tree': {
      color: theme.palette.primary.main,
    },
    'body .rct-title': {
      fontSize: `${theme.typography.fontSize}px !important`,
    },
    'body .rct-node': {
      paddingTop: theme.spacing(2),
    },
  };
};

export function applyDatePickerArrowStyles() {
  const datePickerEl = document.querySelector(
    '.scheduling_layout .react-datepicker',
  ) as HTMLButtonElement;
  if (datePickerEl) {
    datePickerEl.style.position = 'relative';
    const arrowPrev = document.createElement('i');
    arrowPrev.className = 'icon-arrow-left';
    arrowPrev.style.position = 'absolute';
    arrowPrev.style.left = '14px';
    arrowPrev.style.top = '14px';
    datePickerEl.appendChild(arrowPrev);
    const arrowNext = document.createElement('i');
    arrowNext.className = 'icon-arrow-right';
    arrowNext.style.position = 'absolute';
    arrowNext.style.right = '14px';
    arrowNext.style.top = '14px';
    datePickerEl.appendChild(arrowNext);
  }
}

export const calendarTheme = (theme: Theme) => {
  return createTheme({
    ...theme,
    palette: {
      ...theme.palette,
      primary: {
        ...theme.palette.primary,
        main: colors.grey3,
      },
      secondary: {
        ...theme.palette.secondary,
        light: colors.grey15,
        dark: colors.grey12,
        main: colors.grey6,
      },
      info: {
        ...theme.palette.info,
        light: colors.blue4,
      },
      error: {
        ...theme.palette.error,
        main: colors.red2,
      },
    },
    shape: {
      borderRadius: 3,
    },
  });
};

export const useTreeStyles = makeStyles<Theme>(theme => ({
  root: {
    color: theme.palette.info.dark,
  },
}));
