import React from 'react';

import {List} from './components/List';
import {Page} from 'components/Common/Entity/PageLayout';
import {ItemEdit} from './components/ItemEdit';
import {ItemRead} from './components/ItemRead';
import {Modes} from 'components/Common/Entity/context/item-context';
import {
  generateSchedulingCreatePath,
  generateSchedulingEditPath,
  generateSchedulingPath,
  generateSchedulingViewPath,
} from './routes';
import {
  ReservationDeletePermissions,
  ReservationUpdatePermissions,
} from 'api/generated/iop';
interface IPage {
  mode?: Modes;
}

const Scheduling = ({mode}: IPage) => {
  return (
    <Page
      mode={mode}
      createPath={generateSchedulingCreatePath}
      readPath={generateSchedulingViewPath}
      updatePath={generateSchedulingEditPath}
      mainPath={generateSchedulingPath}
      list={<List />}
      edit={<ItemEdit />}
      read={<ItemRead />}
    />
  );
};
export function isReservationUpdatable(
  updatePermissions?: ReservationUpdatePermissions,
) {
  return (
    isReservationUpdatableAll(updatePermissions) ||
    isReservationUpdatableRestricted(updatePermissions)
  );
}
export function isReservationUpdatableRestricted(
  updatePermissions?: ReservationUpdatePermissions,
) {
  return (
    updatePermissions ===
    ReservationUpdatePermissions.ReservationUpdateRestrictedFields
  );
}

export function isReservationUpdatableAll(
  updatePermissions?: ReservationUpdatePermissions,
) {
  return (
    updatePermissions ===
    ReservationUpdatePermissions.ReservationUpdateAllFields
  );
}

export function isReservationDeletable(
  deletePermissions?: ReservationDeletePermissions,
) {
  return deletePermissions === ReservationDeletePermissions.ReservationDelete;
}

export function isMaintenanceUpdatable(
  updatePermissions?: ReservationUpdatePermissions,
) {
  return updatePermissions === ReservationUpdatePermissions.MaintenanceUpdate;
}

export function isMaintenanceDeletable(
  deletePermissions?: ReservationDeletePermissions,
) {
  return deletePermissions === ReservationDeletePermissions.MaintenanceDelete;
}

export default Scheduling;
