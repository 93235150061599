import {IOption} from '@valmet-iop/ui-common';
import {useEndpointMonitoringGetAllowedCompanies} from 'components/Hooks/queries/useEndpointMonitoring';
import React from 'react';
import {useLocation} from 'react-router-dom';
import {orderOptionsBy} from 'utils/form';

export interface IContext {
  isFetchingCompanyOptions: boolean;
  companyOptions: IOption[];
  selectedCompany: IOption | null;
  activeCompanyId: string | null;
  onSelectedCompanyChange: (option: IOption | null) => void;
}

const EndpointMonitoring = React.createContext<IContext>({
  isFetchingCompanyOptions: true,
  activeCompanyId: null,
  companyOptions: [],
  selectedCompany: null,
  onSelectedCompanyChange: () => {},
});

export function useEndpointMonitoringContext(): IContext {
  const context = React.useContext<IContext>(EndpointMonitoring);
  if (!context) {
    throw new Error(
      `useEndpointMonitoringContext must be used within a EndpointMonitoring`,
    );
  }
  return context;
}

interface IProvider {
  children: React.ReactNode;
}

export function EndpointMonitoringContextProvider(props: IProvider) {
  const {pathname} = useLocation();
  const isEndpointMonitoringPath = pathname.startsWith('/endpointMonitoring');
  const [selectedCompany, setSelectedCompany] = React.useState<IOption | null>(
    null,
  );
  const [activeCompanyId, setActiveCompanyId] = React.useState<string | null>(
    null,
  );
  const {companyOptions, isFetchingCompanyOptions, firstOption} =
    useCompanyOptions(isEndpointMonitoringPath);

  React.useEffect(() => {
    setSelectedCompany(firstOption);
    setActiveCompanyId(firstOption?.value || null);
  }, [firstOption]);

  const onSelectedCompanyChange = (option: IOption | null) => {
    setSelectedCompany(option);
    setActiveCompanyId(option?.value || null);
  };

  return (
    <EndpointMonitoring.Provider
      value={{
        activeCompanyId,
        companyOptions,
        isFetchingCompanyOptions,
        selectedCompany,
        onSelectedCompanyChange,
      }}
    >
      {props.children}
    </EndpointMonitoring.Provider>
  );
}

function useCompanyOptions(isEndpointMonitoringPath: boolean) {
  const enabled = isEndpointMonitoringPath;
  const {data: companies = [], isFetching: isFetchingCompanies} =
    useEndpointMonitoringGetAllowedCompanies(enabled);
  const companyOptions = React.useMemo(
    () =>
      orderOptionsBy(
        companies
          .filter(c => c.companyDisplayName && c.companyId)
          .map(c => ({
            text: c.companyDisplayName || '',
            value: c.companyId || '',
          })),
      ),
    [companies],
  );

  return {
    companyOptions,
    isFetchingCompanyOptions: isFetchingCompanies,
    firstOption: companyOptions.length > 0 ? companyOptions[0] : null,
  };
}
