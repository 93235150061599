import React, {useReducer, useEffect, useContext} from 'react';
import {Typography, makeStyles, Theme} from '@material-ui/core';
import {useLocation, Link} from 'react-router-dom';
import {ValmetIcon, IconNames, colors} from '@valmet-iop/ui-common';
import {unnest, filter, descend, prop, sortWith} from 'ramda';
import {useTranslation} from 'react-i18next';
import UserMenu from './UserMenu';
import {AppContext} from '.';
import {generateSchedulingPath} from 'components/Pages/Scheduling/routes';
import {generateAssetTrackingPath} from 'components/Pages/AssetTracking/routes';
import {
  generateEndpointMapPath,
  generateEndpointMonitoringPath,
} from 'components/Pages/EndpointMonitoring/routes';
import {usePermission} from 'context/permission-context';
import {UserInformationOutputDTO} from 'api/generated/iop';
import {Location} from 'history';
const {grey3, white, blue3, grey13} = colors;

interface OwnProps {
  isExpanded: boolean;
  isMobile: boolean;
  onExpandToggleButtonClick: () => void;
  data: Record<string, MenuItemDatum>;
  title: string;
  toggler?: React.ReactNode;
}

type Props = OwnProps;

const useStyles = makeStyles<Theme, Props>(theme => ({
  root: {
    maxWidth: props => (props.isExpanded ? '320px' : '60px'),
    minWidth: props => (props.isExpanded ? '230px' : '60px'),
  },
  collapsed: {
    backgroundColor: grey3,
    height: '100%',
  },
  expanded: {
    backgroundColor: grey3,
    height: '100%',
    display: 'grid',
    gridTemplateColumns: 'auto',
    gridTemplateRows: 'auto 1fr',
  },
  content: {
    overflowY: 'auto',
  },
  userMenu: {
    marginTop: theme.spacing(4),
    color: white,
    display: 'flex',
    justifyContent: 'center',
  },
}));

interface MenuItemDatum {
  translationKey: string;
  href?: string;
  icon?: IconNames;
  requiresAppAdmin?: boolean;
  children?: {
    [key: string]: MenuItemDatum;
  };
}

export const MenuData: Record<string, MenuItemDatum> = {};

export const MenuDataLogistics: Record<string, MenuItemDatum> = {
  scheduling: {
    translationKey: 'navigation.scheduling',
    icon: 'production-summary',
    href: generateSchedulingPath(),
  },
};

export const MenuDataAssetTracking: Record<string, MenuItemDatum> = {
  assetTracking: {
    translationKey: 'navigation.assetTrackingMap',
    icon: 'production-summary',
    href: generateAssetTrackingPath(),
  },
};

export const MenuDataEndpointMonitoring: Record<string, MenuItemDatum> = {
  endpointMonitoring: {
    translationKey: 'navigation.endpointList',
    icon: 'stacked-scales',
    href: generateEndpointMonitoringPath(),
  },
  endpointMap: {
    translationKey: 'navigation.endpointMap',
    icon: 'location',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
    href: generateEndpointMapPath(),
  },
};

export interface LocationState {
  from: {
    pathname: string;
  };
}
const getInitialMenuState = (
  location: Location<LocationState>,
  data: Record<string, MenuItemDatum>,
): MenuState => {
  const getMenuState = (
    itemKey: string,
    item: MenuItemDatum,
    parentKey?: string,
  ): MenuItemsState => {
    const key = !parentKey ? itemKey : `${parentKey}.${itemKey}`;
    const childState = unnest(
      item.children !== undefined
        ? Object.keys(item.children).map(childKey =>
            getMenuState(
              childKey,
              item.children !== undefined
                ? // eslint-disable-next-line security/detect-object-injection
                  item.children[childKey]
                : {translationKey: ''},
              key,
            ),
          )
        : [],
    );
    const isActive =
      location.pathname === item.href || childState.some(c => c.isActive);
    return [
      {
        key,
        href: item.href,
        isActive,
        isExpanded: isActive,
        isHidden: !!item.requiresAppAdmin,
        requiresAppAdmin: item.requiresAppAdmin,
      },
      ...childState,
    ];
  };

  const items = unnest(
    Object.keys(data).map(key => getMenuState(key, data[key])),
  );
  const activeItems = sortWith(
    [descend<MenuItemState>(prop('key'))],
    filter(x => x.isActive, items),
  );

  return {
    items,
    activeItemHref: activeItems.length > 0 ? activeItems[0].href : undefined,
  };
};

interface MenuItemState {
  key: string;
  href?: string;
  isActive: boolean;
  isExpanded: boolean;
  isHidden: boolean;
  requiresAppAdmin?: boolean;
}

type MenuItemsState = readonly MenuItemState[];

interface MenuState {
  items: MenuItemsState;
  activeItemHref?: string;
}

type MenuAction =
  | {
      type: 'EXPAND';
      itemKey: string;
    }
  | {
      type: 'COLLAPSE';
      itemKey: string;
    }
  | {
      type: 'ACTIVATE';
      itemHref: string;
    }
  | {
      type: 'SET_USER_INFO';
      user: UserInformationOutputDTO;
    };

const menuReducer = (state: MenuState, action: MenuAction): MenuState => {
  let itemToCollapse: MenuItemState | undefined;
  let itemToActivate: MenuItemState | undefined;
  switch (action.type) {
    case 'EXPAND':
      return {
        ...state,
        items: state.items.map(i => ({
          ...i,
          isExpanded: i.isExpanded || i.key === action.itemKey,
        })),
      };
    case 'COLLAPSE':
      itemToCollapse = state.items.find(i => i.key === action.itemKey);
      if (itemToCollapse === undefined) {
        return state;
      }

      return {
        ...state,
        items: state.items.map(i => ({
          ...i,
          isExpanded:
            i.isExpanded &&
            itemToCollapse !== undefined &&
            !i.key.startsWith(itemToCollapse.key),
        })),
      };
    case 'ACTIVATE':
      itemToActivate = state.items.find(i => i.href === action.itemHref);
      if (itemToActivate === undefined) {
        return {
          items: state.items.map(i => ({...i, isActive: false})),
          activeItemHref: undefined,
        };
      }

      return {
        items: state.items.map(i => {
          // We need to activate the item in question and all of its parents
          // We also expand all of them and make sure to keep existing expanded ones expanded as well
          const targetKeyIsPrefixOfThisItemKey =
            itemToActivate?.key.startsWith(i.key) ?? false;
          return {
            ...i,
            isActive: targetKeyIsPrefixOfThisItemKey,
            isExpanded: i.isExpanded || targetKeyIsPrefixOfThisItemKey,
          };
        }),
        activeItemHref: itemToActivate.href,
      };
    case 'SET_USER_INFO':
      return {
        ...state,
        items: state.items.map(i => {
          return {
            ...i,
            isHidden: !!i.requiresAppAdmin && action.user.userIsAdmin !== true,
          };
        }),
      };
  }
};

const SideNav = (props: Props) => {
  const classes = useStyles(props);
  const location = useLocation<LocationState>();
  const [menuState, menuDispatch] = useReducer(
    menuReducer,
    getInitialMenuState(location, props.data),
  );
  const {t} = useTranslation();
  const {getRoutes} = usePermission();

  const permissionPaths = [
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    ...Object.values(getRoutes()).reduce((acc, curr) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment
      return [...acc, ...curr];
    }, []),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
  ].map(item => item.path);
  useEffect(() => {
    if (menuState.activeItemHref !== location.pathname) {
      menuDispatch({
        type: 'ACTIVATE',
        itemHref: location.pathname,
      });
    }
  }, [menuState.activeItemHref, location.pathname, menuDispatch]);
  const appContext = useContext(AppContext);
  useEffect(() => {
    if (appContext.user !== null) {
      menuDispatch({
        type: 'SET_USER_INFO',
        user: appContext.user,
      });
    }
  }, [appContext.user, menuDispatch]);

  const userName = appContext.user?.userDisplayName ?? '';
  const getMenuItem = (
    itemKey: string,
    item: MenuItemDatum,
    level = 1,
    parentKey?: string,
  ): JSX.Element | null => {
    const key = !parentKey ? itemKey : `${parentKey}.${itemKey}`;
    const stateItem = menuState.items.find(x => x.key === key);
    if (!stateItem) {
      return null;
    }
    const isHidden = (() => {
      if (stateItem.isHidden) {
        return true;
      }
      if (item.children) {
        return !Object.keys(item.children).some(childKey => {
          const href = item.children?.[childKey].href;
          return href ? permissionPaths.includes(href) : false;
        });
      }
      return item.href ? !permissionPaths.includes(item.href) : false;
    })();
    if (isHidden) {
      return null;
    }
    return (
      <MenuItem
        key={key}
        text={t(item.translationKey)}
        active={stateItem.isActive}
        isExpanded={stateItem.isExpanded}
        level={level}
        toggleItemExpanded={() =>
          menuDispatch({
            type: stateItem.isExpanded ? 'COLLAPSE' : 'EXPAND',
            itemKey: key,
          })
        }
        toggleNavExpanded={props.onExpandToggleButtonClick}
        href={item.href}
        icon={item.icon}
        isHidden={isHidden}
        isMobile={props.isMobile}
      >
        {item.children !== undefined
          ? Object.keys(item.children).map(childKey =>
              getMenuItem(
                childKey,
                item.children !== undefined
                  ? item.children[childKey]
                  : {translationKey: ''},
                level + 1,
                key,
              ),
            )
          : undefined}
      </MenuItem>
    );
  };
  const sections = Object.keys(props.data).map(key =>
    getMenuItem(key, props.data[key]),
  );
  const isVisibleSection = !sections.every(section => section === null);

  return (
    <div className={classes.root}>
      {!props.isExpanded && (
        <div className={classes.collapsed}>{props.toggler}</div>
      )}
      {props.isExpanded && (
        <div className={classes.expanded}>
          {props.toggler}
          <div className={classes.content}>
            {isVisibleSection && <SideNavTitle text={props.title} />}
            {sections}
            {props.isMobile && isVisibleSection && (
              <div className={classes.userMenu}>
                <UserMenu userName={userName} clickableAreaHeight="46px" />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SideNav;

interface ExpandToggleButtonProps {
  isExpanded: boolean;
  onClick: () => void;
}

const useExpandToggleButtonStyles = makeStyles<Theme, ExpandToggleButtonProps>(
  () => ({
    root: {
      backgroundColor: props => (props.isExpanded ? '#1B1B1B' : grey3),
      height: '60px',
      overflowY: 'hidden',
    },
    button: {
      color: white,
      cursor: 'pointer',
      paddingTop: '22px',
      paddingLeft: '20px',
      paddingBottom: '17px',
    },
  }),
);

export const ExpandToggleButton = (props: ExpandToggleButtonProps) => {
  const classes = useExpandToggleButtonStyles(props);
  return (
    <div className={classes.root}>
      <div className={classes.button} onClick={props.onClick}>
        <ValmetIcon
          size="medium"
          icon={props.isExpanded ? 'arrow-left' : 'arrow-right'}
        />
      </div>
    </div>
  );
};

const useTitleStyles = makeStyles<Theme>(() => ({
  root: {
    paddingTop: '21px',
    paddingLeft: '20px',
    backgroundColor: '#282828',
    height: '60px',
  },
  text: {
    color: white,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontSize: '15px',
    lineHeight: '17px',
  },
}));

const SideNavTitle = ({text}: {text: string}) => {
  const classes = useTitleStyles();
  return (
    <div className={classes.root}>
      <Typography className={classes.text} variant="h3">
        {text}
      </Typography>
    </div>
  );
};

interface MenuItemProps {
  text: string;
  level: number;
  active: boolean;
  isExpanded: boolean;
  isMobile: boolean;
  isHidden: boolean;
  href?: string;
  icon?: IconNames;
  children?: React.ReactNode;
  toggleItemExpanded: () => void;
  toggleNavExpanded: () => void;
}

const useMenuItemStyles = makeStyles<Theme, MenuItemProps>(theme => ({
  root: {
    paddingLeft: props => theme.spacing(props.level),
    paddingRight: theme.spacing(1),
    backgroundColor: props => (props.active ? '#414142' : grey3),
    display: props => (props.isHidden ? 'none' : 'flex'),
    alignItems: 'center',
    height: '28px',
    cursor: 'pointer',
    textDecoration: 'none',
    color: props => (props.active ? blue3 : grey13),
    '&:hover': {
      color: props => (props.active ? blue3 : white),
    },
  },
  expandChevron: {
    color: '#DEDEDE',
    visibility: props =>
      props.children === undefined ||
      (Array.isArray(props.children) && props.children.length === 0)
        ? 'hidden'
        : 'visible',
    paddingRight: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  text: {
    color: props => (props.active ? blue3 : 'inherit'),
    flexGrow: 1,
    flexShrink: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const MenuItem = (props: MenuItemProps) => {
  const classes = useMenuItemStyles(props);

  const handleChevronClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    props.toggleItemExpanded();
    e.stopPropagation();
  };
  const handleItemClick = () => {
    props.toggleItemExpanded();
  };
  const handleLinkClick = () => {
    if (props.isMobile) {
      props.toggleNavExpanded();
    }
  };

  const getContainerChildren = () => {
    return (
      <>
        <span className={classes.expandChevron} onClick={handleChevronClick}>
          <ValmetIcon icon={props.isExpanded ? 'arrow-up' : 'arrow-down'} />
        </span>
        <span className={classes.icon}>
          <ValmetIcon icon={props.icon ?? 'menu'} />
        </span>
        <Typography variant="body1" className={classes.text}>
          {props.text}
        </Typography>
      </>
    );
  };

  return (
    <>
      {props.href && (
        <Link
          className={classes.root}
          to={props.href}
          onClick={handleLinkClick}
        >
          {getContainerChildren()}
        </Link>
      )}
      {!props.href && (
        <div className={classes.root} onClick={handleItemClick}>
          {getContainerChildren()}
        </div>
      )}
      {props.isExpanded && props.children}
    </>
  );
};
