import React from 'react';
import {Sort} from '@valmet-iop/ui-common';

interface IContext {
  pageNumber: number;
  pageSize: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
}
const PaginatorContext = React.createContext<IContext | null>(null);

export function usePaginator(): IContext {
  const context = React.useContext<IContext>(
    PaginatorContext as React.Context<IContext>,
  );
  if (!context) {
    throw new Error(`usePaginator must be used within a PaginatorProvider`);
  }
  return context;
}

interface IProvider {
  children: React.ReactNode;
}

export const initialSort: Sort = {
  columnIndex: 0,
  direction: 'ascending',
};

export const pageSizes = [10, 20, 30];
const PAGE_CHANGE = 'PAGE_CHANGE';
const PAGE_SIZE_CHANGE = 'PAGE_SIZE_CHANGE';
interface IPageChangeAction {
  type: typeof PAGE_CHANGE;
  payload: number;
}
interface IPageSizeChangeAction {
  type: typeof PAGE_SIZE_CHANGE;
  payload: number;
}
type TAction = IPageChangeAction | IPageSizeChangeAction;
interface IState {
  pageNumber: number;
  pageSize: number;
}
function reducer(state: IState, action: TAction) {
  switch (action.type) {
    case PAGE_CHANGE:
      return {...state, pageNumber: action.payload + 1};
    case PAGE_SIZE_CHANGE:
      return {pageSize: action.payload, pageNumber: 1};
    default:
      return state;
  }
}

export function PaginatorProvider(props: IProvider) {
  const [{pageNumber, pageSize}, dispatch] = React.useReducer(reducer, {
    pageNumber: 1,
    pageSize: 10,
  });
  const value = React.useMemo(() => {
    return {
      pageNumber,
      pageSize,
      onPageChange: (page: number) => {
        dispatch({type: PAGE_CHANGE, payload: page});
      },
      onPageSizeChange: (pageSize: number) => {
        dispatch({type: PAGE_SIZE_CHANGE, payload: pageSize});
      },
    };
  }, [pageNumber, pageSize]);
  return <PaginatorContext.Provider value={value} {...props} />;
}
