import {Redirect} from 'react-router';
import {
  getIsAppInitialized,
  getUserIsAuthenticated,
  getUserIsLoggingOut,
} from '../../redux/reducers';
import React, {FC, useContext} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {AppContext} from '../Layout';
import {IState, LoadingScreen} from '@valmet-iop/ui-common';
import UserNotFound from '../Pages/UserNotFound';
import AccessDenied from '../Pages/AccessDenied';
import {Modes} from './Entity/context/item-context';

/*
 * Used inside <Router>
 */
const PrivateRoute: FC<{comp: FC; mode?: Modes}> = ({
  comp: Comp,
  mode,

  ...rest
}) => {
  const isAuthenticated = useSelector<IState, boolean>(
    getUserIsAuthenticated,
    shallowEqual,
  );
  const userIsLoggingOut = useSelector<IState, boolean>(
    getUserIsLoggingOut,
    shallowEqual,
  );
  const applicationReady = useSelector<IState, boolean>(
    getIsAppInitialized,
    shallowEqual,
  );
  const {userInfoLoadStatus, userInfoLoadError} = useContext(AppContext);
  if (!applicationReady || userIsLoggingOut) {
    return <LoadingScreen />;
  }

  if (
    !isAuthenticated ||
    (userInfoLoadError as any)?.name === 'acquire_token_failed'
  ) {
    if (!window.location.pathname.startsWith('/login')) {
      window.sessionStorage.setItem('local-url', window.location.pathname);
    }

    return <Redirect to="/login" />;
  }

  if (
    userInfoLoadStatus === 'NOT_STARTED' ||
    userInfoLoadStatus === 'LOADING'
  ) {
    return <LoadingScreen />;
  }

  if (userInfoLoadStatus === 'LOAD_FAILED') {
    if ((userInfoLoadError as any)?.response?.status === 403) {
      // User access forbidden
      return <AccessDenied />;
    }

    // Other error
    return <UserNotFound />;
  }
  return <Comp {...rest} {...{mode}} />;
};

export default PrivateRoute;
