import axios, {AxiosInstance} from 'axios';
import config from 'configs/appConfig';
import {fetchServiceToken} from 'services/authService';
import {
  ReservationsApi,
  Configuration,
  UsersApi,
  FeatureFlagsApi,
  AssetTrackingApi,
  EndpointMonitoringApi,
} from './generated/iop';

const configuration = new Configuration({
  accessToken: fetchServiceToken,
});

class IopApp {
  private axiosInstance: AxiosInstance = axios.create();

  public setAxiosInstance(axiosInstance: AxiosInstance): void {
    this.axiosInstance = axiosInstance;
  }
  public reservationsApi = new ReservationsApi(
    configuration,
    config.api.url,
    this.axiosInstance,
  );
  public usersMeApi = new UsersApi(
    configuration,
    config.api.url,
    this.axiosInstance,
  );
  public featureFlagsApi = new FeatureFlagsApi(
    configuration,
    config.api.url,
    this.axiosInstance,
  );
  public assetTrackingApi = new AssetTrackingApi(
    configuration,
    config.api.url,
    this.axiosInstance,
  );
  public endpointMonitoringApi = new EndpointMonitoringApi(
    configuration,
    config.api.url,
    this.axiosInstance,
  );
}
/**
 * All api endpoints are accessed through iopApi singleton.
 * It allows setting the auth token and other possible headers in one place.
 */
export const iopApp = new IopApp();
