import {usePermission} from 'context/permission-context';
import React, {useContext} from 'react';
import {Redirect, useHistory, useLocation} from 'react-router-dom';
import {AppContext} from '../Layout';
import {generateAssetTrackingPath} from './AssetTracking/routes';
import {generateSchedulingPath} from './Scheduling/routes';
import {generateEndpointMonitoringPath} from './EndpointMonitoring/routes';

enum IndexRedirectTarget {
  Scheduling,
  AssetTracking,
  EndpointMonitoring,
}

const Home = () => {
  // We can also render a loading screen here while we wait to find that out
  const appContext = useContext(AppContext);
  const {pathname} = useLocation();
  const history = useHistory();
  React.useEffect(() => {
    const isFromReachRouterDefault = pathname !== '/';
    if (isFromReachRouterDefault) {
      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  const redirectTarget = useRedirectTarget();

  if (appContext.user === null) {
    return null;
  }

  return (
    <>
      {redirectTarget === IndexRedirectTarget.Scheduling && (
        <Redirect to={generateSchedulingPath()} />
      )}
      {redirectTarget === IndexRedirectTarget.AssetTracking && (
        <Redirect to={generateAssetTrackingPath()} />
      )}
      {redirectTarget === IndexRedirectTarget.EndpointMonitoring && (
        <Redirect to={generateEndpointMonitoringPath()} />
      )}
    </>
  );
};

export default Home;

const useRedirectTarget = (): IndexRedirectTarget | null => {
  const {getRoutes} = usePermission();
  const routes = getRoutes();
  const permissionPaths = routes.endpointMonitoringRoutes
    .concat(routes.schedulingRoutes)
    .map(route => route.path);
  if (permissionPaths.includes(generateSchedulingPath())) {
    return IndexRedirectTarget.Scheduling;
  } else if (permissionPaths.includes(generateAssetTrackingPath())) {
    return IndexRedirectTarget.AssetTracking;
  } else if (permissionPaths.includes(generateEndpointMonitoringPath())) {
    return IndexRedirectTarget.EndpointMonitoring;
  }
  return null;
};
