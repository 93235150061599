import React from 'react';

export enum Modes {
  CREATE = 'CREATE',
  READ = 'READ',
  UPDATE = 'UPDATE',
  COPY = 'COPY',
  HISTORY = 'HISTORY',
}

interface IContext {
  mode?: Modes;
  isDefault: boolean;
  isUpdate: boolean;
  isCreate: boolean;
  isRead: boolean;
  toggle: boolean;
  setToggle: (is: boolean) => void;
}
export function useItem(): IContext {
  const context = React.useContext<IContext>(ItemContext);
  if (!context) {
    throw new Error(`useItem must be used within a EntityItemProvider`);
  }
  return context;
}

const ItemContext = React.createContext<IContext>({
  toggle: false,
  setToggle: (is: boolean) => {},
  isDefault: false,
  isUpdate: false,
  isCreate: false,
  isRead: false,
});

interface IProvider {
  mode?: Modes;
  children: React.ReactNode;
}

export function ItemProvider(props: IProvider) {
  const {mode, ...other} = props;

  const toggleByDefault = !!mode;
  const [toggle, setToggle] = React.useState(toggleByDefault);

  const value = {
    toggle,
    setToggle,
    mode,
    isDefault: mode === undefined,
    isUpdate: mode === Modes.UPDATE,
    isCreate: mode === Modes.CREATE,
    isRead: mode === Modes.READ,
  };
  return <ItemContext.Provider value={value} {...other} />;
}
