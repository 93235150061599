import {Nullable} from 'context/permission-context';
import React from 'react';
import {useLocation} from 'react-router';
import {generateSchedulingPath} from '../routes';

interface IContext {
  isOpened: boolean;
  setIsOpened: (is: boolean) => void;
  mountedTime: Nullable<Date>;
  setMountedTime: (time: Date | null) => void;
}

export function useBanner(): IContext {
  const context = React.useContext<IContext>(BannerContext);
  if (!context) {
    throw new Error(`useBanner must be used within a BannerProvider`);
  }
  return context;
}

const BannerContext = React.createContext<IContext>({
  isOpened: false,
  setIsOpened: () => {},
  mountedTime: null,
  setMountedTime: (time: Date | null) => {},
});

interface IProvider {
  children: React.ReactNode;
}

export function BannerProvider(props: IProvider) {
  const [isOpened, setIsOpened] = React.useState<boolean>(false);
  const [mountedTime, setMountedTime] = React.useState<Date | null>(null);
  const {pathname} = useLocation();
  React.useEffect(() => {
    if (!pathname.includes(generateSchedulingPath())) {
      setIsOpened(false);
      setMountedTime(null);
    }
  }, [pathname]);
  const value = {
    isOpened,
    setIsOpened,
    mountedTime,
    setMountedTime,
  };

  return <BannerContext.Provider value={value} {...props} />;
}
