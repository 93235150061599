import * as React from 'react';

import DatePicker from 'react-datepicker';
import {getIn, useField, useFormikContext} from 'formik';
import {ValmetIcon, ValmetTextInput} from '@valmet-iop/ui-common';
import {makeStyles, Theme} from '@material-ui/core';
import {enUS} from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css';
import {endOfDay, startOfDay} from 'date-fns';

export interface IDateInputProps {
  formProperty: string;
  labelTranslationKey?: string;
  isRequired?: boolean;
  width?: 'default' | 'medium' | 'small' | 'inline';
  minDate?: Date;
  maxDate?: Date;
  minTime?: Date;
  maxTime?: Date;
  onChange?: (val: Date | null) => void;
  noMinDateLimit?: boolean;
  noMaxDateLimit?: boolean;
}

interface IInput extends IDateInputProps {
  onClick?: () => void;
  value?: string;
}
const useStyles = makeStyles<Theme>(theme => ({
  root: {
    position: 'relative',
    '@global': {
      input: {
        paddingRight: `${theme.spacing(4)}px !important`,
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        '&:focus': {
          paddingRight: `${theme.spacing(4)}px !important`,
        },
      },
    },
  },
  icon: {
    position: 'absolute',
    right: '11px',
    bottom: '4px',
  },
  inlineWrapper: {
    width: '155px',
  },
}));

const Input = React.forwardRef<HTMLDivElement, IInput>((props, ref) => {
  const {onClick, labelTranslationKey, formProperty, value, isRequired, width} =
    props;
  const {touched, errors, setFieldTouched} = useFormikContext<any>();
  const fieldTouched = getIn(touched, formProperty) as boolean | undefined;
  const fieldError = getIn(errors, formProperty) as string | undefined;
  const classes = useStyles(props);
  const onBlur = () => {
    setFieldTouched(formProperty);
  };
  const defaultProps = {
    labelTranslationKey: labelTranslationKey || '',
    id: formProperty,
    value,
    errorMessageTranslationKey: fieldTouched ? fieldError : undefined,
    isRequired: isRequired,
    onChange: () => {
      // we don't need it datepicker cares about it
    },
    onBlur,
  };
  return (
    <div onClick={onClick} ref={ref} className={classes.root}>
      {width === 'inline' ? (
        <div className={classes.inlineWrapper}>
          <ValmetTextInput {...defaultProps} />
        </div>
      ) : (
        <ValmetTextInput width={width} {...defaultProps} />
      )}
      {!defaultProps.errorMessageTranslationKey && (
        <div className={classes.icon}>
          <ValmetIcon icon="production-summary" size="medium" />
        </div>
      )}
    </div>
  );
});

export const DatePickerInput = (props: IDateInputProps) => {
  const {
    labelTranslationKey,
    formProperty,
    isRequired,
    width,
    minDate,
    maxDate,
    onChange,
    minTime,
    maxTime,
    noMinDateLimit,
    noMaxDateLimit,
  } = props;
  const {setFieldValue} = useFormikContext();
  const [field] = useField(formProperty);

  const innerMaxTime =
    noMaxDateLimit || !maxTime ? endOfDay(new Date()) : maxTime;
  const innerMinTime =
    noMinDateLimit || !minTime ? startOfDay(new Date()) : minTime;

  return (
    <DatePicker
      {...field}
      {...props}
      locale={{
        ...enUS,
        options: {
          weekStartsOn: 1,
        },
      }}
      customInput={
        <Input
          labelTranslationKey={labelTranslationKey}
          formProperty={formProperty}
          isRequired={isRequired}
          width={width}
        />
      }
      showTimeSelect
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      selected={(field.value && new Date(field.value)) || new Date()}
      onChange={val => {
        setFieldValue(field.name, val);
        onChange && onChange(val as Date);
      }}
      dateFormat="dd.MM.y HH:mm"
      timeFormat="HH:mm"
      minTime={innerMinTime}
      maxTime={innerMaxTime}
      minDate={noMinDateLimit ? undefined : minDate}
      maxDate={noMaxDateLimit ? undefined : maxDate}
      popperModifiers={{
        offset: {
          enabled: true,
        },
        preventOverflow: {
          enabled: true,
          escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
          boundariesElement: 'viewport',
        },
      }}
    />
  );
};
