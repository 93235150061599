const host = window.location.host;
let env: string | undefined;
switch (host) {
  case 'localhost:3000':
    env = 'local';
    break;
  case 'fnc-sap-euw-dv-iop-app-ui.azurewebsites.net':
    env = 'dev';
    break;
  case 'dev.integratedoperations.on.valmetsystem.com':
    env = 'dev';
    break;
  case 'fnc-sap-euw-qa-iop-app-ui.azurewebsites.net':
    env = 'test';
    break;
  case 'test.integratedoperations.on.valmetsystem.com':
    env = 'test';
    break;
  case 'fnc-sad-euw-dm-iop-app-ui.azurewebsites.net':
    env = 'demo';
    break;
  case 'demo.integratedoperations.on.valmetsystem.com':
    env = 'demo';
    break;
  case 'fnc-sad-euw-id-iop-app-ui.azurewebsites.net':
    env = 'internaldemo';
    break;
  case 'internaldemo.integratedoperations.on.valmetsystem.com':
    env = 'internaldemo';
    break;
  case 'fnc-sap-euw-pd-iop-app-ui.azurewebsites.net':
    env = 'prod';
    break;
  case 'integratedoperations.on.valmetsystem.com':
    env = 'prod';
    break;
  case 'fnc-sap-euw-st-iop-app-ui.azurewebsites.net':
    env = 'staging';
    break;
  case 'staging.integratedoperations.on.valmetsystem.com':
    env = 'staging';
    break;
}

const config = {
  api: {
    url: '',
    version: '1.0',
    clientId: '',
  },
  msal: {
    instance: 'https://login.microsoftonline.com/',
    tenant: '',
    clientId: '',
    postLogoutRedirectUri: window.location.origin,
    cacheLocation: 'localStorage',
    apiScopes: 'APP.Access',
    redirectUri: window.location.origin + '/aad-callback',
  },
  azureMaps: {
    clientId: '',
  },
};

if (env === 'local') {
  config.api.url = 'http://localhost:10586';
  config.api.clientId = '605795d6-efab-473a-abe1-a4033d97db65';
  config.msal.tenant = 'valmetsolutionsdv.onmicrosoft.com';
  config.msal.clientId = 'f1c43303-d5e0-4291-97ff-fb38243697bd';
  config.azureMaps.clientId = 'd9b0ace3-9081-42ca-986b-1659582b67f5';
} else if (env === 'dev') {
  config.api.url = 'https://wes-sap-euw-dv-iop-app-backend.azurewebsites.net';
  config.api.clientId = '605795d6-efab-473a-abe1-a4033d97db65';
  config.msal.tenant = 'valmetsolutionsdv.onmicrosoft.com';
  config.msal.clientId = 'f1c43303-d5e0-4291-97ff-fb38243697bd';
  config.azureMaps.clientId = 'd9b0ace3-9081-42ca-986b-1659582b67f5';
} else if (env === 'test') {
  config.api.url = 'https://wes-sap-euw-qa-iop-app-backend.azurewebsites.net';
  config.api.clientId = '605795d6-efab-473a-abe1-a4033d97db65';
  config.msal.tenant = 'valmetsolutionsdv.onmicrosoft.com';
  config.msal.clientId = 'f1c43303-d5e0-4291-97ff-fb38243697bd';
  config.azureMaps.clientId = '1bd28305-23c4-4990-80c0-6f733c21b51b';
} else if (env === 'demo') {
  config.api.url = 'https://wes-sad-euw-dm-iop-app-backend.azurewebsites.net';
  config.api.clientId = '605795d6-efab-473a-abe1-a4033d97db65';
  config.msal.tenant = 'valmetsolutionsdv.onmicrosoft.com';
  config.msal.clientId = 'f1c43303-d5e0-4291-97ff-fb38243697bd';
  config.azureMaps.clientId = '2e74a2e4-b82d-4ee5-87f8-09d1e1695f3f';
} else if (env === 'internaldemo') {
  config.api.url = 'https://wes-sad-euw-id-iop-app-backend.azurewebsites.net';
  config.api.clientId = '605795d6-efab-473a-abe1-a4033d97db65';
  config.msal.tenant = 'valmetsolutionsdv.onmicrosoft.com';
  config.msal.clientId = 'f1c43303-d5e0-4291-97ff-fb38243697bd';
  config.azureMaps.clientId = 'fc105df3-a95a-4dba-ba09-f1c3bb72af3f';
} else if (env === 'prod') {
  config.api.url = 'https://wes-sap-euw-pd-iop-app-backend.azurewebsites.net';
  config.api.clientId = '85061a37-4c5f-4b0a-b56e-1d8b3c055058';
  config.msal.tenant = 'valmetsolutions.onmicrosoft.com';
  config.msal.clientId = 'dfbf99b0-2f57-440f-a39f-dbe39d07adc4';
  config.azureMaps.clientId = 'fc8b4622-f073-4a50-b526-945076f1a7ec';
} else if (env === 'staging') {
  config.api.url = 'https://wes-sap-euw-st-iop-app-backend.azurewebsites.net';
  config.api.clientId = '00b38838-ab9e-4d49-926a-8b5626eba644';
  config.msal.tenant = 'valmetsolutions.onmicrosoft.com';
  config.msal.clientId = '724f56c8-703a-476f-8b23-2988c9e4f6ed';
  config.azureMaps.clientId = 'aa24a365-e67e-4aa2-8ae5-ce5a541f6063';
} else {
  throw new Error(`Unknown environment: ${env || ''}`);
}

export default config;
