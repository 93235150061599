import React from 'react';

export enum EPageView {
  PageViewList,
  PageViewCalendar,
  PageViewTimeline,
}

interface IContext {
  setPageView: (pageView: EPageView) => void;
  pageView: EPageView;
  isSelectedPageViewList: boolean;
  isSelectedPageViewCalendar: boolean;
  isSelectedPageViewTimeline: boolean;
}

export function usePageView(): IContext {
  const context = React.useContext<IContext>(CalendarContext);
  if (!context) {
    throw new Error(`usePageView must be used within a PageViewProvider`);
  }
  return context;
}

const CalendarContext = React.createContext<IContext>({
  setPageView: () => null,
  pageView: EPageView.PageViewList,
  isSelectedPageViewList: true,
  isSelectedPageViewCalendar: false,
  isSelectedPageViewTimeline: false,
});

interface IProvider {
  children: React.ReactNode;
}
export function PageViewProvider(props: IProvider) {
  const [pageView, setPageView] = React.useState<EPageView>(
    EPageView.PageViewList,
  );

  const value = React.useMemo(() => {
    return {
      setPageView,
      pageView,
      isSelectedPageViewList: pageView === EPageView.PageViewList,
      isSelectedPageViewCalendar: pageView === EPageView.PageViewCalendar,
      isSelectedPageViewTimeline: pageView === EPageView.PageViewTimeline,
    };
  }, [setPageView, pageView]);

  return <CalendarContext.Provider value={value} {...props} />;
}
