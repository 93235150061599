import * as React from 'react';
import {ICreatePath, IMainPath, IReadPath, IUpdatePath} from 'utils/router';
import queryString from 'query-string';
import {SyntheticEvent} from 'react';
import {useHistory} from 'react-router-dom';

interface IContext {
  toDefault: () => void;
  toCreate: (qs?: Qs) => void;
  toRead: (id: string, qs?: Qs) => void;
  toUpdate: (id: string, qs?: Qs) => void;
}

const RedirectContext = React.createContext<IContext>({
  toDefault: () => {},
  toCreate: (qs?: Qs) => {},
  toRead: (id: string, qs?: Qs) => {},
  toUpdate: (id: string, qs?: Qs) => {},
});

export function useRedirect(): IContext {
  const context = React.useContext<IContext>(RedirectContext);
  if (!context) {
    throw new Error(
      `useEntityRedirect must be used within a EntityRedirectProvider`,
    );
  }
  return context;
}

export interface IPath {
  mainPath: IMainPath;
  readPath: IReadPath;
  updatePath: IUpdatePath;
  createPath: ICreatePath;
}

interface IProvider extends IPath {
  children: React.ReactNode;
}

export interface Qs {
  [key: string]: string;
}
/**
 * sometimes in our components we use
 * toCreate/toRead/toUpdate... like a prop value
 * without any function wrapper, for example
 * <Component onClick={toCreate}/> ,it means that we
 * send evnt stuff from click for toCreate function,
 * but it is waiting for string and not an event object,
 * to continue to use without wrapper we need to filter query string param from any
 * value except string
 */
function defineQs(qs?: Qs | SyntheticEvent): string {
  if (qs) {
    if (qs.nativeEvent && qs.nativeEvent instanceof Event) {
      return '';
    }
    return `?${queryString.stringify(qs as Qs)}`;
  }
  return '';
}

export const RedirectProvider = (props: IProvider): JSX.Element => {
  const {
    mainPath,
    readPath,
    createPath,
    updatePath,

    children,
  } = props;
  const history = useHistory();
  const navigate = (path: string) => {
    history.push(path);
  };
  const value = {
    toDefault: () => {
      navigate(mainPath());
    },
    toCreate: (qs?: Qs) => {
      navigate(`${createPath()}${defineQs(qs)}`);
    },
    toRead: (id: string, qs?: Qs) => {
      navigate(`${readPath(id)}${defineQs(qs)}`);
    },
    toUpdate: (id: string, qs?: Qs) => {
      navigate(`${updatePath(id)}${defineQs(qs)}`);
    },
  };
  return (
    <RedirectContext.Provider value={value}>
      {children}
    </RedirectContext.Provider>
  );
};
