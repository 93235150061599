import React from 'react';
import {useLocation} from 'react-router-dom';

interface IContext {
  visibleColumns: boolean[];
  setVisibleColumns: (visibleColumns: boolean[]) => void;
}
const ValmetTableSettingsContext = React.createContext<IContext | null>(null);

export function useValmetTableSettings(): IContext {
  const context = React.useContext<IContext>(
    ValmetTableSettingsContext as React.Context<IContext>,
  );
  if (!context) {
    throw new Error(
      `useValmetTabelSettings must be used within a ValmetTableSettingsProvider`,
    );
  }
  return context;
}

interface IProvider {
  children: React.ReactNode;
}
function grabRootPageNameFromPathName(pathname: string): string {
  // get next slash position without root slash
  const slashPosition = pathname.indexOf('/', 1);
  // we have only root slash
  const isRoot = slashPosition === -1;
  if (isRoot) {
    // `/some-root-name`
    return pathname;
  }
  // `/some-root-name/sub-name.../..`
  return pathname.slice(0, slashPosition);
}

// TODO rm me when you will use Table with react-table
export function ValmetTableSettingsProvider(props: IProvider) {
  const {pathname} = useLocation();
  const [visibleColumns, setVisibleColumns] = React.useState<boolean[]>([]);
  const [rootPageName, setRootPageName] = React.useState<string | null>(null);

  React.useEffect(() => {
    setRootPageName(grabRootPageNameFromPathName(pathname));
  }, [pathname]);

  React.useEffect(() => {
    // we want to reset visible columns state between root pages(for example /some-rootA -->/some-rootB)
    // but we don;t want to do the reset between root pages and sub roots(for example /some-rootA --> /some-rootA/sub-nameA and etc..)
    setVisibleColumns([]);
  }, [rootPageName]);

  const value = {
    visibleColumns,
    setVisibleColumns,
  };

  return (
    <ValmetTableSettingsContext.Provider value={value}>
      {props.children}
    </ValmetTableSettingsContext.Provider>
  );
}
