import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import resources from './translations';
import {addResources} from 'components/Pages/i18n';

void i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'en-US',
  lng: 'en-US',
  ns: ['translation'],
  defaultNS: 'translation',
  preload: ['en-US'],
  debug: false,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: true,
  },
});

// Add pages translations
addResources(i18n);

export default i18n;
