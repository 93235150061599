import {
  addMinutes,
  differenceInMinutes,
  format,
  isBefore,
  isPast,
  isSameDay,
  isToday,
  roundToNearestMinutes,
} from 'date-fns';
import {GetUserAccessibleSiteOutputDTO} from 'api/generated/iop';

const SLOT_INTERVAL_MINUTES = 30;
export const DEFAULT_RESERVATION_LENGTH_MINUTES = 120;

/**
 * Get the closest point in time which is
 * not in past, rounded to nearest
 * SLOT_INTERVAL_MINUTES minutes.
 * @param start Intended start point
 */
export function getNearestPossibleStartTime(start: Date) {
  const nearestToStart = roundToNearestMinutes(start, {
    nearestTo: SLOT_INTERVAL_MINUTES,
  });
  if (isPast(nearestToStart)) {
    const nearestToNow = roundToNearestMinutes(new Date(), {
      nearestTo: SLOT_INTERVAL_MINUTES,
    });
    if (isPast(nearestToNow)) {
      // Rounding to closest 30 minutes from current time can end up
      // in the past, e.g. when between 3 PM and 3:14 PM.
      // This will result in 3 PM which is in the past at that
      // point and cannot be used as a start time.
      // So we add 30 minutes to move it to the next increment.
      return addMinutes(nearestToNow, SLOT_INTERVAL_MINUTES);
    }

    return nearestToNow;
  }

  return nearestToStart;
}

export function ensureAboveMinDate(
  date: Date,
  minDate: Date | null | undefined,
) {
  // const updatedValue = ensureAboveMinDate(val as Date, minDateForDatePicker);
  if (minDate === null || minDate === undefined) {
    return date;
  }

  if (isBefore(date, minDate)) {
    // Set date is less than minimum, get closest possible instead
    return getNearestPossibleStartTime(date);
  }

  // Set date is above or equal to minDate so is fine
  return date;
}

/**
 * Get the minimum time value for the
 * start date/time picker.
 * @param start Current start value
 * @returns Date where the time component
 * is set to the minimum or undefined.
 */
export function getMinTimeForStart(start: Date): Date | undefined {
  // If start is in past -> undefined
  // If start is today -> round to nearest 30 minutes, ensure not in past
  // Otherwise is undefined?
  if (isToday(start)) {
    const now = new Date();
    return getNearestPossibleStartTime(now);
  }
  return undefined;
}

/**
 * Get the minimum time value for the
 * end date/time picker.
 * @param start Current start value
 * @param end Current end value
 * @returns Date where the time component
 * is set to the minimum or undefined.
 */
export function getMinTimeForEnd(start: Date, end: Date): Date | undefined {
  if (!isSameDay(start, end)) {
    // The end is set on another day, no need to limit the time
    return undefined;
  }

  const minStart = getMinTimeForStart(start);
  // If not today => minStart === undefined
  return minStart ? addMinutes(minStart, SLOT_INTERVAL_MINUTES) : undefined;
}

/**
 * Get the initial value for the reservation
 * start date/time picker based on a selected
 * slot in the calendar/timeline.
 * @param slotStart The start time of the selected slot
 */
export function getInitialStartForNewReservationFromSelectedCalendarSlot(
  slotStart: Date,
) {
  return getNearestPossibleStartTime(slotStart);
}

/**
 * Get the default end value from a start
 * value.
 * @param start Start date/time
 */
export function getDefaultEndFromStart(start: Date): Date {
  return addMinutes(start, DEFAULT_RESERVATION_LENGTH_MINUTES);
}

export function isStampLessOrEqualDate({
  stamp,
  date,
}: {
  stamp: string;
  date: Date;
}) {
  return differenceInMinutes(new Date(stamp), new Date(date)) <= 0;
}

export function isStampMoreOrEqualDate({
  stamp,
  date,
}: {
  stamp: string;
  date: Date;
}) {
  return differenceInMinutes(new Date(stamp), new Date(date)) >= 0;
}

export function extractTime(date: Date) {
  return format(date, 'HH:mm');
}
