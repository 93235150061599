import 'react-toastify/dist/ReactToastify.css';

import {Theme} from '@material-ui/core/styles';
import {makeStyles} from '@material-ui/styles';
import {ReactQueryDevtools} from 'react-query/devtools';
import {
  getIsAppInitialized,
  getUserIsAuthenticated,
} from '../../redux/reducers';
import React, {useState, useContext, useEffect} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {IState, colors, ToastContainer} from '@valmet-iop/ui-common';
import TopHeader from './TopHeader';
import SideNav, {
  ExpandToggleButton,
  MenuData,
  MenuDataAssetTracking,
  MenuDataLogistics,
  MenuDataEndpointMonitoring,
} from './SideNav';
import {Hidden, useMediaQuery} from '@material-ui/core';
import {Progress} from 'components/Common/Entity/Progress';
import {useTranslation} from 'react-i18next';
import {CalendarProvider} from 'components/Pages/Scheduling/context/calendar-context';
import {TreeProvider} from 'components/Pages/Scheduling/context/tree-context';
import {FormProvider} from 'components/Pages/Scheduling/context/form-context';
import {PaginatorProvider} from 'components/Common/Entity/context/paginator-context';
import {PermissionProvider} from 'context/permission-context';
import {RoutesPermission} from 'components/Pages/Permission';
import {UserInformationOutputDTO} from 'api/generated/iop';
import {useUsersMe} from 'components/Hooks/queries/useUsersMe';
import {BrowserRouter as Router} from 'react-router-dom';
import {PageViewProvider} from 'components/Pages/Scheduling/context/pageView-context';
import {EndpointMonitoringContextProvider} from 'components/Pages/EndpointMonitoring/context/endpointMonitoringContext';
const {grey3} = colors;
const useStyles = makeStyles<Theme, {}>(theme => ({
  '@global': {
    body: {
      fontFamily: theme.typography.body1.fontFamily,
      fontSize: theme.typography.body1.fontSize,
      lineHeight: theme.typography.body1.lineHeight,
    },
  },
  sideNavContentContainer: {
    display: 'flex',
    height: 'calc(100dvh - 70px)',
  },
  contentContainer: {
    position: 'relative',
    flexGrow: 1,
    flexShrink: 1,
    overflowY: 'auto',
    maxHeight: 'calc(100dvh - 70px)',
    '& > .MuiCard-root > .MuiCardContent-root': {
      padding: theme.spacing(8, 4, 4, 4),
    },
  },
}));

interface AppContextModel {
  adminOrganizationId: string;
  user: UserInformationOutputDTO | null;
  userInfoLoadStatus: 'NOT_STARTED' | 'LOADING' | 'LOADED' | 'LOAD_FAILED';
  userInfoLoadError: unknown;
  refreshUserInfo: () => void;
}

export const AppContext = React.createContext<AppContextModel>({
  adminOrganizationId: '7fd0d737-057e-4f98-b2df-f3a0bfe071e1',
  user: null,
  userInfoLoadStatus: 'NOT_STARTED',
  userInfoLoadError: undefined,
  refreshUserInfo: () => undefined,
});
const Layout = (props: {}) => {
  const classes = useStyles(props);
  const applicationReady = useSelector<IState, boolean>(
    getIsAppInitialized,
    shallowEqual,
  );

  const {
    data: userInfo = {
      organizationId: '',
      organizationName: '',
      userIsAdmin: false,
      userDisplayName: '',
      userEmail: '',
      userId: '',
    },
    refetch: getUserInfo,
    isSuccess: isUserInfoAvailable,
    isLoading: isUserInfoLoading,
    isError: isUserInfoError,
    error: userInfoLoadError,
  } = useUsersMe();

  // On mobile the side nav is hidden by default, on desktop visible by default
  // We track these separately and use a media query hook to decide which one to use and update
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );
  const [mobileNavExpanded, setMobileNavExpanded] = useState(false);
  const [desktopNavExpanded, setDesktopNavExpanded] = useState(true);
  const onSideNavExpandToggle = () => {
    if (isMobile) {
      setMobileNavExpanded(!mobileNavExpanded);
    } else {
      window.dispatchEvent(new Event('sideNavResize'));
      setDesktopNavExpanded(!desktopNavExpanded);
    }
  };
  const sideNavExpanded = isMobile ? mobileNavExpanded : desktopNavExpanded;

  useEffect(() => {
    document.title = 'Valmet IOP';
  }, []);

  return (
    <AppContext.Provider
      value={{
        adminOrganizationId: '7fd0d737-057e-4f98-b2df-f3a0bfe071e1',
        user: isUserInfoAvailable ? userInfo : null,
        userInfoLoadStatus: !!userInfo.organizationId
          ? 'LOADED'
          : isUserInfoLoading
          ? 'LOADING'
          : isUserInfoError
          ? 'LOAD_FAILED'
          : 'NOT_STARTED',
        userInfoLoadError,
        refreshUserInfo: getUserInfo,
      }}
    >
      <ToastContainer />

      <TopHeader onMenuButtonClick={onSideNavExpandToggle} />
      <PermissionProvider>
        <div className={classes.sideNavContentContainer}>
          <Router>
            <SideNavContainer
              isExpanded={sideNavExpanded}
              onSideNavExpandToggle={onSideNavExpandToggle}
            />
            {applicationReady && (
              <React.Suspense fallback={<Progress />}>
                <PageViewProvider>
                  <CalendarProvider>
                    <TreeProvider>
                      <FormProvider>
                        <PaginatorProvider>
                          <EndpointMonitoringContextProvider>
                            <RoutesPermission
                              className={classes.contentContainer}
                            />
                          </EndpointMonitoringContextProvider>
                        </PaginatorProvider>
                      </FormProvider>
                    </TreeProvider>
                  </CalendarProvider>
                </PageViewProvider>
              </React.Suspense>
            )}
          </Router>
        </div>
        <ReactQueryDevtools initialIsOpen={false} />
      </PermissionProvider>
    </AppContext.Provider>
  );
};

export default Layout;

const useSideNavContainerStyles = makeStyles<Theme, {}>(() => ({
  desktopSideNav: {
    height: '100%',
    background: grey3,
  },
  mobileSideNav: {
    background: grey3,
    position: 'absolute',
    zIndex: 1,
    width: '100vw',
  },
}));

const SideNavContainer = ({
  isExpanded,
  onSideNavExpandToggle,
}: {
  isExpanded: boolean;
  onSideNavExpandToggle: () => void;
}) => {
  const isAuthenticated = useSelector<IState, boolean>(
    getUserIsAuthenticated,
    shallowEqual,
  );
  const {t} = useTranslation();
  const classes = useSideNavContainerStyles({});
  const {userInfoLoadStatus} = useContext(AppContext);

  if (!isAuthenticated || userInfoLoadStatus !== 'LOADED') {
    return null;
  }
  return (
    <>
      <Hidden smDown>
        <div className={classes.desktopSideNav}>
          <SideNav
            isMobile={false}
            isExpanded={isExpanded}
            onExpandToggleButtonClick={onSideNavExpandToggle}
            title={t('navigation.operationsManagement')}
            data={MenuData}
            toggler={
              <ExpandToggleButton
                isExpanded={isExpanded}
                onClick={onSideNavExpandToggle}
              />
            }
          />
          <SideNav
            isMobile={false}
            isExpanded={isExpanded}
            onExpandToggleButtonClick={onSideNavExpandToggle}
            title={t('navigation.logisticsManagement')}
            data={MenuDataLogistics}
          />
          <SideNav
            isMobile={false}
            isExpanded={isExpanded}
            onExpandToggleButtonClick={onSideNavExpandToggle}
            title={t('navigation.assetTracking')}
            data={MenuDataAssetTracking}
          />
          <SideNav
            isMobile={false}
            isExpanded={isExpanded}
            onExpandToggleButtonClick={onSideNavExpandToggle}
            title={t('navigation.endpointMonitoring')}
            data={MenuDataEndpointMonitoring}
          />
        </div>
      </Hidden>
      <Hidden mdUp>
        {isExpanded && (
          <div className={classes.mobileSideNav}>
            <SideNav
              isMobile
              isExpanded
              onExpandToggleButtonClick={onSideNavExpandToggle}
              title={t('navigation.operationsManagement')}
              data={MenuData}
              toggler={
                <ExpandToggleButton
                  isExpanded={isExpanded}
                  onClick={onSideNavExpandToggle}
                />
              }
            />
            <SideNav
              isMobile
              isExpanded
              onExpandToggleButtonClick={onSideNavExpandToggle}
              title={t('navigation.logisticsManagement')}
              data={MenuDataLogistics}
            />
            <SideNav
              isMobile
              isExpanded
              onExpandToggleButtonClick={onSideNavExpandToggle}
              title={t('navigation.assetTracking')}
              data={MenuDataAssetTracking}
            />
            <SideNav
              isMobile
              isExpanded
              onExpandToggleButtonClick={onSideNavExpandToggle}
              title={t('navigation.endpointMonitoring')}
              data={MenuDataEndpointMonitoring}
            />
          </div>
        )}
      </Hidden>
    </>
  );
};
