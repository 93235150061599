import React from 'react';
import {IFilterBarItem, IOption, TFilterTypeEnum} from '@valmet-iop/ui-common';
import {
  useReservations,
  useReservationsDeliveryDestinations,
} from 'components/Hooks/queries/useReservations';
import {useTranslation} from 'react-i18next';
import {IFilterOption} from '@valmet-iop/ui-common/dist/components/Filter/context';
import equal from 'deep-equal';
import {orderOptionsBy} from 'utils/form';
import {usePaginator} from 'components/Common/Entity/context/paginator-context';
import {
  ReservationDeliveryDestinationOutputDTO,
  ReservationOutputDTO,
} from 'api/generated/iop';
import {useLocation} from 'react-router-dom';
import {generateSchedulingPath} from '../routes';
import {TFunction} from 'i18next';
import {getDestinationText} from '../hooks/useItemData';
import {
  format,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
  subDays,
  addDays,
} from 'date-fns';
import {usePageView} from './pageView-context';

export interface IActions {
  add: (options: IFilterOption[]) => void;
  up: (options: IFilterOption[]) => void;
  remove: (option: IFilterOption) => void;
  reset: () => void;
}

export interface IFilterState {
  text: string;
  selected: IFilterOption[];
}
export interface IFilter {
  state: IFilterState;
  actions: IActions;
}
interface IContext {
  onFilterChange: (filters: IFilter) => void;
  onFilterTextChange: (filterText: string) => void;
  filters: IFilterState;
  scheme: IFilterBarItem[];
  setFiltered: (filtered: undefined | ReservationOutputDTO[]) => void;
}
const FilterContext = React.createContext<IContext | null>(null);

interface IOptions {
  sites: IOption[];
  loadingPlaces: IOption[];
  products: IOption[];
  types: IOption[];
  customers: IOption[];
  logisticsOperators: IOption[];
  orderNos: IOption[];
  destinations: IOption[];
}

export function useFilter(): IContext {
  const context = React.useContext<IContext>(
    FilterContext as React.Context<IContext>,
  );
  if (!context) {
    throw new Error(`useFilter must be used within a FilterProvider`);
  }
  return context;
}

interface IProvider {
  children: React.ReactNode;
}

interface IFilterOptionsByCurrentList {
  options: IOption[];
  list: ReservationOutputDTO[] | undefined;
  propertyName: keyof ReservationOutputDTO;
  selectedDependencies: IFilterOption[];
  dependencyPropertyName: keyof ReservationOutputDTO;
}
function getFilterOptionsById(
  id: string,
  filterOptions: IFilterOption[],
): IFilterOption[] {
  return filterOptions.filter(({filterId}) => filterId === id);
}
function filterOptionsByCurrentList({
  options,
  list,
  propertyName,
  dependencyPropertyName,
  selectedDependencies,
}: IFilterOptionsByCurrentList) {
  if (!list) return [];

  if (selectedDependencies.length === 0) {
    return options;
  }
  return options.filter(({value}) => {
    return list.some(
      item =>
        item[propertyName] === value &&
        selectedDependencies.some(
          dependency => dependency.value === item[dependencyPropertyName],
        ),
    );
  });
}

const dateToIso = (date?: string) =>
  date ? new Date(date).toISOString() : undefined;

export function FilterProvider(props: IProvider) {
  const {pathname} = useLocation();
  const {t} = useTranslation();
  const {pageView} = usePageView();
  const isApiRequestsEnabled = pathname.includes(generateSchedulingPath());

  const filterDateFormat = (fDate: Date) => format(fDate, 'dd.MM.yyyy HH:mm');
  const defaultListLoadingTimeFilter = React.useMemo(() => {
    const roundedDownToday = setMilliseconds(
      setSeconds(setMinutes(setHours(new Date(), 0), 0), 0),
      0,
    );
    return {
      text: `${filterDateFormat(
        subDays(roundedDownToday, 5),
      )} - ${filterDateFormat(addDays(roundedDownToday, 5))}`,
      value: `{"start":"${subDays(
        roundedDownToday,
        5,
      ).toISOString()}","end":"${addDays(roundedDownToday, 5).toISOString()}"}`,
      filterId: 'loadingTime',
      type: 2,
    };
  }, []);

  const [filters, setFilters] = React.useState<IFilterState>({
    text: '',
    selected: [defaultListLoadingTimeFilter],
  });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const startEndTimes = React.useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const [loadingTime] = filters.selected
      .filter(({filterId}) => {
        return filterId === 'loadingTime';
      })
      .map(({value}) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return JSON.parse(value);
      });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return loadingTime;
  }, [filters]);

  const {data: allReservations} = useReservations(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    dateToIso(startEndTimes?.start),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    dateToIso(startEndTimes?.end),
    isApiRequestsEnabled,
  );

  // Include disabled destinations here since they may be in use in older reservations
  // and we still want to be able to filter for them
  const {data: destinations} = useReservationsDeliveryDestinations(
    true,
    isApiRequestsEnabled,
  );

  const options = React.useMemo<IOptions>(() => {
    return getOptionsFromAllReservations(allReservations, destinations, t);
  }, [allReservations, destinations, t]);

  React.useEffect(() => {
    // Reset date filter if leaving the list view
    if (pathname.includes(generateSchedulingPath())) return;

    setFilters({
      text: '',
      selected: [defaultListLoadingTimeFilter],
    });
  }, [pathname, pageView, defaultListLoadingTimeFilter]);

  // TODO: what is this hook even used for?
  const [, setFiltered] = React.useState<undefined | ReservationOutputDTO[]>(
    undefined,
  );

  const {onPageChange} = usePaginator();
  const value = React.useMemo(() => {
    //TODO add some type for setting strict ids
    return {
      setFiltered,
      scheme: [
        {
          id: 'siteId',
          title: t('scheduling:headers.site'),
          type: TFilterTypeEnum.InArrayValues,
          options: options.sites,
        },
        {
          id: 'equipmentId',
          title: t('scheduling:headers.loadingPlace'),
          type: TFilterTypeEnum.InArrayValues,
          options: options.loadingPlaces,
        },
        {
          id: 'productId',
          title: t('scheduling:headers.product'),
          type: TFilterTypeEnum.InArrayValues,
          options: options.products,
        },
        {
          id: 'type',
          title: t('scheduling:headers.type'),
          type: TFilterTypeEnum.InArrayValues,
          options: options.types,
        },
        {
          id: 'loadingTime',
          title: t('scheduling:headers.startTime'),
          type: TFilterTypeEnum.DateTimeRange,
        },
        {
          id: 'customerId',
          title: t('scheduling:headers.customer'),
          type: TFilterTypeEnum.InArrayValues,
          options: filterOptionsByCurrentList({
            options: options.customers,
            list: allReservations,
            propertyName: 'customerCompanyId',
            dependencyPropertyName: 'deliveryDestinationId',
            selectedDependencies: getFilterOptionsById(
              'destination',
              filters.selected,
            ),
          }),
        },
        {
          id: 'logisticsCompany',
          title: t('scheduling:headers.logisticsOperator'),
          type: TFilterTypeEnum.InArrayValues,
          options: options.logisticsOperators,
        },
        {
          id: 'orderNo',
          title: t('scheduling:headers.orderNo'),
          type: TFilterTypeEnum.InArrayValues,
          options: options.orderNos,
          showSelectAllButtonForOptionsMenu: false,
        },
        {
          id: 'destination',
          title: t('scheduling:headers.destination'),
          type: TFilterTypeEnum.InArrayValues,
          options: filterOptionsByCurrentList({
            options: options.destinations,
            list: allReservations,
            propertyName: 'deliveryDestinationId',
            dependencyPropertyName: 'customerCompanyId',
            selectedDependencies: getFilterOptionsById(
              'customerId',
              filters.selected,
            ),
          }),
        },
      ] as IFilterBarItem[],
      onFilterTextChange: (text: string) => {
        setFilters(prev => {
          return {...prev, text};
        });
      },
      onFilterChange: ({state, actions}: IFilter) => {
        if (equal(state, filters)) {
          return;
        }

        onPageChange(0);
        setFilters(state);
      },
      filters,
    };
  }, [filters, options, onPageChange, t, allReservations]);

  return <FilterContext.Provider value={value} {...props} />;
}

function getOptionsFromAllReservations(
  allReservations: readonly ReservationOutputDTO[] | undefined,
  destinations: readonly ReservationDeliveryDestinationOutputDTO[] | undefined,
  t: TFunction,
) {
  if (!allReservations) {
    return {
      sites: [],
      loadingPlaces: [],
      products: [],
      types: [],
      customers: [],
      logisticsOperators: [],
      orderNos: [],
      destinations: [],
    };
  }

  const uniqueValues: Record<keyof IOptions, Set<string>> = {
    sites: new Set<string>(),
    loadingPlaces: new Set<string>(),
    products: new Set<string>(),
    types: new Set<string>(),
    customers: new Set<string>(),
    logisticsOperators: new Set<string>(),
    orderNos: new Set<string>(),
    destinations: new Set<string>(),
  };
  const uniqueOptions: IOptions = {
    sites: [],
    loadingPlaces: [],
    products: [],
    types: [],
    customers: [],
    logisticsOperators: [],
    orderNos: [],
    destinations: [],
  };

  const addOption = (
    set: Set<string>,
    options: IOption[],
    value: string | null | undefined,
    text: string | null | undefined,
  ) => {
    if (!value || !text) {
      return;
    }

    if (set.has(value)) {
      return;
    }

    set.add(value);
    options.push({text, value});
  };

  for (const reservation of allReservations) {
    addOption(
      uniqueValues.sites,
      uniqueOptions.sites,
      reservation.siteId,
      reservation.siteName,
    );
    addOption(
      uniqueValues.loadingPlaces,
      uniqueOptions.loadingPlaces,
      reservation.equipmentId,
      reservation.equipmentName,
    );
    addOption(
      uniqueValues.products,
      uniqueOptions.products,
      reservation.productId,
      reservation.productDisplayName,
    );
    addOption(
      uniqueValues.types,
      uniqueOptions.types,
      reservation.type,
      t(`scheduling:reservationTypes.${reservation.type || ''}`),
    );
    addOption(
      uniqueValues.customers,
      uniqueOptions.customers,
      reservation.customerCompanyId,
      reservation.customerCompanyName,
    );
    addOption(
      uniqueValues.logisticsOperators,
      uniqueOptions.logisticsOperators,
      reservation.logisticsOperatorCompanyId,
      reservation.logisticsOperatorName,
    );
    addOption(
      uniqueValues.orderNos,
      uniqueOptions.orderNos,
      Number.isInteger(reservation.orderNo)
        ? `${reservation.orderNo || ''}`
        : null,
      `${reservation.orderNo || ''}`,
    );
    const destination = destinations?.find(destination => {
      return (
        reservation.deliveryDestinationId === destination.deliveryDestinationId
      );
    });
    addOption(
      uniqueValues.destinations,
      uniqueOptions.destinations,
      reservation.deliveryDestinationId,
      destination ? getDestinationText(destination) : '',
    );
  }

  uniqueOptions.sites = orderOptionsBy(uniqueOptions.sites);
  uniqueOptions.loadingPlaces = orderOptionsBy(uniqueOptions.loadingPlaces);
  uniqueOptions.products = orderOptionsBy(uniqueOptions.products);
  uniqueOptions.types = orderOptionsBy(uniqueOptions.types);
  uniqueOptions.customers = orderOptionsBy(uniqueOptions.customers);
  uniqueOptions.logisticsOperators = orderOptionsBy(
    uniqueOptions.logisticsOperators,
  );
  uniqueOptions.orderNos = uniqueOptions.orderNos.sort((a, b) =>
    a.value.localeCompare(b.value, undefined, {
      numeric: true,
      sensitivity: 'base',
    }),
  );
  uniqueOptions.destinations = orderOptionsBy(uniqueOptions.destinations);

  return uniqueOptions;
}
