import {Modes} from 'components/Common/Entity/context/item-context';
import {LazyExoticComponent} from 'react';

export interface IRouterMap {
  comp: LazyExoticComponent<any>;
  path: string;
  mode?: Modes;
  requiredFeatureFlag?: string;
}

export type ICreatePath = () => string;
export type IUpdatePath = (id: string) => string;
export type IReadPath = (id: string) => string;
export type IMainPath = () => string;

export function generatePathById(prePath: string, id: string) {
  return prePath.replace(':id', id);
}
