/* tslint:disable */
/* eslint-disable */
/**
 * Valmet.Iop.App.Api 1.0
 * Valmet.Iop.App.Api
 *
 * The version of the OpenAPI document: 1.0
 * Contact: n.n@zure.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {Configuration} from './configuration';
import globalAxios, {AxiosPromise, AxiosInstance} from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from './base';

/**
 *
 * @export
 * @interface AllowedChildCompanyTypeOutputDTO
 */
export interface AllowedChildCompanyTypeOutputDTO {
  /**
   *
   * @type {string}
   * @memberof AllowedChildCompanyTypeOutputDTO
   */
  parentTypeId: string;
  /**
   *
   * @type {string}
   * @memberof AllowedChildCompanyTypeOutputDTO
   */
  parentTypeDisplayName: string;
  /**
   *
   * @type {string}
   * @memberof AllowedChildCompanyTypeOutputDTO
   */
  childTypeId: string;
  /**
   *
   * @type {string}
   * @memberof AllowedChildCompanyTypeOutputDTO
   */
  childTypeDisplayName: string;
}
/**
 *
 * @export
 * @interface BasicCompanyDataOutputDTO
 */
export interface BasicCompanyDataOutputDTO {
  /**
   * Company Guid
   * @type {string}
   * @memberof BasicCompanyDataOutputDTO
   */
  companyId: string;
  /**
   * Organization Guid
   * @type {string}
   * @memberof BasicCompanyDataOutputDTO
   */
  organizationId: string;
  /**
   * Company name
   * @type {string}
   * @memberof BasicCompanyDataOutputDTO
   */
  displayName: string;
  /**
   * VAT
   * @type {string}
   * @memberof BasicCompanyDataOutputDTO
   */
  vat: string;
}
/**
 *
 * @export
 * @interface ChildSiteCachedForecastOutputDTO
 */
export interface ChildSiteCachedForecastOutputDTO {
  /**
   *
   * @type {string}
   * @memberof ChildSiteCachedForecastOutputDTO
   */
  childSiteId: string;
  /**
   *
   * @type {string}
   * @memberof ChildSiteCachedForecastOutputDTO
   */
  childSiteDisplayName: string;
  /**
   *
   * @type {string}
   * @memberof ChildSiteCachedForecastOutputDTO
   */
  firstFillTimestamp?: string | null;
  /**
   *
   * @type {string}
   * @memberof ChildSiteCachedForecastOutputDTO
   */
  lastFillTimestamp?: string | null;
  /**
   *
   * @type {string}
   * @memberof ChildSiteCachedForecastOutputDTO
   */
  zeroTimestamp?: string | null;
  /**
   *
   * @type {SiteRunoutOutputDTO}
   * @memberof ChildSiteCachedForecastOutputDTO
   */
  runout: SiteRunoutOutputDTO;
  /**
   *
   * @type {number}
   * @memberof ChildSiteCachedForecastOutputDTO
   */
  runOutInHours?: number | null;
  /**
   *
   * @type {number}
   * @memberof ChildSiteCachedForecastOutputDTO
   */
  lastLevel?: number | null;
  /**
   *
   * @type {string}
   * @memberof ChildSiteCachedForecastOutputDTO
   */
  lastLevelTimeStamp?: string | null;
  /**
   *
   * @type {number}
   * @memberof ChildSiteCachedForecastOutputDTO
   */
  totalSiteCapacity?: number | null;
  /**
   *
   * @type {string}
   * @memberof ChildSiteCachedForecastOutputDTO
   */
  totalSiteCapacityUnitAbbreviation?: string | null;
  /**
   *
   * @type {string}
   * @memberof ChildSiteCachedForecastOutputDTO
   */
  forecastCacheTimestamp: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum CompanyParameterDataType {
  Numeric = 'Numeric',
  String = 'String',
  Toggle = 'Toggle',
  Choice = 'Choice',
}

/**
 *
 * @export
 * @interface CompanyParameterOutputDTO
 */
export interface CompanyParameterOutputDTO {
  /**
   *
   * @type {string}
   * @memberof CompanyParameterOutputDTO
   */
  value: string;
  /**
   *
   * @type {CompanyParameterDataType}
   * @memberof CompanyParameterOutputDTO
   */
  dataType: CompanyParameterDataType;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum CompanySortColumnName {
  Name = 'Name',
  Vat = 'Vat',
}

/**
 *
 * @export
 * @interface CompanyTypeInputDTO
 */
export interface CompanyTypeInputDTO {
  /**
   *
   * @type {string}
   * @memberof CompanyTypeInputDTO
   */
  displayName?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CompanyTypeInputDTO
   */
  createAzureResources?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof CompanyTypeInputDTO
   */
  hasCompanyHierarchy?: boolean | null;
  /**
   *
   * @type {Array<string>}
   * @memberof CompanyTypeInputDTO
   */
  allowedChildTypeIds?: Array<string> | null;
}
/**
 *
 * @export
 * @interface CompanyTypeOutputDTO
 */
export interface CompanyTypeOutputDTO {
  /**
   *
   * @type {string}
   * @memberof CompanyTypeOutputDTO
   */
  companyTypeId?: string;
  /**
   *
   * @type {string}
   * @memberof CompanyTypeOutputDTO
   */
  displayName?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CompanyTypeOutputDTO
   */
  createAzureResources?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CompanyTypeOutputDTO
   */
  hasCompanyHierarchy?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof CompanyTypeOutputDTO
   */
  allowedChildTypeIds?: Array<string> | null;
}
/**
 *
 * @export
 * @interface DeleteReservationOutputDTO
 */
export interface DeleteReservationOutputDTO {
  /**
   *
   * @type {string}
   * @memberof DeleteReservationOutputDTO
   */
  reservationId: string;
  /**
   *
   * @type {ReservationType}
   * @memberof DeleteReservationOutputDTO
   */
  type: ReservationType;
}
/**
 *
 * @export
 * @interface EndpointMonitoringSitesRunout
 */
export interface EndpointMonitoringSitesRunout {
  /**
   *
   * @type {string}
   * @memberof EndpointMonitoringSitesRunout
   */
  runoutTime?: string | null;
  /**
   *
   * @type {string}
   * @memberof EndpointMonitoringSitesRunout
   */
  siteTimeZone?: string | null;
  /**
   *
   * @type {string}
   * @memberof EndpointMonitoringSitesRunout
   */
  runoutTimeSource?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof EndpointMonitoringSitesRunout
   */
  isRunoutAlarmActive?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EndpointMonitoringSitesRunout
   */
  isRunoutWarningActive?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EndpointMonitoringSitesRunout
   */
  isRunoutAfterMaxDaysToForecast?: boolean;
  /**
   *
   * @type {number}
   * @memberof EndpointMonitoringSitesRunout
   */
  maxDaysToForecast?: number | null;
}
/**
 *
 * @export
 * @interface EquipmentDirectForecastOutputDTO
 */
export interface EquipmentDirectForecastOutputDTO {
  /**
   *
   * @type {string}
   * @memberof EquipmentDirectForecastOutputDTO
   */
  zeroTimestamp?: string | null;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof EquipmentDirectForecastOutputDTO
   */
  historicalData: {[key: string]: number};
  /**
   *
   * @type {{ [key: string]: ForecastConfidenceValuesDTO; }}
   * @memberof EquipmentDirectForecastOutputDTO
   */
  forecastData: {[key: string]: ForecastConfidenceValuesDTO};
  /**
   *
   * @type {Array<string>}
   * @memberof EquipmentDirectForecastOutputDTO
   */
  warnings: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof EquipmentDirectForecastOutputDTO
   */
  errors: Array<string>;
  /**
   *
   * @type {number}
   * @memberof EquipmentDirectForecastOutputDTO
   */
  totalMeanUsage?: number | null;
  /**
   *
   * @type {number}
   * @memberof EquipmentDirectForecastOutputDTO
   */
  equipmentCapacity?: number | null;
  /**
   *
   * @type {string}
   * @memberof EquipmentDirectForecastOutputDTO
   */
  equipmentCapacityUnitAbbreviation?: string | null;
  /**
   *
   * @type {number}
   * @memberof EquipmentDirectForecastOutputDTO
   */
  minimumLevelKg?: number | null;
  /**
   *
   * @type {number}
   * @memberof EquipmentDirectForecastOutputDTO
   */
  maximumLevelKg?: number | null;
}
/**
 *
 * @export
 * @interface EquipmentForSiteOutputDTO
 */
export interface EquipmentForSiteOutputDTO {
  /**
   *
   * @type {string}
   * @memberof EquipmentForSiteOutputDTO
   */
  equipmentId: string;
  /**
   *
   * @type {string}
   * @memberof EquipmentForSiteOutputDTO
   */
  displayName: string;
  /**
   *
   * @type {EquipmentType}
   * @memberof EquipmentForSiteOutputDTO
   */
  type: EquipmentType;
  /**
   *
   * @type {Array<EquipmentForSiteProductOutputDTO>}
   * @memberof EquipmentForSiteOutputDTO
   */
  products: Array<EquipmentForSiteProductOutputDTO>;
}
/**
 *
 * @export
 * @interface EquipmentForSiteProductOutputDTO
 */
export interface EquipmentForSiteProductOutputDTO {
  /**
   *
   * @type {string}
   * @memberof EquipmentForSiteProductOutputDTO
   */
  productId: string;
  /**
   *
   * @type {string}
   * @memberof EquipmentForSiteProductOutputDTO
   */
  displayName: string;
  /**
   *
   * @type {boolean}
   * @memberof EquipmentForSiteProductOutputDTO
   */
  canReceive: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EquipmentForSiteProductOutputDTO
   */
  canProvide: boolean;
}
/**
 *
 * @export
 * @interface EquipmentMeasurementResultsOutputDTO
 */
export interface EquipmentMeasurementResultsOutputDTO {
  /**
   *
   * @type {string}
   * @memberof EquipmentMeasurementResultsOutputDTO
   */
  equipmentId: string;
  /**
   *
   * @type {string}
   * @memberof EquipmentMeasurementResultsOutputDTO
   */
  displayName: string;
  /**
   *
   * @type {number}
   * @memberof EquipmentMeasurementResultsOutputDTO
   */
  totalCapacity?: number | null;
  /**
   *
   * @type {number}
   * @memberof EquipmentMeasurementResultsOutputDTO
   */
  latestLevel?: number | null;
  /**
   *
   * @type {number}
   * @memberof EquipmentMeasurementResultsOutputDTO
   */
  minimumLevelKg?: number | null;
  /**
   *
   * @type {number}
   * @memberof EquipmentMeasurementResultsOutputDTO
   */
  maximumLevelKg?: number | null;
  /**
   *
   * @type {string}
   * @memberof EquipmentMeasurementResultsOutputDTO
   */
  latestLevelUnitAbbreviation?: string | null;
  /**
   *
   * @type {Array<LatestEquipmentTagValueDTO>}
   * @memberof EquipmentMeasurementResultsOutputDTO
   */
  latestTagValues?: Array<LatestEquipmentTagValueDTO> | null;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum EquipmentType {
  Bay = 'Bay',
  Tank = 'Tank',
  Lot = 'Lot',
}

/**
 *
 * @export
 * @interface FeatureFlagsOutputDto
 */
export interface FeatureFlagsOutputDto {
  /**
   *
   * @type {{ [key: string]: boolean; }}
   * @memberof FeatureFlagsOutputDto
   */
  featureFlags?: {[key: string]: boolean} | null;
}
/**
 *
 * @export
 * @interface ForecastConfidenceValuesDTO
 */
export interface ForecastConfidenceValuesDTO {
  /**
   *
   * @type {number}
   * @memberof ForecastConfidenceValuesDTO
   */
  min?: number | null;
  /**
   *
   * @type {number}
   * @memberof ForecastConfidenceValuesDTO
   */
  mean?: number | null;
  /**
   *
   * @type {number}
   * @memberof ForecastConfidenceValuesDTO
   */
  max?: number | null;
}
/**
 *
 * @export
 * @interface ForecastParametersInputDTO
 */
export interface ForecastParametersInputDTO {
  /**
   *
   * @type {string}
   * @memberof ForecastParametersInputDTO
   */
  forecastType?: string | null;
  /**
   *
   * @type {number}
   * @memberof ForecastParametersInputDTO
   */
  historyStartDaysAgo?: number;
  /**
   *
   * @type {number}
   * @memberof ForecastParametersInputDTO
   */
  historyDaysToDisplay?: number | null;
  /**
   *
   * @type {number}
   * @memberof ForecastParametersInputDTO
   */
  maximumDaysToForecast?: number | null;
  /**
   *
   * @type {number}
   * @memberof ForecastParametersInputDTO
   */
  minimumReplenishmentAmountKilograms?: number | null;
  /**
   *
   * @type {number}
   * @memberof ForecastParametersInputDTO
   */
  lastReplenishmentLevelKilograms?: number | null;
  /**
   *
   * @type {number}
   * @memberof ForecastParametersInputDTO
   */
  warningLimitKilograms?: number | null;
  /**
   *
   * @type {number}
   * @memberof ForecastParametersInputDTO
   */
  alarmLimitKilograms?: number | null;
  /**
   *
   * @type {number}
   * @memberof ForecastParametersInputDTO
   */
  runoutWarningHours?: number | null;
  /**
   *
   * @type {number}
   * @memberof ForecastParametersInputDTO
   */
  runoutAlarmHours?: number | null;
}
/**
 *
 * @export
 * @interface ForecastParametersOutputDTO
 */
export interface ForecastParametersOutputDTO {
  /**
   *
   * @type {ForecastType}
   * @memberof ForecastParametersOutputDTO
   */
  forecastType: ForecastType;
  /**
   * By default 30. See Valmet.Iop.App.Sql.Repositories.Shared.KnownParameterCodes.ForecastingSiteHistoryStartDaysAgo.
   * @type {number}
   * @memberof ForecastParametersOutputDTO
   */
  historyStartDaysAgo: number;
  /**
   * By default 30. See Valmet.Iop.App.Sql.Repositories.Shared.KnownParameterCodes.ForecastingSiteMaximumDaysToForecast.
   * @type {number}
   * @memberof ForecastParametersOutputDTO
   */
  maximumDaysToForecast: number;
  /**
   * See Valmet.Iop.App.Sql.Repositories.Shared.KnownParameterCodes.ForecastingSiteHistoryDaysToDisplay.
   * @type {number}
   * @memberof ForecastParametersOutputDTO
   */
  historyDaysToDisplay?: number | null;
  /**
   * See Valmet.Iop.App.Sql.Repositories.Shared.KnownParameterCodes.ForecastingSiteMinimumReplenishmentAmountKilograms.
   * @type {number}
   * @memberof ForecastParametersOutputDTO
   */
  minimumReplenishmentAmountKilograms?: number | null;
  /**
   * See Valmet.Iop.App.Sql.Repositories.Shared.KnownParameterCodes.ForecastingSiteLastReplenishmentLevelKilograms.
   * @type {number}
   * @memberof ForecastParametersOutputDTO
   */
  lastReplenishmentLevelKilograms?: number | null;
  /**
   * See Valmet.Iop.App.Sql.Repositories.Shared.KnownParameterCodes.ForecastingSiteWarningLimitKilograms.
   * @type {number}
   * @memberof ForecastParametersOutputDTO
   */
  warningLimitKilograms?: number | null;
  /**
   * See Valmet.Iop.App.Sql.Repositories.Shared.KnownParameterCodes.ForecastingSiteAlarmLimitKilograms.
   * @type {number}
   * @memberof ForecastParametersOutputDTO
   */
  alarmLimitKilograms?: number | null;
  /**
   * See Valmet.Iop.App.Sql.Repositories.Shared.KnownParameterCodes.ForecastingSiteRunoutWarningHours.
   * @type {number}
   * @memberof ForecastParametersOutputDTO
   */
  runoutWarningHours?: number | null;
  /**
   * See Valmet.Iop.App.Sql.Repositories.Shared.KnownParameterCodes.ForecastingSiteRunoutAlarmHours.
   * @type {number}
   * @memberof ForecastParametersOutputDTO
   */
  runoutAlarmHours?: number | null;
  /**
   * See Valmet.Iop.App.Sql.Repositories.Shared.KnownParameterCodes.ForecastingDisableForecastCalculation.
   * @type {boolean}
   * @memberof ForecastParametersOutputDTO
   */
  disableForecastCalculation?: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum ForecastType {
  Linear = 'Linear',
  Pattern = 'Pattern',
}

/**
 *
 * @export
 * @interface GeofenceEvent
 */
export interface GeofenceEvent {
  /**
   *
   * @type {string}
   * @memberof GeofenceEvent
   */
  enterTimestamp?: string;
  /**
   *
   * @type {string}
   * @memberof GeofenceEvent
   */
  exitTimestamp?: string | null;
  /**
   *
   * @type {string}
   * @memberof GeofenceEvent
   */
  assetId?: string | null;
  /**
   *
   * @type {string}
   * @memberof GeofenceEvent
   */
  assetDisplayName?: string | null;
}
/**
 *
 * @export
 * @interface GetAvailableBaseProductsForCompanyOutputDTO
 */
export interface GetAvailableBaseProductsForCompanyOutputDTO {
  /**
   *
   * @type {string}
   * @memberof GetAvailableBaseProductsForCompanyOutputDTO
   */
  baseProductId: string;
  /**
   *
   * @type {string}
   * @memberof GetAvailableBaseProductsForCompanyOutputDTO
   */
  baseProductDisplayName: string;
}
/**
 *
 * @export
 * @interface GetAvailableCountriesForCompanyOutputDTO
 */
export interface GetAvailableCountriesForCompanyOutputDTO {
  /**
   *
   * @type {string}
   * @memberof GetAvailableCountriesForCompanyOutputDTO
   */
  countryCode: string;
}
/**
 *
 * @export
 * @interface GetAvailableProductsForCompanyOutputDTO
 */
export interface GetAvailableProductsForCompanyOutputDTO {
  /**
   *
   * @type {string}
   * @memberof GetAvailableProductsForCompanyOutputDTO
   */
  productId: string;
  /**
   *
   * @type {string}
   * @memberof GetAvailableProductsForCompanyOutputDTO
   */
  productDisplayName: string;
}
/**
 *
 * @export
 * @interface GetCompanyByIdChildCompanyOutputDTO
 */
export interface GetCompanyByIdChildCompanyOutputDTO {
  /**
   *
   * @type {string}
   * @memberof GetCompanyByIdChildCompanyOutputDTO
   */
  childCompanyId: string;
  /**
   *
   * @type {string}
   * @memberof GetCompanyByIdChildCompanyOutputDTO
   */
  childCompanyDisplayName: string;
  /**
   *
   * @type {string}
   * @memberof GetCompanyByIdChildCompanyOutputDTO
   */
  parentCompanyTypeId: string;
  /**
   *
   * @type {string}
   * @memberof GetCompanyByIdChildCompanyOutputDTO
   */
  parentCompanyTypeDisplayName: string;
  /**
   *
   * @type {string}
   * @memberof GetCompanyByIdChildCompanyOutputDTO
   */
  childCompanyTypeId: string;
  /**
   *
   * @type {string}
   * @memberof GetCompanyByIdChildCompanyOutputDTO
   */
  childCompanyTypeDisplayName: string;
}
/**
 *
 * @export
 * @interface GetCompanyByIdOutputDTO
 */
export interface GetCompanyByIdOutputDTO {
  /**
   * Company Guid
   * @type {string}
   * @memberof GetCompanyByIdOutputDTO
   */
  companyId?: string;
  /**
   * Organization Guid
   * @type {string}
   * @memberof GetCompanyByIdOutputDTO
   */
  organizationId?: string;
  /**
   * Company name
   * @type {string}
   * @memberof GetCompanyByIdOutputDTO
   */
  displayName: string;
  /**
   * VAT
   * @type {string}
   * @memberof GetCompanyByIdOutputDTO
   */
  vat: string;
  /**
   *
   * @type {RequiredAzureResources}
   * @memberof GetCompanyByIdOutputDTO
   */
  requiredAzureResources: RequiredAzureResources;
  /**
   *
   * @type {Array<GetCompanyByIdTypeOutputDTO>}
   * @memberof GetCompanyByIdOutputDTO
   */
  assignedTypes: Array<GetCompanyByIdTypeOutputDTO>;
  /**
   *
   * @type {Array<GetCompanyByIdChildCompanyOutputDTO>}
   * @memberof GetCompanyByIdOutputDTO
   */
  childCompanies: Array<GetCompanyByIdChildCompanyOutputDTO>;
}
/**
 *
 * @export
 * @interface GetCompanyByIdTypeOutputDTO
 */
export interface GetCompanyByIdTypeOutputDTO {
  /**
   *
   * @type {string}
   * @memberof GetCompanyByIdTypeOutputDTO
   */
  typeId: string;
  /**
   *
   * @type {string}
   * @memberof GetCompanyByIdTypeOutputDTO
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof GetCompanyByIdTypeOutputDTO
   */
  hasCompanyHierarchy: boolean;
}
/**
 *
 * @export
 * @interface GetCompanyParametersOutputDTO
 */
export interface GetCompanyParametersOutputDTO {
  /**
   *
   * @type {{ [key: string]: CompanyParameterOutputDTO; }}
   * @memberof GetCompanyParametersOutputDTO
   */
  parameters: {[key: string]: CompanyParameterOutputDTO};
}
/**
 *
 * @export
 * @interface GetCompanyTypesTypeOutputDTO
 */
export interface GetCompanyTypesTypeOutputDTO {
  /**
   *
   * @type {string}
   * @memberof GetCompanyTypesTypeOutputDTO
   */
  companyTypeId?: string;
  /**
   *
   * @type {string}
   * @memberof GetCompanyTypesTypeOutputDTO
   */
  displayName?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof GetCompanyTypesTypeOutputDTO
   */
  createAzureResources?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GetCompanyTypesTypeOutputDTO
   */
  hasCompanyHierarchy?: boolean;
}
/**
 *
 * @export
 * @interface GetEndpointMonitoringAvailableCompaniesOutputDTO
 */
export interface GetEndpointMonitoringAvailableCompaniesOutputDTO {
  /**
   *
   * @type {string}
   * @memberof GetEndpointMonitoringAvailableCompaniesOutputDTO
   */
  companyId?: string;
  /**
   *
   * @type {string}
   * @memberof GetEndpointMonitoringAvailableCompaniesOutputDTO
   */
  companyDisplayName?: string | null;
}
/**
 *
 * @export
 * @interface GetGeofenceEventsOutputDTO
 */
export interface GetGeofenceEventsOutputDTO {
  /**
   *
   * @type {string}
   * @memberof GetGeofenceEventsOutputDTO
   */
  geofenceId?: string | null;
  /**
   *
   * @type {string}
   * @memberof GetGeofenceEventsOutputDTO
   */
  geofenceDisplayName?: string | null;
  /**
   *
   * @type {Array<GeofenceEvent>}
   * @memberof GetGeofenceEventsOutputDTO
   */
  geofenceEvents?: Array<GeofenceEvent> | null;
}
/**
 *
 * @export
 * @interface GetSiteParametersOutputDTO
 */
export interface GetSiteParametersOutputDTO {
  /**
   *
   * @type {{ [key: string]: SiteParameterOutputDTO; }}
   * @memberof GetSiteParametersOutputDTO
   */
  parameters: {[key: string]: SiteParameterOutputDTO};
}
/**
 *
 * @export
 * @interface GetUserAccessibleSiteOutputDTO
 */
export interface GetUserAccessibleSiteOutputDTO {
  /**
   * Site Guid
   * @type {string}
   * @memberof GetUserAccessibleSiteOutputDTO
   */
  siteId?: string;
  /**
   * Site name
   * @type {string}
   * @memberof GetUserAccessibleSiteOutputDTO
   */
  displayName?: string | null;
  /**
   * Site type. Possible values are      Distribution Terminal      Customer Terminal      Terminal
   * @type {string}
   * @memberof GetUserAccessibleSiteOutputDTO
   */
  type?: string | null;
  /**
   * Country alpha-2 code
   * @type {string}
   * @memberof GetUserAccessibleSiteOutputDTO
   */
  country?: string | null;
  /**
   * Customer Guid
   * @type {string}
   * @memberof GetUserAccessibleSiteOutputDTO
   */
  companyId?: string;
  /**
   * If set, a user making a loading reservation  on this site will get this long of a reservation by default  once they set the starting time.
   * @type {number}
   * @memberof GetUserAccessibleSiteOutputDTO
   */
  defaultLoadingTimeIntervalMinutes?: number | null;
  /**
   * If set, a user making an unloading reservation  on this site will get this long of a reservation by default  once they set the starting time.
   * @type {number}
   * @memberof GetUserAccessibleSiteOutputDTO
   */
  defaultUnloadingTimeIntervalMinutes?: number | null;
  /**
   * If set, a user can\'t make a loading reservation shorter than this.
   * @type {number}
   * @memberof GetUserAccessibleSiteOutputDTO
   */
  minimumLoadingTimeIntervalMinutes?: number | null;
  /**
   * If set, a user can\'t make an unloading reservation shorter than this.
   * @type {number}
   * @memberof GetUserAccessibleSiteOutputDTO
   */
  minimumUnloadingTimeIntervalMinutes?: number | null;
}
/**
 *
 * @export
 * @interface LatestEquipmentTagValueDTO
 */
export interface LatestEquipmentTagValueDTO {
  /**
   *
   * @type {string}
   * @memberof LatestEquipmentTagValueDTO
   */
  alternateId: string;
  /**
   *
   * @type {number}
   * @memberof LatestEquipmentTagValueDTO
   */
  tagId: number;
  /**
   *
   * @type {string}
   * @memberof LatestEquipmentTagValueDTO
   */
  displayName: string;
  /**
   *
   * @type {number}
   * @memberof LatestEquipmentTagValueDTO
   */
  latestValue?: number | null;
  /**
   *
   * @type {string}
   * @memberof LatestEquipmentTagValueDTO
   */
  measurementUnitAbbreviation?: string | null;
  /**
   *
   * @type {number}
   * @memberof LatestEquipmentTagValueDTO
   */
  warningLow?: number | null;
  /**
   *
   * @type {number}
   * @memberof LatestEquipmentTagValueDTO
   */
  warningHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof LatestEquipmentTagValueDTO
   */
  alarmLow?: number | null;
  /**
   *
   * @type {number}
   * @memberof LatestEquipmentTagValueDTO
   */
  alarmHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof LatestEquipmentTagValueDTO
   */
  invalidHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof LatestEquipmentTagValueDTO
   */
  invalidLow?: number | null;
}
/**
 *
 * @export
 * @interface LatestTagValueDTO
 */
export interface LatestTagValueDTO {
  /**
   *
   * @type {string}
   * @memberof LatestTagValueDTO
   */
  alternateId: string;
  /**
   *
   * @type {number}
   * @memberof LatestTagValueDTO
   */
  tagId: number;
  /**
   *
   * @type {string}
   * @memberof LatestTagValueDTO
   */
  displayName: string;
  /**
   *
   * @type {number}
   * @memberof LatestTagValueDTO
   */
  latestValue?: number | null;
  /**
   *
   * @type {string}
   * @memberof LatestTagValueDTO
   */
  measurementUnitAbbreviation?: string | null;
  /**
   *
   * @type {number}
   * @memberof LatestTagValueDTO
   */
  warningLow?: number | null;
  /**
   *
   * @type {number}
   * @memberof LatestTagValueDTO
   */
  warningHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof LatestTagValueDTO
   */
  alarmLow?: number | null;
  /**
   *
   * @type {number}
   * @memberof LatestTagValueDTO
   */
  alarmHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof LatestTagValueDTO
   */
  invalidHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof LatestTagValueDTO
   */
  invalidLow?: number | null;
}
/**
 *
 * @export
 * @interface LogisticsCompanyOutputDTO
 */
export interface LogisticsCompanyOutputDTO {
  /**
   * Company Guid
   * @type {string}
   * @memberof LogisticsCompanyOutputDTO
   */
  companyId?: string;
  /**
   * Company name
   * @type {string}
   * @memberof LogisticsCompanyOutputDTO
   */
  displayName?: string | null;
}
/**
 *
 * @export
 * @interface LogisticsOperatorTruckOutputDTO
 */
export interface LogisticsOperatorTruckOutputDTO {
  /**
   *
   * @type {string}
   * @memberof LogisticsOperatorTruckOutputDTO
   */
  truckId: string;
  /**
   *
   * @type {string}
   * @memberof LogisticsOperatorTruckOutputDTO
   */
  truckLicensePlate: string;
}
/**
 *
 * @export
 * @interface NotVisibleReservationQueryDTO
 */
export interface NotVisibleReservationQueryDTO {
  /**
   *
   * @type {string}
   * @memberof NotVisibleReservationQueryDTO
   */
  startStamp?: string | null;
  /**
   *
   * @type {string}
   * @memberof NotVisibleReservationQueryDTO
   */
  endStamp?: string | null;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum OrderByType {
  Ascending = 'Ascending',
  Descending = 'Descending',
}

/**
 *
 * @export
 * @interface PaginatedCompanyQueryDTO
 */
export interface PaginatedCompanyQueryDTO {
  /**
   *
   * @type {string}
   * @memberof PaginatedCompanyQueryDTO
   */
  searchWord?: string | null;
  /**
   *
   * @type {number}
   * @memberof PaginatedCompanyQueryDTO
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedCompanyQueryDTO
   */
  pageSize?: number;
  /**
   *
   * @type {OrderByType}
   * @memberof PaginatedCompanyQueryDTO
   */
  orderByType?: OrderByType;
  /**
   *
   * @type {CompanySortColumnName}
   * @memberof PaginatedCompanyQueryDTO
   */
  sortColumnName?: CompanySortColumnName;
}
/**
 *
 * @export
 * @interface PaginatedReservationsQueryDTO
 */
export interface PaginatedReservationsQueryDTO {
  /**
   *
   * @type {ReservationType}
   * @memberof PaginatedReservationsQueryDTO
   */
  type?: ReservationType;
  /**
   *
   * @type {boolean}
   * @memberof PaginatedReservationsQueryDTO
   */
  anyReservationType?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedReservationsQueryDTO
   */
  startStamp?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedReservationsQueryDTO
   */
  endStamp?: string | null;
  /**
   *
   * @type {number}
   * @memberof PaginatedReservationsQueryDTO
   */
  orderNo?: number | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedReservationsQueryDTO
   */
  reservationId?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedReservationsQueryDTO
   */
  companyId?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedReservationsQueryDTO
   */
  siteId?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedReservationsQueryDTO
   */
  equipmentId?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedReservationsQueryDTO
   */
  customerCompanyId?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedReservationsQueryDTO
   */
  logisticsOperatorCompanyId?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedReservationsQueryDTO
   */
  deliveryDestinationId?: string | null;
  /**
   *
   * @type {number}
   * @memberof PaginatedReservationsQueryDTO
   */
  pageNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedReservationsQueryDTO
   */
  pageSize?: number;
  /**
   *
   * @type {OrderByType}
   * @memberof PaginatedReservationsQueryDTO
   */
  orderByType?: OrderByType;
  /**
   *
   * @type {ReservationSortColumnName}
   * @memberof PaginatedReservationsQueryDTO
   */
  sortByColumn?: ReservationSortColumnName;
}
/**
 *
 * @export
 * @interface PermissionDTO
 */
export interface PermissionDTO {
  /**
   *
   * @type {string}
   * @memberof PermissionDTO
   */
  id?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof PermissionDTO
   */
  scopes?: Array<string> | null;
}
/**
 *
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
  [key: string]: any | any;

  /**
   *
   * @type {string}
   * @memberof ProblemDetails
   */
  type?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProblemDetails
   */
  title?: string | null;
  /**
   *
   * @type {number}
   * @memberof ProblemDetails
   */
  status?: number | null;
  /**
   *
   * @type {string}
   * @memberof ProblemDetails
   */
  detail?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProblemDetails
   */
  instance?: string | null;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum RequiredAzureResources {
  None = 'None',
  DnaConnectDataCollection = 'DnaConnectDataCollection',
  ApiDataCollection = 'ApiDataCollection',
  EdgeGatewayDataCollection = 'EdgeGatewayDataCollection',
}

/**
 *
 * @export
 * @interface ReservationAvailableProductEquipmentOutputDTO
 */
export interface ReservationAvailableProductEquipmentOutputDTO {
  /**
   *
   * @type {string}
   * @memberof ReservationAvailableProductEquipmentOutputDTO
   */
  equipmentId?: string;
  /**
   * True if this equipment supports Loading  type reservations.
   * @type {boolean}
   * @memberof ReservationAvailableProductEquipmentOutputDTO
   */
  canProvide?: boolean;
  /**
   * True if this equipment supports Unloading  type reservations.
   * @type {boolean}
   * @memberof ReservationAvailableProductEquipmentOutputDTO
   */
  canReceive?: boolean;
  /**
   * Id of the site that the equipment belongs  to. Helps with filtering data so  there is no need to map the equipments  to sites in front-end.
   * @type {string}
   * @memberof ReservationAvailableProductEquipmentOutputDTO
   */
  siteId?: string;
}
/**
 *
 * @export
 * @interface ReservationAvailableProductOutputDTO
 */
export interface ReservationAvailableProductOutputDTO {
  /**
   *
   * @type {string}
   * @memberof ReservationAvailableProductOutputDTO
   */
  productId?: string;
  /**
   *
   * @type {string}
   * @memberof ReservationAvailableProductOutputDTO
   */
  productDisplayName?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ReservationAvailableProductOutputDTO
   */
  allowedCustomerCompanyIds?: Array<string> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ReservationAvailableProductOutputDTO
   */
  allowedLogisticsOperatorCompanyIds?: Array<string> | null;
  /**
   *
   * @type {Array<ReservationAvailableProductEquipmentOutputDTO>}
   * @memberof ReservationAvailableProductOutputDTO
   */
  allowedEquipments?: Array<ReservationAvailableProductEquipmentOutputDTO> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ReservationAvailableProductOutputDTO
   */
  allowedAssetIds?: Array<string> | null;
}
/**
 *
 * @export
 * @interface ReservationAvailableProductsOutputDTO
 */
export interface ReservationAvailableProductsOutputDTO {
  /**
   *
   * @type {Array<ReservationAvailableProductOutputDTO>}
   * @memberof ReservationAvailableProductsOutputDTO
   */
  products?: Array<ReservationAvailableProductOutputDTO> | null;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof ReservationAvailableProductsOutputDTO
   */
  defaultProductIdsByCustomerCompanyId?: {[key: string]: string} | null;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof ReservationAvailableProductsOutputDTO
   */
  defaultProductIdsByLogisticsOperatorCompanyId?: {
    [key: string]: string;
  } | null;
}
/**
 *
 * @export
 * @interface ReservationCustomerOutputDTO
 */
export interface ReservationCustomerOutputDTO {
  /**
   * Company Guid
   * @type {string}
   * @memberof ReservationCustomerOutputDTO
   */
  companyId: string;
  /**
   * Organization Guid
   * @type {string}
   * @memberof ReservationCustomerOutputDTO
   */
  organizationId: string;
  /**
   * Company name
   * @type {string}
   * @memberof ReservationCustomerOutputDTO
   */
  displayName: string;
  /**
   * VAT
   * @type {string}
   * @memberof ReservationCustomerOutputDTO
   */
  vat: string;
  /**
   * Sites
   * @type {Array<string>}
   * @memberof ReservationCustomerOutputDTO
   */
  sitesIds?: Array<string> | null;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum ReservationDeletePermissions {
  None = 'None',
  ReservationDelete = 'ReservationDelete',
  MaintenanceDelete = 'MaintenanceDelete',
}

/**
 *
 * @export
 * @interface ReservationDeliveryDestinationOutputDTO
 */
export interface ReservationDeliveryDestinationOutputDTO {
  /**
   *
   * @type {string}
   * @memberof ReservationDeliveryDestinationOutputDTO
   */
  deliveryDestinationId?: string;
  /**
   *
   * @type {string}
   * @memberof ReservationDeliveryDestinationOutputDTO
   */
  displayName?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReservationDeliveryDestinationOutputDTO
   */
  companyId?: string;
  /**
   *
   * @type {string}
   * @memberof ReservationDeliveryDestinationOutputDTO
   */
  code?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReservationDeliveryDestinationOutputDTO
   */
  address?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ReservationDeliveryDestinationOutputDTO
   */
  isDisabled: boolean;
}
/**
 *
 * @export
 * @interface ReservationEquipmentOutputDTO
 */
export interface ReservationEquipmentOutputDTO {
  /**
   * Lot name
   * @type {string}
   * @memberof ReservationEquipmentOutputDTO
   */
  displayName?: string | null;
  /**
   * Equipment Guid
   * @type {string}
   * @memberof ReservationEquipmentOutputDTO
   */
  equipmentId?: string;
  /**
   * Company Id
   * @type {string}
   * @memberof ReservationEquipmentOutputDTO
   */
  companyId?: string;
  /**
   * Site Guid
   * @type {string}
   * @memberof ReservationEquipmentOutputDTO
   */
  siteId?: string;
  /**
   *
   * @type {EquipmentType}
   * @memberof ReservationEquipmentOutputDTO
   */
  type?: EquipmentType;
  /**
   *
   * @type {ReservationEquipmentUserNotificationOutputDTO}
   * @memberof ReservationEquipmentOutputDTO
   */
  userNotification?: ReservationEquipmentUserNotificationOutputDTO;
  /**
   * Default interval for loading. If set, overrides value specified on site.
   * @type {number}
   * @memberof ReservationEquipmentOutputDTO
   */
  defaultLoadingTimeIntervalMinutes?: number | null;
  /**
   * Default interval for unloading. If set, overrides value specified on site.
   * @type {number}
   * @memberof ReservationEquipmentOutputDTO
   */
  defaultUnloadingTimeIntervalMinutes?: number | null;
  /**
   * If set, a user can\'t make a loading reservation shorter than this.
   * @type {number}
   * @memberof ReservationEquipmentOutputDTO
   */
  minimumLoadingTimeIntervalMinutes?: number | null;
  /**
   * If set, a user can\'t make an unloading reservation shorter than this.
   * @type {number}
   * @memberof ReservationEquipmentOutputDTO
   */
  minimumUnloadingTimeIntervalMinutes?: number | null;
}
/**
 *
 * @export
 * @interface ReservationEquipmentUserNotificationOutputDTO
 */
export interface ReservationEquipmentUserNotificationOutputDTO {
  /**
   *
   * @type {string}
   * @memberof ReservationEquipmentUserNotificationOutputDTO
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ReservationEquipmentUserNotificationOutputDTO
   */
  message?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReservationEquipmentUserNotificationOutputDTO
   */
  validFrom?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReservationEquipmentUserNotificationOutputDTO
   */
  validTo?: string | null;
}
/**
 *
 * @export
 * @interface ReservationInputDTO
 */
export interface ReservationInputDTO {
  /**
   *
   * @type {string}
   * @memberof ReservationInputDTO
   */
  companyId: string;
  /**
   *
   * @type {string}
   * @memberof ReservationInputDTO
   */
  siteId: string;
  /**
   *
   * @type {string}
   * @memberof ReservationInputDTO
   */
  equipmentId: string;
  /**
   *
   * @type {string}
   * @memberof ReservationInputDTO
   */
  startStamp: string;
  /**
   *
   * @type {string}
   * @memberof ReservationInputDTO
   */
  endStamp: string;
  /**
   *
   * @type {string}
   * @memberof ReservationInputDTO
   */
  deliveryDestinationId?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReservationInputDTO
   */
  customerCompanyId?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReservationInputDTO
   */
  logisticsOperatorCompanyId?: string | null;
  /**
   *
   * @type {number}
   * @memberof ReservationInputDTO
   */
  orderNo?: number | null;
  /**
   *
   * @type {number}
   * @memberof ReservationInputDTO
   */
  amount?: number | null;
  /**
   *
   * @type {ReservationType}
   * @memberof ReservationInputDTO
   */
  type?: ReservationType;
  /**
   *
   * @type {string}
   * @memberof ReservationInputDTO
   */
  displayName?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReservationInputDTO
   */
  comments?: string | null;
  /**
   * Setting this field to true will mark  the reservation as having been completed  which locks modifications to only terminal  operators. Currently only terminal  operators can update this field and  it should be left null when a logistics  operator updates the reservation.
   * @type {boolean}
   * @memberof ReservationInputDTO
   */
  isCompleted?: boolean | null;
  /**
   * Id of the product this reservation is for.
   * @type {string}
   * @memberof ReservationInputDTO
   */
  productId?: string | null;
  /**
   * The id of the truck asset that will  transport this reservation.  Must be connected to the company  identified by Valmet.Iop.App.Api.V1.Reservations.Dtos.ReservationInputDTO.CompanyId  and the logistics operator identified by  Valmet.Iop.App.Api.V1.Reservations.Dtos.ReservationInputDTO.LogisticsOperatorCompanyId.
   * @type {string}
   * @memberof ReservationInputDTO
   */
  truckAssetId?: string | null;
  /**
   * Reference order number, optional, must be enabled via company   parameter Valmet.Iop.App.Sql.Repositories.Shared.KnownParameterCodes.ReservationsReferenceOrderNumberFieldEnabled.  If enabled, provided value must be unique across company.
   * @type {string}
   * @memberof ReservationInputDTO
   */
  referenceOrderNumber?: string | null;
}
/**
 *
 * @export
 * @interface ReservationOutputDTO
 */
export interface ReservationOutputDTO {
  /**
   *
   * @type {string}
   * @memberof ReservationOutputDTO
   */
  reservationId: string;
  /**
   *
   * @type {string}
   * @memberof ReservationOutputDTO
   */
  companyId: string;
  /**
   *
   * @type {string}
   * @memberof ReservationOutputDTO
   */
  companyName?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReservationOutputDTO
   */
  siteId: string;
  /**
   *
   * @type {string}
   * @memberof ReservationOutputDTO
   */
  siteName?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReservationOutputDTO
   */
  equipmentId: string;
  /**
   *
   * @type {string}
   * @memberof ReservationOutputDTO
   */
  equipmentName?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReservationOutputDTO
   */
  startStamp: string;
  /**
   *
   * @type {string}
   * @memberof ReservationOutputDTO
   */
  endStamp: string;
  /**
   *
   * @type {string}
   * @memberof ReservationOutputDTO
   */
  deliveryDestinationId?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReservationOutputDTO
   */
  deliveryDestinationName?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReservationOutputDTO
   */
  deliveryDestinationCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReservationOutputDTO
   */
  deliveryDestinationAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReservationOutputDTO
   */
  customerCompanyId?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReservationOutputDTO
   */
  customerCompanyName?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReservationOutputDTO
   */
  logisticsOperatorCompanyId?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReservationOutputDTO
   */
  logisticsOperatorName?: string | null;
  /**
   *
   * @type {number}
   * @memberof ReservationOutputDTO
   */
  orderNo?: number | null;
  /**
   *
   * @type {number}
   * @memberof ReservationOutputDTO
   */
  amount?: number | null;
  /**
   *
   * @type {ReservationType}
   * @memberof ReservationOutputDTO
   */
  type?: ReservationType;
  /**
   *
   * @type {string}
   * @memberof ReservationOutputDTO
   */
  displayName?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReservationOutputDTO
   */
  comments?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ReservationOutputDTO
   */
  isCompleted: boolean;
  /**
   *
   * @type {ReservationUpdatePermissions}
   * @memberof ReservationOutputDTO
   */
  updatePermissions: ReservationUpdatePermissions;
  /**
   *
   * @type {ReservationDeletePermissions}
   * @memberof ReservationOutputDTO
   */
  deletePermissions: ReservationDeletePermissions;
  /**
   *
   * @type {string}
   * @memberof ReservationOutputDTO
   */
  productId?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReservationOutputDTO
   */
  productDisplayName?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReservationOutputDTO
   */
  truckAssetId?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReservationOutputDTO
   */
  truckAssetLicensePlate?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReservationOutputDTO
   */
  referenceOrderNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReservationOutputDTO
   */
  createdByEmail?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReservationOutputDTO
   */
  modifiedByEmail?: string | null;
}
/**
 *
 * @export
 * @interface ReservationOutputDTOPagedResponse
 */
export interface ReservationOutputDTOPagedResponse {
  /**
   *
   * @type {Array<ReservationOutputDTO>}
   * @memberof ReservationOutputDTOPagedResponse
   */
  data?: Array<ReservationOutputDTO> | null;
  /**
   *
   * @type {number}
   * @memberof ReservationOutputDTOPagedResponse
   */
  pageNumber?: number | null;
  /**
   *
   * @type {number}
   * @memberof ReservationOutputDTOPagedResponse
   */
  pageSize?: number | null;
  /**
   *
   * @type {number}
   * @memberof ReservationOutputDTOPagedResponse
   */
  resultCount?: number | null;
  /**
   *
   * @type {string}
   * @memberof ReservationOutputDTOPagedResponse
   */
  sortColumnName?: string | null;
  /**
   *
   * @type {OrderByType}
   * @memberof ReservationOutputDTOPagedResponse
   */
  orderByType?: OrderByType;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum ReservationSortColumnName {
  Type = 'Type',
  DisplayName = 'DisplayName',
  StartStamp = 'StartStamp',
  EndStamp = 'EndStamp',
  OrderNo = 'OrderNo',
  Amount = 'Amount',
  CreatedAt = 'CreatedAt',
}

/**
 *
 * @export
 * @interface ReservationTimestampOutputDTO
 */
export interface ReservationTimestampOutputDTO {
  /**
   *
   * @type {string}
   * @memberof ReservationTimestampOutputDTO
   */
  startStamp?: string;
  /**
   *
   * @type {string}
   * @memberof ReservationTimestampOutputDTO
   */
  endStamp?: string;
  /**
   *
   * @type {string}
   * @memberof ReservationTimestampOutputDTO
   */
  siteId?: string;
  /**
   *
   * @type {string}
   * @memberof ReservationTimestampOutputDTO
   */
  siteName: string;
  /**
   *
   * @type {string}
   * @memberof ReservationTimestampOutputDTO
   */
  bayId?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReservationTimestampOutputDTO
   */
  bayName: string;
  /**
   * Has a value if site allows logistics operators to  see each others codes.
   * @type {string}
   * @memberof ReservationTimestampOutputDTO
   */
  logisticsOperatorCode?: string | null;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum ReservationType {
  Maintenance = 'Maintenance',
  ReservationLoading = 'ReservationLoading',
  ReservationUnloading = 'ReservationUnloading',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum ReservationUpdatePermissions {
  None = 'None',
  ReservationUpdateAllFields = 'ReservationUpdateAllFields',
  ReservationUpdateRestrictedFields = 'ReservationUpdateRestrictedFields',
  MaintenanceUpdate = 'MaintenanceUpdate',
}

/**
 *
 * @export
 * @interface ReservationUpdateRestrictedInputDTO
 */
export interface ReservationUpdateRestrictedInputDTO {
  /**
   *
   * @type {string}
   * @memberof ReservationUpdateRestrictedInputDTO
   */
  startStamp: string;
  /**
   *
   * @type {string}
   * @memberof ReservationUpdateRestrictedInputDTO
   */
  endStamp: string;
  /**
   *
   * @type {string}
   * @memberof ReservationUpdateRestrictedInputDTO
   */
  deliveryDestinationId: string;
  /**
   *
   * @type {boolean}
   * @memberof ReservationUpdateRestrictedInputDTO
   */
  isCompleted?: boolean | null;
}
/**
 *
 * @export
 * @interface SearchCompaniesOutputDTO
 */
export interface SearchCompaniesOutputDTO {
  /**
   * Company Guid
   * @type {string}
   * @memberof SearchCompaniesOutputDTO
   */
  companyId: string;
  /**
   * Organization Guid
   * @type {string}
   * @memberof SearchCompaniesOutputDTO
   */
  organizationId: string;
  /**
   * Company name
   * @type {string}
   * @memberof SearchCompaniesOutputDTO
   */
  displayName: string;
  /**
   * VAT
   * @type {string}
   * @memberof SearchCompaniesOutputDTO
   */
  vat: string;
  /**
   *
   * @type {Array<string>}
   * @memberof SearchCompaniesOutputDTO
   */
  assignedTypes?: Array<string> | null;
}
/**
 *
 * @export
 * @interface SearchCompaniesOutputDTOPagedResponse
 */
export interface SearchCompaniesOutputDTOPagedResponse {
  /**
   *
   * @type {Array<SearchCompaniesOutputDTO>}
   * @memberof SearchCompaniesOutputDTOPagedResponse
   */
  data?: Array<SearchCompaniesOutputDTO> | null;
  /**
   *
   * @type {number}
   * @memberof SearchCompaniesOutputDTOPagedResponse
   */
  pageNumber?: number | null;
  /**
   *
   * @type {number}
   * @memberof SearchCompaniesOutputDTOPagedResponse
   */
  pageSize?: number | null;
  /**
   *
   * @type {number}
   * @memberof SearchCompaniesOutputDTOPagedResponse
   */
  resultCount?: number | null;
  /**
   *
   * @type {string}
   * @memberof SearchCompaniesOutputDTOPagedResponse
   */
  sortColumnName?: string | null;
  /**
   *
   * @type {OrderByType}
   * @memberof SearchCompaniesOutputDTOPagedResponse
   */
  orderByType?: OrderByType;
}
/**
 *
 * @export
 * @interface SearchEndpointMonitoringSitesFlatEquipmentOutputDTO
 */
export interface SearchEndpointMonitoringSitesFlatEquipmentOutputDTO {
  /**
   *
   * @type {string}
   * @memberof SearchEndpointMonitoringSitesFlatEquipmentOutputDTO
   */
  equipmentId: string;
  /**
   *
   * @type {string}
   * @memberof SearchEndpointMonitoringSitesFlatEquipmentOutputDTO
   */
  equipmentDisplayName: string;
  /**
   *
   * @type {TankForecastStatus}
   * @memberof SearchEndpointMonitoringSitesFlatEquipmentOutputDTO
   */
  tankForecastStatus: TankForecastStatus;
  /**
   *
   * @type {string}
   * @memberof SearchEndpointMonitoringSitesFlatEquipmentOutputDTO
   */
  tankLatestLevelTimestamp?: string | null;
  /**
   *
   * @type {number}
   * @memberof SearchEndpointMonitoringSitesFlatEquipmentOutputDTO
   */
  tankCapacityKilograms?: number | null;
  /**
   *
   * @type {number}
   * @memberof SearchEndpointMonitoringSitesFlatEquipmentOutputDTO
   */
  tankMaximumFillLevelKilograms?: number | null;
  /**
   *
   * @type {number}
   * @memberof SearchEndpointMonitoringSitesFlatEquipmentOutputDTO
   */
  tankMinimumFillLevelKilograms?: number | null;
  /**
   *
   * @type {number}
   * @memberof SearchEndpointMonitoringSitesFlatEquipmentOutputDTO
   */
  tankCurrentLevelKilograms?: number | null;
}
/**
 *
 * @export
 * @interface SearchEndpointMonitoringSitesFlatInputDTO
 */
export interface SearchEndpointMonitoringSitesFlatInputDTO {
  /**
   * Id of the company to search sites for.  Required.
   * @type {string}
   * @memberof SearchEndpointMonitoringSitesFlatInputDTO
   */
  companyId?: string | null;
  /**
   * Text value to filter sites with.  Matches e.g. to site display name. Optional.
   * @type {string}
   * @memberof SearchEndpointMonitoringSitesFlatInputDTO
   */
  searchText?: string | null;
  /**
   * 2-character country code used to search for sites in  one country. Optional.
   * @type {string}
   * @memberof SearchEndpointMonitoringSitesFlatInputDTO
   */
  country?: string | null;
  /**
   * Id of the product to search for within sites.  Only sites that have this product will be  returned. Optional.
   * @type {string}
   * @memberof SearchEndpointMonitoringSitesFlatInputDTO
   */
  productId?: string | null;
  /**
   * Id of the base product to search for within sites.  Only sites that have a product that have this base  product are returned. Optional.
   * @type {string}
   * @memberof SearchEndpointMonitoringSitesFlatInputDTO
   */
  baseProductId?: string | null;
  /**
   *
   * @type {SiteType}
   * @memberof SearchEndpointMonitoringSitesFlatInputDTO
   */
  siteType?: SiteType;
  /**
   * 1-based page number
   * @type {number}
   * @memberof SearchEndpointMonitoringSitesFlatInputDTO
   */
  pageNumber?: number | null;
  /**
   * How many sites are returned at most
   * @type {number}
   * @memberof SearchEndpointMonitoringSitesFlatInputDTO
   */
  pageSize?: number | null;
  /**
   *
   * @type {OrderByType}
   * @memberof SearchEndpointMonitoringSitesFlatInputDTO
   */
  orderByType?: OrderByType;
  /**
   *
   * @type {SearchSitesFlatSortColumn}
   * @memberof SearchEndpointMonitoringSitesFlatInputDTO
   */
  sortByColumn?: SearchSitesFlatSortColumn;
}
/**
 *
 * @export
 * @interface SearchEndpointMonitoringSitesFlatOutputDTO
 */
export interface SearchEndpointMonitoringSitesFlatOutputDTO {
  /**
   *
   * @type {string}
   * @memberof SearchEndpointMonitoringSitesFlatOutputDTO
   */
  siteId: string;
  /**
   *
   * @type {string}
   * @memberof SearchEndpointMonitoringSitesFlatOutputDTO
   */
  siteDisplayName: string;
  /**
   *
   * @type {SiteForecastStatus}
   * @memberof SearchEndpointMonitoringSitesFlatOutputDTO
   */
  siteForecastStatus: SiteForecastStatus;
  /**
   *
   * @type {number}
   * @memberof SearchEndpointMonitoringSitesFlatOutputDTO
   */
  totalMeanUsage?: number | null;
  /**
   *
   * @type {SearchEndpointMonitoringSitesFlatOutputDTORunout}
   * @memberof SearchEndpointMonitoringSitesFlatOutputDTO
   */
  runout: SearchEndpointMonitoringSitesFlatOutputDTORunout;
  /**
   *
   * @type {Array<SearchEndpointMonitoringSitesFlatEquipmentOutputDTO>}
   * @memberof SearchEndpointMonitoringSitesFlatOutputDTO
   */
  equipments: Array<SearchEndpointMonitoringSitesFlatEquipmentOutputDTO>;
}
/**
 *
 * @export
 * @interface SearchEndpointMonitoringSitesFlatOutputDTOPagedResponse
 */
export interface SearchEndpointMonitoringSitesFlatOutputDTOPagedResponse {
  /**
   *
   * @type {Array<SearchEndpointMonitoringSitesFlatOutputDTO>}
   * @memberof SearchEndpointMonitoringSitesFlatOutputDTOPagedResponse
   */
  data?: Array<SearchEndpointMonitoringSitesFlatOutputDTO> | null;
  /**
   *
   * @type {number}
   * @memberof SearchEndpointMonitoringSitesFlatOutputDTOPagedResponse
   */
  pageNumber?: number | null;
  /**
   *
   * @type {number}
   * @memberof SearchEndpointMonitoringSitesFlatOutputDTOPagedResponse
   */
  pageSize?: number | null;
  /**
   *
   * @type {number}
   * @memberof SearchEndpointMonitoringSitesFlatOutputDTOPagedResponse
   */
  resultCount?: number | null;
  /**
   *
   * @type {string}
   * @memberof SearchEndpointMonitoringSitesFlatOutputDTOPagedResponse
   */
  sortColumnName?: string | null;
  /**
   *
   * @type {OrderByType}
   * @memberof SearchEndpointMonitoringSitesFlatOutputDTOPagedResponse
   */
  orderByType?: OrderByType;
}
/**
 *
 * @export
 * @interface SearchEndpointMonitoringSitesFlatOutputDTORunout
 */
export interface SearchEndpointMonitoringSitesFlatOutputDTORunout {
  /**
   *
   * @type {string}
   * @memberof SearchEndpointMonitoringSitesFlatOutputDTORunout
   */
  runoutTime?: string | null;
  /**
   *
   * @type {string}
   * @memberof SearchEndpointMonitoringSitesFlatOutputDTORunout
   */
  siteTimeZone?: string | null;
  /**
   *
   * @type {string}
   * @memberof SearchEndpointMonitoringSitesFlatOutputDTORunout
   */
  runoutTimeSource?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof SearchEndpointMonitoringSitesFlatOutputDTORunout
   */
  isRunoutWarningActive?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SearchEndpointMonitoringSitesFlatOutputDTORunout
   */
  isRunoutAlarmActive?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SearchEndpointMonitoringSitesFlatOutputDTORunout
   */
  isRunoutAfterMaxDaysToForecast?: boolean;
  /**
   *
   * @type {number}
   * @memberof SearchEndpointMonitoringSitesFlatOutputDTORunout
   */
  maxDaysToForecast?: number | null;
}
/**
 *
 * @export
 * @interface SearchEndpointMonitoringSitesHierarchicalChildSiteOutputDTO
 */
export interface SearchEndpointMonitoringSitesHierarchicalChildSiteOutputDTO {
  /**
   *
   * @type {string}
   * @memberof SearchEndpointMonitoringSitesHierarchicalChildSiteOutputDTO
   */
  siteId: string;
  /**
   *
   * @type {string}
   * @memberof SearchEndpointMonitoringSitesHierarchicalChildSiteOutputDTO
   */
  siteDisplayName: string;
  /**
   *
   * @type {EndpointMonitoringSitesRunout}
   * @memberof SearchEndpointMonitoringSitesHierarchicalChildSiteOutputDTO
   */
  runout: EndpointMonitoringSitesRunout;
  /**
   *
   * @type {SiteForecastStatus}
   * @memberof SearchEndpointMonitoringSitesHierarchicalChildSiteOutputDTO
   */
  siteForecastStatus: SiteForecastStatus;
  /**
   *
   * @type {number}
   * @memberof SearchEndpointMonitoringSitesHierarchicalChildSiteOutputDTO
   */
  totalMeanUsage?: number | null;
  /**
   *
   * @type {Array<SearchEndpointMonitoringSitesHierarchicalEquipmentOutputDTO>}
   * @memberof SearchEndpointMonitoringSitesHierarchicalChildSiteOutputDTO
   */
  equipments: Array<SearchEndpointMonitoringSitesHierarchicalEquipmentOutputDTO>;
}
/**
 *
 * @export
 * @interface SearchEndpointMonitoringSitesHierarchicalEquipmentOutputDTO
 */
export interface SearchEndpointMonitoringSitesHierarchicalEquipmentOutputDTO {
  /**
   *
   * @type {string}
   * @memberof SearchEndpointMonitoringSitesHierarchicalEquipmentOutputDTO
   */
  equipmentId: string;
  /**
   *
   * @type {string}
   * @memberof SearchEndpointMonitoringSitesHierarchicalEquipmentOutputDTO
   */
  equipmentDisplayName: string;
  /**
   *
   * @type {TankForecastStatus}
   * @memberof SearchEndpointMonitoringSitesHierarchicalEquipmentOutputDTO
   */
  tankForecastStatus: TankForecastStatus;
  /**
   *
   * @type {string}
   * @memberof SearchEndpointMonitoringSitesHierarchicalEquipmentOutputDTO
   */
  tankLatestLevelTimestamp?: string | null;
  /**
   *
   * @type {number}
   * @memberof SearchEndpointMonitoringSitesHierarchicalEquipmentOutputDTO
   */
  tankCapacityKilograms?: number | null;
  /**
   *
   * @type {number}
   * @memberof SearchEndpointMonitoringSitesHierarchicalEquipmentOutputDTO
   */
  tankCurrentLevelKilograms?: number | null;
  /**
   *
   * @type {number}
   * @memberof SearchEndpointMonitoringSitesHierarchicalEquipmentOutputDTO
   */
  tankMaximumFillLevelKilograms?: number | null;
  /**
   *
   * @type {number}
   * @memberof SearchEndpointMonitoringSitesHierarchicalEquipmentOutputDTO
   */
  tankMinimumFillLevelKilograms?: number | null;
}
/**
 *
 * @export
 * @interface SearchEndpointMonitoringSitesHierarchicalInputDTO
 */
export interface SearchEndpointMonitoringSitesHierarchicalInputDTO {
  /**
   * Id of the company to search sites for.  Required.
   * @type {string}
   * @memberof SearchEndpointMonitoringSitesHierarchicalInputDTO
   */
  companyId?: string | null;
  /**
   * Text value to filter sites with.  Matches e.g. to site display name. Optional.
   * @type {string}
   * @memberof SearchEndpointMonitoringSitesHierarchicalInputDTO
   */
  searchText?: string | null;
  /**
   * 2-character country code used to search for sites in  one country. Optional.
   * @type {string}
   * @memberof SearchEndpointMonitoringSitesHierarchicalInputDTO
   */
  country?: string | null;
  /**
   * Id of the product to search for within sites.  Only sites that have this product will be  returned. Optional.
   * @type {string}
   * @memberof SearchEndpointMonitoringSitesHierarchicalInputDTO
   */
  productId?: string | null;
  /**
   * Id of the base product to search for within sites.  Only sites that have a product that have this base  product are returned. Optional.
   * @type {string}
   * @memberof SearchEndpointMonitoringSitesHierarchicalInputDTO
   */
  baseProductId?: string | null;
  /**
   *
   * @type {SiteType}
   * @memberof SearchEndpointMonitoringSitesHierarchicalInputDTO
   */
  siteType?: SiteType;
  /**
   * 1-based page number
   * @type {number}
   * @memberof SearchEndpointMonitoringSitesHierarchicalInputDTO
   */
  pageNumber?: number | null;
  /**
   * How many sites are returned at most
   * @type {number}
   * @memberof SearchEndpointMonitoringSitesHierarchicalInputDTO
   */
  pageSize?: number | null;
  /**
   *
   * @type {OrderByType}
   * @memberof SearchEndpointMonitoringSitesHierarchicalInputDTO
   */
  orderByType?: OrderByType;
  /**
   *
   * @type {SearchSitesHierarchicalSortColumn}
   * @memberof SearchEndpointMonitoringSitesHierarchicalInputDTO
   */
  sortByColumn?: SearchSitesHierarchicalSortColumn;
}
/**
 *
 * @export
 * @interface SearchEndpointMonitoringSitesHierarchicalOutputDTO
 */
export interface SearchEndpointMonitoringSitesHierarchicalOutputDTO {
  /**
   *
   * @type {string}
   * @memberof SearchEndpointMonitoringSitesHierarchicalOutputDTO
   */
  siteId: string;
  /**
   *
   * @type {string}
   * @memberof SearchEndpointMonitoringSitesHierarchicalOutputDTO
   */
  siteDisplayName: string;
  /**
   *
   * @type {SiteForecastStatus}
   * @memberof SearchEndpointMonitoringSitesHierarchicalOutputDTO
   */
  siteForecastStatus: SiteForecastStatus;
  /**
   *
   * @type {SearchEndpointMonitoringSitesHierarchicalOutputDTORunout}
   * @memberof SearchEndpointMonitoringSitesHierarchicalOutputDTO
   */
  runout: SearchEndpointMonitoringSitesHierarchicalOutputDTORunout;
  /**
   *
   * @type {number}
   * @memberof SearchEndpointMonitoringSitesHierarchicalOutputDTO
   */
  totalMeanUsage?: number | null;
  /**
   *
   * @type {Array<SearchEndpointMonitoringSitesHierarchicalEquipmentOutputDTO>}
   * @memberof SearchEndpointMonitoringSitesHierarchicalOutputDTO
   */
  equipments: Array<SearchEndpointMonitoringSitesHierarchicalEquipmentOutputDTO>;
  /**
   *
   * @type {Array<SearchEndpointMonitoringSitesHierarchicalChildSiteOutputDTO>}
   * @memberof SearchEndpointMonitoringSitesHierarchicalOutputDTO
   */
  childSites: Array<SearchEndpointMonitoringSitesHierarchicalChildSiteOutputDTO>;
}
/**
 *
 * @export
 * @interface SearchEndpointMonitoringSitesHierarchicalOutputDTOPagedResponse
 */
export interface SearchEndpointMonitoringSitesHierarchicalOutputDTOPagedResponse {
  /**
   *
   * @type {Array<SearchEndpointMonitoringSitesHierarchicalOutputDTO>}
   * @memberof SearchEndpointMonitoringSitesHierarchicalOutputDTOPagedResponse
   */
  data?: Array<SearchEndpointMonitoringSitesHierarchicalOutputDTO> | null;
  /**
   *
   * @type {number}
   * @memberof SearchEndpointMonitoringSitesHierarchicalOutputDTOPagedResponse
   */
  pageNumber?: number | null;
  /**
   *
   * @type {number}
   * @memberof SearchEndpointMonitoringSitesHierarchicalOutputDTOPagedResponse
   */
  pageSize?: number | null;
  /**
   *
   * @type {number}
   * @memberof SearchEndpointMonitoringSitesHierarchicalOutputDTOPagedResponse
   */
  resultCount?: number | null;
  /**
   *
   * @type {string}
   * @memberof SearchEndpointMonitoringSitesHierarchicalOutputDTOPagedResponse
   */
  sortColumnName?: string | null;
  /**
   *
   * @type {OrderByType}
   * @memberof SearchEndpointMonitoringSitesHierarchicalOutputDTOPagedResponse
   */
  orderByType?: OrderByType;
}
/**
 *
 * @export
 * @interface SearchEndpointMonitoringSitesHierarchicalOutputDTORunout
 */
export interface SearchEndpointMonitoringSitesHierarchicalOutputDTORunout {
  /**
   *
   * @type {string}
   * @memberof SearchEndpointMonitoringSitesHierarchicalOutputDTORunout
   */
  runoutTime?: string | null;
  /**
   *
   * @type {string}
   * @memberof SearchEndpointMonitoringSitesHierarchicalOutputDTORunout
   */
  runoutTimeSource?: string | null;
  /**
   *
   * @type {string}
   * @memberof SearchEndpointMonitoringSitesHierarchicalOutputDTORunout
   */
  siteTimeZone?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof SearchEndpointMonitoringSitesHierarchicalOutputDTORunout
   */
  isRunoutAlarmActive?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SearchEndpointMonitoringSitesHierarchicalOutputDTORunout
   */
  isRunoutWarningActive?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SearchEndpointMonitoringSitesHierarchicalOutputDTORunout
   */
  isRunoutAfterMaxDaysToForecast?: boolean;
  /**
   *
   * @type {number}
   * @memberof SearchEndpointMonitoringSitesHierarchicalOutputDTORunout
   */
  maxDaysToForecast?: number | null;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum SearchSitesFlatSortColumn {
  DisplayName = 'DisplayName',
  RunoutTime = 'RunoutTime',
  TotalMeanUsage = 'TotalMeanUsage',
}

/**
 *
 * @export
 * @enum {string}
 */
export enum SearchSitesHierarchicalSortColumn {
  DisplayName = 'DisplayName',
  RunoutTime = 'RunoutTime',
  TotalMeanUsage = 'TotalMeanUsage',
}

/**
 *
 * @export
 * @interface ServiceBusConfigInputDTO
 */
export interface ServiceBusConfigInputDTO {
  /**
   *
   * @type {string}
   * @memberof ServiceBusConfigInputDTO
   */
  queueName: string;
  /**
   *
   * @type {string}
   * @memberof ServiceBusConfigInputDTO
   */
  fdmMessageReference: string;
}
/**
 *
 * @export
 * @interface ServiceBusConfigOutputDTO
 */
export interface ServiceBusConfigOutputDTO {
  /**
   *
   * @type {string}
   * @memberof ServiceBusConfigOutputDTO
   */
  serviceBusConfigId?: string;
  /**
   *
   * @type {string}
   * @memberof ServiceBusConfigOutputDTO
   */
  queueName?: string | null;
  /**
   *
   * @type {string}
   * @memberof ServiceBusConfigOutputDTO
   */
  fdmMessageReference?: string | null;
}
/**
 *
 * @export
 * @interface SiteCachedForecastOutputDTO
 */
export interface SiteCachedForecastOutputDTO {
  /**
   *
   * @type {string}
   * @memberof SiteCachedForecastOutputDTO
   */
  firstFillTimestamp?: string | null;
  /**
   *
   * @type {string}
   * @memberof SiteCachedForecastOutputDTO
   */
  lastFillTimestamp?: string | null;
  /**
   *
   * @type {string}
   * @memberof SiteCachedForecastOutputDTO
   */
  zeroTimestamp?: string | null;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof SiteCachedForecastOutputDTO
   */
  historicalData: {[key: string]: number};
  /**
   *
   * @type {{ [key: string]: ForecastConfidenceValuesDTO; }}
   * @memberof SiteCachedForecastOutputDTO
   */
  forecastData: {[key: string]: ForecastConfidenceValuesDTO};
  /**
   *
   * @type {Array<string>}
   * @memberof SiteCachedForecastOutputDTO
   */
  warnings: Array<string>;
  /**
   *
   * @type {string}
   * @memberof SiteCachedForecastOutputDTO
   */
  forecastCacheTimestamp: string;
  /**
   *
   * @type {number}
   * @memberof SiteCachedForecastOutputDTO
   */
  totalMeanUsage?: number | null;
  /**
   *
   * @type {ForecastType}
   * @memberof SiteCachedForecastOutputDTO
   */
  forecastType?: ForecastType;
  /**
   *
   * @type {number}
   * @memberof SiteCachedForecastOutputDTO
   */
  totalSiteCapacity?: number | null;
  /**
   *
   * @type {string}
   * @memberof SiteCachedForecastOutputDTO
   */
  totalSiteCapacityUnitAbbreviation?: string | null;
  /**
   *
   * @type {number}
   * @memberof SiteCachedForecastOutputDTO
   */
  firstFillLevel?: number | null;
  /**
   *
   * @type {number}
   * @memberof SiteCachedForecastOutputDTO
   */
  lastFillLevel?: number | null;
  /**
   *
   * @type {number}
   * @memberof SiteCachedForecastOutputDTO
   */
  warningLimit?: number | null;
  /**
   *
   * @type {number}
   * @memberof SiteCachedForecastOutputDTO
   */
  alarmLimit?: number | null;
  /**
   *
   * @type {number}
   * @memberof SiteCachedForecastOutputDTO
   */
  minimumLevelKg?: number | null;
  /**
   *
   * @type {number}
   * @memberof SiteCachedForecastOutputDTO
   */
  maximumLevelKg?: number | null;
}
/**
 *
 * @export
 * @interface SiteChildrenCachedForecastOutputDTO
 */
export interface SiteChildrenCachedForecastOutputDTO {
  /**
   *
   * @type {string}
   * @memberof SiteChildrenCachedForecastOutputDTO
   */
  parentSiteId: string;
  /**
   *
   * @type {Array<ChildSiteCachedForecastOutputDTO>}
   * @memberof SiteChildrenCachedForecastOutputDTO
   */
  childSiteForecasts?: Array<ChildSiteCachedForecastOutputDTO> | null;
}
/**
 *
 * @export
 * @interface SiteDirectForecastOutputDTO
 */
export interface SiteDirectForecastOutputDTO {
  /**
   *
   * @type {string}
   * @memberof SiteDirectForecastOutputDTO
   */
  firstFillTimestamp?: string | null;
  /**
   *
   * @type {string}
   * @memberof SiteDirectForecastOutputDTO
   */
  lastFillTimestamp?: string | null;
  /**
   *
   * @type {string}
   * @memberof SiteDirectForecastOutputDTO
   */
  zeroTimestamp?: string | null;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof SiteDirectForecastOutputDTO
   */
  historicalData: {[key: string]: number};
  /**
   *
   * @type {{ [key: string]: ForecastConfidenceValuesDTO; }}
   * @memberof SiteDirectForecastOutputDTO
   */
  forecastData: {[key: string]: ForecastConfidenceValuesDTO};
  /**
   *
   * @type {Array<string>}
   * @memberof SiteDirectForecastOutputDTO
   */
  warnings: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof SiteDirectForecastOutputDTO
   */
  errors: Array<string>;
  /**
   *
   * @type {number}
   * @memberof SiteDirectForecastOutputDTO
   */
  totalMeanUsage?: number | null;
  /**
   *
   * @type {number}
   * @memberof SiteDirectForecastOutputDTO
   */
  totalSiteCapacity?: number | null;
  /**
   *
   * @type {string}
   * @memberof SiteDirectForecastOutputDTO
   */
  totalSiteCapacityUnitAbbreviation?: string | null;
  /**
   *
   * @type {number}
   * @memberof SiteDirectForecastOutputDTO
   */
  firstFillLevel?: number | null;
  /**
   *
   * @type {number}
   * @memberof SiteDirectForecastOutputDTO
   */
  lastFillLevel?: number | null;
  /**
   *
   * @type {number}
   * @memberof SiteDirectForecastOutputDTO
   */
  warningLimit?: number | null;
  /**
   *
   * @type {number}
   * @memberof SiteDirectForecastOutputDTO
   */
  alarmLimit?: number | null;
  /**
   *
   * @type {number}
   * @memberof SiteDirectForecastOutputDTO
   */
  minimumLevelKg?: number | null;
  /**
   *
   * @type {number}
   * @memberof SiteDirectForecastOutputDTO
   */
  maximumLevelKg?: number | null;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum SiteForecastStatus {
  Unknown = 'Unknown',
  Ok = 'Ok',
  Warning = 'Warning',
  Error = 'Error',
}

/**
 *
 * @export
 * @interface SiteMeasurementsOutputDTO
 */
export interface SiteMeasurementsOutputDTO {
  /**
   *
   * @type {string}
   * @memberof SiteMeasurementsOutputDTO
   */
  siteId: string;
  /**
   *
   * @type {string}
   * @memberof SiteMeasurementsOutputDTO
   */
  displayName: string;
  /**
   *
   * @type {number}
   * @memberof SiteMeasurementsOutputDTO
   */
  totalCapacityAggregate?: number | null;
  /**
   *
   * @type {number}
   * @memberof SiteMeasurementsOutputDTO
   */
  latestLevelAggregate?: number | null;
  /**
   *
   * @type {string}
   * @memberof SiteMeasurementsOutputDTO
   */
  latestLevelUnitAbbreviation?: string | null;
  /**
   *
   * @type {number}
   * @memberof SiteMeasurementsOutputDTO
   */
  totalMinimumLevelAggregate?: number | null;
  /**
   *
   * @type {number}
   * @memberof SiteMeasurementsOutputDTO
   */
  totalMaximumLevelAggregate?: number | null;
  /**
   *
   * @type {Array<LatestTagValueDTO>}
   * @memberof SiteMeasurementsOutputDTO
   */
  latestTagValues?: Array<LatestTagValueDTO> | null;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum SiteParameterDataType {
  Numeric = 'Numeric',
  String = 'String',
  Toggle = 'Toggle',
  Choice = 'Choice',
  StringCollection = 'StringCollection',
}

/**
 *
 * @export
 * @interface SiteParameterOutputDTO
 */
export interface SiteParameterOutputDTO {
  /**
   *
   * @type {string}
   * @memberof SiteParameterOutputDTO
   */
  value: string;
  /**
   *
   * @type {SiteParameterDataType}
   * @memberof SiteParameterOutputDTO
   */
  dataType: SiteParameterDataType;
}
/**
 *
 * @export
 * @interface SiteRunoutOutputDTO
 */
export interface SiteRunoutOutputDTO {
  /**
   *
   * @type {string}
   * @memberof SiteRunoutOutputDTO
   */
  runoutTime?: string | null;
  /**
   *
   * @type {string}
   * @memberof SiteRunoutOutputDTO
   */
  siteTimeZone?: string | null;
  /**
   *
   * @type {string}
   * @memberof SiteRunoutOutputDTO
   */
  runoutTimeSource?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof SiteRunoutOutputDTO
   */
  isRunoutAfterMaxDaysToForecast?: boolean;
  /**
   *
   * @type {number}
   * @memberof SiteRunoutOutputDTO
   */
  maxDaysToForecast?: number | null;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum SiteType {
  DistributionTerminal = 'DistributionTerminal',
  LoadingStation = 'LoadingStation',
  FuelingStation = 'FuelingStation',
  CustomerTerminal = 'CustomerTerminal',
  Depot = 'Depot',
}

/**
 *
 * @export
 * @interface TagEquipmentTrendValueDTO
 */
export interface TagEquipmentTrendValueDTO {
  /**
   *
   * @type {string}
   * @memberof TagEquipmentTrendValueDTO
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof TagEquipmentTrendValueDTO
   */
  tagId: number;
  /**
   *
   * @type {string}
   * @memberof TagEquipmentTrendValueDTO
   */
  displayName: string;
  /**
   *
   * @type {string}
   * @memberof TagEquipmentTrendValueDTO
   */
  identifier: string;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof TagEquipmentTrendValueDTO
   */
  historicalData: {[key: string]: number};
  /**
   *
   * @type {string}
   * @memberof TagEquipmentTrendValueDTO
   */
  measurementUnitAbbreviation?: string | null;
  /**
   *
   * @type {number}
   * @memberof TagEquipmentTrendValueDTO
   */
  warningLow?: number | null;
  /**
   *
   * @type {number}
   * @memberof TagEquipmentTrendValueDTO
   */
  warningHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof TagEquipmentTrendValueDTO
   */
  alarmLow?: number | null;
  /**
   *
   * @type {number}
   * @memberof TagEquipmentTrendValueDTO
   */
  alarmHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof TagEquipmentTrendValueDTO
   */
  invalidHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof TagEquipmentTrendValueDTO
   */
  invalidLow?: number | null;
}
/**
 *
 * @export
 * @interface TagTrendValueDTO
 */
export interface TagTrendValueDTO {
  /**
   *
   * @type {string}
   * @memberof TagTrendValueDTO
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof TagTrendValueDTO
   */
  tagId: number;
  /**
   *
   * @type {string}
   * @memberof TagTrendValueDTO
   */
  displayName: string;
  /**
   *
   * @type {string}
   * @memberof TagTrendValueDTO
   */
  identifier: string;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof TagTrendValueDTO
   */
  historicalData: {[key: string]: number};
  /**
   *
   * @type {string}
   * @memberof TagTrendValueDTO
   */
  measurementUnitAbbreviation?: string | null;
  /**
   *
   * @type {number}
   * @memberof TagTrendValueDTO
   */
  warningLow?: number | null;
  /**
   *
   * @type {number}
   * @memberof TagTrendValueDTO
   */
  warningHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof TagTrendValueDTO
   */
  alarmLow?: number | null;
  /**
   *
   * @type {number}
   * @memberof TagTrendValueDTO
   */
  alarmHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof TagTrendValueDTO
   */
  invalidHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof TagTrendValueDTO
   */
  invalidLow?: number | null;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum TankForecastStatus {
  Unknown = 'Unknown',
  Ok = 'Ok',
  Warning = 'Warning',
  Error = 'Error',
}

/**
 *
 * @export
 * @interface UpdateCompanyChildCompanyInputDTO
 */
export interface UpdateCompanyChildCompanyInputDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateCompanyChildCompanyInputDTO
   */
  parentTypeId?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateCompanyChildCompanyInputDTO
   */
  childCompanyId?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateCompanyChildCompanyInputDTO
   */
  childTypeId?: string | null;
}
/**
 *
 * @export
 * @interface UpdateCompanyChildCompanyOutputDTO
 */
export interface UpdateCompanyChildCompanyOutputDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateCompanyChildCompanyOutputDTO
   */
  childCompanyId?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCompanyChildCompanyOutputDTO
   */
  childCompanyDisplayName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateCompanyChildCompanyOutputDTO
   */
  parentCompanyTypeId?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCompanyChildCompanyOutputDTO
   */
  parentCompanyTypeDisplayName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateCompanyChildCompanyOutputDTO
   */
  childCompanyTypeId?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCompanyChildCompanyOutputDTO
   */
  childCompanyTypeDisplayName?: string | null;
}
/**
 *
 * @export
 * @interface UpdateCompanyInputDTO
 */
export interface UpdateCompanyInputDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateCompanyInputDTO
   */
  vat?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateCompanyInputDTO
   */
  companyTypeIds?: Array<string> | null;
  /**
   *
   * @type {Array<UpdateCompanyChildCompanyInputDTO>}
   * @memberof UpdateCompanyInputDTO
   */
  childCompanies?: Array<UpdateCompanyChildCompanyInputDTO> | null;
  /**
   *
   * @type {RequiredAzureResources}
   * @memberof UpdateCompanyInputDTO
   */
  requiredAzureResources?: RequiredAzureResources;
}
/**
 *
 * @export
 * @interface UpdateCompanyOutputDTO
 */
export interface UpdateCompanyOutputDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateCompanyOutputDTO
   */
  vat?: string | null;
  /**
   *
   * @type {Array<UpdateCompanyTypeOutputDTO>}
   * @memberof UpdateCompanyOutputDTO
   */
  companyTypes?: Array<UpdateCompanyTypeOutputDTO> | null;
  /**
   *
   * @type {Array<UpdateCompanyChildCompanyOutputDTO>}
   * @memberof UpdateCompanyOutputDTO
   */
  childCompanies?: Array<UpdateCompanyChildCompanyOutputDTO> | null;
  /**
   *
   * @type {RequiredAzureResources}
   * @memberof UpdateCompanyOutputDTO
   */
  requiredAzureResources?: RequiredAzureResources;
}
/**
 *
 * @export
 * @interface UpdateCompanyTypeOutputDTO
 */
export interface UpdateCompanyTypeOutputDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateCompanyTypeOutputDTO
   */
  typeId?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCompanyTypeOutputDTO
   */
  typeDisplayName?: string | null;
}
/**
 *
 * @export
 * @interface UpdateTagOptionsInputDTO
 */
export interface UpdateTagOptionsInputDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateTagOptionsInputDTO
   */
  id?: string | null;
  /**
   *
   * @type {number}
   * @memberof UpdateTagOptionsInputDTO
   */
  warningLow?: number | null;
  /**
   *
   * @type {number}
   * @memberof UpdateTagOptionsInputDTO
   */
  warningHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof UpdateTagOptionsInputDTO
   */
  alarmLow?: number | null;
  /**
   *
   * @type {number}
   * @memberof UpdateTagOptionsInputDTO
   */
  alarmHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof UpdateTagOptionsInputDTO
   */
  invalidHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof UpdateTagOptionsInputDTO
   */
  invalidLow?: number | null;
}
/**
 *
 * @export
 * @interface UpdateTagOptionsOutputDTO
 */
export interface UpdateTagOptionsOutputDTO {
  /**
   *
   * @type {string}
   * @memberof UpdateTagOptionsOutputDTO
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof UpdateTagOptionsOutputDTO
   */
  warningLow?: number | null;
  /**
   *
   * @type {number}
   * @memberof UpdateTagOptionsOutputDTO
   */
  warningHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof UpdateTagOptionsOutputDTO
   */
  alarmLow?: number | null;
  /**
   *
   * @type {number}
   * @memberof UpdateTagOptionsOutputDTO
   */
  alarmHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof UpdateTagOptionsOutputDTO
   */
  invalidHigh?: number | null;
  /**
   *
   * @type {number}
   * @memberof UpdateTagOptionsOutputDTO
   */
  invalidLow?: number | null;
}
/**
 *
 * @export
 * @interface UserInformationOutputDTO
 */
export interface UserInformationOutputDTO {
  /**
   *
   * @type {string}
   * @memberof UserInformationOutputDTO
   */
  userId?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserInformationOutputDTO
   */
  userIsAdmin?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserInformationOutputDTO
   */
  userDisplayName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserInformationOutputDTO
   */
  userEmail?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserInformationOutputDTO
   */
  organizationId?: string;
  /**
   *
   * @type {string}
   * @memberof UserInformationOutputDTO
   */
  organizationName?: string | null;
  /**
   *
   * @type {Array<PermissionDTO>}
   * @memberof UserInformationOutputDTO
   */
  permissions?: Array<PermissionDTO> | null;
}
/**
 *
 * @export
 * @interface UserNotificationInputDTO
 */
export interface UserNotificationInputDTO {
  /**
   *
   * @type {string}
   * @memberof UserNotificationInputDTO
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof UserNotificationInputDTO
   */
  validFrom?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserNotificationInputDTO
   */
  validTo?: string | null;
}
/**
 *
 * @export
 * @interface UserNotificationOutputDTO
 */
export interface UserNotificationOutputDTO {
  /**
   *
   * @type {string}
   * @memberof UserNotificationOutputDTO
   */
  userNotificationId?: string;
  /**
   *
   * @type {string}
   * @memberof UserNotificationOutputDTO
   */
  message?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserNotificationOutputDTO
   */
  validFrom?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserNotificationOutputDTO
   */
  validTo?: string | null;
}

/**
 * AssetTrackingApi - axios parameter creator
 * @export
 */
export const AssetTrackingApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get a companies assets with latest locations
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAssetTrackingCompanyIdAssetslatestGet: async (
      companyId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists(
        'apiAssetTrackingCompanyIdAssetslatestGet',
        'companyId',
        companyId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiAssetTrackingCompanyIdAssetslatestGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/AssetTracking/{companyId}/assetslatest`.replace(
          `{${'companyId'}}`,
          encodeURIComponent(String(companyId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get azure maps render only token
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAssetTrackingCompanyIdGeofenceeventsGet: async (
      companyId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists(
        'apiAssetTrackingCompanyIdGeofenceeventsGet',
        'companyId',
        companyId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiAssetTrackingCompanyIdGeofenceeventsGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/AssetTracking/{companyId}/geofenceevents`.replace(
          `{${'companyId'}}`,
          encodeURIComponent(String(companyId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get geofences for a company
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAssetTrackingCompanyIdGeofencesGet: async (
      companyId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists(
        'apiAssetTrackingCompanyIdGeofencesGet',
        'companyId',
        companyId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiAssetTrackingCompanyIdGeofencesGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/AssetTracking/{companyId}/geofences`.replace(
        `{${'companyId'}}`,
        encodeURIComponent(String(companyId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get azure maps render only token
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAssetTrackingRendertokenGet: async (
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiAssetTrackingRendertokenGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/AssetTracking/rendertoken`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AssetTrackingApi - functional programming interface
 * @export
 */
export const AssetTrackingApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    AssetTrackingApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get a companies assets with latest locations
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAssetTrackingCompanyIdAssetslatestGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiAssetTrackingCompanyIdAssetslatestGet(
          companyId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get azure maps render only token
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAssetTrackingCompanyIdGeofenceeventsGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<GetGeofenceEventsOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiAssetTrackingCompanyIdGeofenceeventsGet(
          companyId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get geofences for a company
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAssetTrackingCompanyIdGeofencesGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiAssetTrackingCompanyIdGeofencesGet(
          companyId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get azure maps render only token
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAssetTrackingRendertokenGet(
      apiVersion: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiAssetTrackingRendertokenGet(
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AssetTrackingApi - factory interface
 * @export
 */
export const AssetTrackingApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AssetTrackingApiFp(configuration);
  return {
    /**
     *
     * @summary Get a companies assets with latest locations
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAssetTrackingCompanyIdAssetslatestGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<string> {
      return localVarFp
        .apiAssetTrackingCompanyIdAssetslatestGet(
          companyId,
          apiVersion,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get azure maps render only token
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAssetTrackingCompanyIdGeofenceeventsGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<Array<GetGeofenceEventsOutputDTO>> {
      return localVarFp
        .apiAssetTrackingCompanyIdGeofenceeventsGet(
          companyId,
          apiVersion,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get geofences for a company
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAssetTrackingCompanyIdGeofencesGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<string> {
      return localVarFp
        .apiAssetTrackingCompanyIdGeofencesGet(companyId, apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get azure maps render only token
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAssetTrackingRendertokenGet(
      apiVersion: string,
      options?: any,
    ): AxiosPromise<string> {
      return localVarFp
        .apiAssetTrackingRendertokenGet(apiVersion, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * AssetTrackingApi - object-oriented interface
 * @export
 * @class AssetTrackingApi
 * @extends {BaseAPI}
 */
export class AssetTrackingApi extends BaseAPI {
  /**
   *
   * @summary Get a companies assets with latest locations
   * @param {string} companyId
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssetTrackingApi
   */
  public apiAssetTrackingCompanyIdAssetslatestGet(
    companyId: string,
    apiVersion: string,
    options?: any,
  ) {
    return AssetTrackingApiFp(this.configuration)
      .apiAssetTrackingCompanyIdAssetslatestGet(companyId, apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get azure maps render only token
   * @param {string} companyId
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssetTrackingApi
   */
  public apiAssetTrackingCompanyIdGeofenceeventsGet(
    companyId: string,
    apiVersion: string,
    options?: any,
  ) {
    return AssetTrackingApiFp(this.configuration)
      .apiAssetTrackingCompanyIdGeofenceeventsGet(
        companyId,
        apiVersion,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get geofences for a company
   * @param {string} companyId
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssetTrackingApi
   */
  public apiAssetTrackingCompanyIdGeofencesGet(
    companyId: string,
    apiVersion: string,
    options?: any,
  ) {
    return AssetTrackingApiFp(this.configuration)
      .apiAssetTrackingCompanyIdGeofencesGet(companyId, apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get azure maps render only token
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssetTrackingApi
   */
  public apiAssetTrackingRendertokenGet(apiVersion: string, options?: any) {
    return AssetTrackingApiFp(this.configuration)
      .apiAssetTrackingRendertokenGet(apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * CompaniesApi - axios parameter creator
 * @export
 */
export const CompaniesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get basic data about all companies
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesBasicGet: async (
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiCompaniesBasicGet', 'apiVersion', apiVersion);
      const localVarPath = `/api/Companies/basic`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all company types by company
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCompanyIdCompanytypesGet: async (
      companyId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists(
        'apiCompaniesCompanyIdCompanytypesGet',
        'companyId',
        companyId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiCompaniesCompanyIdCompanytypesGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/Companies/{companyId}/companytypes`.replace(
        `{${'companyId'}}`,
        encodeURIComponent(String(companyId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete existing company
     * @param {string} companyId Company Guid
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCompanyIdDelete: async (
      companyId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists('apiCompaniesCompanyIdDelete', 'companyId', companyId);
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiCompaniesCompanyIdDelete',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/Companies/{companyId}`.replace(
        `{${'companyId'}}`,
        encodeURIComponent(String(companyId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get single company by company Guid
     * @param {string} companyId Company Guid
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCompanyIdGet: async (
      companyId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists('apiCompaniesCompanyIdGet', 'companyId', companyId);
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiCompaniesCompanyIdGet', 'apiVersion', apiVersion);
      const localVarPath = `/api/Companies/{companyId}`.replace(
        `{${'companyId'}}`,
        encodeURIComponent(String(companyId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update single company\'s types and hierarchies
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {UpdateCompanyInputDTO} [updateCompanyInputDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCompanyIdPut: async (
      companyId: string,
      apiVersion: string,
      updateCompanyInputDTO?: UpdateCompanyInputDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists('apiCompaniesCompanyIdPut', 'companyId', companyId);
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiCompaniesCompanyIdPut', 'apiVersion', apiVersion);
      const localVarPath = `/api/Companies/{companyId}`.replace(
        `{${'companyId'}}`,
        encodeURIComponent(String(companyId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateCompanyInputDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} apiVersion The requested API version
     * @param {PaginatedCompanyQueryDTO} [paginatedCompanyQueryDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesSearchPost: async (
      apiVersion: string,
      paginatedCompanyQueryDTO?: PaginatedCompanyQueryDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiCompaniesSearchPost', 'apiVersion', apiVersion);
      const localVarPath = `/api/Companies/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        paginatedCompanyQueryDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CompaniesApi - functional programming interface
 * @export
 */
export const CompaniesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    CompaniesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get basic data about all companies
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompaniesBasicGet(
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<BasicCompanyDataOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiCompaniesBasicGet(
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get all company types by company
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompaniesCompanyIdCompanytypesGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<GetCompanyTypesTypeOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiCompaniesCompanyIdCompanytypesGet(
          companyId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Delete existing company
     * @param {string} companyId Company Guid
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompaniesCompanyIdDelete(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiCompaniesCompanyIdDelete(
          companyId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get single company by company Guid
     * @param {string} companyId Company Guid
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompaniesCompanyIdGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetCompanyByIdOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiCompaniesCompanyIdGet(
          companyId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update single company\'s types and hierarchies
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {UpdateCompanyInputDTO} [updateCompanyInputDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompaniesCompanyIdPut(
      companyId: string,
      apiVersion: string,
      updateCompanyInputDTO?: UpdateCompanyInputDTO,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateCompanyOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiCompaniesCompanyIdPut(
          companyId,
          apiVersion,
          updateCompanyInputDTO,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} apiVersion The requested API version
     * @param {PaginatedCompanyQueryDTO} [paginatedCompanyQueryDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompaniesSearchPost(
      apiVersion: string,
      paginatedCompanyQueryDTO?: PaginatedCompanyQueryDTO,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SearchCompaniesOutputDTOPagedResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiCompaniesSearchPost(
          apiVersion,
          paginatedCompanyQueryDTO,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * CompaniesApi - factory interface
 * @export
 */
export const CompaniesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CompaniesApiFp(configuration);
  return {
    /**
     *
     * @summary Get basic data about all companies
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesBasicGet(
      apiVersion: string,
      options?: any,
    ): AxiosPromise<Array<BasicCompanyDataOutputDTO>> {
      return localVarFp
        .apiCompaniesBasicGet(apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get all company types by company
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCompanyIdCompanytypesGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<Array<GetCompanyTypesTypeOutputDTO>> {
      return localVarFp
        .apiCompaniesCompanyIdCompanytypesGet(companyId, apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete existing company
     * @param {string} companyId Company Guid
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCompanyIdDelete(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .apiCompaniesCompanyIdDelete(companyId, apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get single company by company Guid
     * @param {string} companyId Company Guid
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCompanyIdGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<GetCompanyByIdOutputDTO> {
      return localVarFp
        .apiCompaniesCompanyIdGet(companyId, apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update single company\'s types and hierarchies
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {UpdateCompanyInputDTO} [updateCompanyInputDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCompanyIdPut(
      companyId: string,
      apiVersion: string,
      updateCompanyInputDTO?: UpdateCompanyInputDTO,
      options?: any,
    ): AxiosPromise<UpdateCompanyOutputDTO> {
      return localVarFp
        .apiCompaniesCompanyIdPut(
          companyId,
          apiVersion,
          updateCompanyInputDTO,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} apiVersion The requested API version
     * @param {PaginatedCompanyQueryDTO} [paginatedCompanyQueryDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesSearchPost(
      apiVersion: string,
      paginatedCompanyQueryDTO?: PaginatedCompanyQueryDTO,
      options?: any,
    ): AxiosPromise<SearchCompaniesOutputDTOPagedResponse> {
      return localVarFp
        .apiCompaniesSearchPost(apiVersion, paginatedCompanyQueryDTO, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * CompaniesApi - object-oriented interface
 * @export
 * @class CompaniesApi
 * @extends {BaseAPI}
 */
export class CompaniesApi extends BaseAPI {
  /**
   *
   * @summary Get basic data about all companies
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public apiCompaniesBasicGet(apiVersion: string, options?: any) {
    return CompaniesApiFp(this.configuration)
      .apiCompaniesBasicGet(apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all company types by company
   * @param {string} companyId
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public apiCompaniesCompanyIdCompanytypesGet(
    companyId: string,
    apiVersion: string,
    options?: any,
  ) {
    return CompaniesApiFp(this.configuration)
      .apiCompaniesCompanyIdCompanytypesGet(companyId, apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete existing company
   * @param {string} companyId Company Guid
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public apiCompaniesCompanyIdDelete(
    companyId: string,
    apiVersion: string,
    options?: any,
  ) {
    return CompaniesApiFp(this.configuration)
      .apiCompaniesCompanyIdDelete(companyId, apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get single company by company Guid
   * @param {string} companyId Company Guid
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public apiCompaniesCompanyIdGet(
    companyId: string,
    apiVersion: string,
    options?: any,
  ) {
    return CompaniesApiFp(this.configuration)
      .apiCompaniesCompanyIdGet(companyId, apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update single company\'s types and hierarchies
   * @param {string} companyId
   * @param {string} apiVersion The requested API version
   * @param {UpdateCompanyInputDTO} [updateCompanyInputDTO]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public apiCompaniesCompanyIdPut(
    companyId: string,
    apiVersion: string,
    updateCompanyInputDTO?: UpdateCompanyInputDTO,
    options?: any,
  ) {
    return CompaniesApiFp(this.configuration)
      .apiCompaniesCompanyIdPut(
        companyId,
        apiVersion,
        updateCompanyInputDTO,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} apiVersion The requested API version
   * @param {PaginatedCompanyQueryDTO} [paginatedCompanyQueryDTO]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public apiCompaniesSearchPost(
    apiVersion: string,
    paginatedCompanyQueryDTO?: PaginatedCompanyQueryDTO,
    options?: any,
  ) {
    return CompaniesApiFp(this.configuration)
      .apiCompaniesSearchPost(apiVersion, paginatedCompanyQueryDTO, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * CompanyTypesApi - axios parameter creator
 * @export
 */
export const CompanyTypesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get all allowed child company types
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompanyTypesAllowedChildTypesGet: async (
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiCompanyTypesAllowedChildTypesGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/CompanyTypes/allowedChildTypes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete company type
     * @param {string} companyTypeId Company type Guid
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompanyTypesCompanyTypeIdDelete: async (
      companyTypeId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyTypeId' is not null or undefined
      assertParamExists(
        'apiCompanyTypesCompanyTypeIdDelete',
        'companyTypeId',
        companyTypeId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiCompanyTypesCompanyTypeIdDelete',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/CompanyTypes/{companyTypeId}`.replace(
        `{${'companyTypeId'}}`,
        encodeURIComponent(String(companyTypeId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get single company type by Guid
     * @param {string} companyTypeId Company type Guid
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompanyTypesCompanyTypeIdGet: async (
      companyTypeId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyTypeId' is not null or undefined
      assertParamExists(
        'apiCompanyTypesCompanyTypeIdGet',
        'companyTypeId',
        companyTypeId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiCompanyTypesCompanyTypeIdGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/CompanyTypes/{companyTypeId}`.replace(
        `{${'companyTypeId'}}`,
        encodeURIComponent(String(companyTypeId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Modify existing company type
     * @param {string} companyTypeId Existing company type Guid
     * @param {string} apiVersion The requested API version
     * @param {CompanyTypeInputDTO} [companyTypeInputDTO] Modified company type data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompanyTypesCompanyTypeIdPut: async (
      companyTypeId: string,
      apiVersion: string,
      companyTypeInputDTO?: CompanyTypeInputDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyTypeId' is not null or undefined
      assertParamExists(
        'apiCompanyTypesCompanyTypeIdPut',
        'companyTypeId',
        companyTypeId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiCompanyTypesCompanyTypeIdPut',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/CompanyTypes/{companyTypeId}`.replace(
        `{${'companyTypeId'}}`,
        encodeURIComponent(String(companyTypeId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        companyTypeInputDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all company types
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompanyTypesGet: async (
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiCompanyTypesGet', 'apiVersion', apiVersion);
      const localVarPath = `/api/CompanyTypes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create company type
     * @param {string} apiVersion The requested API version
     * @param {CompanyTypeInputDTO} [companyTypeInputDTO] Company type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompanyTypesPost: async (
      apiVersion: string,
      companyTypeInputDTO?: CompanyTypeInputDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiCompanyTypesPost', 'apiVersion', apiVersion);
      const localVarPath = `/api/CompanyTypes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        companyTypeInputDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CompanyTypesApi - functional programming interface
 * @export
 */
export const CompanyTypesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    CompanyTypesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get all allowed child company types
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompanyTypesAllowedChildTypesGet(
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<AllowedChildCompanyTypeOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiCompanyTypesAllowedChildTypesGet(
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Delete company type
     * @param {string} companyTypeId Company type Guid
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompanyTypesCompanyTypeIdDelete(
      companyTypeId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiCompanyTypesCompanyTypeIdDelete(
          companyTypeId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get single company type by Guid
     * @param {string} companyTypeId Company type Guid
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompanyTypesCompanyTypeIdGet(
      companyTypeId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CompanyTypeOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiCompanyTypesCompanyTypeIdGet(
          companyTypeId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Modify existing company type
     * @param {string} companyTypeId Existing company type Guid
     * @param {string} apiVersion The requested API version
     * @param {CompanyTypeInputDTO} [companyTypeInputDTO] Modified company type data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompanyTypesCompanyTypeIdPut(
      companyTypeId: string,
      apiVersion: string,
      companyTypeInputDTO?: CompanyTypeInputDTO,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CompanyTypeOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiCompanyTypesCompanyTypeIdPut(
          companyTypeId,
          apiVersion,
          companyTypeInputDTO,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get all company types
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompanyTypesGet(
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<CompanyTypeOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiCompanyTypesGet(apiVersion, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Create company type
     * @param {string} apiVersion The requested API version
     * @param {CompanyTypeInputDTO} [companyTypeInputDTO] Company type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompanyTypesPost(
      apiVersion: string,
      companyTypeInputDTO?: CompanyTypeInputDTO,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CompanyTypeOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiCompanyTypesPost(
          apiVersion,
          companyTypeInputDTO,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * CompanyTypesApi - factory interface
 * @export
 */
export const CompanyTypesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CompanyTypesApiFp(configuration);
  return {
    /**
     *
     * @summary Get all allowed child company types
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompanyTypesAllowedChildTypesGet(
      apiVersion: string,
      options?: any,
    ): AxiosPromise<Array<AllowedChildCompanyTypeOutputDTO>> {
      return localVarFp
        .apiCompanyTypesAllowedChildTypesGet(apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete company type
     * @param {string} companyTypeId Company type Guid
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompanyTypesCompanyTypeIdDelete(
      companyTypeId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .apiCompanyTypesCompanyTypeIdDelete(companyTypeId, apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get single company type by Guid
     * @param {string} companyTypeId Company type Guid
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompanyTypesCompanyTypeIdGet(
      companyTypeId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<CompanyTypeOutputDTO> {
      return localVarFp
        .apiCompanyTypesCompanyTypeIdGet(companyTypeId, apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Modify existing company type
     * @param {string} companyTypeId Existing company type Guid
     * @param {string} apiVersion The requested API version
     * @param {CompanyTypeInputDTO} [companyTypeInputDTO] Modified company type data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompanyTypesCompanyTypeIdPut(
      companyTypeId: string,
      apiVersion: string,
      companyTypeInputDTO?: CompanyTypeInputDTO,
      options?: any,
    ): AxiosPromise<CompanyTypeOutputDTO> {
      return localVarFp
        .apiCompanyTypesCompanyTypeIdPut(
          companyTypeId,
          apiVersion,
          companyTypeInputDTO,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get all company types
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompanyTypesGet(
      apiVersion: string,
      options?: any,
    ): AxiosPromise<Array<CompanyTypeOutputDTO>> {
      return localVarFp
        .apiCompanyTypesGet(apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create company type
     * @param {string} apiVersion The requested API version
     * @param {CompanyTypeInputDTO} [companyTypeInputDTO] Company type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompanyTypesPost(
      apiVersion: string,
      companyTypeInputDTO?: CompanyTypeInputDTO,
      options?: any,
    ): AxiosPromise<CompanyTypeOutputDTO> {
      return localVarFp
        .apiCompanyTypesPost(apiVersion, companyTypeInputDTO, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * CompanyTypesApi - object-oriented interface
 * @export
 * @class CompanyTypesApi
 * @extends {BaseAPI}
 */
export class CompanyTypesApi extends BaseAPI {
  /**
   *
   * @summary Get all allowed child company types
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanyTypesApi
   */
  public apiCompanyTypesAllowedChildTypesGet(
    apiVersion: string,
    options?: any,
  ) {
    return CompanyTypesApiFp(this.configuration)
      .apiCompanyTypesAllowedChildTypesGet(apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete company type
   * @param {string} companyTypeId Company type Guid
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanyTypesApi
   */
  public apiCompanyTypesCompanyTypeIdDelete(
    companyTypeId: string,
    apiVersion: string,
    options?: any,
  ) {
    return CompanyTypesApiFp(this.configuration)
      .apiCompanyTypesCompanyTypeIdDelete(companyTypeId, apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get single company type by Guid
   * @param {string} companyTypeId Company type Guid
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanyTypesApi
   */
  public apiCompanyTypesCompanyTypeIdGet(
    companyTypeId: string,
    apiVersion: string,
    options?: any,
  ) {
    return CompanyTypesApiFp(this.configuration)
      .apiCompanyTypesCompanyTypeIdGet(companyTypeId, apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Modify existing company type
   * @param {string} companyTypeId Existing company type Guid
   * @param {string} apiVersion The requested API version
   * @param {CompanyTypeInputDTO} [companyTypeInputDTO] Modified company type data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanyTypesApi
   */
  public apiCompanyTypesCompanyTypeIdPut(
    companyTypeId: string,
    apiVersion: string,
    companyTypeInputDTO?: CompanyTypeInputDTO,
    options?: any,
  ) {
    return CompanyTypesApiFp(this.configuration)
      .apiCompanyTypesCompanyTypeIdPut(
        companyTypeId,
        apiVersion,
        companyTypeInputDTO,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all company types
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanyTypesApi
   */
  public apiCompanyTypesGet(apiVersion: string, options?: any) {
    return CompanyTypesApiFp(this.configuration)
      .apiCompanyTypesGet(apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create company type
   * @param {string} apiVersion The requested API version
   * @param {CompanyTypeInputDTO} [companyTypeInputDTO] Company type
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanyTypesApi
   */
  public apiCompanyTypesPost(
    apiVersion: string,
    companyTypeInputDTO?: CompanyTypeInputDTO,
    options?: any,
  ) {
    return CompanyTypesApiFp(this.configuration)
      .apiCompanyTypesPost(apiVersion, companyTypeInputDTO, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * EndpointMonitoringApi - axios parameter creator
 * @export
 */
export const EndpointMonitoringApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get all available base products for a company  for filtering the site list.
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringCompaniesCompanyIdBaseProductsGet: async (
      companyId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringCompaniesCompanyIdBaseProductsGet',
        'companyId',
        companyId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringCompaniesCompanyIdBaseProductsGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/endpointmonitoring/companies/{companyId}/baseProducts`.replace(
          `{${'companyId'}}`,
          encodeURIComponent(String(companyId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all available countries for a company  for filtering the site list.
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringCompaniesCompanyIdCountriesGet: async (
      companyId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringCompaniesCompanyIdCountriesGet',
        'companyId',
        companyId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringCompaniesCompanyIdCountriesGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/endpointmonitoring/companies/{companyId}/countries`.replace(
          `{${'companyId'}}`,
          encodeURIComponent(String(companyId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all available products for a company  for filtering the site list.
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringCompaniesCompanyIdProductsGet: async (
      companyId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringCompaniesCompanyIdProductsGet',
        'companyId',
        companyId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringCompaniesCompanyIdProductsGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/endpointmonitoring/companies/{companyId}/products`.replace(
          `{${'companyId'}}`,
          encodeURIComponent(String(companyId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get sites and their coordinates.
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringCompaniesCompanyIdSitesCoordinatesGet: async (
      companyId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringCompaniesCompanyIdSitesCoordinatesGet',
        'companyId',
        companyId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringCompaniesCompanyIdSitesCoordinatesGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/endpointmonitoring/companies/{companyId}/sites/coordinates`.replace(
          `{${'companyId'}}`,
          encodeURIComponent(String(companyId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get available companies for which  the current user can view endpoint monitoring  for.
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringCompaniesGet: async (
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringCompaniesGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/endpointmonitoring/companies`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get azure maps render only token
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringMapsrendertokenGet: async (
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringMapsrendertokenGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/endpointmonitoring/mapsrendertoken`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Search for sites for flat view, site  hierarchy will not be returned.
     * @param {string} apiVersion The requested API version
     * @param {SearchEndpointMonitoringSitesFlatInputDTO} [searchEndpointMonitoringSitesFlatInputDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringSitesSearchFlatPost: async (
      apiVersion: string,
      searchEndpointMonitoringSitesFlatInputDTO?: SearchEndpointMonitoringSitesFlatInputDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringSitesSearchFlatPost',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/endpointmonitoring/sites/search/flat`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        searchEndpointMonitoringSitesFlatInputDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Search for sites for hierarchical view.
     * @param {string} apiVersion The requested API version
     * @param {SearchEndpointMonitoringSitesHierarchicalInputDTO} [searchEndpointMonitoringSitesHierarchicalInputDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringSitesSearchHierarchicalPost: async (
      apiVersion: string,
      searchEndpointMonitoringSitesHierarchicalInputDTO?: SearchEndpointMonitoringSitesHierarchicalInputDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringSitesSearchHierarchicalPost',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/endpointmonitoring/sites/search/hierarchical`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        searchEndpointMonitoringSitesHierarchicalInputDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get the most recent details from forecasts for the given site\'s children.  Results come from cache that is refreshed periodically in background.
     * @param {string} siteId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringSitesSiteIdChildrenForecastCachedGet: async (
      siteId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'siteId' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringSitesSiteIdChildrenForecastCachedGet',
        'siteId',
        siteId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringSitesSiteIdChildrenForecastCachedGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/endpointmonitoring/sites/{siteId}/children/forecast/cached`.replace(
          `{${'siteId'}}`,
          encodeURIComponent(String(siteId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Directly calculates a forecast for equipment (tank) with given parameters.
     * @param {string} siteId
     * @param {string} equipmentId
     * @param {string} apiVersion The requested API version
     * @param {string} [forecastType] Forecast type (Linear/Pattern).  If not given, the parameter value from site will be used.
     * @param {number} [historyStartDaysAgo] The number of days in past of historical data to take into account  when calculating forecast.  If not given, the parameter value from site will be used.
     * @param {number} [historyDaysToDisplay] The number of days of history data to return with forecast.  If not given, the parameter value from site will be used.
     * @param {number} [maximumDaysToForecast] The number of days to forecast into the future from  last known data point.  If not given, the parameter value from site will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringSitesSiteIdEquipmentEquipmentIdForecastNocacheGet:
      async (
        siteId: string,
        equipmentId: string,
        apiVersion: string,
        forecastType?: string,
        historyStartDaysAgo?: number,
        historyDaysToDisplay?: number,
        maximumDaysToForecast?: number,
        options: any = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'siteId' is not null or undefined
        assertParamExists(
          'apiEndpointmonitoringSitesSiteIdEquipmentEquipmentIdForecastNocacheGet',
          'siteId',
          siteId,
        );
        // verify required parameter 'equipmentId' is not null or undefined
        assertParamExists(
          'apiEndpointmonitoringSitesSiteIdEquipmentEquipmentIdForecastNocacheGet',
          'equipmentId',
          equipmentId,
        );
        // verify required parameter 'apiVersion' is not null or undefined
        assertParamExists(
          'apiEndpointmonitoringSitesSiteIdEquipmentEquipmentIdForecastNocacheGet',
          'apiVersion',
          apiVersion,
        );
        const localVarPath =
          `/api/endpointmonitoring/sites/{siteId}/equipment/{equipmentId}/forecast/nocache`
            .replace(`{${'siteId'}}`, encodeURIComponent(String(siteId)))
            .replace(
              `{${'equipmentId'}}`,
              encodeURIComponent(String(equipmentId)),
            );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        // authentication Bearer required
        // oauth required
        await setOAuthToObject(
          localVarHeaderParameter,
          'Bearer',
          [],
          configuration,
        );

        if (forecastType !== undefined) {
          localVarQueryParameter['ForecastType'] = forecastType;
        }

        if (historyStartDaysAgo !== undefined) {
          localVarQueryParameter['HistoryStartDaysAgo'] = historyStartDaysAgo;
        }

        if (historyDaysToDisplay !== undefined) {
          localVarQueryParameter['HistoryDaysToDisplay'] = historyDaysToDisplay;
        }

        if (maximumDaysToForecast !== undefined) {
          localVarQueryParameter['MaximumDaysToForecast'] =
            maximumDaysToForecast;
        }

        if (apiVersion !== undefined) {
          localVarQueryParameter['api-version'] = apiVersion;
        }

        setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @summary Get latest tag values for given equipment.
     * @param {string} siteId
     * @param {string} equipmentId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringSitesSiteIdEquipmentEquipmentIdMeasurementsGet: async (
      siteId: string,
      equipmentId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'siteId' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringSitesSiteIdEquipmentEquipmentIdMeasurementsGet',
        'siteId',
        siteId,
      );
      // verify required parameter 'equipmentId' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringSitesSiteIdEquipmentEquipmentIdMeasurementsGet',
        'equipmentId',
        equipmentId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringSitesSiteIdEquipmentEquipmentIdMeasurementsGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/endpointmonitoring/sites/{siteId}/equipment/{equipmentId}/measurements`
          .replace(`{${'siteId'}}`, encodeURIComponent(String(siteId)))
          .replace(
            `{${'equipmentId'}}`,
            encodeURIComponent(String(equipmentId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get measurement trends (history) for the equipment.
     * @param {string} siteId
     * @param {string} equipmentId
     * @param {string} apiVersion The requested API version
     * @param {number} [historyStartDaysAgo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringSitesSiteIdEquipmentEquipmentIdTrendsGet: async (
      siteId: string,
      equipmentId: string,
      apiVersion: string,
      historyStartDaysAgo?: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'siteId' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringSitesSiteIdEquipmentEquipmentIdTrendsGet',
        'siteId',
        siteId,
      );
      // verify required parameter 'equipmentId' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringSitesSiteIdEquipmentEquipmentIdTrendsGet',
        'equipmentId',
        equipmentId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringSitesSiteIdEquipmentEquipmentIdTrendsGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/endpointmonitoring/sites/{siteId}/equipment/{equipmentId}/trends`
          .replace(`{${'siteId'}}`, encodeURIComponent(String(siteId)))
          .replace(
            `{${'equipmentId'}}`,
            encodeURIComponent(String(equipmentId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (historyStartDaysAgo !== undefined) {
        localVarQueryParameter['historyStartDaysAgo'] = historyStartDaysAgo;
      }

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get site equipment available for endpoint monitoring.
     * @param {string} siteId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringSitesSiteIdEquipmentGet: async (
      siteId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'siteId' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringSitesSiteIdEquipmentGet',
        'siteId',
        siteId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringSitesSiteIdEquipmentGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/endpointmonitoring/sites/{siteId}/equipment`.replace(
          `{${'siteId'}}`,
          encodeURIComponent(String(siteId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get the most recent forecast for the given site.  Results come from cache that is refreshed periodically  in background.  This should be used in most cases.
     * @param {string} siteId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringSitesSiteIdForecastCachedGet: async (
      siteId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'siteId' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringSitesSiteIdForecastCachedGet',
        'siteId',
        siteId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringSitesSiteIdForecastCachedGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/endpointmonitoring/sites/{siteId}/forecast/cached`.replace(
          `{${'siteId'}}`,
          encodeURIComponent(String(siteId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Directly calculates a forecast for the given site with given parameters,  bypassing cache. Should only be used sparingly when testing new parameters.
     * @param {string} siteId
     * @param {string} forecastType
     * @param {number} historyStartDaysAgo
     * @param {string} apiVersion The requested API version
     * @param {number} [historyDaysToDisplay]
     * @param {number} [minimumReplenishmentAmount]
     * @param {number} [lastReplenishmentLevel]
     * @param {number} [maximumDaysToForecast]
     * @param {number} [warningLimit]
     * @param {number} [alarmLimit]
     * @param {boolean} [replaceNullsWithZero]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringSitesSiteIdForecastNocacheGet: async (
      siteId: string,
      forecastType: string,
      historyStartDaysAgo: number,
      apiVersion: string,
      historyDaysToDisplay?: number,
      minimumReplenishmentAmount?: number,
      lastReplenishmentLevel?: number,
      maximumDaysToForecast?: number,
      warningLimit?: number,
      alarmLimit?: number,
      replaceNullsWithZero?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'siteId' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringSitesSiteIdForecastNocacheGet',
        'siteId',
        siteId,
      );
      // verify required parameter 'forecastType' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringSitesSiteIdForecastNocacheGet',
        'forecastType',
        forecastType,
      );
      // verify required parameter 'historyStartDaysAgo' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringSitesSiteIdForecastNocacheGet',
        'historyStartDaysAgo',
        historyStartDaysAgo,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringSitesSiteIdForecastNocacheGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/endpointmonitoring/sites/{siteId}/forecast/nocache`.replace(
          `{${'siteId'}}`,
          encodeURIComponent(String(siteId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (forecastType !== undefined) {
        localVarQueryParameter['ForecastType'] = forecastType;
      }

      if (historyStartDaysAgo !== undefined) {
        localVarQueryParameter['HistoryStartDaysAgo'] = historyStartDaysAgo;
      }

      if (historyDaysToDisplay !== undefined) {
        localVarQueryParameter['HistoryDaysToDisplay'] = historyDaysToDisplay;
      }

      if (minimumReplenishmentAmount !== undefined) {
        localVarQueryParameter['MinimumReplenishmentAmount'] =
          minimumReplenishmentAmount;
      }

      if (lastReplenishmentLevel !== undefined) {
        localVarQueryParameter['LastReplenishmentLevel'] =
          lastReplenishmentLevel;
      }

      if (maximumDaysToForecast !== undefined) {
        localVarQueryParameter['MaximumDaysToForecast'] = maximumDaysToForecast;
      }

      if (warningLimit !== undefined) {
        localVarQueryParameter['WarningLimit'] = warningLimit;
      }

      if (alarmLimit !== undefined) {
        localVarQueryParameter['AlarmLimit'] = alarmLimit;
      }

      if (replaceNullsWithZero !== undefined) {
        localVarQueryParameter['ReplaceNullsWithZero'] = replaceNullsWithZero;
      }

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get forecast related parameters for a site.
     * @param {string} siteId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringSitesSiteIdForecastparametersGet: async (
      siteId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'siteId' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringSitesSiteIdForecastparametersGet',
        'siteId',
        siteId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringSitesSiteIdForecastparametersGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/endpointmonitoring/sites/{siteId}/forecastparameters`.replace(
          `{${'siteId'}}`,
          encodeURIComponent(String(siteId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update forecast related parameters for a site.
     * @param {string} siteId
     * @param {string} apiVersion The requested API version
     * @param {ForecastParametersInputDTO} [forecastParametersInputDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringSitesSiteIdForecastparametersPut: async (
      siteId: string,
      apiVersion: string,
      forecastParametersInputDTO?: ForecastParametersInputDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'siteId' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringSitesSiteIdForecastparametersPut',
        'siteId',
        siteId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringSitesSiteIdForecastparametersPut',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/endpointmonitoring/sites/{siteId}/forecastparameters`.replace(
          `{${'siteId'}}`,
          encodeURIComponent(String(siteId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        forecastParametersInputDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get latest measurements for site and its equipment.
     * @param {string} siteId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringSitesSiteIdMeasurementsLatestGet: async (
      siteId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'siteId' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringSitesSiteIdMeasurementsLatestGet',
        'siteId',
        siteId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringSitesSiteIdMeasurementsLatestGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/endpointmonitoring/sites/{siteId}/measurements/latest`.replace(
          `{${'siteId'}}`,
          encodeURIComponent(String(siteId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update measurement (tag) options.
     * @param {string} siteId
     * @param {string} apiVersion The requested API version
     * @param {UpdateTagOptionsInputDTO} [updateTagOptionsInputDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringSitesSiteIdMeasurementsPut: async (
      siteId: string,
      apiVersion: string,
      updateTagOptionsInputDTO?: UpdateTagOptionsInputDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'siteId' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringSitesSiteIdMeasurementsPut',
        'siteId',
        siteId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringSitesSiteIdMeasurementsPut',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/endpointmonitoring/sites/{siteId}/measurements`.replace(
          `{${'siteId'}}`,
          encodeURIComponent(String(siteId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateTagOptionsInputDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get measurement trends (history) for site.
     * @param {string} siteId
     * @param {string} apiVersion The requested API version
     * @param {number} [historyStartDaysAgo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringSitesSiteIdMeasurementsTrendsGet: async (
      siteId: string,
      apiVersion: string,
      historyStartDaysAgo?: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'siteId' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringSitesSiteIdMeasurementsTrendsGet',
        'siteId',
        siteId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringSitesSiteIdMeasurementsTrendsGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/endpointmonitoring/sites/{siteId}/measurements/trends`.replace(
          `{${'siteId'}}`,
          encodeURIComponent(String(siteId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (historyStartDaysAgo !== undefined) {
        localVarQueryParameter['historyStartDaysAgo'] = historyStartDaysAgo;
      }

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get runout information for site.
     * @param {string} siteId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringSitesSiteIdRunoutGet: async (
      siteId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'siteId' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringSitesSiteIdRunoutGet',
        'siteId',
        siteId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiEndpointmonitoringSitesSiteIdRunoutGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/endpointmonitoring/sites/{siteId}/runout`.replace(
          `{${'siteId'}}`,
          encodeURIComponent(String(siteId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EndpointMonitoringApi - functional programming interface
 * @export
 */
export const EndpointMonitoringApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    EndpointMonitoringApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get all available base products for a company  for filtering the site list.
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiEndpointmonitoringCompaniesCompanyIdBaseProductsGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<GetAvailableBaseProductsForCompanyOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiEndpointmonitoringCompaniesCompanyIdBaseProductsGet(
          companyId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get all available countries for a company  for filtering the site list.
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiEndpointmonitoringCompaniesCompanyIdCountriesGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<GetAvailableCountriesForCompanyOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiEndpointmonitoringCompaniesCompanyIdCountriesGet(
          companyId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get all available products for a company  for filtering the site list.
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiEndpointmonitoringCompaniesCompanyIdProductsGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<GetAvailableProductsForCompanyOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiEndpointmonitoringCompaniesCompanyIdProductsGet(
          companyId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get sites and their coordinates.
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiEndpointmonitoringCompaniesCompanyIdSitesCoordinatesGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiEndpointmonitoringCompaniesCompanyIdSitesCoordinatesGet(
          companyId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get available companies for which  the current user can view endpoint monitoring  for.
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiEndpointmonitoringCompaniesGet(
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<GetEndpointMonitoringAvailableCompaniesOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiEndpointmonitoringCompaniesGet(
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get azure maps render only token
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiEndpointmonitoringMapsrendertokenGet(
      apiVersion: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiEndpointmonitoringMapsrendertokenGet(
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Search for sites for flat view, site  hierarchy will not be returned.
     * @param {string} apiVersion The requested API version
     * @param {SearchEndpointMonitoringSitesFlatInputDTO} [searchEndpointMonitoringSitesFlatInputDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiEndpointmonitoringSitesSearchFlatPost(
      apiVersion: string,
      searchEndpointMonitoringSitesFlatInputDTO?: SearchEndpointMonitoringSitesFlatInputDTO,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SearchEndpointMonitoringSitesFlatOutputDTOPagedResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiEndpointmonitoringSitesSearchFlatPost(
          apiVersion,
          searchEndpointMonitoringSitesFlatInputDTO,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Search for sites for hierarchical view.
     * @param {string} apiVersion The requested API version
     * @param {SearchEndpointMonitoringSitesHierarchicalInputDTO} [searchEndpointMonitoringSitesHierarchicalInputDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiEndpointmonitoringSitesSearchHierarchicalPost(
      apiVersion: string,
      searchEndpointMonitoringSitesHierarchicalInputDTO?: SearchEndpointMonitoringSitesHierarchicalInputDTO,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SearchEndpointMonitoringSitesHierarchicalOutputDTOPagedResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiEndpointmonitoringSitesSearchHierarchicalPost(
          apiVersion,
          searchEndpointMonitoringSitesHierarchicalInputDTO,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get the most recent details from forecasts for the given site\'s children.  Results come from cache that is refreshed periodically in background.
     * @param {string} siteId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiEndpointmonitoringSitesSiteIdChildrenForecastCachedGet(
      siteId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SiteChildrenCachedForecastOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiEndpointmonitoringSitesSiteIdChildrenForecastCachedGet(
          siteId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Directly calculates a forecast for equipment (tank) with given parameters.
     * @param {string} siteId
     * @param {string} equipmentId
     * @param {string} apiVersion The requested API version
     * @param {string} [forecastType] Forecast type (Linear/Pattern).  If not given, the parameter value from site will be used.
     * @param {number} [historyStartDaysAgo] The number of days in past of historical data to take into account  when calculating forecast.  If not given, the parameter value from site will be used.
     * @param {number} [historyDaysToDisplay] The number of days of history data to return with forecast.  If not given, the parameter value from site will be used.
     * @param {number} [maximumDaysToForecast] The number of days to forecast into the future from  last known data point.  If not given, the parameter value from site will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiEndpointmonitoringSitesSiteIdEquipmentEquipmentIdForecastNocacheGet(
      siteId: string,
      equipmentId: string,
      apiVersion: string,
      forecastType?: string,
      historyStartDaysAgo?: number,
      historyDaysToDisplay?: number,
      maximumDaysToForecast?: number,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<EquipmentDirectForecastOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiEndpointmonitoringSitesSiteIdEquipmentEquipmentIdForecastNocacheGet(
          siteId,
          equipmentId,
          apiVersion,
          forecastType,
          historyStartDaysAgo,
          historyDaysToDisplay,
          maximumDaysToForecast,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get latest tag values for given equipment.
     * @param {string} siteId
     * @param {string} equipmentId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiEndpointmonitoringSitesSiteIdEquipmentEquipmentIdMeasurementsGet(
      siteId: string,
      equipmentId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<EquipmentMeasurementResultsOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiEndpointmonitoringSitesSiteIdEquipmentEquipmentIdMeasurementsGet(
          siteId,
          equipmentId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get measurement trends (history) for the equipment.
     * @param {string} siteId
     * @param {string} equipmentId
     * @param {string} apiVersion The requested API version
     * @param {number} [historyStartDaysAgo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiEndpointmonitoringSitesSiteIdEquipmentEquipmentIdTrendsGet(
      siteId: string,
      equipmentId: string,
      apiVersion: string,
      historyStartDaysAgo?: number,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<TagEquipmentTrendValueDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiEndpointmonitoringSitesSiteIdEquipmentEquipmentIdTrendsGet(
          siteId,
          equipmentId,
          apiVersion,
          historyStartDaysAgo,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get site equipment available for endpoint monitoring.
     * @param {string} siteId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiEndpointmonitoringSitesSiteIdEquipmentGet(
      siteId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<EquipmentForSiteOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiEndpointmonitoringSitesSiteIdEquipmentGet(
          siteId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get the most recent forecast for the given site.  Results come from cache that is refreshed periodically  in background.  This should be used in most cases.
     * @param {string} siteId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiEndpointmonitoringSitesSiteIdForecastCachedGet(
      siteId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SiteCachedForecastOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiEndpointmonitoringSitesSiteIdForecastCachedGet(
          siteId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Directly calculates a forecast for the given site with given parameters,  bypassing cache. Should only be used sparingly when testing new parameters.
     * @param {string} siteId
     * @param {string} forecastType
     * @param {number} historyStartDaysAgo
     * @param {string} apiVersion The requested API version
     * @param {number} [historyDaysToDisplay]
     * @param {number} [minimumReplenishmentAmount]
     * @param {number} [lastReplenishmentLevel]
     * @param {number} [maximumDaysToForecast]
     * @param {number} [warningLimit]
     * @param {number} [alarmLimit]
     * @param {boolean} [replaceNullsWithZero]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiEndpointmonitoringSitesSiteIdForecastNocacheGet(
      siteId: string,
      forecastType: string,
      historyStartDaysAgo: number,
      apiVersion: string,
      historyDaysToDisplay?: number,
      minimumReplenishmentAmount?: number,
      lastReplenishmentLevel?: number,
      maximumDaysToForecast?: number,
      warningLimit?: number,
      alarmLimit?: number,
      replaceNullsWithZero?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SiteDirectForecastOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiEndpointmonitoringSitesSiteIdForecastNocacheGet(
          siteId,
          forecastType,
          historyStartDaysAgo,
          apiVersion,
          historyDaysToDisplay,
          minimumReplenishmentAmount,
          lastReplenishmentLevel,
          maximumDaysToForecast,
          warningLimit,
          alarmLimit,
          replaceNullsWithZero,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get forecast related parameters for a site.
     * @param {string} siteId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiEndpointmonitoringSitesSiteIdForecastparametersGet(
      siteId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ForecastParametersOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiEndpointmonitoringSitesSiteIdForecastparametersGet(
          siteId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update forecast related parameters for a site.
     * @param {string} siteId
     * @param {string} apiVersion The requested API version
     * @param {ForecastParametersInputDTO} [forecastParametersInputDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiEndpointmonitoringSitesSiteIdForecastparametersPut(
      siteId: string,
      apiVersion: string,
      forecastParametersInputDTO?: ForecastParametersInputDTO,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ForecastParametersOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiEndpointmonitoringSitesSiteIdForecastparametersPut(
          siteId,
          apiVersion,
          forecastParametersInputDTO,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get latest measurements for site and its equipment.
     * @param {string} siteId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiEndpointmonitoringSitesSiteIdMeasurementsLatestGet(
      siteId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SiteMeasurementsOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiEndpointmonitoringSitesSiteIdMeasurementsLatestGet(
          siteId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update measurement (tag) options.
     * @param {string} siteId
     * @param {string} apiVersion The requested API version
     * @param {UpdateTagOptionsInputDTO} [updateTagOptionsInputDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiEndpointmonitoringSitesSiteIdMeasurementsPut(
      siteId: string,
      apiVersion: string,
      updateTagOptionsInputDTO?: UpdateTagOptionsInputDTO,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpdateTagOptionsOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiEndpointmonitoringSitesSiteIdMeasurementsPut(
          siteId,
          apiVersion,
          updateTagOptionsInputDTO,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get measurement trends (history) for site.
     * @param {string} siteId
     * @param {string} apiVersion The requested API version
     * @param {number} [historyStartDaysAgo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiEndpointmonitoringSitesSiteIdMeasurementsTrendsGet(
      siteId: string,
      apiVersion: string,
      historyStartDaysAgo?: number,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<TagTrendValueDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiEndpointmonitoringSitesSiteIdMeasurementsTrendsGet(
          siteId,
          apiVersion,
          historyStartDaysAgo,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get runout information for site.
     * @param {string} siteId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiEndpointmonitoringSitesSiteIdRunoutGet(
      siteId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SiteRunoutOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiEndpointmonitoringSitesSiteIdRunoutGet(
          siteId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * EndpointMonitoringApi - factory interface
 * @export
 */
export const EndpointMonitoringApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = EndpointMonitoringApiFp(configuration);
  return {
    /**
     *
     * @summary Get all available base products for a company  for filtering the site list.
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringCompaniesCompanyIdBaseProductsGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<Array<GetAvailableBaseProductsForCompanyOutputDTO>> {
      return localVarFp
        .apiEndpointmonitoringCompaniesCompanyIdBaseProductsGet(
          companyId,
          apiVersion,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get all available countries for a company  for filtering the site list.
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringCompaniesCompanyIdCountriesGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<Array<GetAvailableCountriesForCompanyOutputDTO>> {
      return localVarFp
        .apiEndpointmonitoringCompaniesCompanyIdCountriesGet(
          companyId,
          apiVersion,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get all available products for a company  for filtering the site list.
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringCompaniesCompanyIdProductsGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<Array<GetAvailableProductsForCompanyOutputDTO>> {
      return localVarFp
        .apiEndpointmonitoringCompaniesCompanyIdProductsGet(
          companyId,
          apiVersion,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get sites and their coordinates.
     * @param {string} companyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringCompaniesCompanyIdSitesCoordinatesGet(
      companyId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<string> {
      return localVarFp
        .apiEndpointmonitoringCompaniesCompanyIdSitesCoordinatesGet(
          companyId,
          apiVersion,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get available companies for which  the current user can view endpoint monitoring  for.
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringCompaniesGet(
      apiVersion: string,
      options?: any,
    ): AxiosPromise<Array<GetEndpointMonitoringAvailableCompaniesOutputDTO>> {
      return localVarFp
        .apiEndpointmonitoringCompaniesGet(apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get azure maps render only token
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringMapsrendertokenGet(
      apiVersion: string,
      options?: any,
    ): AxiosPromise<string> {
      return localVarFp
        .apiEndpointmonitoringMapsrendertokenGet(apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Search for sites for flat view, site  hierarchy will not be returned.
     * @param {string} apiVersion The requested API version
     * @param {SearchEndpointMonitoringSitesFlatInputDTO} [searchEndpointMonitoringSitesFlatInputDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringSitesSearchFlatPost(
      apiVersion: string,
      searchEndpointMonitoringSitesFlatInputDTO?: SearchEndpointMonitoringSitesFlatInputDTO,
      options?: any,
    ): AxiosPromise<SearchEndpointMonitoringSitesFlatOutputDTOPagedResponse> {
      return localVarFp
        .apiEndpointmonitoringSitesSearchFlatPost(
          apiVersion,
          searchEndpointMonitoringSitesFlatInputDTO,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Search for sites for hierarchical view.
     * @param {string} apiVersion The requested API version
     * @param {SearchEndpointMonitoringSitesHierarchicalInputDTO} [searchEndpointMonitoringSitesHierarchicalInputDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringSitesSearchHierarchicalPost(
      apiVersion: string,
      searchEndpointMonitoringSitesHierarchicalInputDTO?: SearchEndpointMonitoringSitesHierarchicalInputDTO,
      options?: any,
    ): AxiosPromise<SearchEndpointMonitoringSitesHierarchicalOutputDTOPagedResponse> {
      return localVarFp
        .apiEndpointmonitoringSitesSearchHierarchicalPost(
          apiVersion,
          searchEndpointMonitoringSitesHierarchicalInputDTO,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get the most recent details from forecasts for the given site\'s children.  Results come from cache that is refreshed periodically in background.
     * @param {string} siteId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringSitesSiteIdChildrenForecastCachedGet(
      siteId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<SiteChildrenCachedForecastOutputDTO> {
      return localVarFp
        .apiEndpointmonitoringSitesSiteIdChildrenForecastCachedGet(
          siteId,
          apiVersion,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Directly calculates a forecast for equipment (tank) with given parameters.
     * @param {string} siteId
     * @param {string} equipmentId
     * @param {string} apiVersion The requested API version
     * @param {string} [forecastType] Forecast type (Linear/Pattern).  If not given, the parameter value from site will be used.
     * @param {number} [historyStartDaysAgo] The number of days in past of historical data to take into account  when calculating forecast.  If not given, the parameter value from site will be used.
     * @param {number} [historyDaysToDisplay] The number of days of history data to return with forecast.  If not given, the parameter value from site will be used.
     * @param {number} [maximumDaysToForecast] The number of days to forecast into the future from  last known data point.  If not given, the parameter value from site will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringSitesSiteIdEquipmentEquipmentIdForecastNocacheGet(
      siteId: string,
      equipmentId: string,
      apiVersion: string,
      forecastType?: string,
      historyStartDaysAgo?: number,
      historyDaysToDisplay?: number,
      maximumDaysToForecast?: number,
      options?: any,
    ): AxiosPromise<EquipmentDirectForecastOutputDTO> {
      return localVarFp
        .apiEndpointmonitoringSitesSiteIdEquipmentEquipmentIdForecastNocacheGet(
          siteId,
          equipmentId,
          apiVersion,
          forecastType,
          historyStartDaysAgo,
          historyDaysToDisplay,
          maximumDaysToForecast,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get latest tag values for given equipment.
     * @param {string} siteId
     * @param {string} equipmentId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringSitesSiteIdEquipmentEquipmentIdMeasurementsGet(
      siteId: string,
      equipmentId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<EquipmentMeasurementResultsOutputDTO> {
      return localVarFp
        .apiEndpointmonitoringSitesSiteIdEquipmentEquipmentIdMeasurementsGet(
          siteId,
          equipmentId,
          apiVersion,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get measurement trends (history) for the equipment.
     * @param {string} siteId
     * @param {string} equipmentId
     * @param {string} apiVersion The requested API version
     * @param {number} [historyStartDaysAgo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringSitesSiteIdEquipmentEquipmentIdTrendsGet(
      siteId: string,
      equipmentId: string,
      apiVersion: string,
      historyStartDaysAgo?: number,
      options?: any,
    ): AxiosPromise<Array<TagEquipmentTrendValueDTO>> {
      return localVarFp
        .apiEndpointmonitoringSitesSiteIdEquipmentEquipmentIdTrendsGet(
          siteId,
          equipmentId,
          apiVersion,
          historyStartDaysAgo,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get site equipment available for endpoint monitoring.
     * @param {string} siteId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringSitesSiteIdEquipmentGet(
      siteId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<Array<EquipmentForSiteOutputDTO>> {
      return localVarFp
        .apiEndpointmonitoringSitesSiteIdEquipmentGet(
          siteId,
          apiVersion,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get the most recent forecast for the given site.  Results come from cache that is refreshed periodically  in background.  This should be used in most cases.
     * @param {string} siteId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringSitesSiteIdForecastCachedGet(
      siteId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<SiteCachedForecastOutputDTO> {
      return localVarFp
        .apiEndpointmonitoringSitesSiteIdForecastCachedGet(
          siteId,
          apiVersion,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Directly calculates a forecast for the given site with given parameters,  bypassing cache. Should only be used sparingly when testing new parameters.
     * @param {string} siteId
     * @param {string} forecastType
     * @param {number} historyStartDaysAgo
     * @param {string} apiVersion The requested API version
     * @param {number} [historyDaysToDisplay]
     * @param {number} [minimumReplenishmentAmount]
     * @param {number} [lastReplenishmentLevel]
     * @param {number} [maximumDaysToForecast]
     * @param {number} [warningLimit]
     * @param {number} [alarmLimit]
     * @param {boolean} [replaceNullsWithZero]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringSitesSiteIdForecastNocacheGet(
      siteId: string,
      forecastType: string,
      historyStartDaysAgo: number,
      apiVersion: string,
      historyDaysToDisplay?: number,
      minimumReplenishmentAmount?: number,
      lastReplenishmentLevel?: number,
      maximumDaysToForecast?: number,
      warningLimit?: number,
      alarmLimit?: number,
      replaceNullsWithZero?: boolean,
      options?: any,
    ): AxiosPromise<SiteDirectForecastOutputDTO> {
      return localVarFp
        .apiEndpointmonitoringSitesSiteIdForecastNocacheGet(
          siteId,
          forecastType,
          historyStartDaysAgo,
          apiVersion,
          historyDaysToDisplay,
          minimumReplenishmentAmount,
          lastReplenishmentLevel,
          maximumDaysToForecast,
          warningLimit,
          alarmLimit,
          replaceNullsWithZero,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get forecast related parameters for a site.
     * @param {string} siteId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringSitesSiteIdForecastparametersGet(
      siteId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<ForecastParametersOutputDTO> {
      return localVarFp
        .apiEndpointmonitoringSitesSiteIdForecastparametersGet(
          siteId,
          apiVersion,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update forecast related parameters for a site.
     * @param {string} siteId
     * @param {string} apiVersion The requested API version
     * @param {ForecastParametersInputDTO} [forecastParametersInputDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringSitesSiteIdForecastparametersPut(
      siteId: string,
      apiVersion: string,
      forecastParametersInputDTO?: ForecastParametersInputDTO,
      options?: any,
    ): AxiosPromise<ForecastParametersOutputDTO> {
      return localVarFp
        .apiEndpointmonitoringSitesSiteIdForecastparametersPut(
          siteId,
          apiVersion,
          forecastParametersInputDTO,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get latest measurements for site and its equipment.
     * @param {string} siteId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringSitesSiteIdMeasurementsLatestGet(
      siteId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<SiteMeasurementsOutputDTO> {
      return localVarFp
        .apiEndpointmonitoringSitesSiteIdMeasurementsLatestGet(
          siteId,
          apiVersion,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Update measurement (tag) options.
     * @param {string} siteId
     * @param {string} apiVersion The requested API version
     * @param {UpdateTagOptionsInputDTO} [updateTagOptionsInputDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringSitesSiteIdMeasurementsPut(
      siteId: string,
      apiVersion: string,
      updateTagOptionsInputDTO?: UpdateTagOptionsInputDTO,
      options?: any,
    ): AxiosPromise<UpdateTagOptionsOutputDTO> {
      return localVarFp
        .apiEndpointmonitoringSitesSiteIdMeasurementsPut(
          siteId,
          apiVersion,
          updateTagOptionsInputDTO,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get measurement trends (history) for site.
     * @param {string} siteId
     * @param {string} apiVersion The requested API version
     * @param {number} [historyStartDaysAgo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringSitesSiteIdMeasurementsTrendsGet(
      siteId: string,
      apiVersion: string,
      historyStartDaysAgo?: number,
      options?: any,
    ): AxiosPromise<Array<TagTrendValueDTO>> {
      return localVarFp
        .apiEndpointmonitoringSitesSiteIdMeasurementsTrendsGet(
          siteId,
          apiVersion,
          historyStartDaysAgo,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get runout information for site.
     * @param {string} siteId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiEndpointmonitoringSitesSiteIdRunoutGet(
      siteId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<SiteRunoutOutputDTO> {
      return localVarFp
        .apiEndpointmonitoringSitesSiteIdRunoutGet(siteId, apiVersion, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * EndpointMonitoringApi - object-oriented interface
 * @export
 * @class EndpointMonitoringApi
 * @extends {BaseAPI}
 */
export class EndpointMonitoringApi extends BaseAPI {
  /**
   *
   * @summary Get all available base products for a company  for filtering the site list.
   * @param {string} companyId
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndpointMonitoringApi
   */
  public apiEndpointmonitoringCompaniesCompanyIdBaseProductsGet(
    companyId: string,
    apiVersion: string,
    options?: any,
  ) {
    return EndpointMonitoringApiFp(this.configuration)
      .apiEndpointmonitoringCompaniesCompanyIdBaseProductsGet(
        companyId,
        apiVersion,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all available countries for a company  for filtering the site list.
   * @param {string} companyId
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndpointMonitoringApi
   */
  public apiEndpointmonitoringCompaniesCompanyIdCountriesGet(
    companyId: string,
    apiVersion: string,
    options?: any,
  ) {
    return EndpointMonitoringApiFp(this.configuration)
      .apiEndpointmonitoringCompaniesCompanyIdCountriesGet(
        companyId,
        apiVersion,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all available products for a company  for filtering the site list.
   * @param {string} companyId
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndpointMonitoringApi
   */
  public apiEndpointmonitoringCompaniesCompanyIdProductsGet(
    companyId: string,
    apiVersion: string,
    options?: any,
  ) {
    return EndpointMonitoringApiFp(this.configuration)
      .apiEndpointmonitoringCompaniesCompanyIdProductsGet(
        companyId,
        apiVersion,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get sites and their coordinates.
   * @param {string} companyId
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndpointMonitoringApi
   */
  public apiEndpointmonitoringCompaniesCompanyIdSitesCoordinatesGet(
    companyId: string,
    apiVersion: string,
    options?: any,
  ) {
    return EndpointMonitoringApiFp(this.configuration)
      .apiEndpointmonitoringCompaniesCompanyIdSitesCoordinatesGet(
        companyId,
        apiVersion,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get available companies for which  the current user can view endpoint monitoring  for.
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndpointMonitoringApi
   */
  public apiEndpointmonitoringCompaniesGet(apiVersion: string, options?: any) {
    return EndpointMonitoringApiFp(this.configuration)
      .apiEndpointmonitoringCompaniesGet(apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get azure maps render only token
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndpointMonitoringApi
   */
  public apiEndpointmonitoringMapsrendertokenGet(
    apiVersion: string,
    options?: any,
  ) {
    return EndpointMonitoringApiFp(this.configuration)
      .apiEndpointmonitoringMapsrendertokenGet(apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Search for sites for flat view, site  hierarchy will not be returned.
   * @param {string} apiVersion The requested API version
   * @param {SearchEndpointMonitoringSitesFlatInputDTO} [searchEndpointMonitoringSitesFlatInputDTO]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndpointMonitoringApi
   */
  public apiEndpointmonitoringSitesSearchFlatPost(
    apiVersion: string,
    searchEndpointMonitoringSitesFlatInputDTO?: SearchEndpointMonitoringSitesFlatInputDTO,
    options?: any,
  ) {
    return EndpointMonitoringApiFp(this.configuration)
      .apiEndpointmonitoringSitesSearchFlatPost(
        apiVersion,
        searchEndpointMonitoringSitesFlatInputDTO,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Search for sites for hierarchical view.
   * @param {string} apiVersion The requested API version
   * @param {SearchEndpointMonitoringSitesHierarchicalInputDTO} [searchEndpointMonitoringSitesHierarchicalInputDTO]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndpointMonitoringApi
   */
  public apiEndpointmonitoringSitesSearchHierarchicalPost(
    apiVersion: string,
    searchEndpointMonitoringSitesHierarchicalInputDTO?: SearchEndpointMonitoringSitesHierarchicalInputDTO,
    options?: any,
  ) {
    return EndpointMonitoringApiFp(this.configuration)
      .apiEndpointmonitoringSitesSearchHierarchicalPost(
        apiVersion,
        searchEndpointMonitoringSitesHierarchicalInputDTO,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get the most recent details from forecasts for the given site\'s children.  Results come from cache that is refreshed periodically in background.
   * @param {string} siteId
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndpointMonitoringApi
   */
  public apiEndpointmonitoringSitesSiteIdChildrenForecastCachedGet(
    siteId: string,
    apiVersion: string,
    options?: any,
  ) {
    return EndpointMonitoringApiFp(this.configuration)
      .apiEndpointmonitoringSitesSiteIdChildrenForecastCachedGet(
        siteId,
        apiVersion,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Directly calculates a forecast for equipment (tank) with given parameters.
   * @param {string} siteId
   * @param {string} equipmentId
   * @param {string} apiVersion The requested API version
   * @param {string} [forecastType] Forecast type (Linear/Pattern).  If not given, the parameter value from site will be used.
   * @param {number} [historyStartDaysAgo] The number of days in past of historical data to take into account  when calculating forecast.  If not given, the parameter value from site will be used.
   * @param {number} [historyDaysToDisplay] The number of days of history data to return with forecast.  If not given, the parameter value from site will be used.
   * @param {number} [maximumDaysToForecast] The number of days to forecast into the future from  last known data point.  If not given, the parameter value from site will be used.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndpointMonitoringApi
   */
  public apiEndpointmonitoringSitesSiteIdEquipmentEquipmentIdForecastNocacheGet(
    siteId: string,
    equipmentId: string,
    apiVersion: string,
    forecastType?: string,
    historyStartDaysAgo?: number,
    historyDaysToDisplay?: number,
    maximumDaysToForecast?: number,
    options?: any,
  ) {
    return EndpointMonitoringApiFp(this.configuration)
      .apiEndpointmonitoringSitesSiteIdEquipmentEquipmentIdForecastNocacheGet(
        siteId,
        equipmentId,
        apiVersion,
        forecastType,
        historyStartDaysAgo,
        historyDaysToDisplay,
        maximumDaysToForecast,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get latest tag values for given equipment.
   * @param {string} siteId
   * @param {string} equipmentId
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndpointMonitoringApi
   */
  public apiEndpointmonitoringSitesSiteIdEquipmentEquipmentIdMeasurementsGet(
    siteId: string,
    equipmentId: string,
    apiVersion: string,
    options?: any,
  ) {
    return EndpointMonitoringApiFp(this.configuration)
      .apiEndpointmonitoringSitesSiteIdEquipmentEquipmentIdMeasurementsGet(
        siteId,
        equipmentId,
        apiVersion,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get measurement trends (history) for the equipment.
   * @param {string} siteId
   * @param {string} equipmentId
   * @param {string} apiVersion The requested API version
   * @param {number} [historyStartDaysAgo]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndpointMonitoringApi
   */
  public apiEndpointmonitoringSitesSiteIdEquipmentEquipmentIdTrendsGet(
    siteId: string,
    equipmentId: string,
    apiVersion: string,
    historyStartDaysAgo?: number,
    options?: any,
  ) {
    return EndpointMonitoringApiFp(this.configuration)
      .apiEndpointmonitoringSitesSiteIdEquipmentEquipmentIdTrendsGet(
        siteId,
        equipmentId,
        apiVersion,
        historyStartDaysAgo,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get site equipment available for endpoint monitoring.
   * @param {string} siteId
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndpointMonitoringApi
   */
  public apiEndpointmonitoringSitesSiteIdEquipmentGet(
    siteId: string,
    apiVersion: string,
    options?: any,
  ) {
    return EndpointMonitoringApiFp(this.configuration)
      .apiEndpointmonitoringSitesSiteIdEquipmentGet(siteId, apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get the most recent forecast for the given site.  Results come from cache that is refreshed periodically  in background.  This should be used in most cases.
   * @param {string} siteId
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndpointMonitoringApi
   */
  public apiEndpointmonitoringSitesSiteIdForecastCachedGet(
    siteId: string,
    apiVersion: string,
    options?: any,
  ) {
    return EndpointMonitoringApiFp(this.configuration)
      .apiEndpointmonitoringSitesSiteIdForecastCachedGet(
        siteId,
        apiVersion,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Directly calculates a forecast for the given site with given parameters,  bypassing cache. Should only be used sparingly when testing new parameters.
   * @param {string} siteId
   * @param {string} forecastType
   * @param {number} historyStartDaysAgo
   * @param {string} apiVersion The requested API version
   * @param {number} [historyDaysToDisplay]
   * @param {number} [minimumReplenishmentAmount]
   * @param {number} [lastReplenishmentLevel]
   * @param {number} [maximumDaysToForecast]
   * @param {number} [warningLimit]
   * @param {number} [alarmLimit]
   * @param {boolean} [replaceNullsWithZero]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndpointMonitoringApi
   */
  public apiEndpointmonitoringSitesSiteIdForecastNocacheGet(
    siteId: string,
    forecastType: string,
    historyStartDaysAgo: number,
    apiVersion: string,
    historyDaysToDisplay?: number,
    minimumReplenishmentAmount?: number,
    lastReplenishmentLevel?: number,
    maximumDaysToForecast?: number,
    warningLimit?: number,
    alarmLimit?: number,
    replaceNullsWithZero?: boolean,
    options?: any,
  ) {
    return EndpointMonitoringApiFp(this.configuration)
      .apiEndpointmonitoringSitesSiteIdForecastNocacheGet(
        siteId,
        forecastType,
        historyStartDaysAgo,
        apiVersion,
        historyDaysToDisplay,
        minimumReplenishmentAmount,
        lastReplenishmentLevel,
        maximumDaysToForecast,
        warningLimit,
        alarmLimit,
        replaceNullsWithZero,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get forecast related parameters for a site.
   * @param {string} siteId
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndpointMonitoringApi
   */
  public apiEndpointmonitoringSitesSiteIdForecastparametersGet(
    siteId: string,
    apiVersion: string,
    options?: any,
  ) {
    return EndpointMonitoringApiFp(this.configuration)
      .apiEndpointmonitoringSitesSiteIdForecastparametersGet(
        siteId,
        apiVersion,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update forecast related parameters for a site.
   * @param {string} siteId
   * @param {string} apiVersion The requested API version
   * @param {ForecastParametersInputDTO} [forecastParametersInputDTO]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndpointMonitoringApi
   */
  public apiEndpointmonitoringSitesSiteIdForecastparametersPut(
    siteId: string,
    apiVersion: string,
    forecastParametersInputDTO?: ForecastParametersInputDTO,
    options?: any,
  ) {
    return EndpointMonitoringApiFp(this.configuration)
      .apiEndpointmonitoringSitesSiteIdForecastparametersPut(
        siteId,
        apiVersion,
        forecastParametersInputDTO,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get latest measurements for site and its equipment.
   * @param {string} siteId
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndpointMonitoringApi
   */
  public apiEndpointmonitoringSitesSiteIdMeasurementsLatestGet(
    siteId: string,
    apiVersion: string,
    options?: any,
  ) {
    return EndpointMonitoringApiFp(this.configuration)
      .apiEndpointmonitoringSitesSiteIdMeasurementsLatestGet(
        siteId,
        apiVersion,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update measurement (tag) options.
   * @param {string} siteId
   * @param {string} apiVersion The requested API version
   * @param {UpdateTagOptionsInputDTO} [updateTagOptionsInputDTO]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndpointMonitoringApi
   */
  public apiEndpointmonitoringSitesSiteIdMeasurementsPut(
    siteId: string,
    apiVersion: string,
    updateTagOptionsInputDTO?: UpdateTagOptionsInputDTO,
    options?: any,
  ) {
    return EndpointMonitoringApiFp(this.configuration)
      .apiEndpointmonitoringSitesSiteIdMeasurementsPut(
        siteId,
        apiVersion,
        updateTagOptionsInputDTO,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get measurement trends (history) for site.
   * @param {string} siteId
   * @param {string} apiVersion The requested API version
   * @param {number} [historyStartDaysAgo]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndpointMonitoringApi
   */
  public apiEndpointmonitoringSitesSiteIdMeasurementsTrendsGet(
    siteId: string,
    apiVersion: string,
    historyStartDaysAgo?: number,
    options?: any,
  ) {
    return EndpointMonitoringApiFp(this.configuration)
      .apiEndpointmonitoringSitesSiteIdMeasurementsTrendsGet(
        siteId,
        apiVersion,
        historyStartDaysAgo,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get runout information for site.
   * @param {string} siteId
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EndpointMonitoringApi
   */
  public apiEndpointmonitoringSitesSiteIdRunoutGet(
    siteId: string,
    apiVersion: string,
    options?: any,
  ) {
    return EndpointMonitoringApiFp(this.configuration)
      .apiEndpointmonitoringSitesSiteIdRunoutGet(siteId, apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * FeatureFlagsApi - axios parameter creator
 * @export
 */
export const FeatureFlagsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Gets the enabled/disabled status of all feature flags.
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiFeatureFlagsGet: async (
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiFeatureFlagsGet', 'apiVersion', apiVersion);
      const localVarPath = `/api/FeatureFlags`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FeatureFlagsApi - functional programming interface
 * @export
 */
export const FeatureFlagsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    FeatureFlagsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Gets the enabled/disabled status of all feature flags.
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiFeatureFlagsGet(
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FeatureFlagsOutputDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiFeatureFlagsGet(apiVersion, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * FeatureFlagsApi - factory interface
 * @export
 */
export const FeatureFlagsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = FeatureFlagsApiFp(configuration);
  return {
    /**
     *
     * @summary Gets the enabled/disabled status of all feature flags.
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiFeatureFlagsGet(
      apiVersion: string,
      options?: any,
    ): AxiosPromise<FeatureFlagsOutputDto> {
      return localVarFp
        .apiFeatureFlagsGet(apiVersion, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * FeatureFlagsApi - object-oriented interface
 * @export
 * @class FeatureFlagsApi
 * @extends {BaseAPI}
 */
export class FeatureFlagsApi extends BaseAPI {
  /**
   *
   * @summary Gets the enabled/disabled status of all feature flags.
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeatureFlagsApi
   */
  public apiFeatureFlagsGet(apiVersion: string, options?: any) {
    return FeatureFlagsApiFp(this.configuration)
      .apiFeatureFlagsGet(apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ReservationsApi - axios parameter creator
 * @export
 */
export const ReservationsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReservationsCustomersGet: async (
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiReservationsCustomersGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/Reservations/customers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all delivery destinations which are filtered by company hierarchy and reservation permissions
     * @param {string} apiVersion The requested API version
     * @param {boolean} [includeDisabled] Setting to true will also return disabled destinations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReservationsDeliverydestinationsGet: async (
      apiVersion: string,
      includeDisabled?: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiReservationsDeliverydestinationsGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/Reservations/deliverydestinations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (includeDisabled !== undefined) {
        localVarQueryParameter['includeDisabled'] = includeDisabled;
      }

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all reservations that are accessible to the current user.
     * @param {string} apiVersion The requested API version
     * @param {string} [fromStartTime] Reservation start time on or after this timestamp (optional).
     * @param {string} [toStartTime] Reservation start time on or before this timestamp (optional).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReservationsGet: async (
      apiVersion: string,
      fromStartTime?: string,
      toStartTime?: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiReservationsGet', 'apiVersion', apiVersion);
      const localVarPath = `/api/Reservations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (fromStartTime !== undefined) {
        localVarQueryParameter['fromStartTime'] =
          (fromStartTime as any) instanceof Date
            ? (fromStartTime as any).toISOString()
            : fromStartTime;
      }

      if (toStartTime !== undefined) {
        localVarQueryParameter['toStartTime'] =
          (toStartTime as any) instanceof Date
            ? (toStartTime as any).toISOString()
            : toStartTime;
      }

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} apiVersion The requested API version
     * @param {NotVisibleReservationQueryDTO} [notVisibleReservationQueryDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReservationsGetNotVisibleReservationsPost: async (
      apiVersion: string,
      notVisibleReservationQueryDTO?: NotVisibleReservationQueryDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiReservationsGetNotVisibleReservationsPost',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/Reservations/getNotVisibleReservations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        notVisibleReservationQueryDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReservationsLoadingPlacesGet: async (
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiReservationsLoadingPlacesGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/Reservations/loadingPlaces`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get logistics companies that are accessible to the current user and part of a certain customer\'s hierarchy.
     * @param {string} customerId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReservationsLogisticCompaniesCustomerCustomerIdGet: async (
      customerId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerId' is not null or undefined
      assertParamExists(
        'apiReservationsLogisticCompaniesCustomerCustomerIdGet',
        'customerId',
        customerId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiReservationsLogisticCompaniesCustomerCustomerIdGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/Reservations/logisticCompanies/customer/{customerId}`.replace(
          `{${'customerId'}}`,
          encodeURIComponent(String(customerId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} apiVersion The requested API version
     * @param {ReservationInputDTO} [reservationInputDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReservationsPost: async (
      apiVersion: string,
      reservationInputDTO?: ReservationInputDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiReservationsPost', 'apiVersion', apiVersion);
      const localVarPath = `/api/Reservations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reservationInputDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get available products for the specified provider company
     * @param {string} providerCompanyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReservationsProductsProviderCompanyIdGet: async (
      providerCompanyId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'providerCompanyId' is not null or undefined
      assertParamExists(
        'apiReservationsProductsProviderCompanyIdGet',
        'providerCompanyId',
        providerCompanyId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiReservationsProductsProviderCompanyIdGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/Reservations/products/{providerCompanyId}`.replace(
          `{${'providerCompanyId'}}`,
          encodeURIComponent(String(providerCompanyId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get truck assets for a logistics operator  which are available with the given  provider.
     * @param {string} providerCompanyId
     * @param {string} logisticsOperatorCompanyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReservationsProvidersProviderCompanyIdLogisticOperatorsLogisticsOperatorCompanyIdTrucksGet:
      async (
        providerCompanyId: string,
        logisticsOperatorCompanyId: string,
        apiVersion: string,
        options: any = {},
      ): Promise<RequestArgs> => {
        // verify required parameter 'providerCompanyId' is not null or undefined
        assertParamExists(
          'apiReservationsProvidersProviderCompanyIdLogisticOperatorsLogisticsOperatorCompanyIdTrucksGet',
          'providerCompanyId',
          providerCompanyId,
        );
        // verify required parameter 'logisticsOperatorCompanyId' is not null or undefined
        assertParamExists(
          'apiReservationsProvidersProviderCompanyIdLogisticOperatorsLogisticsOperatorCompanyIdTrucksGet',
          'logisticsOperatorCompanyId',
          logisticsOperatorCompanyId,
        );
        // verify required parameter 'apiVersion' is not null or undefined
        assertParamExists(
          'apiReservationsProvidersProviderCompanyIdLogisticOperatorsLogisticsOperatorCompanyIdTrucksGet',
          'apiVersion',
          apiVersion,
        );
        const localVarPath =
          `/api/Reservations/providers/{providerCompanyId}/logisticOperators/{logisticsOperatorCompanyId}/trucks`
            .replace(
              `{${'providerCompanyId'}}`,
              encodeURIComponent(String(providerCompanyId)),
            )
            .replace(
              `{${'logisticsOperatorCompanyId'}}`,
              encodeURIComponent(String(logisticsOperatorCompanyId)),
            );
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {
          method: 'GET',
          ...baseOptions,
          ...options,
        };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        // authentication Bearer required
        // oauth required
        await setOAuthToObject(
          localVarHeaderParameter,
          'Bearer',
          [],
          configuration,
        );

        if (apiVersion !== undefined) {
          localVarQueryParameter['api-version'] = apiVersion;
        }

        setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
        let headersFromBaseOptions =
          baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {
          ...localVarHeaderParameter,
          ...headersFromBaseOptions,
          ...options.headers,
        };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    /**
     *
     * @param {string} reservationId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReservationsReservationIdDelete: async (
      reservationId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'reservationId' is not null or undefined
      assertParamExists(
        'apiReservationsReservationIdDelete',
        'reservationId',
        reservationId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiReservationsReservationIdDelete',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/Reservations/{reservationId}`.replace(
        `{${'reservationId'}}`,
        encodeURIComponent(String(reservationId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} reservationId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReservationsReservationIdGet: async (
      reservationId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'reservationId' is not null or undefined
      assertParamExists(
        'apiReservationsReservationIdGet',
        'reservationId',
        reservationId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiReservationsReservationIdGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/Reservations/{reservationId}`.replace(
        `{${'reservationId'}}`,
        encodeURIComponent(String(reservationId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} reservationId
     * @param {string} apiVersion The requested API version
     * @param {ReservationInputDTO} [reservationInputDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReservationsReservationIdPut: async (
      reservationId: string,
      apiVersion: string,
      reservationInputDTO?: ReservationInputDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'reservationId' is not null or undefined
      assertParamExists(
        'apiReservationsReservationIdPut',
        'reservationId',
        reservationId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiReservationsReservationIdPut',
        'apiVersion',
        apiVersion,
      );
      const localVarPath = `/api/Reservations/{reservationId}`.replace(
        `{${'reservationId'}}`,
        encodeURIComponent(String(reservationId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reservationInputDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} reservationId
     * @param {string} apiVersion The requested API version
     * @param {ReservationUpdateRestrictedInputDTO} [reservationUpdateRestrictedInputDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReservationsReservationIdRestrictedPut: async (
      reservationId: string,
      apiVersion: string,
      reservationUpdateRestrictedInputDTO?: ReservationUpdateRestrictedInputDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'reservationId' is not null or undefined
      assertParamExists(
        'apiReservationsReservationIdRestrictedPut',
        'reservationId',
        reservationId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiReservationsReservationIdRestrictedPut',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/Reservations/{reservationId}/restricted`.replace(
          `{${'reservationId'}}`,
          encodeURIComponent(String(reservationId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reservationUpdateRestrictedInputDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} apiVersion The requested API version
     * @param {PaginatedReservationsQueryDTO} [paginatedReservationsQueryDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReservationsSearchPost: async (
      apiVersion: string,
      paginatedReservationsQueryDTO?: PaginatedReservationsQueryDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiReservationsSearchPost', 'apiVersion', apiVersion);
      const localVarPath = `/api/Reservations/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        paginatedReservationsQueryDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReservationsSitesGet: async (
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiReservationsSitesGet', 'apiVersion', apiVersion);
      const localVarPath = `/api/Reservations/sites`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gets configuration values for the company;  these can be default values or company-specific  values.  Note that only Scheduling-related  configuration is returned.
     * @param {string} siteId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReservationsSitesSiteIdCompanyparametersGet: async (
      siteId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'siteId' is not null or undefined
      assertParamExists(
        'apiReservationsSitesSiteIdCompanyparametersGet',
        'siteId',
        siteId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiReservationsSitesSiteIdCompanyparametersGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/Reservations/sites/{siteId}/companyparameters`.replace(
          `{${'siteId'}}`,
          encodeURIComponent(String(siteId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gets configuration values for the site;  these can be default values or site-specific  values.  Note that only Scheduling-related  configuration is returned.
     * @param {string} siteId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReservationsSitesSiteIdParametersGet: async (
      siteId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'siteId' is not null or undefined
      assertParamExists(
        'apiReservationsSitesSiteIdParametersGet',
        'siteId',
        siteId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiReservationsSitesSiteIdParametersGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/Reservations/sites/{siteId}/parameters`.replace(
          `{${'siteId'}}`,
          encodeURIComponent(String(siteId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ReservationsApi - functional programming interface
 * @export
 */
export const ReservationsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ReservationsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReservationsCustomersGet(
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<ReservationCustomerOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiReservationsCustomersGet(
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get all delivery destinations which are filtered by company hierarchy and reservation permissions
     * @param {string} apiVersion The requested API version
     * @param {boolean} [includeDisabled] Setting to true will also return disabled destinations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReservationsDeliverydestinationsGet(
      apiVersion: string,
      includeDisabled?: boolean,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<ReservationDeliveryDestinationOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiReservationsDeliverydestinationsGet(
          apiVersion,
          includeDisabled,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get all reservations that are accessible to the current user.
     * @param {string} apiVersion The requested API version
     * @param {string} [fromStartTime] Reservation start time on or after this timestamp (optional).
     * @param {string} [toStartTime] Reservation start time on or before this timestamp (optional).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReservationsGet(
      apiVersion: string,
      fromStartTime?: string,
      toStartTime?: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<ReservationOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiReservationsGet(
          apiVersion,
          fromStartTime,
          toStartTime,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} apiVersion The requested API version
     * @param {NotVisibleReservationQueryDTO} [notVisibleReservationQueryDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReservationsGetNotVisibleReservationsPost(
      apiVersion: string,
      notVisibleReservationQueryDTO?: NotVisibleReservationQueryDTO,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<ReservationTimestampOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiReservationsGetNotVisibleReservationsPost(
          apiVersion,
          notVisibleReservationQueryDTO,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReservationsLoadingPlacesGet(
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<ReservationEquipmentOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiReservationsLoadingPlacesGet(
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get logistics companies that are accessible to the current user and part of a certain customer\'s hierarchy.
     * @param {string} customerId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReservationsLogisticCompaniesCustomerCustomerIdGet(
      customerId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<LogisticsCompanyOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiReservationsLogisticCompaniesCustomerCustomerIdGet(
          customerId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} apiVersion The requested API version
     * @param {ReservationInputDTO} [reservationInputDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReservationsPost(
      apiVersion: string,
      reservationInputDTO?: ReservationInputDTO,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ReservationOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiReservationsPost(
          apiVersion,
          reservationInputDTO,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get available products for the specified provider company
     * @param {string} providerCompanyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReservationsProductsProviderCompanyIdGet(
      providerCompanyId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ReservationAvailableProductsOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiReservationsProductsProviderCompanyIdGet(
          providerCompanyId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get truck assets for a logistics operator  which are available with the given  provider.
     * @param {string} providerCompanyId
     * @param {string} logisticsOperatorCompanyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReservationsProvidersProviderCompanyIdLogisticOperatorsLogisticsOperatorCompanyIdTrucksGet(
      providerCompanyId: string,
      logisticsOperatorCompanyId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<LogisticsOperatorTruckOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiReservationsProvidersProviderCompanyIdLogisticOperatorsLogisticsOperatorCompanyIdTrucksGet(
          providerCompanyId,
          logisticsOperatorCompanyId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} reservationId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReservationsReservationIdDelete(
      reservationId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DeleteReservationOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiReservationsReservationIdDelete(
          reservationId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} reservationId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReservationsReservationIdGet(
      reservationId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ReservationOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiReservationsReservationIdGet(
          reservationId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} reservationId
     * @param {string} apiVersion The requested API version
     * @param {ReservationInputDTO} [reservationInputDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReservationsReservationIdPut(
      reservationId: string,
      apiVersion: string,
      reservationInputDTO?: ReservationInputDTO,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ReservationOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiReservationsReservationIdPut(
          reservationId,
          apiVersion,
          reservationInputDTO,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} reservationId
     * @param {string} apiVersion The requested API version
     * @param {ReservationUpdateRestrictedInputDTO} [reservationUpdateRestrictedInputDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReservationsReservationIdRestrictedPut(
      reservationId: string,
      apiVersion: string,
      reservationUpdateRestrictedInputDTO?: ReservationUpdateRestrictedInputDTO,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ReservationOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiReservationsReservationIdRestrictedPut(
          reservationId,
          apiVersion,
          reservationUpdateRestrictedInputDTO,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} apiVersion The requested API version
     * @param {PaginatedReservationsQueryDTO} [paginatedReservationsQueryDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReservationsSearchPost(
      apiVersion: string,
      paginatedReservationsQueryDTO?: PaginatedReservationsQueryDTO,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ReservationOutputDTOPagedResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiReservationsSearchPost(
          apiVersion,
          paginatedReservationsQueryDTO,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReservationsSitesGet(
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<GetUserAccessibleSiteOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiReservationsSitesGet(
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Gets configuration values for the company;  these can be default values or company-specific  values.  Note that only Scheduling-related  configuration is returned.
     * @param {string} siteId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReservationsSitesSiteIdCompanyparametersGet(
      siteId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetCompanyParametersOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiReservationsSitesSiteIdCompanyparametersGet(
          siteId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Gets configuration values for the site;  these can be default values or site-specific  values.  Note that only Scheduling-related  configuration is returned.
     * @param {string} siteId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReservationsSitesSiteIdParametersGet(
      siteId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetSiteParametersOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiReservationsSitesSiteIdParametersGet(
          siteId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ReservationsApi - factory interface
 * @export
 */
export const ReservationsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ReservationsApiFp(configuration);
  return {
    /**
     *
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReservationsCustomersGet(
      apiVersion: string,
      options?: any,
    ): AxiosPromise<Array<ReservationCustomerOutputDTO>> {
      return localVarFp
        .apiReservationsCustomersGet(apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get all delivery destinations which are filtered by company hierarchy and reservation permissions
     * @param {string} apiVersion The requested API version
     * @param {boolean} [includeDisabled] Setting to true will also return disabled destinations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReservationsDeliverydestinationsGet(
      apiVersion: string,
      includeDisabled?: boolean,
      options?: any,
    ): AxiosPromise<Array<ReservationDeliveryDestinationOutputDTO>> {
      return localVarFp
        .apiReservationsDeliverydestinationsGet(
          apiVersion,
          includeDisabled,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get all reservations that are accessible to the current user.
     * @param {string} apiVersion The requested API version
     * @param {string} [fromStartTime] Reservation start time on or after this timestamp (optional).
     * @param {string} [toStartTime] Reservation start time on or before this timestamp (optional).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReservationsGet(
      apiVersion: string,
      fromStartTime?: string,
      toStartTime?: string,
      options?: any,
    ): AxiosPromise<Array<ReservationOutputDTO>> {
      return localVarFp
        .apiReservationsGet(apiVersion, fromStartTime, toStartTime, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} apiVersion The requested API version
     * @param {NotVisibleReservationQueryDTO} [notVisibleReservationQueryDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReservationsGetNotVisibleReservationsPost(
      apiVersion: string,
      notVisibleReservationQueryDTO?: NotVisibleReservationQueryDTO,
      options?: any,
    ): AxiosPromise<Array<ReservationTimestampOutputDTO>> {
      return localVarFp
        .apiReservationsGetNotVisibleReservationsPost(
          apiVersion,
          notVisibleReservationQueryDTO,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReservationsLoadingPlacesGet(
      apiVersion: string,
      options?: any,
    ): AxiosPromise<Array<ReservationEquipmentOutputDTO>> {
      return localVarFp
        .apiReservationsLoadingPlacesGet(apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get logistics companies that are accessible to the current user and part of a certain customer\'s hierarchy.
     * @param {string} customerId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReservationsLogisticCompaniesCustomerCustomerIdGet(
      customerId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<Array<LogisticsCompanyOutputDTO>> {
      return localVarFp
        .apiReservationsLogisticCompaniesCustomerCustomerIdGet(
          customerId,
          apiVersion,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} apiVersion The requested API version
     * @param {ReservationInputDTO} [reservationInputDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReservationsPost(
      apiVersion: string,
      reservationInputDTO?: ReservationInputDTO,
      options?: any,
    ): AxiosPromise<ReservationOutputDTO> {
      return localVarFp
        .apiReservationsPost(apiVersion, reservationInputDTO, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get available products for the specified provider company
     * @param {string} providerCompanyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReservationsProductsProviderCompanyIdGet(
      providerCompanyId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<ReservationAvailableProductsOutputDTO> {
      return localVarFp
        .apiReservationsProductsProviderCompanyIdGet(
          providerCompanyId,
          apiVersion,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get truck assets for a logistics operator  which are available with the given  provider.
     * @param {string} providerCompanyId
     * @param {string} logisticsOperatorCompanyId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReservationsProvidersProviderCompanyIdLogisticOperatorsLogisticsOperatorCompanyIdTrucksGet(
      providerCompanyId: string,
      logisticsOperatorCompanyId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<Array<LogisticsOperatorTruckOutputDTO>> {
      return localVarFp
        .apiReservationsProvidersProviderCompanyIdLogisticOperatorsLogisticsOperatorCompanyIdTrucksGet(
          providerCompanyId,
          logisticsOperatorCompanyId,
          apiVersion,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} reservationId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReservationsReservationIdDelete(
      reservationId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<DeleteReservationOutputDTO> {
      return localVarFp
        .apiReservationsReservationIdDelete(reservationId, apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} reservationId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReservationsReservationIdGet(
      reservationId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<ReservationOutputDTO> {
      return localVarFp
        .apiReservationsReservationIdGet(reservationId, apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} reservationId
     * @param {string} apiVersion The requested API version
     * @param {ReservationInputDTO} [reservationInputDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReservationsReservationIdPut(
      reservationId: string,
      apiVersion: string,
      reservationInputDTO?: ReservationInputDTO,
      options?: any,
    ): AxiosPromise<ReservationOutputDTO> {
      return localVarFp
        .apiReservationsReservationIdPut(
          reservationId,
          apiVersion,
          reservationInputDTO,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} reservationId
     * @param {string} apiVersion The requested API version
     * @param {ReservationUpdateRestrictedInputDTO} [reservationUpdateRestrictedInputDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReservationsReservationIdRestrictedPut(
      reservationId: string,
      apiVersion: string,
      reservationUpdateRestrictedInputDTO?: ReservationUpdateRestrictedInputDTO,
      options?: any,
    ): AxiosPromise<ReservationOutputDTO> {
      return localVarFp
        .apiReservationsReservationIdRestrictedPut(
          reservationId,
          apiVersion,
          reservationUpdateRestrictedInputDTO,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} apiVersion The requested API version
     * @param {PaginatedReservationsQueryDTO} [paginatedReservationsQueryDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReservationsSearchPost(
      apiVersion: string,
      paginatedReservationsQueryDTO?: PaginatedReservationsQueryDTO,
      options?: any,
    ): AxiosPromise<ReservationOutputDTOPagedResponse> {
      return localVarFp
        .apiReservationsSearchPost(
          apiVersion,
          paginatedReservationsQueryDTO,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReservationsSitesGet(
      apiVersion: string,
      options?: any,
    ): AxiosPromise<Array<GetUserAccessibleSiteOutputDTO>> {
      return localVarFp
        .apiReservationsSitesGet(apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Gets configuration values for the company;  these can be default values or company-specific  values.  Note that only Scheduling-related  configuration is returned.
     * @param {string} siteId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReservationsSitesSiteIdCompanyparametersGet(
      siteId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<GetCompanyParametersOutputDTO> {
      return localVarFp
        .apiReservationsSitesSiteIdCompanyparametersGet(
          siteId,
          apiVersion,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Gets configuration values for the site;  these can be default values or site-specific  values.  Note that only Scheduling-related  configuration is returned.
     * @param {string} siteId
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReservationsSitesSiteIdParametersGet(
      siteId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<GetSiteParametersOutputDTO> {
      return localVarFp
        .apiReservationsSitesSiteIdParametersGet(siteId, apiVersion, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * ReservationsApi - object-oriented interface
 * @export
 * @class ReservationsApi
 * @extends {BaseAPI}
 */
export class ReservationsApi extends BaseAPI {
  /**
   *
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationsApi
   */
  public apiReservationsCustomersGet(apiVersion: string, options?: any) {
    return ReservationsApiFp(this.configuration)
      .apiReservationsCustomersGet(apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all delivery destinations which are filtered by company hierarchy and reservation permissions
   * @param {string} apiVersion The requested API version
   * @param {boolean} [includeDisabled] Setting to true will also return disabled destinations
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationsApi
   */
  public apiReservationsDeliverydestinationsGet(
    apiVersion: string,
    includeDisabled?: boolean,
    options?: any,
  ) {
    return ReservationsApiFp(this.configuration)
      .apiReservationsDeliverydestinationsGet(
        apiVersion,
        includeDisabled,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all reservations that are accessible to the current user.
   * @param {string} apiVersion The requested API version
   * @param {string} [fromStartTime] Reservation start time on or after this timestamp (optional).
   * @param {string} [toStartTime] Reservation start time on or before this timestamp (optional).
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationsApi
   */
  public apiReservationsGet(
    apiVersion: string,
    fromStartTime?: string,
    toStartTime?: string,
    options?: any,
  ) {
    return ReservationsApiFp(this.configuration)
      .apiReservationsGet(apiVersion, fromStartTime, toStartTime, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} apiVersion The requested API version
   * @param {NotVisibleReservationQueryDTO} [notVisibleReservationQueryDTO]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationsApi
   */
  public apiReservationsGetNotVisibleReservationsPost(
    apiVersion: string,
    notVisibleReservationQueryDTO?: NotVisibleReservationQueryDTO,
    options?: any,
  ) {
    return ReservationsApiFp(this.configuration)
      .apiReservationsGetNotVisibleReservationsPost(
        apiVersion,
        notVisibleReservationQueryDTO,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationsApi
   */
  public apiReservationsLoadingPlacesGet(apiVersion: string, options?: any) {
    return ReservationsApiFp(this.configuration)
      .apiReservationsLoadingPlacesGet(apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get logistics companies that are accessible to the current user and part of a certain customer\'s hierarchy.
   * @param {string} customerId
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationsApi
   */
  public apiReservationsLogisticCompaniesCustomerCustomerIdGet(
    customerId: string,
    apiVersion: string,
    options?: any,
  ) {
    return ReservationsApiFp(this.configuration)
      .apiReservationsLogisticCompaniesCustomerCustomerIdGet(
        customerId,
        apiVersion,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} apiVersion The requested API version
   * @param {ReservationInputDTO} [reservationInputDTO]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationsApi
   */
  public apiReservationsPost(
    apiVersion: string,
    reservationInputDTO?: ReservationInputDTO,
    options?: any,
  ) {
    return ReservationsApiFp(this.configuration)
      .apiReservationsPost(apiVersion, reservationInputDTO, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get available products for the specified provider company
   * @param {string} providerCompanyId
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationsApi
   */
  public apiReservationsProductsProviderCompanyIdGet(
    providerCompanyId: string,
    apiVersion: string,
    options?: any,
  ) {
    return ReservationsApiFp(this.configuration)
      .apiReservationsProductsProviderCompanyIdGet(
        providerCompanyId,
        apiVersion,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get truck assets for a logistics operator  which are available with the given  provider.
   * @param {string} providerCompanyId
   * @param {string} logisticsOperatorCompanyId
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationsApi
   */
  public apiReservationsProvidersProviderCompanyIdLogisticOperatorsLogisticsOperatorCompanyIdTrucksGet(
    providerCompanyId: string,
    logisticsOperatorCompanyId: string,
    apiVersion: string,
    options?: any,
  ) {
    return ReservationsApiFp(this.configuration)
      .apiReservationsProvidersProviderCompanyIdLogisticOperatorsLogisticsOperatorCompanyIdTrucksGet(
        providerCompanyId,
        logisticsOperatorCompanyId,
        apiVersion,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} reservationId
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationsApi
   */
  public apiReservationsReservationIdDelete(
    reservationId: string,
    apiVersion: string,
    options?: any,
  ) {
    return ReservationsApiFp(this.configuration)
      .apiReservationsReservationIdDelete(reservationId, apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} reservationId
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationsApi
   */
  public apiReservationsReservationIdGet(
    reservationId: string,
    apiVersion: string,
    options?: any,
  ) {
    return ReservationsApiFp(this.configuration)
      .apiReservationsReservationIdGet(reservationId, apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} reservationId
   * @param {string} apiVersion The requested API version
   * @param {ReservationInputDTO} [reservationInputDTO]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationsApi
   */
  public apiReservationsReservationIdPut(
    reservationId: string,
    apiVersion: string,
    reservationInputDTO?: ReservationInputDTO,
    options?: any,
  ) {
    return ReservationsApiFp(this.configuration)
      .apiReservationsReservationIdPut(
        reservationId,
        apiVersion,
        reservationInputDTO,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} reservationId
   * @param {string} apiVersion The requested API version
   * @param {ReservationUpdateRestrictedInputDTO} [reservationUpdateRestrictedInputDTO]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationsApi
   */
  public apiReservationsReservationIdRestrictedPut(
    reservationId: string,
    apiVersion: string,
    reservationUpdateRestrictedInputDTO?: ReservationUpdateRestrictedInputDTO,
    options?: any,
  ) {
    return ReservationsApiFp(this.configuration)
      .apiReservationsReservationIdRestrictedPut(
        reservationId,
        apiVersion,
        reservationUpdateRestrictedInputDTO,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} apiVersion The requested API version
   * @param {PaginatedReservationsQueryDTO} [paginatedReservationsQueryDTO]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationsApi
   */
  public apiReservationsSearchPost(
    apiVersion: string,
    paginatedReservationsQueryDTO?: PaginatedReservationsQueryDTO,
    options?: any,
  ) {
    return ReservationsApiFp(this.configuration)
      .apiReservationsSearchPost(
        apiVersion,
        paginatedReservationsQueryDTO,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationsApi
   */
  public apiReservationsSitesGet(apiVersion: string, options?: any) {
    return ReservationsApiFp(this.configuration)
      .apiReservationsSitesGet(apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Gets configuration values for the company;  these can be default values or company-specific  values.  Note that only Scheduling-related  configuration is returned.
   * @param {string} siteId
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationsApi
   */
  public apiReservationsSitesSiteIdCompanyparametersGet(
    siteId: string,
    apiVersion: string,
    options?: any,
  ) {
    return ReservationsApiFp(this.configuration)
      .apiReservationsSitesSiteIdCompanyparametersGet(
        siteId,
        apiVersion,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Gets configuration values for the site;  these can be default values or site-specific  values.  Note that only Scheduling-related  configuration is returned.
   * @param {string} siteId
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReservationsApi
   */
  public apiReservationsSitesSiteIdParametersGet(
    siteId: string,
    apiVersion: string,
    options?: any,
  ) {
    return ReservationsApiFp(this.configuration)
      .apiReservationsSitesSiteIdParametersGet(siteId, apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ServiceBusConfigsApi - axios parameter creator
 * @export
 */
export const ServiceBusConfigsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get all Service Bus configs
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiServiceBusConfigsGet: async (
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiServiceBusConfigsGet', 'apiVersion', apiVersion);
      const localVarPath = `/api/ServiceBusConfigs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create Service Bus config
     * @param {string} apiVersion The requested API version
     * @param {ServiceBusConfigInputDTO} [serviceBusConfigInputDTO] Service Bus config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiServiceBusConfigsPost: async (
      apiVersion: string,
      serviceBusConfigInputDTO?: ServiceBusConfigInputDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiServiceBusConfigsPost', 'apiVersion', apiVersion);
      const localVarPath = `/api/ServiceBusConfigs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        serviceBusConfigInputDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Service Bus config
     * @param {string} serviceBusConfigId Service Bus config Guid
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiServiceBusConfigsServiceBusConfigIdDelete: async (
      serviceBusConfigId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'serviceBusConfigId' is not null or undefined
      assertParamExists(
        'apiServiceBusConfigsServiceBusConfigIdDelete',
        'serviceBusConfigId',
        serviceBusConfigId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiServiceBusConfigsServiceBusConfigIdDelete',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/ServiceBusConfigs/{serviceBusConfigId}`.replace(
          `{${'serviceBusConfigId'}}`,
          encodeURIComponent(String(serviceBusConfigId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get single Service Bus config by Guid
     * @param {string} serviceBusConfigId Service Bus config Guid
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiServiceBusConfigsServiceBusConfigIdGet: async (
      serviceBusConfigId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'serviceBusConfigId' is not null or undefined
      assertParamExists(
        'apiServiceBusConfigsServiceBusConfigIdGet',
        'serviceBusConfigId',
        serviceBusConfigId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiServiceBusConfigsServiceBusConfigIdGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/ServiceBusConfigs/{serviceBusConfigId}`.replace(
          `{${'serviceBusConfigId'}}`,
          encodeURIComponent(String(serviceBusConfigId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Modify existing Service Bus config
     * @param {string} serviceBusConfigId Existing Service Bus config Guid
     * @param {string} apiVersion The requested API version
     * @param {ServiceBusConfigInputDTO} [serviceBusConfigInputDTO] Modified Service Bus config data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiServiceBusConfigsServiceBusConfigIdPut: async (
      serviceBusConfigId: string,
      apiVersion: string,
      serviceBusConfigInputDTO?: ServiceBusConfigInputDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'serviceBusConfigId' is not null or undefined
      assertParamExists(
        'apiServiceBusConfigsServiceBusConfigIdPut',
        'serviceBusConfigId',
        serviceBusConfigId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiServiceBusConfigsServiceBusConfigIdPut',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/ServiceBusConfigs/{serviceBusConfigId}`.replace(
          `{${'serviceBusConfigId'}}`,
          encodeURIComponent(String(serviceBusConfigId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        serviceBusConfigInputDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ServiceBusConfigsApi - functional programming interface
 * @export
 */
export const ServiceBusConfigsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ServiceBusConfigsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get all Service Bus configs
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiServiceBusConfigsGet(
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<ServiceBusConfigOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiServiceBusConfigsGet(
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Create Service Bus config
     * @param {string} apiVersion The requested API version
     * @param {ServiceBusConfigInputDTO} [serviceBusConfigInputDTO] Service Bus config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiServiceBusConfigsPost(
      apiVersion: string,
      serviceBusConfigInputDTO?: ServiceBusConfigInputDTO,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ServiceBusConfigOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiServiceBusConfigsPost(
          apiVersion,
          serviceBusConfigInputDTO,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Delete Service Bus config
     * @param {string} serviceBusConfigId Service Bus config Guid
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiServiceBusConfigsServiceBusConfigIdDelete(
      serviceBusConfigId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiServiceBusConfigsServiceBusConfigIdDelete(
          serviceBusConfigId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get single Service Bus config by Guid
     * @param {string} serviceBusConfigId Service Bus config Guid
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiServiceBusConfigsServiceBusConfigIdGet(
      serviceBusConfigId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ServiceBusConfigOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiServiceBusConfigsServiceBusConfigIdGet(
          serviceBusConfigId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Modify existing Service Bus config
     * @param {string} serviceBusConfigId Existing Service Bus config Guid
     * @param {string} apiVersion The requested API version
     * @param {ServiceBusConfigInputDTO} [serviceBusConfigInputDTO] Modified Service Bus config data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiServiceBusConfigsServiceBusConfigIdPut(
      serviceBusConfigId: string,
      apiVersion: string,
      serviceBusConfigInputDTO?: ServiceBusConfigInputDTO,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ServiceBusConfigOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiServiceBusConfigsServiceBusConfigIdPut(
          serviceBusConfigId,
          apiVersion,
          serviceBusConfigInputDTO,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ServiceBusConfigsApi - factory interface
 * @export
 */
export const ServiceBusConfigsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ServiceBusConfigsApiFp(configuration);
  return {
    /**
     *
     * @summary Get all Service Bus configs
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiServiceBusConfigsGet(
      apiVersion: string,
      options?: any,
    ): AxiosPromise<Array<ServiceBusConfigOutputDTO>> {
      return localVarFp
        .apiServiceBusConfigsGet(apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create Service Bus config
     * @param {string} apiVersion The requested API version
     * @param {ServiceBusConfigInputDTO} [serviceBusConfigInputDTO] Service Bus config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiServiceBusConfigsPost(
      apiVersion: string,
      serviceBusConfigInputDTO?: ServiceBusConfigInputDTO,
      options?: any,
    ): AxiosPromise<ServiceBusConfigOutputDTO> {
      return localVarFp
        .apiServiceBusConfigsPost(apiVersion, serviceBusConfigInputDTO, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Service Bus config
     * @param {string} serviceBusConfigId Service Bus config Guid
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiServiceBusConfigsServiceBusConfigIdDelete(
      serviceBusConfigId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .apiServiceBusConfigsServiceBusConfigIdDelete(
          serviceBusConfigId,
          apiVersion,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get single Service Bus config by Guid
     * @param {string} serviceBusConfigId Service Bus config Guid
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiServiceBusConfigsServiceBusConfigIdGet(
      serviceBusConfigId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<ServiceBusConfigOutputDTO> {
      return localVarFp
        .apiServiceBusConfigsServiceBusConfigIdGet(
          serviceBusConfigId,
          apiVersion,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Modify existing Service Bus config
     * @param {string} serviceBusConfigId Existing Service Bus config Guid
     * @param {string} apiVersion The requested API version
     * @param {ServiceBusConfigInputDTO} [serviceBusConfigInputDTO] Modified Service Bus config data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiServiceBusConfigsServiceBusConfigIdPut(
      serviceBusConfigId: string,
      apiVersion: string,
      serviceBusConfigInputDTO?: ServiceBusConfigInputDTO,
      options?: any,
    ): AxiosPromise<ServiceBusConfigOutputDTO> {
      return localVarFp
        .apiServiceBusConfigsServiceBusConfigIdPut(
          serviceBusConfigId,
          apiVersion,
          serviceBusConfigInputDTO,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * ServiceBusConfigsApi - object-oriented interface
 * @export
 * @class ServiceBusConfigsApi
 * @extends {BaseAPI}
 */
export class ServiceBusConfigsApi extends BaseAPI {
  /**
   *
   * @summary Get all Service Bus configs
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceBusConfigsApi
   */
  public apiServiceBusConfigsGet(apiVersion: string, options?: any) {
    return ServiceBusConfigsApiFp(this.configuration)
      .apiServiceBusConfigsGet(apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create Service Bus config
   * @param {string} apiVersion The requested API version
   * @param {ServiceBusConfigInputDTO} [serviceBusConfigInputDTO] Service Bus config
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceBusConfigsApi
   */
  public apiServiceBusConfigsPost(
    apiVersion: string,
    serviceBusConfigInputDTO?: ServiceBusConfigInputDTO,
    options?: any,
  ) {
    return ServiceBusConfigsApiFp(this.configuration)
      .apiServiceBusConfigsPost(apiVersion, serviceBusConfigInputDTO, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Service Bus config
   * @param {string} serviceBusConfigId Service Bus config Guid
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceBusConfigsApi
   */
  public apiServiceBusConfigsServiceBusConfigIdDelete(
    serviceBusConfigId: string,
    apiVersion: string,
    options?: any,
  ) {
    return ServiceBusConfigsApiFp(this.configuration)
      .apiServiceBusConfigsServiceBusConfigIdDelete(
        serviceBusConfigId,
        apiVersion,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get single Service Bus config by Guid
   * @param {string} serviceBusConfigId Service Bus config Guid
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceBusConfigsApi
   */
  public apiServiceBusConfigsServiceBusConfigIdGet(
    serviceBusConfigId: string,
    apiVersion: string,
    options?: any,
  ) {
    return ServiceBusConfigsApiFp(this.configuration)
      .apiServiceBusConfigsServiceBusConfigIdGet(
        serviceBusConfigId,
        apiVersion,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Modify existing Service Bus config
   * @param {string} serviceBusConfigId Existing Service Bus config Guid
   * @param {string} apiVersion The requested API version
   * @param {ServiceBusConfigInputDTO} [serviceBusConfigInputDTO] Modified Service Bus config data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceBusConfigsApi
   */
  public apiServiceBusConfigsServiceBusConfigIdPut(
    serviceBusConfigId: string,
    apiVersion: string,
    serviceBusConfigInputDTO?: ServiceBusConfigInputDTO,
    options?: any,
  ) {
    return ServiceBusConfigsApiFp(this.configuration)
      .apiServiceBusConfigsServiceBusConfigIdPut(
        serviceBusConfigId,
        apiVersion,
        serviceBusConfigInputDTO,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * UserNotificationsApi - axios parameter creator
 * @export
 */
export const UserNotificationsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get all user notifications
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUserNotificationsGet: async (
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiUserNotificationsGet', 'apiVersion', apiVersion);
      const localVarPath = `/api/UserNotifications`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create user notification
     * @param {string} apiVersion The requested API version
     * @param {UserNotificationInputDTO} [userNotificationInputDTO] User notification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUserNotificationsPost: async (
      apiVersion: string,
      userNotificationInputDTO?: UserNotificationInputDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiUserNotificationsPost', 'apiVersion', apiVersion);
      const localVarPath = `/api/UserNotifications`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userNotificationInputDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete user notification
     * @param {string} userNotificationId User notification Guid
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUserNotificationsUserNotificationIdDelete: async (
      userNotificationId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userNotificationId' is not null or undefined
      assertParamExists(
        'apiUserNotificationsUserNotificationIdDelete',
        'userNotificationId',
        userNotificationId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiUserNotificationsUserNotificationIdDelete',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/UserNotifications/{userNotificationId}`.replace(
          `{${'userNotificationId'}}`,
          encodeURIComponent(String(userNotificationId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get single user notification by Guid
     * @param {string} userNotificationId User notification Guid
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUserNotificationsUserNotificationIdGet: async (
      userNotificationId: string,
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userNotificationId' is not null or undefined
      assertParamExists(
        'apiUserNotificationsUserNotificationIdGet',
        'userNotificationId',
        userNotificationId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiUserNotificationsUserNotificationIdGet',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/UserNotifications/{userNotificationId}`.replace(
          `{${'userNotificationId'}}`,
          encodeURIComponent(String(userNotificationId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Modify existing user notification
     * @param {string} userNotificationId Existing user notification Guid
     * @param {string} apiVersion The requested API version
     * @param {UserNotificationInputDTO} [userNotificationInputDTO] Modified user notification data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUserNotificationsUserNotificationIdPut: async (
      userNotificationId: string,
      apiVersion: string,
      userNotificationInputDTO?: UserNotificationInputDTO,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userNotificationId' is not null or undefined
      assertParamExists(
        'apiUserNotificationsUserNotificationIdPut',
        'userNotificationId',
        userNotificationId,
      );
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists(
        'apiUserNotificationsUserNotificationIdPut',
        'apiVersion',
        apiVersion,
      );
      const localVarPath =
        `/api/UserNotifications/{userNotificationId}`.replace(
          `{${'userNotificationId'}}`,
          encodeURIComponent(String(userNotificationId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userNotificationInputDTO,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserNotificationsApi - functional programming interface
 * @export
 */
export const UserNotificationsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    UserNotificationsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get all user notifications
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiUserNotificationsGet(
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<UserNotificationOutputDTO>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiUserNotificationsGet(
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Create user notification
     * @param {string} apiVersion The requested API version
     * @param {UserNotificationInputDTO} [userNotificationInputDTO] User notification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiUserNotificationsPost(
      apiVersion: string,
      userNotificationInputDTO?: UserNotificationInputDTO,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserNotificationOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiUserNotificationsPost(
          apiVersion,
          userNotificationInputDTO,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Delete user notification
     * @param {string} userNotificationId User notification Guid
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiUserNotificationsUserNotificationIdDelete(
      userNotificationId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiUserNotificationsUserNotificationIdDelete(
          userNotificationId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get single user notification by Guid
     * @param {string} userNotificationId User notification Guid
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiUserNotificationsUserNotificationIdGet(
      userNotificationId: string,
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserNotificationOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiUserNotificationsUserNotificationIdGet(
          userNotificationId,
          apiVersion,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Modify existing user notification
     * @param {string} userNotificationId Existing user notification Guid
     * @param {string} apiVersion The requested API version
     * @param {UserNotificationInputDTO} [userNotificationInputDTO] Modified user notification data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiUserNotificationsUserNotificationIdPut(
      userNotificationId: string,
      apiVersion: string,
      userNotificationInputDTO?: UserNotificationInputDTO,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserNotificationOutputDTO>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiUserNotificationsUserNotificationIdPut(
          userNotificationId,
          apiVersion,
          userNotificationInputDTO,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * UserNotificationsApi - factory interface
 * @export
 */
export const UserNotificationsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UserNotificationsApiFp(configuration);
  return {
    /**
     *
     * @summary Get all user notifications
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUserNotificationsGet(
      apiVersion: string,
      options?: any,
    ): AxiosPromise<Array<UserNotificationOutputDTO>> {
      return localVarFp
        .apiUserNotificationsGet(apiVersion, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Create user notification
     * @param {string} apiVersion The requested API version
     * @param {UserNotificationInputDTO} [userNotificationInputDTO] User notification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUserNotificationsPost(
      apiVersion: string,
      userNotificationInputDTO?: UserNotificationInputDTO,
      options?: any,
    ): AxiosPromise<UserNotificationOutputDTO> {
      return localVarFp
        .apiUserNotificationsPost(apiVersion, userNotificationInputDTO, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete user notification
     * @param {string} userNotificationId User notification Guid
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUserNotificationsUserNotificationIdDelete(
      userNotificationId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .apiUserNotificationsUserNotificationIdDelete(
          userNotificationId,
          apiVersion,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get single user notification by Guid
     * @param {string} userNotificationId User notification Guid
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUserNotificationsUserNotificationIdGet(
      userNotificationId: string,
      apiVersion: string,
      options?: any,
    ): AxiosPromise<UserNotificationOutputDTO> {
      return localVarFp
        .apiUserNotificationsUserNotificationIdGet(
          userNotificationId,
          apiVersion,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Modify existing user notification
     * @param {string} userNotificationId Existing user notification Guid
     * @param {string} apiVersion The requested API version
     * @param {UserNotificationInputDTO} [userNotificationInputDTO] Modified user notification data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUserNotificationsUserNotificationIdPut(
      userNotificationId: string,
      apiVersion: string,
      userNotificationInputDTO?: UserNotificationInputDTO,
      options?: any,
    ): AxiosPromise<UserNotificationOutputDTO> {
      return localVarFp
        .apiUserNotificationsUserNotificationIdPut(
          userNotificationId,
          apiVersion,
          userNotificationInputDTO,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * UserNotificationsApi - object-oriented interface
 * @export
 * @class UserNotificationsApi
 * @extends {BaseAPI}
 */
export class UserNotificationsApi extends BaseAPI {
  /**
   *
   * @summary Get all user notifications
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserNotificationsApi
   */
  public apiUserNotificationsGet(apiVersion: string, options?: any) {
    return UserNotificationsApiFp(this.configuration)
      .apiUserNotificationsGet(apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create user notification
   * @param {string} apiVersion The requested API version
   * @param {UserNotificationInputDTO} [userNotificationInputDTO] User notification
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserNotificationsApi
   */
  public apiUserNotificationsPost(
    apiVersion: string,
    userNotificationInputDTO?: UserNotificationInputDTO,
    options?: any,
  ) {
    return UserNotificationsApiFp(this.configuration)
      .apiUserNotificationsPost(apiVersion, userNotificationInputDTO, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete user notification
   * @param {string} userNotificationId User notification Guid
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserNotificationsApi
   */
  public apiUserNotificationsUserNotificationIdDelete(
    userNotificationId: string,
    apiVersion: string,
    options?: any,
  ) {
    return UserNotificationsApiFp(this.configuration)
      .apiUserNotificationsUserNotificationIdDelete(
        userNotificationId,
        apiVersion,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get single user notification by Guid
   * @param {string} userNotificationId User notification Guid
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserNotificationsApi
   */
  public apiUserNotificationsUserNotificationIdGet(
    userNotificationId: string,
    apiVersion: string,
    options?: any,
  ) {
    return UserNotificationsApiFp(this.configuration)
      .apiUserNotificationsUserNotificationIdGet(
        userNotificationId,
        apiVersion,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Modify existing user notification
   * @param {string} userNotificationId Existing user notification Guid
   * @param {string} apiVersion The requested API version
   * @param {UserNotificationInputDTO} [userNotificationInputDTO] Modified user notification data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserNotificationsApi
   */
  public apiUserNotificationsUserNotificationIdPut(
    userNotificationId: string,
    apiVersion: string,
    userNotificationInputDTO?: UserNotificationInputDTO,
    options?: any,
  ) {
    return UserNotificationsApiFp(this.configuration)
      .apiUserNotificationsUserNotificationIdPut(
        userNotificationId,
        apiVersion,
        userNotificationInputDTO,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUsersMeGet: async (
      apiVersion: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'apiVersion' is not null or undefined
      assertParamExists('apiUsersMeGet', 'apiVersion', apiVersion);
      const localVarPath = `/api/Users/me`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'Bearer',
        [],
        configuration,
      );

      if (apiVersion !== undefined) {
        localVarQueryParameter['api-version'] = apiVersion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiUsersMeGet(
      apiVersion: string,
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserInformationOutputDTO>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersMeGet(
        apiVersion,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UsersApiFp(configuration);
  return {
    /**
     *
     * @param {string} apiVersion The requested API version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUsersMeGet(
      apiVersion: string,
      options?: any,
    ): AxiosPromise<UserInformationOutputDTO> {
      return localVarFp
        .apiUsersMeGet(apiVersion, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
  /**
   *
   * @param {string} apiVersion The requested API version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public apiUsersMeGet(apiVersion: string, options?: any) {
    return UsersApiFp(this.configuration)
      .apiUsersMeGet(apiVersion, options)
      .then(request => request(this.axios, this.basePath));
  }
}
