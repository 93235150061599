import {Column, Sort} from '@valmet-iop/ui-common';
export const columns: readonly (Column & {sortColumn?: string})[] = [
  {
    translationKey: 'scheduling:columns.site',
    dataKey: 'site',
    defaultWidthPixels: 130,
    isSortable: true,
    sortColumn: 'siteName',
  },
  {
    translationKey: 'scheduling:columns.loadingPlace',
    dataKey: 'loadingPlace',
    defaultWidthPixels: 240,
    isSortable: true,
    sortColumn: 'equipmentName',
  },
  {
    translationKey: 'scheduling:columns.product',
    dataKey: 'product',
    defaultWidthPixels: 240,
    isSortable: true,
    sortColumn: 'productDisplayName',
  },
  {
    translationKey: 'scheduling:columns.type',
    dataKey: 'type',
    defaultWidthPixels: 180,
    isSortable: true,
    sortColumn: 'type',
  },
  {
    translationKey: 'scheduling:columns.startTime',
    dataKey: 'loadingTime',
    defaultWidthPixels: 180,
    isSortable: true,
    sortColumn: 'startStamp',
  },
  {
    translationKey: 'scheduling:columns.customer',
    dataKey: 'customerCompanyName',
    defaultWidthPixels: 180,
    isSortable: true,
    sortColumn: 'customerCompanyName',
  },
  {
    translationKey: 'scheduling:columns.logisticsOperator',
    dataKey: 'logisticsOperatorName',
    defaultWidthPixels: 180,
    isSortable: true,
    sortColumn: 'logisticsOperatorName',
  },
  {
    translationKey: 'scheduling:columns.orderNo',
    dataKey: 'orderNo',
    defaultWidthPixels: 180,
    isSortable: true,
    sortColumn: 'orderNo',
  },
  {
    translationKey: 'scheduling:columns.destination',
    dataKey: 'destination',
    defaultWidthPixels: 180,
    isSortable: true,
    sortColumn: 'destination',
  },
];

export const initialSort: Sort = {
  columnIndex: 4,
  direction: 'descending',
};
