import {Switch, Route, Redirect} from 'react-router-dom';
import PrivateRoute from 'components/Common/PrivateRoute';
import {usePermission} from 'context/permission-context';
import React from 'react';
import {IRouterMap} from 'utils/router';
import Home from '.';
import AadCallback from './AadCallback';
import Login from './Login';
import {FilterProvider} from './Scheduling/context/filter-context';
import {BannerProvider} from './Scheduling/context/banner-context';
import {ValmetTableSettingsProvider} from 'context/valmet-table-settings-context';

export function RoutesPermission({className}: {className: string}) {
  const {getRoutes} = usePermission();
  return (
    <ValmetTableSettingsProvider>
      <FilterProvider>
        <div className={className}>
          <BannerProvider>
            <Switch>
              <Route exact path="/login">
                <Login />
              </Route>
              <Route exact path="/aad-callback">
                <AadCallback />
              </Route>
              <Route exact path="/">
                <PrivateRoute comp={Home} />
              </Route>
              {[
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                ...[...Object.values(getRoutes())].map(
                  (route: IRouterMap[]) => {
                    return route.map(({comp, path, mode}: IRouterMap) => {
                      return (
                        <Route exact path={path} key={path}>
                          <PrivateRoute comp={comp} mode={mode} />
                        </Route>
                      );
                    });
                  },
                ),
              ]}
              <Redirect to="/" />
            </Switch>
          </BannerProvider>
        </div>
      </FilterProvider>
    </ValmetTableSettingsProvider>
  );
}
