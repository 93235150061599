import {useItem} from 'components/Common/Entity/context/item-context';
import {
  Qs,
  useRedirect,
} from 'components/Common/Entity/context/redirect-context';
import {Panel} from 'components/Common/Entity/Panel';
import * as React from 'react';
import {
  FormCancelConfirmDialog,
  TextButton,
  Title,
} from '@valmet-iop/ui-common';
import {useLocation, useParams} from 'react-router-dom';
import {
  isMaintenanceQsEventRoute,
  isReservationQsEventRoute,
} from '../../routes';
import {ChoiceStep, MaintenanceStep, ReservationStep} from './Steps';
import {useReservationsReservationIdGet} from 'components/Hooks/queries/useReservations';
import {useItemDataEdit} from '../../hooks/useItemData';
import {ReservationType} from 'api/generated/iop';
import {useFormikContext} from 'formik';
import {
  PERMISSION_ALIAS_SCHEDULING_MAINTENANCE,
  PERMISSION_ALIAS_SCHEDULING_RESERVATIONS,
  usePermission,
} from 'context/permission-context';
import {WrapperTitle} from '../ItemRead';
import {useTranslation} from 'react-i18next';
import {useForm} from '../../context/form-context';
import {isMaintenanceUpdatable, isReservationUpdatable} from '../..';

export function ItemEdit() {
  const {Content} = useWizard();
  const [dialog, setDialog] = React.useState(false);
  const [confirmEvent, setConfirmEvent] = React.useState<null | (() => void)>(
    null,
  );
  React.useEffect(() => {
    if (confirmEvent) {
      setDialog(true);
    }
  }, [confirmEvent, setDialog]);
  React.useEffect(() => {
    if (!dialog) {
      setConfirmEvent(null);
    }
  }, [dialog, setConfirmEvent]);
  const {status} = useItemDataEdit();
  return (
    <Panel status={status} onErrorRender={ErrorNotFoundItem}>
      <Content setConfirmEvent={setConfirmEvent} />
      <FormCancelConfirmDialog
        isOpen={dialog}
        onResult={confirmed => {
          if (confirmed && confirmEvent) {
            confirmEvent();
            setDialog(false);
          } else {
            setDialog(false);
          }
        }}
      />
    </Panel>
  );
}

export function ErrorNotFoundItem() {
  const {toDefault} = useRedirect();
  const {t} = useTranslation();
  return (
    <Title
      titleTranslationKey={t('errors.notFound')}
      onCloseClick={() => toDefault()}
      isPreviousPageLinkVisible={false}
      onPreviousPageLinkClick={() => undefined}
    />
  );
}
export function TitleContainer({
  setConfirmEvent,
  isLoading,
}: {
  setConfirmEvent: (ev: (() => void) | null) => void;
  isLoading?: boolean;
}) {
  const {getTitleProps, showResetButton} = useWizard();
  const {dirty, isSubmitting} = useFormikContext<any>();
  const titleProps = getTitleProps();

  if (titleProps.isPreviousPageLinkVisible) {
    titleProps.isPreviousPageLinkVisible = !isSubmitting && !isLoading;
  }
  return (
    <WrapperTitle>
      <Title
        {...titleProps}
        onCloseClick={() => {
          if (dirty && !isSubmitting) {
            setConfirmEvent(() => titleProps.onCloseClick);
          } else {
            titleProps.onCloseClick();
          }
        }}
        onPreviousPageLinkClick={() => {
          if (isSubmitting) {
            return;
          }
          if (dirty && !isSubmitting) {
            setConfirmEvent(() => titleProps.onPreviousPageLinkClick);
          } else {
            titleProps.onPreviousPageLinkClick();
          }
        }}
        additionalContentAfterTitleText={
          showResetButton && !isSubmitting && !isLoading ? (
            <FormEliminateButtons />
          ) : undefined
        }
      />
    </WrapperTitle>
  );
}
const FormEliminateButtons = () => {
  const {isUpdate} = useItem();
  if (isUpdate) {
    return <FormResetRevertButtons />;
  }
  return <FormClearButton />;
};

// **Reset|Revert should work only in UPDATE form mode**
// *Reset action:*
// - no affect on text/numbers fields,
// - clear drop-downs with multiple options(>1)
// *Revert action:*
// - all fields to default initial values
const FormResetRevertButtons = () => {
  const {t} = useTranslation();
  const {resetForm, initialValues, setFieldValue, setFieldTouched} =
    useFormikContext<any>();
  const isReservation = isReservationTypeContent(initialValues.type.value);
  // initialOptions is a place for storing the initial options data(first loaded options)
  const {resetEliminate, dropdownsValueOnReset, setFormManuallyReset} =
    useForm();
  React.useEffect(() => {
    return () => {
      // we don't want to have the same  initial options as we use custom global Form context, so we need to reset that global state
      resetEliminate();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div style={{alignSelf: 'end'}}>
      {isReservation && (
        <TextButton
          onClick={() => {
            setFormManuallyReset(true);
            for (const option in dropdownsValueOnReset) {
              setFieldValue(option, dropdownsValueOnReset[option]);
            }
            setFieldTouched('loadingTimeEnd');
          }}
          text={t('buttons.reset')}
        />
      )}
      <TextButton
        onClick={() => {
          resetForm(initialValues);
          setFieldTouched('loadingTimeEnd');
        }}
        text={t('buttons.revert')}
      />
    </div>
  );
};
// **Clear should work only in CREATE form mode**
// *Clear action:*
// - affect on text/numbers fields(clear them),
// - pre-fill dropdowns if they have one option
const FormClearButton = () => {
  const {resetForm, initialValues, setFieldValue} = useFormikContext<any>();
  const {t} = useTranslation();
  const {setFormManuallyReset} = useForm();

  const onClick = () => {
    resetForm();
    setFormManuallyReset(true);
    // In edit case, we reset the loading place (bay) field to original state.
    if (!!initialValues.loadingPlace?.value) {
      setFieldValue('loadingPlace', initialValues.loadingPlace);
    }
  };

  return (
    <div style={{alignSelf: 'end'}}>
      <TextButton onClick={onClick} text={t('buttons.clear')} />
    </div>
  );
};

interface ITitleProps {
  titleTranslationKey: string;
  onCloseClick: () => void;
  isPreviousPageLinkVisible: boolean;
  onPreviousPageLinkClick: (qs?: Qs) => void;
}

interface IContent {
  setConfirmEvent: (ev: (() => void) | null) => void;
}
const ReservationContent = (props: IContent) => {
  const {isCreatable} = usePermission();
  const {isCreate} = useItem();
  const {toDefault} = useRedirect();
  React.useEffect(() => {
    if (isCreate && !isCreatable(PERMISSION_ALIAS_SCHEDULING_RESERVATIONS)) {
      toDefault();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreate]);
  return (
    <ReservationStep
      setConfirmEvent={props.setConfirmEvent}
      title={() => <TitleContainer setConfirmEvent={props.setConfirmEvent} />}
    />
  );
};
const MaintenanceContent = (props: IContent) => {
  const {isCreatable} = usePermission();
  const {isCreate} = useItem();
  const {toDefault} = useRedirect();
  React.useEffect(() => {
    if (isCreate && !isCreatable(PERMISSION_ALIAS_SCHEDULING_MAINTENANCE)) {
      toDefault();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreate]);
  return (
    <MaintenanceStep
      setConfirmEvent={props.setConfirmEvent}
      title={isLoading => (
        <TitleContainer
          isLoading={isLoading}
          setConfirmEvent={props.setConfirmEvent}
        />
      )}
    />
  );
};
const ChoiceStepContent = () => {
  const {getTitleProps} = useWizard();
  return (
    <>
      <Title {...getTitleProps()} />
      <ChoiceStep />
    </>
  );
};
interface IUseWizard {
  showResetButton: boolean;
  getTitleProps: () => ITitleProps;
  Content: (props: IContent) => React.ReactElement;
}
function useCurrentReservation() {
  const {id} = useParams<{id: string}>();
  return useReservationsReservationIdGet(id);
}

export function isReservationTypeContent(type?: ReservationType): boolean {
  if (!type) {
    return false;
  }
  return (
    type === ReservationType.ReservationLoading ||
    type === ReservationType.ReservationUnloading
  );
}
function useWizard(): IUseWizard {
  const {setFormManuallyReset} = useForm();
  const {isCreate, isUpdate} = useItem();
  const {toDefault} = useRedirect();
  const {search} = useLocation();
  const {toCreate} = useRedirect();
  const defaultTitlePropsProps = {
    onCloseClick: () => {
      toDefault();
      setFormManuallyReset(false);
    },
    isPreviousPageLinkVisible: true,
    onPreviousPageLinkClick: (qs?: Qs | undefined) => {
      toCreate(qs);
      setFormManuallyReset(false);
    },
  };

  const {data: reservation} = useCurrentReservation();
  if (isReservationQsEventRoute(search)) {
    if (isCreate) {
      return {
        showResetButton: true,
        getTitleProps: () => {
          return {
            ...defaultTitlePropsProps,
            titleTranslationKey: `scheduling:titleNewReservation`,
          };
        },
        Content: ReservationContent,
      };
    }
  }
  if (isMaintenanceQsEventRoute(search)) {
    if (isCreate) {
      return {
        showResetButton: true,
        getTitleProps: () => {
          return {
            ...defaultTitlePropsProps,
            titleTranslationKey: 'scheduling:titleNewMainenance',
          };
        },
        Content: MaintenanceContent,
      };
    }
  }
  if (isUpdate && reservation?.type) {
    const isReservationContent = isReservationTypeContent(reservation.type);
    return {
      showResetButton: isReservationContent
        ? isReservationUpdatable(reservation.updatePermissions)
        : isMaintenanceUpdatable(reservation.updatePermissions),
      getTitleProps: () => {
        return {
          ...defaultTitlePropsProps,
          isPreviousPageLinkVisible: false,
          titleTranslationKey: isReservationContent
            ? 'scheduling:headers.reservation'
            : '',
          titleSuffix: isReservationContent
            ? ''
            : reservation.displayName ?? '',
        };
      },
      Content: isReservationContent ? ReservationContent : MaintenanceContent,
    };
  }
  return {
    showResetButton: false,
    getTitleProps: () => {
      return {
        ...defaultTitlePropsProps,
        isPreviousPageLinkVisible: false,
        titleTranslationKey: 'scheduling:titleNew',
      };
    },
    Content: ChoiceStepContent,
  };
}
