import {makeStyles, Theme} from '@material-ui/core';
import {ReservationType} from 'api/generated/iop';
import {SLOT_ID} from 'components/Pages/Scheduling/context/calendar-context';
import {isReservationNotVisible} from 'components/Pages/Scheduling/hooks/useCalendarTimelineReservations';
import {TimelineItemBase} from 'react-calendar-timeline';
import {isReservationTypeContent} from '../../ItemEdit';
import {getEventStyles} from '../CalendarView/styles';
import {getLayoutStyles} from '../styles';

export const useTimeLineStyles = makeStyles<Theme>(theme => ({
  '@global': {
    'body .rct-hl-odd, body .rct-sidebar-row-odd': {
      background: '#F6F7F8 !important',
    },
    'body .rct-day-6, body .rct-day-0': {
      background: 'none !important',
    },
    'body .rct-calendar-header': {
      // Header without HeaderRoot
      borderBottom: 'none !important',
      borderLeft: `none !important`,
      paddingLeft: '2px !important',
    },
    'body .rct-sidebar': {
      borderRight: `none !important`,
    },
    'body .rct-header-root, body .rct-hl-even, body .rct-hl-odd, body .rct-sidebar-row, body .rct-vl, body .rct-sidebar, body .rct-calendar-header':
      {
        // All slots
        borderColor: `${theme.palette.secondary.dark} !important`,
      },
    'body .rct-hl-even, body .rct-hl-odd, body .rct-vl-first': {
      // Even and odd lines + first column
      borderWidth: '1px !important',
    },
    'body .rct-outer': {
      borderLeft: `1px solid ${theme.palette.secondary.dark}`,
      borderRight: `1px solid ${theme.palette.secondary.dark}`,
    },
    '.react-calendar-timeline .rct-scroll': {
      overflowX: 'hidden !important',
      cursor: 'default !important',
    },
    ...getLayoutStyles(theme),
  },
}));

interface CustomItem extends TimelineItemBase<any> {
  type: ReservationType;
  isCompleted: boolean;
}

export const getTimelineEventStyles = (
  item: TimelineItemBase<any>,
  theme: Theme,
) => {
  if ((item.id as string) === SLOT_ID) {
    return {
      background: 'rgb(0, 138, 186,0.1)',
      border: '1px  dashed #C1C2C5',
    };
  }
  const type = (item as CustomItem).type;
  const isCompleted = (item as CustomItem).isCompleted;
  const commonStyles = getCommonStyles();
  const {maintenance, reservation, notVisible, completed} =
    getEventStyles(theme);
  if (type === ReservationType.Maintenance) {
    return {
      ...maintenance,
      ...commonStyles,
      border: `1px solid ${maintenance.color}`,
    };
  } else if (isReservationTypeContent(type)) {
    if (isCompleted) {
      return {
        ...completed,
        ...commonStyles,
        border: `1px solid ${completed.color}`,
      };
    }
    return {
      ...reservation,
      ...commonStyles,
      border: `1px solid ${reservation.color}`,
    };
  } else if (isReservationNotVisible({reservationId: item.id as string})) {
    return {
      ...notVisible,
      ...commonStyles,
      cursor: 'not-allowed',
      //otherwise without background,the selected item will have orange color by default
      background: 'transparent',
      border: `1px solid ${notVisible.color}`,
    };
  }
};

function getCommonStyles() {
  return {
    border: 'none',
    borderRadius: '1px',
    overflow: 'hidden',
    paddingLeft: 3,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  };
}
