import React from 'react';
import ReactDOM from 'react-dom';
import {makeStyles} from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export function Progress() {
  const classes = useStyles();
  const placeEl = document.querySelector('.portal-progress');
  return placeEl
    ? ReactDOM.createPortal(
        <div className={classes.root}>
          <LinearProgress color="secondary" />
        </div>,
        placeEl,
      )
    : null;
}
