import React from 'react';
import {IRouterMap} from 'utils/router';

const AssetTrackingPage = React.lazy(() => import('./'));

const ROUTE_ASSETTRACKING = '/assettracking';

export const generateAssetTrackingPath = (): string => {
  return ROUTE_ASSETTRACKING;
};
export const routes: IRouterMap[] = [
  {
    comp: AssetTrackingPage,
    path: ROUTE_ASSETTRACKING,
    requiredFeatureFlag: 'AssetTracking',
  },
];
