/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as React from 'react';
import {ListLayout, ListPageLayout} from 'components/Common/Entity/ListLayout';
import {useTranslation} from 'react-i18next';
import {
  GhostButton,
  IconButton,
  IconNames,
  Pagination,
  RowAction,
  Sort,
  ValmetTableSource,
  Filter,
} from '@valmet-iop/ui-common';
import {makeStyles, Tooltip, useTheme} from '@material-ui/core';
import {columns, initialSort} from './settings';
import {useRedirect} from 'components/Common/Entity/context/redirect-context';
import {generateEventQs, QsEvents} from '../../routes';
import {CalendarView} from './CalendarView';
import {TimeView} from './TimeView';
import {useListReservations} from '../../hooks/useListReservations';
import {useDeleteMutation} from '../ItemRead';
import {useTree} from '../../context/tree-context';
import {DeleteConfirmDialog} from '../ConfirmDialog';
import {
  pageSizes,
  usePaginator,
} from 'components/Common/Entity/context/paginator-context';
import {useFilter} from '../../context/filter-context';
import {ReservationType} from 'api/generated/iop';
import {
  PERMISSION_ALIAS_SCHEDULING_MAINTENANCE,
  PERMISSION_ALIAS_SCHEDULING_RESERVATIONS,
  usePermission,
} from 'context/permission-context';
import {usePostReservationsGetNotVisible} from 'components/Hooks/queries/useReservations';
import {
  isMaintenanceDeletable,
  isMaintenanceUpdatable,
  isReservationDeletable,
  isReservationUpdatable,
} from '../..';
import {useValmetTableSettings} from 'context/valmet-table-settings-context';
import {LastRefreshBanner, ThemeLayout} from './utils';
import {EPageView, usePageView} from '../../context/pageView-context';
import {LayoutStatus} from 'components/Common/Entity/PageLayout';

interface IconBtnProps {
  icon: IconNames;
  disabled?: boolean;
  onClick: () => void;
  rotate?: boolean;
  active?: boolean;
}

const useStyles = makeStyles({
  root: {
    '& > button': {
      transform: (props: any) => (props.rotate ? 'rotate(90deg)' : ''),
      color: (props: any) => (props.active ? '#008ABA' : ''),
      pointerEvents: (props: any) => (props.active ? 'none' : 'auto'),
      '&:focus': {
        outline: 'none',
        border: 'none',
        color: '#008ABA',
      },
    },
  },
});

function StyledIconButton(props: IconBtnProps) {
  const {rotate, active, ...rest} = props;
  const className = useStyles(props);
  return (
    <div className={className.root}>
      <IconButton {...rest} />
    </div>
  );
}
interface IIconButtonWithTooltip {
  tooltip: string;
  icon: IconNames;
  active: boolean;
  onClick: () => void;
  rotate?: boolean;
}
function IconButtonWithTooltip({tooltip, ...btnProps}: IIconButtonWithTooltip) {
  return (
    <Tooltip title={tooltip} placement="top" arrow>
      <div>
        <StyledIconButton {...btnProps} />
      </div>
    </Tooltip>
  );
}

export function List() {
  const {normalizedFormatTable, status, resultCount, setReservationsSort} =
    useListReservations();
  const {toUpdate} = useRedirect();
  const {t} = useTranslation();
  const theme = useTheme();
  const {mutationDelete} = useDeleteMutation();
  usePostReservationsGetNotVisible();

  const {
    setPageView,
    pageView,
    isSelectedPageViewList,
    isSelectedPageViewCalendar,
    isSelectedPageViewTimeline,
  } = usePageView();

  const [rowIdOnDelete, setRowIdOnDelete] = React.useState<null | string>(null);
  const actions: RowAction[] = [
    {
      icon: 'edit',
      translationKey: 'scheduling:actions.edit',
      onClick: rowId => {
        toUpdate(rowId);
      },
    },
    {
      icon: 'delete',
      translationKey: 'scheduling:actions.delete',
      color: theme.palette.error.main,
      onClick: rowId => {
        setRowIdOnDelete(rowId);
      },
    },
  ];
  const {toCreate, toRead} = useRedirect();
  const {checkedEquipmentsSites} = useTree();
  const {pageNumber, pageSize, onPageChange, onPageSizeChange} = usePaginator();
  const {isCreatable} = usePermission();
  const isMaintenanceCreatable = isCreatable(
    PERMISSION_ALIAS_SCHEDULING_MAINTENANCE,
  );
  const isReservationCreatable = isCreatable(
    PERMISSION_ALIAS_SCHEDULING_RESERVATIONS,
  );
  const {scheme, filters, onFilterChange} = useFilter();
  const {visibleColumns, setVisibleColumns} = useValmetTableSettings();
  React.useEffect(() => {
    if (mutationDelete.isLoading) {
      setRowIdOnDelete(null);
    }
  }, [mutationDelete.isLoading, setRowIdOnDelete]);

  React.useEffect(() => {
    if (pageView !== EPageView.PageViewList) {
      onPageChange(0);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageView]);

  const getActionsForRow = (
    row: any,
    globalActions: readonly RowAction<any>[],
  ) => {
    const [editBtn, deleteBtn] = globalActions;
    let actions: RowAction<any>[] = [];

    if (row.type === ReservationType.Maintenance) {
      if (isMaintenanceUpdatable(row.updatePermissions)) {
        actions = [editBtn];
      }
      if (isMaintenanceDeletable(row.deletePermissions)) {
        actions = [...actions, deleteBtn];
      }
    } else {
      if (isReservationUpdatable(row.updatePermissions)) {
        actions = [editBtn];
      }
      if (isReservationDeletable(row.deletePermissions)) {
        actions = [...actions, deleteBtn];
      }
    }
    return actions;
  };

  const isCreateDisabled = (() => {
    if (isSelectedPageViewList) {
      return false;
    }
    if (isSelectedPageViewCalendar) {
      return checkedEquipmentsSites.length !== 1;
    }
    return checkedEquipmentsSites.length === 0;
  })();

  return (
    <ListPageLayout
      title={t('scheduling:title')}
      createTitle={t('scheduling:buttons.create')}
      status={status}
      onCreate={() => toCreate(generateEventQs(QsEvents.RESERVATION))}
      isCreateDisabled={isCreateDisabled || !isReservationCreatable}
      preTitle={<LastRefreshBanner />}
      titleButtons={
        <>
          {/* 
            TODO: design and add feature OR remove icon button completly
            <StyledIconButton icon="more-info" onClick={() => {}} /> 
          */}
          <IconButtonWithTooltip
            tooltip={t('scheduling:modes.list')}
            icon="categories-all"
            active={isSelectedPageViewList}
            onClick={() => {
              setPageView(EPageView.PageViewList);
            }}
          />
          <IconButtonWithTooltip
            tooltip={t('scheduling:modes.calendar')}
            icon="production-summary"
            active={isSelectedPageViewCalendar}
            onClick={() => {
              setPageView(EPageView.PageViewCalendar);
            }}
          />

          <IconButtonWithTooltip
            tooltip={t('scheduling:modes.timeline')}
            icon="trends"
            active={isSelectedPageViewTimeline}
            rotate
            onClick={() => {
              setPageView(EPageView.PageViewTimeline);
            }}
          />
        </>
      }
      extraButtons={
        <GhostButton
          icon="wrench"
          text={t('scheduling:buttons.maintenance')}
          onClick={() => toCreate(generateEventQs(QsEvents.MAINTENANCE))}
          disableMinimumWidth
          isDisabled={isCreateDisabled || !isMaintenanceCreatable}
        />
      }
    >
      {isSelectedPageViewList && (
        <ListLayout
          filter={
            <Filter
              filterText={filters.text}
              onFilterChange={onFilterChange}
              scheme={scheme}
              selected={filters.selected}
            />
          }
          pagination={
            <Pagination
              currentPage={pageNumber - 1}
              pageSize={pageSize}
              pageSizeOptions={pageSizes}
              rowAmount={resultCount}
              onChangeCurrentPage={onPageChange}
              onChangePageSize={onPageSizeChange}
            />
          }
        >
          <LayoutStatus status={status}>
            <>
              <ValmetTableSource
                columns={columns}
                actions={actions}
                getActionsForRow={getActionsForRow}
                actionsMenuNoItemsTooltip={t(
                  'table.tooltips.rowActionsNoPermissions',
                )}
                setVisibleColumns={setVisibleColumns}
                visibleColumns={visibleColumns}
                noResultsMessageTranslationKey={t('table.noResultsFound')}
                data={normalizedFormatTable}
                initialSort={initialSort}
                onSortChange={({columnIndex, direction}: Sort) => {
                  const sortByColumn = columns[columnIndex].sortColumn;
                  if (sortByColumn === undefined) {
                    return;
                  }

                  setReservationsSort({column: sortByColumn, direction});
                  onPageChange(0);
                }}
                onRowClick={row => {
                  toRead(row.id);
                }}
              />
            </>
          </LayoutStatus>

          <DeleteConfirmDialog
            isOpen={!!rowIdOnDelete}
            onResult={confirmed => {
              if (confirmed && rowIdOnDelete) {
                mutationDelete.mutate(rowIdOnDelete);
              } else {
                setRowIdOnDelete(null);
              }
            }}
          />
        </ListLayout>
      )}

      {isSelectedPageViewCalendar && (
        <ThemeLayout>
          <CalendarView />
        </ThemeLayout>
      )}

      {isSelectedPageViewTimeline && (
        <ThemeLayout>
          <TimeView />
        </ThemeLayout>
      )}
    </ListPageLayout>
  );
}
