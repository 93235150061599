import {FeatureFlagsOutputDto} from 'api/generated/iop';
import {iopApp} from 'api/iopApp';
import config from 'configs/appConfig';
import {useQuery, UseQueryOptions} from 'react-query';
import {optionsGet} from 'utils/query';

export function useFeatureFlags(applicationReady: boolean) {
  return useQuery<FeatureFlagsOutputDto>(
    'featureFlag',
    async () => {
      const {data} = await iopApp.featureFlagsApi.apiFeatureFlagsGet(
        config.api.version,
      );
      return data;
    },
    {
      enabled: applicationReady,
      ...optionsGet,
    } as UseQueryOptions<FeatureFlagsOutputDto>,
  );
}
