import * as React from 'react';
import {ReservationType} from 'api/generated/iop';
import {usePaginator} from 'components/Common/Entity/context/paginator-context';
import {useReservations} from 'components/Hooks/queries/useReservations';
import {format, isValid} from 'date-fns';
import {useFilter} from '../context/filter-context';
import {getDestinationText} from './useItemData';
import {ReservationOutputDTO} from 'api/generated/iop';
import {useTranslation} from 'react-i18next';
import {isStampLessOrEqualDate, isStampMoreOrEqualDate} from '../timeUtils';
import i18n from 'i18n';
import {TableSortDirection} from '@valmet-iop/ui-common';
import orderBy from 'lodash.orderby';
export const NOT_VISIBLE_RESERVATION = 'NOT_VISIBLE_RESERVATION';

export enum RenderMode {
  Group,
  Item,
  Link,
}

function useClientSideSortedReservations(startEndTimes: any) {
  const dateToIso = (date?: string) =>
    date ? new Date(date).toISOString() : undefined;

  const [sort, setSort] = React.useState<{
    column: string | undefined;
    direction: TableSortDirection;
  }>({column: undefined, direction: 'ascending'});

  const {
    status: reservationsStatus,
    data: reservations,
    isLoading: reservationsIsLoading,
    error: reservationsError,
    dataUpdatedAt: reservationsDataUpdatedAt,
  } = useReservations(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    dateToIso(startEndTimes?.start),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    dateToIso(startEndTimes?.end),
  );

  const reservationsWithDestination = React.useMemo<
    (ReservationOutputDTO & {destination: string})[] | undefined
  >(() => {
    return reservations?.map(
      ({
        deliveryDestinationName,
        deliveryDestinationCode,
        deliveryDestinationAddress,
        ...other
      }) => {
        return {
          ...other,
          destination: !!deliveryDestinationName
            ? getDestinationText({
                displayName: deliveryDestinationName,
                code: deliveryDestinationCode,
                address: deliveryDestinationAddress,
              })
            : '',
        };
      },
    );
  }, [reservations]);

  const sortedReservations = React.useMemo<
    (ReservationOutputDTO & {destination: string})[] | undefined
  >(() => {
    if (reservationsWithDestination === undefined) {
      return undefined;
    }

    if (sort.column === undefined) {
      return reservationsWithDestination;
    }

    return orderBy(
      reservationsWithDestination,
      reservation =>
        reservation[
          sort.column as keyof ReservationOutputDTO | 'destination'
        ] || '',
      sort.direction === 'ascending' ? 'asc' : 'desc',
    );
  }, [reservationsWithDestination, sort]);

  return {
    setReservationsSort: setSort,
    reservations: sortedReservations,
    reservationsStatus,
    reservationsError,
    reservationsDataUpdatedAt,
    reservationsIsLoading,
  };
}

export function useListReservations() {
  const {t} = useTranslation();
  const {filters, setFiltered} = useFilter();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const startEndTimes = React.useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const [loadingTime] = filters.selected
      .filter(({filterId}) => {
        return filterId === 'loadingTime';
      })
      .map(({value}) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return JSON.parse(value);
      });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return loadingTime;
  }, [filters]);

  const {
    reservations,
    reservationsDataUpdatedAt,
    reservationsError,
    reservationsIsLoading,
    reservationsStatus,
    setReservationsSort,
  } = useClientSideSortedReservations(startEndTimes);

  const {pageNumber, pageSize} = usePaginator();

  const [filteredReservations, setFilteredReservations] = React.useState<
    (ReservationOutputDTO & {destination: string})[] | undefined
  >(undefined);

  React.useEffect(() => {
    if (filters.text || filters.selected.length) {
      const siteIds = filters.selected
        .filter(({filterId}) => {
          return filterId === 'siteId';
        })
        .map(({value}) => {
          return value;
        });
      const productIds = filters.selected
        .filter(({filterId}) => {
          return filterId === 'productId';
        })
        .map(({value}) => {
          return value;
        });
      const equipmentIds = filters.selected
        .filter(({filterId}) => {
          return filterId === 'equipmentId';
        })
        .map(({value}) => {
          return value;
        });

      const customerIds = filters.selected
        .filter(({filterId}) => {
          return filterId === 'customerId';
        })
        .map(({value}) => {
          return value;
        });
      const orderNos = filters.selected
        .filter(({filterId}) => {
          return filterId === 'orderNo';
        })
        .map(({value}) => {
          return value;
        });
      const logisticsCompanyIds = filters.selected
        .filter(({filterId}) => {
          return filterId === 'logisticsCompany';
        })
        .map(({value}) => {
          return value;
        });
      const deliveryDestinationIds = filters.selected
        .filter(({filterId}) => {
          return filterId === 'destination';
        })
        .map(({value}) => {
          return value;
        });
      const types = filters.selected
        .filter(({filterId}) => {
          return filterId === 'type';
        })
        .map(({value}) => {
          return value;
        });
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const [loadingTime] = filters.selected
        .filter(({filterId}) => {
          return filterId === 'loadingTime';
        })
        .map(({value}) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return JSON.parse(value);
        });
      const filtered = reservations?.filter(
        ({
          siteId,
          equipmentId,
          customerCompanyId,
          logisticsOperatorCompanyId,
          orderNo,
          deliveryDestinationId,
          type,
          startStamp,
          productId,
        }) => {
          if (siteIds.length && !siteIds.some(id => id === siteId)) {
            return false;
          }
          if (productIds.length && !productIds.some(id => id === productId)) {
            return false;
          }

          if (
            equipmentIds.length &&
            !equipmentIds.some(id => id === equipmentId)
          ) {
            return false;
          }
          if (
            customerIds.length &&
            !customerIds.some(id => id === customerCompanyId)
          ) {
            return false;
          }
          if (
            logisticsCompanyIds.length &&
            !logisticsCompanyIds.some(id => id === logisticsOperatorCompanyId)
          ) {
            return false;
          }
          if (orderNos.length && !orderNos.some(id => +id === orderNo)) {
            return false;
          }
          if (
            deliveryDestinationIds.length &&
            !deliveryDestinationIds.some(id => id === deliveryDestinationId)
          ) {
            return false;
          }
          if (
            types.length &&
            !types.some(currentType => currentType === type)
          ) {
            return false;
          }
          if (loadingTime) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const {start, end}: {start: Date; end: Date} = loadingTime;
            const isStart = isValid(new Date(start));
            const isEnd = isValid(new Date(end));
            if (isStart && isEnd) {
              return (
                isStampMoreOrEqualDate({stamp: startStamp, date: start}) &&
                isStampLessOrEqualDate({stamp: startStamp, date: end})
              );
            }
            if (isStart) {
              return isStampMoreOrEqualDate({stamp: startStamp, date: start});
            }
            if (isEnd) {
              return isStampLessOrEqualDate({stamp: startStamp, date: end});
            }
          }
          return true;
        },
      );

      setFilteredReservations(filtered);
      setFiltered(filtered);
    } else {
      setFilteredReservations(reservations);
      setFiltered(reservations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, reservationsDataUpdatedAt, reservations]);

  const dataPaginatorBinded = (filteredReservations || reservations)
    ?.slice()
    .splice(pageSize * (pageNumber - 1), pageSize);

  return {
    status: reservationsStatus,
    normalizedFormatTable:
      dataPaginatorBinded?.map(
        ({
          siteName,
          reservationId,
          startStamp,
          customerCompanyName,
          logisticsOperatorName,
          orderNo,
          equipmentName,
          destination,
          type,
          updatePermissions,
          deletePermissions,
          productDisplayName,
        }) => {
          return {
            id: reservationId,
            site: siteName ?? '',
            loadingPlace: equipmentName ?? '',
            loadingTime: startStamp
              ? format(new Date(startStamp), 'dd.MM.y HH:mm')
              : '',
            startStamp,
            orderNo,
            destination,
            customerCompanyName: customerCompanyName ?? '',
            logisticsOperatorName: logisticsOperatorName ?? '',
            updatePermissions,
            deletePermissions,
            type:
              type !== undefined
                ? t(`scheduling:reservationTypes.${type}`)
                : undefined,
            product: productDisplayName,
          };
        },
      ) || [],
    isLoading: reservationsIsLoading,
    error: reservationsError,
    reservations,
    resultCount: filteredReservations?.length ?? 0,
    setReservationsSort,
  };
}

export const getTextByType = (type?: string) => {
  if (type === ReservationType.ReservationLoading)
    return i18n.t('scheduling:reservationTypes.ReservationLoading');

  if (type === ReservationType.ReservationUnloading)
    return i18n.t('scheduling:reservationTypes.ReservationUnloading');

  return type;
};
