import React from 'react';
import {useField} from 'formik';
import {FormikValmetSelect, IOption} from '@valmet-iop/ui-common';

type FormikSelectProps<Multiple extends boolean | undefined> = {
  formProperty: string;
  multiple?: Multiple;
  id: string;
  options: IOption[];
  spyOnChange?: (selectedOption: IOption | null) => void;
  isDisabled?: boolean;
  labelTranslationKey: string;
  isRequired?: boolean;
};

export type IAutoSelect = FormikSelectProps<false> & {
  disableAutoSelect?: boolean;
};
/**
 * A FormikSelect that automatically
 * selects the available option if only one option
 * can be selected. The user can still clear the select
 * in which case the auto-selection is turned off
 * until the user makes another selection.
 */
export default function AutoSelectingFormikSingleSelect(props: IAutoSelect) {
  const [field, , fieldHelpers] = useField({name: props.formProperty});
  const [userClearedSelect, setUserClearedSelect] = React.useState(false);
  const fieldValue = field.value;
  const spyOnChangeProp = props.spyOnChange;
  const disableAutoSelectProp = props.disableAutoSelect;

  // Auto-select the only available option if:
  // 1. Options array changed
  // 2. AND there is only 1 option
  // 3. AND there is no value selected currently OR the current value is not the only option
  // 4. AND user has not cleared the select
  // 5. AND auto-select is not disabled
  React.useEffect(() => {
    if (props.options.length !== 1) {
      return;
    }

    if (fieldValue?.value && fieldValue.value === props.options[0].value) {
      return;
    }

    if (userClearedSelect) {
      return;
    }

    if (disableAutoSelectProp) {
      return;
    }

    fieldHelpers.setValue(props.options[0]);
    if (spyOnChangeProp) {
      spyOnChangeProp(props.options[0]);
    }
  }, [
    props.options,
    fieldHelpers,
    fieldValue,
    userClearedSelect,
    disableAutoSelectProp,
    spyOnChangeProp,
  ]);

  return (
    <FormikValmetSelect
      {...props}
      width="full"
      spyOnChange={selected => {
        if (selected === null && userClearedSelect === false) {
          setUserClearedSelect(true);
        } else if (selected !== null && userClearedSelect) {
          setUserClearedSelect(false);
        }

        if (spyOnChangeProp) {
          spyOnChangeProp(selected);
        }
      }}
    />
  );
}
