import {useMutation, useQuery, useQueryClient} from 'react-query';
import {
  EquipmentDirectForecastOutputDTO,
  EquipmentForSiteOutputDTO,
  EquipmentMeasurementResultsOutputDTO,
  ForecastParametersInputDTO,
  ForecastParametersOutputDTO,
  ForecastType,
  GetAvailableBaseProductsForCompanyOutputDTO,
  GetAvailableCountriesForCompanyOutputDTO,
  GetAvailableProductsForCompanyOutputDTO,
  GetEndpointMonitoringAvailableCompaniesOutputDTO,
  SiteRunoutOutputDTO,
  SearchEndpointMonitoringSitesFlatInputDTO,
  SearchEndpointMonitoringSitesFlatOutputDTOPagedResponse,
  SearchEndpointMonitoringSitesHierarchicalInputDTO,
  SearchEndpointMonitoringSitesHierarchicalOutputDTOPagedResponse,
  SiteCachedForecastOutputDTO,
  SiteChildrenCachedForecastOutputDTO,
  SiteDirectForecastOutputDTO,
  SiteMeasurementsOutputDTO,
  TagEquipmentTrendValueDTO,
  TagTrendValueDTO,
} from 'api/generated/iop';
import {iopApp} from 'api/iopApp';
import config from 'configs/appConfig';
import {toast} from 'react-toastify';
import {TFunction} from 'react-i18next';
import {errorNotification} from '@valmet-iop/ui-common';
import i18n from 'i18n';
import {AxiosError} from 'axios';

export function useEndpointMonitoringGetAllowedCompanies(enabled: boolean) {
  return useQuery<GetEndpointMonitoringAvailableCompaniesOutputDTO[]>(
    ['endpointMonitoringGetAllowedCompanies'],
    async () => {
      const {data} =
        await iopApp.endpointMonitoringApi.apiEndpointmonitoringCompaniesGet(
          config.api.version,
        );
      return data;
    },
    {
      enabled,
    },
  );
}

export function useEndpointMonitoringGetProducts(companyId: string) {
  return useQuery<GetAvailableProductsForCompanyOutputDTO[]>(
    ['endpointMonitoringGetCompanyProducts', companyId],
    async () => {
      const {data} =
        await iopApp.endpointMonitoringApi.apiEndpointmonitoringCompaniesCompanyIdProductsGet(
          companyId,
          config.api.version,
        );
      return data;
    },
    {
      enabled: !!companyId,
    },
  );
}

export function useEndpointMonitoringGetBaseProducts(companyId: string) {
  return useQuery<GetAvailableBaseProductsForCompanyOutputDTO[]>(
    ['endpointMonitoringGetCompanyBaseProducts', companyId],
    async () => {
      const {data} =
        await iopApp.endpointMonitoringApi.apiEndpointmonitoringCompaniesCompanyIdBaseProductsGet(
          companyId,
          config.api.version,
        );
      return data;
    },
    {
      enabled: !!companyId,
    },
  );
}

export function useEndpointMonitoringGetCountries(companyId: string) {
  return useQuery<GetAvailableCountriesForCompanyOutputDTO[]>(
    ['endpointMonitoringGetCompanyCountries', companyId],
    async () => {
      const {data} =
        await iopApp.endpointMonitoringApi.apiEndpointmonitoringCompaniesCompanyIdCountriesGet(
          companyId,
          config.api.version,
        );
      return data;
    },
    {
      enabled: !!companyId,
    },
  );
}

export function useEndpointMonitoringSitesSearchFlat(
  searchParams: SearchEndpointMonitoringSitesFlatInputDTO,
  enabled: boolean,
) {
  return useQuery<SearchEndpointMonitoringSitesFlatOutputDTOPagedResponse>(
    [
      'endpointMonitoringSitesSearchFlat',
      searchParams.companyId,
      searchParams.searchText,
      searchParams.country,
      searchParams.productId,
      searchParams.baseProductId,
      searchParams.siteType,
      searchParams.pageNumber,
      searchParams.pageSize,
      searchParams.sortByColumn,
      searchParams.orderByType,
    ],
    async () => {
      const {data} =
        await iopApp.endpointMonitoringApi.apiEndpointmonitoringSitesSearchFlatPost(
          config.api.version,
          searchParams,
        );
      return data;
    },
    {
      enabled: !!searchParams.companyId && enabled,
    },
  );
}

export function useEndpointMonitoringSitesSearchHierarchical(
  searchParams: SearchEndpointMonitoringSitesHierarchicalInputDTO,
  enabled: boolean,
) {
  return useQuery<SearchEndpointMonitoringSitesHierarchicalOutputDTOPagedResponse>(
    [
      'endpointMonitoringSitesSearchHierarchical',
      searchParams.companyId,
      searchParams.searchText,
      searchParams.country,
      searchParams.productId,
      searchParams.baseProductId,
      searchParams.siteType,
      searchParams.pageNumber,
      searchParams.pageSize,
      searchParams.sortByColumn,
      searchParams.orderByType,
    ],
    async () => {
      const {data} =
        await iopApp.endpointMonitoringApi.apiEndpointmonitoringSitesSearchHierarchicalPost(
          config.api.version,
          searchParams,
        );
      return data;
    },
    {
      enabled: !!searchParams.companyId && enabled,
    },
  );
}

export function useEndpointMonitoringGetSiteForecast(siteId: string) {
  return useQuery<SiteCachedForecastOutputDTO>(
    ['endpointMonitoringGetSiteForcast', siteId],
    async () => {
      const {data} =
        await iopApp.endpointMonitoringApi.apiEndpointmonitoringSitesSiteIdForecastCachedGet(
          siteId,
          config.api.version,
        );
      return data;
    },
    {
      enabled: !!siteId.length,
    },
  );
}

export function useEndpointMonitoringGetSiteChildrenForecasts(siteId: string) {
  return useQuery<SiteChildrenCachedForecastOutputDTO>(
    ['endpointMonitoringGetSiteChildrenForcasts', siteId],
    async () => {
      const {data} =
        await iopApp.endpointMonitoringApi.apiEndpointmonitoringSitesSiteIdChildrenForecastCachedGet(
          siteId,
          config.api.version,
        );
      return data;
    },
    {
      enabled: !!siteId.length,
    },
  );
}

export interface ISiteForecastParams {
  forecastType: ForecastType;
  historyStartDaysAgo: number;
  historyDaysToDisplay?: number;
  minimumReplenishmentAmountKilograms?: number;
  lastReplenishmentLevelKilograms?: number;
  maximumDaysToForecast?: number;
  warningLimitKilograms?: number;
  alarmLimitKilograms?: number;
  runoutWarningHours?: number;
  runoutAlarmHours?: number;
}
export function useEndpointMonitoringGetSiteNoCacheForecast(
  siteId: string,
  forecastConfig: ISiteForecastParams,
) {
  return useQuery<SiteDirectForecastOutputDTO>(
    ['endpointMonitoringGetSiteNoCacheForecast', siteId, forecastConfig],
    async () => {
      const {data} =
        await iopApp.endpointMonitoringApi.apiEndpointmonitoringSitesSiteIdForecastNocacheGet(
          siteId,
          forecastConfig.forecastType,
          forecastConfig.historyStartDaysAgo,
          config.api.version,
          forecastConfig.historyDaysToDisplay,
          forecastConfig.minimumReplenishmentAmountKilograms,
          forecastConfig.lastReplenishmentLevelKilograms,
          forecastConfig.maximumDaysToForecast,
        );

      if (data.errors.length > 0) {
        data.errors.forEach(e => toast.error(e));
      }
      return data;
    },
    {
      enabled: !!siteId.length,
    },
  );
}

export function useEndpointMonitoringGetSiteRunout(siteId: string) {
  return useQuery<SiteRunoutOutputDTO>(
    ['endpointMonitoringGetSiteRunout', siteId],
    async () => {
      const {data} =
        await iopApp.endpointMonitoringApi.apiEndpointmonitoringSitesSiteIdRunoutGet(
          siteId,
          config.api.version,
        );
      return data;
    },
    {
      enabled: !!siteId.length,
    },
  );
}

export function useEndpointMonitoringGetSiteForecastParameters(siteId: string) {
  return useQuery<ForecastParametersOutputDTO>(
    ['endpointMonitoringGetSiteForcastParameters', siteId],
    async () => {
      const {data} =
        await iopApp.endpointMonitoringApi.apiEndpointmonitoringSitesSiteIdForecastparametersGet(
          siteId,
          config.api.version,
        );
      return data;
    },
    {
      enabled: !!siteId.length,
    },
  );
}

export function useEndpointMonitoringPutForecastParameters(
  t: TFunction,
  siteId: string,
) {
  const queryClient = useQueryClient();
  return useMutation(
    (data: ForecastParametersInputDTO) =>
      iopApp.endpointMonitoringApi.apiEndpointmonitoringSitesSiteIdForecastparametersPut(
        siteId,
        config.api.version,
        data,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'endpointMonitoringGetSiteForcastParameters',
          siteId,
        ]);
        queryClient.invalidateQueries([
          'endpointMonitoringGetSiteForcast',
          siteId,
        ]);
        toast.success(
          t('endpointMonitoring:toast.forecastParamsUpdateSuccess'),
        );
      },
      onError: (error: AxiosError) => {
        if (error.response && error.response.data) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          errorNotification(error.response.data.detail);
        } else {
          toast.error(
            t('endpointMonitoring:toast.forecastParamsUpdateFailure'),
          );
        }
      },
    },
  );
}

export function useEndpointMonitoringGetLatestMeasurements(siteId: string) {
  return useQuery<SiteMeasurementsOutputDTO>(
    ['endpointMonitoringGetSiteLatestMeasurements', siteId],
    async () => {
      const {data} =
        await iopApp.endpointMonitoringApi.apiEndpointmonitoringSitesSiteIdMeasurementsLatestGet(
          siteId,
          config.api.version,
        );
      return data;
    },
    {
      enabled: !!siteId.length,
    },
  );
}

export function useEndpointMonitoringGetSiteTrends(siteId: string) {
  return useQuery<TagTrendValueDTO[]>(
    ['endpointMonitoringGetSiteTrends', siteId],
    async () => {
      const {data} =
        await iopApp.endpointMonitoringApi.apiEndpointmonitoringSitesSiteIdMeasurementsTrendsGet(
          siteId,
          config.api.version,
        );
      return data;
    },
    {
      enabled: !!siteId.length,
    },
  );
}

export function useEndpointMonitoringGetSiteEquipments(siteId: string) {
  return useQuery<EquipmentForSiteOutputDTO[]>(
    ['endpointMonitoringGetSiteEquipments', siteId],
    async () => {
      const {data} =
        await iopApp.endpointMonitoringApi.apiEndpointmonitoringSitesSiteIdEquipmentGet(
          siteId,
          config.api.version,
        );
      return data;
    },
    {
      enabled: !!siteId.length,
    },
  );
}

export function useEndpointMonitoringGetEquipmentLatestMeasurements(
  siteId: string,
  equipmentId: string,
) {
  return useQuery<EquipmentMeasurementResultsOutputDTO>(
    [
      'endpointMonitoringGetSiteEquipmentLatestMeasurements',
      siteId,
      equipmentId,
    ],
    async () => {
      const {data} =
        await iopApp.endpointMonitoringApi.apiEndpointmonitoringSitesSiteIdEquipmentEquipmentIdMeasurementsGet(
          siteId,
          equipmentId,
          config.api.version,
        );
      return data;
    },
    {
      enabled: !!siteId.length && !!equipmentId.length,
    },
  );
}

export function useEndpointMonitoringGetEquipmentTrends(
  siteId: string,
  equipmentId: string,
) {
  return useQuery<TagEquipmentTrendValueDTO[]>(
    ['endpointMonitoringGetSiteEquipmentTrends', siteId, equipmentId],
    async () => {
      const {data} =
        await iopApp.endpointMonitoringApi.apiEndpointmonitoringSitesSiteIdEquipmentEquipmentIdTrendsGet(
          siteId,
          equipmentId,
          config.api.version,
        );
      return data;
    },
    {
      enabled: !!siteId.length && !!equipmentId.length,
    },
  );
}

export interface IEquipmentForecastParams {
  forecastType: ForecastType;
  historyStartDaysAgo?: number | null;
  maximumDaysToForecast?: number | null;
  historyDaysToDisplay?: number | null;
}
export function useEndpointMonitoringGetEquipmentNoCacheForecast(
  siteId: string,
  equipmentId: string,
  forecastConfig?: IEquipmentForecastParams,
) {
  return useQuery<EquipmentDirectForecastOutputDTO>(
    [
      'endpointMonitoringGetEquipmentNoCacheForcast',
      siteId,
      equipmentId,
      forecastConfig,
    ],
    async () => {
      const {data} =
        await iopApp.endpointMonitoringApi.apiEndpointmonitoringSitesSiteIdEquipmentEquipmentIdForecastNocacheGet(
          siteId,
          equipmentId,
          config.api.version,
          forecastConfig?.forecastType,
          forecastConfig?.historyStartDaysAgo || undefined,
          forecastConfig?.historyDaysToDisplay || undefined,
          forecastConfig?.maximumDaysToForecast || undefined,
        );

      if (data.errors.length > 0) {
        data.errors.forEach(e => toast.error(e));
      }
      return data;
    },
    {
      enabled: !!siteId.length && !!equipmentId.length,
    },
  );
}

export function useEndpointMonitoringSitesCoordinates(
  companyId: string | null,
) {
  return useQuery<string>(
    ['endpointMonitoringSitesCoordinates', companyId],
    async () => {
      const {data} =
        await iopApp.endpointMonitoringApi.apiEndpointmonitoringCompaniesCompanyIdSitesCoordinatesGet(
          companyId ?? '',
          config.api.version,
        );
      return data;
    },
    {
      enabled: !!companyId,
      onError: () => {
        errorNotification(
          i18n.t('endpointMonitoring:notifications.couldNotLoadSiteLocations'),
        );
      },
    },
  );
}
