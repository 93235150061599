import actionCreatorFactory from 'typescript-fsa';
import {AccountInfo} from '@azure/msal-browser';

const actionCreator = actionCreatorFactory();

export const INITIALIZE_USER = 'INITIALIZE_USER';
export const CHANGE_UI_LANGUAGE = 'CHANGE_UI_LANGUAGE';

export const initializeUserActionCreator = actionCreator.async<{}, AccountInfo>(
  'INITIALIZE_USER',
  {
    notification: {show: false},
  },
);

export const acquireTokenFailedActionCreator = actionCreator<void>(
  'ACQUIRE_TOKEN_FAILED',
);

export const logoutUserActionCreator = actionCreator<void>('LOGOUT');

export const changeLanguageActionCreator = actionCreator<string>(
  CHANGE_UI_LANGUAGE,
);
