import {Box, Typography, withStyles} from '@material-ui/core';
import {
  ActionButton,
  colors,
  IconButton,
  TextButton,
} from '@valmet-iop/ui-common';
import * as React from 'react';
import format from 'date-fns/format';

import {useTranslation} from 'react-i18next';
import {
  differenceInHours,
  isPast,
  isSameDay,
  isSameMinute,
  isSameSecond,
  isSunday,
  isToday,
} from 'date-fns';
import {useCalendarStyles} from './styles';

const StyledActionButton = withStyles(() => ({
  root: {
    background: colors.blue2,
    borderColor: colors.blue2,
    '&:hover, &:focus, &:active': {
      background: colors.blue2,
      borderColor: colors.blue2,
    },
  },
}))(ActionButton);
interface IViewButton {
  isActive?: boolean;
  onClick: () => void;
  text: string;
}
function ViewButton({isActive, ...other}: IViewButton) {
  if (isActive) {
    return <StyledActionButton type="primary" {...other} />;
  }
  return <TextButton {...other} />;
}

export function Toolbar({onNavigate, onView, label, date, view}: any) {
  let labelTxt = label;
  const {t} = useTranslation();
  const dateToFormat = new Date(date);
  const month = format(dateToFormat, 'LLLL');
  const year = format(dateToFormat, 'y');
  if (view === 'week') {
    const week = t('scheduling:titleWeek', {
      num: format(dateToFormat, 'I'),
    });
    labelTxt = `${week} (${month} ${year})`;
  } else if (view === 'day') {
    const weekday = format(dateToFormat, 'EEEE');
    const dayOfMonth = format(dateToFormat, 'do');
    labelTxt = `${weekday} ${dayOfMonth} of ${month} ${year}`;
  } else {
    const month = format(dateToFormat, 'MMMM');
    const year = format(dateToFormat, 'y');
    labelTxt = `${month} ${year}`;
  }
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={1}
    >
      <Typography variant="h2" style={{color: colors.grey3}}>
        {labelTxt}
      </Typography>
      <Box display="flex">
        <Box display="flex">
          <Box mr={1}>
            <ViewButton
              isActive={view === 'day'}
              text={t('scheduling:buttons.day')}
              onClick={() => onView('day')}
            />
          </Box>
          <ViewButton
            isActive={view === 'week'}
            text={t('scheduling:buttons.week')}
            onClick={() => onView('week')}
          />
          <Box ml={1}>
            <ViewButton
              isActive={view === 'month'}
              text={t('scheduling:buttons.month')}
              onClick={() => onView('month')}
            />
          </Box>
        </Box>
        <Box ml={9} display="flex">
          <IconButton icon="arrow-left" onClick={() => onNavigate('PREV')} />
          <Box ml={2} mr={2}>
            <ViewButton
              text={t('scheduling:buttons.today')}
              onClick={() => onNavigate('TODAY')}
            />
          </Box>
          <IconButton icon="arrow-right" onClick={() => onNavigate('NEXT')} />
        </Box>
      </Box>
    </Box>
  );
}

export function DateCellWrapper(props: any) {
  const {children, value, selectedSlot, selectedView} = props;
  const classes = useCalendarStyles();
  const isPrevDay = isPast(value) && !isToday(value);
  const isSelected =
    isSameDay(new Date(value), new Date(selectedSlot)) && !isPrevDay;
  if (selectedView === 'day') {
    return (
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <b>{format(new Date(value), 'eeee')}</b>
        <Box ml={1}>
          <div className={isToday(value) ? classes.headerWeekDayToday : ''}>
            {format(new Date(value), 'd')}
          </div>
        </Box>
      </Box>
    );
  }
  return React.cloneElement(React.Children.only(children), {
    style: {
      ...children.style,
      backgroundColor:
        isSelected && selectedView === 'month'
          ? 'rgb(0, 138, 186,0.1)'
          : isPrevDay
          ? colors.grey14
          : colors.white,
    },
  });
}

export function TimeSlotWrapper(props: any) {
  const {children, value, resource, selectedView, selectedSlot} = props;
  const isPrevHour = differenceInHours(new Date(), new Date(value)) > 0;
  const isSelected =
    selectedView === 'day'
      ? isSameSecond(new Date(value), new Date(selectedSlot)) && !isPrevHour
      : isSameMinute(new Date(value), new Date(selectedSlot)) && !isPrevHour;
  const isGutter = resource === undefined;
  if (isGutter && selectedView === 'week') {
    return props.children;
  }
  const rowIndex = format(new Date(value), 'H');
  let backgroundColor = +rowIndex % 2 ? colors.grey15 : colors.white;
  if (isPrevHour) {
    backgroundColor = colors.grey14;
  }
  return React.cloneElement(React.Children.only(children), {
    style: {
      ...children.style,
      backgroundColor: isSelected ? 'rgb(0, 138, 186,0.1)' : backgroundColor,
    },
  });
}

export function MonthDateHeader(props: any) {
  const classes = useCalendarStyles();
  const {t} = useTranslation();
  if (isSunday(new Date(props.date))) {
    return (
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <div className={classes.monthWeek}>
          {t('scheduling:titleWeek', {
            num: format(new Date(props.date), 'I'),
          })}
        </div>
        <div className={classes.monthDay}>
          <div>{props.label}</div>
        </div>
      </Box>
    );
  }
  return (
    <Box display="flex" justifyContent="flex-end">
      <div className={classes.monthSlot}>
        <div
          className={
            isToday(new Date(props.date))
              ? classes.monthDayToday
              : classes.monthDay
          }
        >
          {props.label}
        </div>
      </div>
    </Box>
  );
}

export function WeekHeader(props: any) {
  const classes = useCalendarStyles();
  const isCurrent = isToday(new Date(props.date));

  return (
    <Box
      display="flex"
      alignItems="center"
      height="100%"
      justifyContent="space-between"
    >
      <div>{format(new Date(props.date), 'EEEE')}</div>
      <div
        className={`${classes.headerWeekDay}  ${
          isCurrent ? classes.headerWeekDayToday : ''
        }`}
      >
        {format(new Date(props.date), 'd')}
      </div>
    </Box>
  );
}

export function TimeGutterHeader(props: any) {
  const {currentWeek} = props;
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <b>W{currentWeek}</b>
    </Box>
  );
}
