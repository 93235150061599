import {initializeUserActionCreator} from '../../redux/actions/user';
import {
  getAllAccounts,
  handleRedirectPromise,
  setActiveAccount,
} from '../../services/authService';
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {AccountInfo} from '@azure/msal-common';

function getFirstAvailableAccount() {
  const accounts = getAllAccounts();
  if (accounts === null || accounts.length === 0) {
    throw new Error('No account found');
  }

  return accounts[0];
}

async function handleAuthenticationRedirect(): Promise<AccountInfo> {
  const res = await handleRedirectPromise();

  const account = res?.account ?? getFirstAvailableAccount();

  setActiveAccount(account);
  return account;
}

export default (WrappedComponent: any) => {
  const InitializeHOC = (props: any) => {
    const dispatch = useDispatch();

    useEffect(() => {
      // Dispatch init started on mount
      dispatch(initializeUserActionCreator.started({}));
      handleAuthenticationRedirect()
        .then(account => {
          // Once we have an account, update state
          dispatch(
            initializeUserActionCreator.done({params: {}, result: account}),
          );
        })
        .catch((err: any) => {
          dispatch(
            initializeUserActionCreator.failed({error: err, params: {}}),
          );
        });
    }, [dispatch]);

    return <WrappedComponent {...props} />;
  };

  return InitializeHOC;
};
