import {Modes} from 'components/Common/Entity/context/item-context';
import React from 'react';
import {generatePathById, IRouterMap} from 'utils/router';

const EndpointMonitoringPage = React.lazy(() => import('./'));
const EndpointMapPage = React.lazy(() => import('./components/Map'));

const ROUTE_ENDPOINT_MONITORING = '/endpointMonitoring';
const ROUTE_ENDPOINT_MONITORING_VIEW = `${ROUTE_ENDPOINT_MONITORING}/view/:id`;
const ROUTE_ENDPOINT_MONITORING_MAP = '/endpointMonitoringMap';

export const generateEndpointMonitoringPath = (): string => {
  return ROUTE_ENDPOINT_MONITORING;
};
export const generateEndpointMonitoringViewPath = (id: string): string => {
  return generatePathById(`${ROUTE_ENDPOINT_MONITORING_VIEW}`, id);
};
export const generateEndpointMapPath = () => {
  return ROUTE_ENDPOINT_MONITORING_MAP;
};

export const routes: IRouterMap[] = [
  {
    comp: EndpointMonitoringPage,
    path: ROUTE_ENDPOINT_MONITORING,
    requiredFeatureFlag: 'EndpointMonitoring',
  },
  {
    comp: EndpointMonitoringPage,
    path: ROUTE_ENDPOINT_MONITORING_VIEW,
    mode: Modes.READ,
    requiredFeatureFlag: 'EndpointMonitoring',
  },
  {
    comp: EndpointMapPage,
    path: ROUTE_ENDPOINT_MONITORING_MAP,
    requiredFeatureFlag: 'EndpointMonitoring',
  },
];
