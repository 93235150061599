/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  GetUserAccessibleSiteOutputDTO,
  LogisticsCompanyOutputDTO,
  LogisticsOperatorTruckOutputDTO,
  PaginatedReservationsQueryDTO,
  ReservationAvailableProductsOutputDTO,
  ReservationCustomerOutputDTO,
  ReservationDeliveryDestinationOutputDTO,
  ReservationEquipmentOutputDTO,
  ReservationInputDTO,
  ReservationOutputDTO,
  ReservationTimestampOutputDTO,
  ReservationUpdateRestrictedInputDTO,
} from 'api/generated/iop';
import {iopApp} from 'api/iopApp';
import config from 'configs/appConfig';
import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import {optionsGet, parseMsg} from 'utils/query';
import orderBy from 'lodash.orderby';
import {AxiosError} from 'axios';
import {compose, prop, sortBy, toLower} from 'ramda';
import {
  SiteParameters,
  getSiteParametersFromOutput,
  CompanyParameters,
  getCompanyParametersFromOutput,
} from 'utils/parameters';
import {errorNotification, successNotification} from '@valmet-iop/ui-common';
import {useRedirect} from 'components/Common/Entity/context/redirect-context';
import {useTranslation} from 'react-i18next';
import {useItem} from 'components/Common/Entity/context/item-context';
import {usePaginator} from 'components/Common/Entity/context/paginator-context';

export const useDeleteReservationsReservationId = () => {
  const queryClient = useQueryClient();
  const {toDefault} = useRedirect();
  const {isDefault} = useItem();
  const {t} = useTranslation();
  const {onPageChange} = usePaginator();
  return useMutation(
    (reservationId: string) =>
      iopApp.reservationsApi.apiReservationsReservationIdDelete(
        reservationId,
        config.api.version,
      ),
    {
      onSuccess: ({data}) => {
        void queryClient.invalidateQueries('reservations');
        const type =
          data.type !== undefined
            ? t(`scheduling:reservationTypes.${data.type}`)
            : '';
        successNotification(t('scheduling:delete.success', {type}));
        onPageChange(0);
        if (!isDefault) {
          toDefault();
        }
      },
      onError: (error: AxiosError) => {
        if (error.response && error.response.data) {
          errorNotification(error.response.data.detail);
        }
      },
    },
  );
};

export const usePostReservationsSearch = () =>
  useMutation(
    (matchParams: PaginatedReservationsQueryDTO) =>
      iopApp.reservationsApi.apiReservationsSearchPost(
        config.api.version,
        matchParams,
      ),
    {
      onError: (error: AxiosError) => {
        if (error.response && error.response.data) {
          errorNotification(error.response.data.detail);
        }
      },
    },
  );

export const usePostReservations = () => {
  const queryClient = useQueryClient();
  const {toRead} = useRedirect();
  const {t} = useTranslation();
  const {isCreate} = useItem();
  return useMutation(
    (reservation: ReservationInputDTO) =>
      iopApp.reservationsApi.apiReservationsPost(
        config.api.version,
        reservation,
      ),
    {
      onSuccess: ({data}: {data: ReservationOutputDTO}) => {
        void queryClient.invalidateQueries('reservations');
        void queryClient.invalidateQueries([
          'reservationsReservationIdGet',
          data.reservationId,
        ]);
        const type =
          data.type !== undefined
            ? t(`scheduling:reservationTypes.${data.type}`)
            : '';
        successNotification(
          isCreate
            ? t('scheduling:create.success', {type})
            : t('scheduling:update.success', {type}),
        );
        toRead(data.reservationId);
      },
      onError: (error: AxiosError) => {
        // TODO do some util to parse message, or set some flag for the BE to get the message in lang
        if (error.response && error.response.data) {
          errorNotification(parseMsg(error.response.data.detail));
        }
      },
    },
  );
};

export const usePutReservationsReservationId = () => {
  const queryClient = useQueryClient();
  const {toRead} = useRedirect();
  const {t} = useTranslation();
  const {isCreate} = useItem();
  const getReservationInputDTO = (
    reservation: {reservationId: string} & ReservationInputDTO,
  ) => {
    const {reservationId, ...rest} = reservation;
    return rest;
  };
  return useMutation(
    (reservation: {reservationId: string} & ReservationInputDTO) =>
      iopApp.reservationsApi.apiReservationsReservationIdPut(
        reservation.reservationId,
        config.api.version,
        getReservationInputDTO(reservation),
      ),
    {
      onSuccess: ({data}: {data: ReservationOutputDTO}) => {
        void queryClient.invalidateQueries('reservations');
        void queryClient.invalidateQueries([
          'reservationsReservationIdGet',
          data.reservationId,
        ]);
        const type =
          data.type !== undefined
            ? t(`scheduling:reservationTypes.${data.type}`)
            : '';
        successNotification(
          isCreate
            ? t('scheduling:create.success', {type})
            : t('scheduling:update.success', {type}),
        );
        toRead(data.reservationId);
      },
      onError: (error: AxiosError) => {
        // TODO do some util to parse message, or set some flag for the BE to get the message in lang
        if (error.response && error.response.data) {
          errorNotification(parseMsg(error.response.data.detail));
        }
      },
    },
  );
};

export const usePutReservationsReservationRestrictedId = () => {
  const queryClient = useQueryClient();
  const {toRead} = useRedirect();
  const {t} = useTranslation();
  const getReservationUpdateRestrictedInputDTO = (
    reservation: {
      reservationId: string;
    } & ReservationUpdateRestrictedInputDTO,
  ) => {
    const {reservationId, ...rest} = reservation;
    return rest;
  };
  return useMutation(
    (
      reservation: {
        reservationId: string;
      } & ReservationUpdateRestrictedInputDTO,
    ) =>
      iopApp.reservationsApi.apiReservationsReservationIdRestrictedPut(
        reservation.reservationId,
        config.api.version,
        getReservationUpdateRestrictedInputDTO(reservation),
      ),
    {
      onSuccess: ({data}: {data: ReservationOutputDTO}) => {
        void queryClient.invalidateQueries('reservations');
        void queryClient.invalidateQueries([
          'reservationsReservationIdGet',
          data.reservationId,
        ]);
        const type =
          data.type !== undefined
            ? t(`scheduling:reservationTypes.${data.type}`)
            : '';
        successNotification(t('scheduling:update.success', {type}));
        toRead(data.reservationId);
      },
      onError: (error: AxiosError) => {
        // TODO do some util to parse message, or set some flag for the BE to get the message in lang
        if (error.response && error.response.data) {
          errorNotification(parseMsg(error.response.data.detail));
        }
      },
    },
  );
};

export function useReservations(
  startDateTime?: string,
  toDateTime?: string,
  enabled?: boolean,
) {
  return useQuery<ReservationOutputDTO[]>(
    ['reservations', startDateTime, toDateTime],
    async () => {
      try {
        const {data} = await iopApp.reservationsApi.apiReservationsGet(
          config.api.version,
          startDateTime,
          toDateTime,
        );

        return orderBy(data, 'startStamp', 'desc');
      } catch (e) {
        //TODO: check on 404 somehow
        return [];
      }
    },
    {
      ...(optionsGet as UseQueryOptions<ReservationOutputDTO[]>),
      enabled,
    },
  );
}

// Unused
export const useReservationsSearchPost = () =>
  useMutation((params: PaginatedReservationsQueryDTO) =>
    iopApp.reservationsApi.apiReservationsSearchPost(
      config.api.version,
      params,
    ),
  );

export function useReservationsDeliveryDestinations(
  includeDisabled?: boolean,
  enabled?: boolean,
) {
  return useQuery<ReservationDeliveryDestinationOutputDTO[]>(
    ['reservationsDeliverydestinations', includeDisabled],
    async () => {
      const {data} =
        await iopApp.reservationsApi.apiReservationsDeliverydestinationsGet(
          config.api.version,
          includeDisabled,
        );
      return data;
    },
    {
      ...(optionsGet as UseQueryOptions<
        ReservationDeliveryDestinationOutputDTO[]
      >),
      enabled,
    },
  );
}

export function useReservationsReservationIdGet(reservationId: string) {
  return useQuery<ReservationOutputDTO>(
    ['reservationsReservationIdGet', reservationId],
    async () => {
      const {data} =
        await iopApp.reservationsApi.apiReservationsReservationIdGet(
          reservationId,
          config.api.version,
        );
      return data;
    },
    {
      enabled: !!reservationId,
      ...optionsGet,
    } as UseQueryOptions<ReservationOutputDTO>,
  );
}

export function useReservationsSites(
  options?: UseQueryOptions<GetUserAccessibleSiteOutputDTO[]>,
) {
  return useQuery<GetUserAccessibleSiteOutputDTO[]>(
    'reservationsSites',
    async () => {
      const {data} = await iopApp.reservationsApi.apiReservationsSitesGet(
        config.api.version,
      );
      return orderBy(data, 'displayName');
    },
    {...optionsGet, ...options} as UseQueryOptions<
      GetUserAccessibleSiteOutputDTO[]
    >,
  );
}

export function useReservationsCustomers() {
  return useQuery<ReservationCustomerOutputDTO[]>(
    'reservationsCustomers',
    async () => {
      const {data} = await iopApp.reservationsApi.apiReservationsCustomersGet(
        config.api.version,
      );

      return data;
    },
    optionsGet as UseQueryOptions<ReservationCustomerOutputDTO[]>,
  );
}

export function getReservationsLoadingPlacesQueryKey() {
  return ['reservationsEquipments'];
}
export function useReservationsLoadingPlaces(
  options?: UseQueryOptions<ReservationEquipmentOutputDTO[]>,
) {
  return useQuery<ReservationEquipmentOutputDTO[]>(
    getReservationsLoadingPlacesQueryKey(),
    async () => {
      const {data} =
        await iopApp.reservationsApi.apiReservationsLoadingPlacesGet(
          config.api.version,
        );

      return orderBy(
        data,
        [option => option.displayName?.toLowerCase()],
        'asc',
      );
    },
    {...optionsGet, ...options} as UseQueryOptions<
      ReservationEquipmentOutputDTO[]
    >,
  );
}

export function useReservationsLogisticCompaniesCustomerIdGet(
  customerId: string,
) {
  return useQuery<LogisticsCompanyOutputDTO[]>(
    ['reservationsLogisticCompaniesCustomerIdGet', customerId],
    async () => {
      const {data} =
        await iopApp.reservationsApi.apiReservationsLogisticCompaniesCustomerCustomerIdGet(
          customerId,
          config.api.version,
        );
      return orderBy(data);
    },
    {
      enabled: false,
      ...optionsGet,
    } as UseQueryOptions<LogisticsCompanyOutputDTO[]>,
  );
}

export function usePostReservationsGetNotVisible() {
  return useQuery<ReservationTimestampOutputDTO[]>(
    ['reservationsNotVisible'],
    async () => {
      const {data} =
        await iopApp.reservationsApi.apiReservationsGetNotVisibleReservationsPost(
          config.api.version,
        );
      return data;
    },
    {
      ...optionsGet,
    } as UseQueryOptions<ReservationTimestampOutputDTO[]>,
  );
}

export function useReservationsLogisticCompaniesCustomerIdsGet(
  customerIds: string[],
) {
  return useQueries(
    customerIds.map(id => {
      return {
        queryKey: ['reservationsLogisticCompaniesCustomerIdsGet', id],
        queryFn: () =>
          iopApp.reservationsApi.apiReservationsLogisticCompaniesCustomerCustomerIdGet(
            id,
            config.api.version,
          ),
        enabled: false,
      };
    }),
  ) as UseQueryResult<{data: LogisticsCompanyOutputDTO[]}, AxiosError>[];
}

export const getReservationAvailableProductsQueryKey = (
  providerCompanyId: string | undefined,
) => ['reservationAvailableProducts', providerCompanyId];

const getReservationAvailableProductsQueryFunc = (
  providerCompanyId: string | undefined,
) => {
  return async () => {
    const {data} =
      await iopApp.reservationsApi.apiReservationsProductsProviderCompanyIdGet(
        providerCompanyId ?? '',
        config.api.version,
      );
    return data;
  };
};
const getReservationAvailableProductsQueryOptions = (
  providerCompanyId: string | undefined,
) =>
  ({
    enabled: !!providerCompanyId,
    ...optionsGet,
  } as UseQueryOptions<ReservationAvailableProductsOutputDTO>);

export function useReservationAvailableProductsForAllProviders(
  providerCompanyIds: (string | undefined)[],
) {
  return useQueries(
    providerCompanyIds.map(providerCompanyId => {
      return {
        queryKey: getReservationAvailableProductsQueryKey(providerCompanyId),
        queryFn: getReservationAvailableProductsQueryFunc(providerCompanyId),
        ...getReservationAvailableProductsQueryOptions(providerCompanyId),
      };
    }),
  );
}

export function useReservationAvailableProducts(
  providerCompanyId: string | undefined,
) {
  return useQuery<ReservationAvailableProductsOutputDTO>(
    getReservationAvailableProductsQueryKey(providerCompanyId),
    getReservationAvailableProductsQueryFunc(providerCompanyId),
    getReservationAvailableProductsQueryOptions(providerCompanyId),
  );
}

export function useLogisticsOperatorTrucksGet(
  providerCompanyId: string,
  logisticsOperatorCompanyId: string,
) {
  return useQuery<LogisticsOperatorTruckOutputDTO[]>(
    [
      'reservationsLogisticCompaniesTrucksGet',
      providerCompanyId,
      logisticsOperatorCompanyId,
    ],
    async () => {
      const {data} =
        await iopApp.reservationsApi.apiReservationsProvidersProviderCompanyIdLogisticOperatorsLogisticsOperatorCompanyIdTrucksGet(
          providerCompanyId,
          logisticsOperatorCompanyId,
          config.api.version,
        );
      return sortBy(compose(toLower, prop('truckLicensePlate')), data);
    },
    {
      ...optionsGet,
      enabled: false,
    } as UseQueryOptions<LogisticsOperatorTruckOutputDTO[]>,
  );
}

export function useSiteParametersGet(siteId: string) {
  return useQuery<SiteParameters>(
    ['reservationsSiteParametersGet', siteId],
    async () => {
      const {data} =
        await iopApp.reservationsApi.apiReservationsSitesSiteIdParametersGet(
          siteId,
          config.api.version,
        );
      return getSiteParametersFromOutput(data);
    },
    {
      ...optionsGet,
      enabled: !!siteId,
    } as UseQueryOptions<SiteParameters>,
  );
}

export function useCompanyParametersBySiteGet(siteId: string) {
  return useQuery<CompanyParameters>(
    ['reservationsCompanyParametersGet', siteId],
    async () => {
      const {data} =
        await iopApp.reservationsApi.apiReservationsSitesSiteIdCompanyparametersGet(
          siteId,
          config.api.version,
        );
      return getCompanyParametersFromOutput(data);
    },
    {
      ...optionsGet,
      enabled: !!siteId,
    } as UseQueryOptions<CompanyParameters>,
  );
}
