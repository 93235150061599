const translations = {
  notifications: {
    forecastNotFound: 'Forecast for this site was not found',
    endpointDetailNotFound: 'No detailed data found',
    forecastPercentMissingCapacity: 'Site capacity is needed for % view ',
    noData: 'No data available',
    noSiteTrendsFound: 'No trend was found for this site',
    noEquipmentTrendsFound: 'No trend was found for this equipment',
    couldNotLoadSiteLocations: 'Unable to load site locations',
  },
  actions: {
    switchToFlatList: 'Switch view to flat list',
    switchToHierarchyList: 'Switch view to hierarchy list',
  },
  toast: {
    forecastParamsUpdateSuccess: 'Forecast parameters succesfully updated',
    forecastParamsUpdateFailure: 'Forecast parameters failed to update',
  },
  buttons: {
    percentUnitSwitch: '% view',
  },
  forecast: {
    forecastTitle: 'Consumption forecast',
    forecastType: 'Forecast type',
    historyTitle: 'History chart',
    noDataFound: 'No historical nor forecast data was found for this site',
    noHistoricalDataFound: 'No historical data was found for this site',
    noMonitoringData: 'No monitoring data available',
    noParamsData: 'No parameters data available',
    runoutTimeSource: 'Runout time source',
    siteUpdatedAt: 'Page data updated at:',
    config: {
      saveButton: 'Save',
      forecastBasis: 'Forecast basis',
      alarmTriggerType: 'Alarm trigger type',
      alarmWarningLevel: 'Warning',
      alarmCriticalLevel: 'Critical',
      consumptionRate: 'Consumption rate',
      historyLength: 'History calculation',
      historyLengthHelpText: 'Number of days to be included in calculation',
      historyDisplay: 'History display',
      historyDisplayHelpText: 'Number of days to be displayed in the chart',
      runoutTime: 'Runout time',
      maxDaysToForecast: 'Maximum number of days to forecast',
      minResplenishLevel: 'Minimum refill amount tons',
      maxResplenishLevel: 'Last refill level tons',
      warningLevel: 'Warning level',
      warningLevelHelpText: 'Warning level of the site',
      alarmLevel: 'Alarm level',
      alarmLevelHelpText: 'Alarm level of the site',
      runoutTimeWarningHours: 'Warning hours',
      runoutWarningHoursHelpText: 'Warning is triggered hours before runout',
      runoutTimeAlarmHours: 'Alarm hours',
      runoutAlarmHoursHelpText: 'Alarm is triggered hours before runout',
    },
    forecastBasis: {
      linear: 'Linear',
      pattern: 'Pattern',
      disabled: 'Disabled',
    },
    alarmTriggerType: {
      time: 'Time',
      level: 'Level',
    },
    legend: {
      trendLegendTitle: 'Legend and alarms',
      forecastMonitoringTitle: 'Monitoring',
      forecastAlarmTitle: 'Alarms',
      forecastParametersTitle: 'Parameters',
      history: 'History',
      criticalLow: 'Critical low',
      warningLow: 'Warning low',
      criticalHigh: 'Critical high',
      warningHigh: 'Warning high',
      warning: 'Warning level',
      alarm: 'Alarm level',
      minimum: 'Minimum',
      estimate: 'Estimate',
      maximum: 'Maximum',
      firstRefillLevel: 'First refill level',
      lastRefillLevel: 'Last refill level',
    },
  },
  tooltip: {
    tagIdentifier: 'Tag',
    historicalValue: 'historical',
    minValue: 'min',
    meanValue: 'mean',
    maxValue: 'max',
  },
  forecastStatusTooltip: {
    status: 'Status',
    tankLevelLastReceived: 'Tank level last received',
  },
  columns: {
    displayName: 'Site name',
    runoutTime: 'Runout time',
    runoutAfterMaxDaysToForecast: '> {{days}} days',
    runoutWarningTooltip: 'Runout warning time has triggered',
    runoutAlarmToolTip: 'Runout alert time has triggered',
    rate: 'Rate',
    sendout: 'Sendout',
    tanks: 'Tanks',
    fillLevel: 'Fill level',
    firstFill: 'First possible refill',
    lastFill: 'Last refill',
    actionView: 'See details',
    equipmentName: 'Name',
    equipmentType: 'Type',
    equipmentProducts: 'Product',
  },
  filters: {
    product: 'Product',
    baseProduct: 'Base product',
    siteType: 'Site type',
    country: 'Country',
  },
  title: 'Forecast',
  listTitle: 'Site list',
  mapTitle: 'Site map',
  siteTypes: {
    DistributionTerminal: 'Distribution terminal',
    LoadingStation: 'Loading station',
    FuelingStation: 'Fueling station',
    CustomerTerminal: 'Customer terminal',
    Depot: 'Depot',
  },
  tabs: {
    general: 'General',
    trends: 'Trends',
    equipment: 'Equipment',
  },
  generalTab: {
    forecastGraph: 'History chart',
  },
};
export default translations;
