import {
  initializeUserActionCreator,
  logoutUserActionCreator,
  acquireTokenFailedActionCreator,
} from '../actions/user';
import {reducerWithInitialState} from 'typescript-fsa-reducers';
import {IUserState} from '@valmet-iop/ui-common';

import {combineReducers} from 'redux';

export const initialState: IUserState = {
  userName: '',
  loggingOut: false,
  acquireTokenFailed: false,
};

const loggingOut = reducerWithInitialState<boolean>(
  initialState.loggingOut,
).case(logoutUserActionCreator, () => true);

const userName = reducerWithInitialState<string>(initialState.userName)
  .case(
    initializeUserActionCreator.done,
    (_, payload) => payload?.result.name ?? '',
  )
  .case(logoutUserActionCreator, () => '');

const acquireTokenFailed = reducerWithInitialState<boolean>(
  initialState.acquireTokenFailed,
).case(acquireTokenFailedActionCreator, () => true);

export default combineReducers({
  loggingOut,
  userName,
  acquireTokenFailed,
});

export const getUserName: (state: IUserState) => string = state => {
  return state.userName;
};

export const getUserIsLoggingOut: (state: IUserState) => boolean = state => {
  return state.loggingOut;
};

export const getAcquireTokenFailed: (state: IUserState) => boolean = state => {
  return state.acquireTokenFailed;
};
