import {IOption} from '@valmet-iop/ui-common';
import produce from 'immer';
import React from 'react';

interface IContext {
  formManuallyReset: boolean;
  setFormManuallyReset: (value: boolean) => void;
  addDropdownValueOnReset: (
    fieldName: string,
    options: IOption[],
    isSiteFieldSelectableMode: boolean,
  ) => void;
  resetEliminate: () => void;
  dropdownsValueOnReset: any;
}

const FormContext = React.createContext<IContext>({
  formManuallyReset: false,
  setFormManuallyReset: () => {},
  addDropdownValueOnReset: (fieldName: string, options: IOption[]) => {},
  resetEliminate: () => {},
  dropdownsValueOnReset: {},
});

export function useForm(): IContext {
  const context = React.useContext(FormContext);
  if (!context) {
    throw new Error(`useForm must be used within a FormProvider`);
  }
  return context;
}

export function FormProvider(props: React.PropsWithChildren<{}>) {
  const [formManuallyReset, setFormManuallyReset] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [dropdownsValueOnReset, setDropdownsValueOnReset] = React.useState<any>(
    {},
  );
  const value: IContext = {
    formManuallyReset,
    setFormManuallyReset,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    dropdownsValueOnReset,
    addDropdownValueOnReset: (
      fieldName: string,
      options: IOption[],
      isSiteFieldSelectableMode: boolean,
    ) => {
      // we need to store only initial value and we don't need to listen spy changes
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (dropdownsValueOnReset[fieldName]) {
        return;
      }

      setDropdownsValueOnReset(
        produce((draft: any) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          draft[fieldName] = null;
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (draft.site === null || !isSiteFieldSelectableMode) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            draft[fieldName] = null;
          } else {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            draft[fieldName] = (() => {
              if (options.length > 1) {
                return null;
              }
              return options[0];
            })();
          }
        }),
      );
    },

    resetEliminate: () => {
      setDropdownsValueOnReset({});
    },
  };

  return (
    <FormContext.Provider value={value}>{props.children}</FormContext.Provider>
  );
}
