import {makeStyles, Button, Theme} from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {logoutUserActionCreator} from '../../redux/actions/user';
import {logout} from '../../services/authService';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {ValmetIcon, colors} from '@valmet-iop/ui-common';
const {white, grey3, grey13} = colors;

const useStyles = makeStyles<Theme, {clickableAreaHeight?: string}>(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  clickableArea: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    height: props => props.clickableAreaHeight ?? '70px',
  },
  userIcon: {
    height: '20px',
    marginRight: theme.spacing(2),
  },
  userName: {
    marginRight: theme.spacing(1),
  },
  chevron: {
    marginRight: theme.spacing(2),
  },
  menuPaper: {
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    boxShadow: 'none',
    borderRadius: '0 0 2px 2px',
    minWidth: '224px',
  },
  menuItemRoot: {
    height: '32px',
    minHeight: '32px',
    backgroundColor: grey3,
    padding: theme.spacing(1, 3),
    textAlign: 'left',
    '&:hover': {
      backgroundColor: grey3,
    },
  },
  menuItemIconContainer: {
    minWidth: '1px',
  },
  menuItemTextContainer: {
    margin: '0',
    marginLeft: theme.spacing(1),
  },
  menuItemText: {
    lineHeight: '18px',
    color: grey13,
  },
}));

const UserMenu = (props: {userName: string; clickableAreaHeight?: string}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles(props);
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLDivElement) | null
  >(null);

  const handleLogout = () => {
    setAnchorEl(null);
    logout();
    dispatch(logoutUserActionCreator());
  };

  return (
    <div className={classes.container}>
      <div
        className={classes.clickableArea}
        aria-controls="menu-user"
        aria-haspopup="true"
        onClick={e => setAnchorEl(e.currentTarget)}
      >
        <span className={classes.userIcon}>
          <ValmetIcon icon="user" size="medium" />
        </span>
        <span className={classes.userName}>{props.userName}</span>
        <div className={classes.chevron}>
          <MenuChevronButton />
        </div>
      </div>

      <Menu
        disableAutoFocusItem
        id="menu-user"
        classes={{
          paper: classes.menuPaper,
        }}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={handleLogout} classes={{root: classes.menuItemRoot}}>
          <ListItemIcon className={classes.menuItemIconContainer}>
            <ValmetIcon icon="sign-out" color={white} />
          </ListItemIcon>
          <ListItemText
            primary={t('logout')}
            className={classes.menuItemTextContainer}
            classes={{primary: classes.menuItemText}}
          />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserMenu;

const useMenuChevronButtonStyles = makeStyles<Theme, {}>(() => ({
  root: {
    color: white,
    width: '20px',
    minWidth: '20px',
    height: '20px',
  },
}));

const MenuChevronButton = () => {
  const classes = useMenuChevronButtonStyles({});
  return (
    <Button size="large" className={classes.root}>
      <ValmetIcon icon="arrow-down" size="medium" />
    </Button>
  );
};
