import {errorNotification} from '@valmet-iop/ui-common';
import {AxiosError} from 'axios';
import {format, isValid} from 'date-fns';
import {QueryStatus} from 'react-query';

export function mergedStatus(statuses: QueryStatus[]): QueryStatus {
  if (statuses.some(status => status === 'loading')) {
    return 'loading';
  }
  if (statuses.some(status => status === 'error')) {
    return 'error';
  }
  if (statuses.every(status => status === 'success')) {
    return 'success';
  }
  return 'idle';
}

export const optionsGet = {
  onError: (error: AxiosError) => {
    // TODO do some util to parse message, or set some flag for the BE to get the message in lang
    if (error.response && error.response.data) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      errorNotification(error.response.data.detail);
    }
  },
};

export function parseMsg(msg: string): string {
  // Interval (2021-06-17T08:17:58.0000000Z - 2021-06-17T10:17:58.0000000Z) already contains another reservation"
  // --> [1]--> 2021-06-17T08:17:58.0000000Z - 2021-06-17T10:17:58.0000000Z
  const regExpTemplate = /\((.*?)\)/;
  const extractDateFromBrackets = msg.match(regExpTemplate);
  if (extractDateFromBrackets) {
    const formatted = extractDateFromBrackets[1]
      .split(' ')
      .map(sub => {
        const date = new Date(sub);
        if (isValid(date)) {
          return format(date, 'dd.MM.y HH:mm');
        }
        return sub;
      })
      .join(' ');

    //--> Interval (dd.MM.y HH:mm - dd.MM.y HH:mm) already contains another reservation"
    return msg.replace(regExpTemplate, formatted);
  }
  return msg;
}
