import {Box, makeStyles, Theme} from '@material-ui/core';
import {
  ContextPanelContentSection,
  ContextPanelDataDisplay,
  LoadingScreen,
  Title,
  TopAction,
  TopActions,
} from '@valmet-iop/ui-common';
import {ReservationType} from 'api/generated/iop';
import {useRedirect} from 'components/Common/Entity/context/redirect-context';
import {ItemContentLayout} from 'components/Common/Entity/ItemLayout';
import {Panel} from 'components/Common/Entity/Panel';
import {useDeleteReservationsReservationId} from 'components/Hooks/queries/useReservations';
import {format} from 'date-fns';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useQueryClient} from 'react-query';
import {getDestinationText, useItemDataRead} from '../../hooks/useItemData';
import {colors} from '@valmet-iop/ui-common';
import {DeleteConfirmDialog} from '../ConfirmDialog';
import {
  isMaintenanceDeletable,
  isMaintenanceUpdatable,
  isReservationDeletable,
  isReservationUpdatable,
} from '../..';
import {ErrorNotFoundItem, isReservationTypeContent} from '../ItemEdit';
import {getTextByType} from '../../hooks/useListReservations';

export function useDeleteMutation() {
  const mutationDelete = useDeleteReservationsReservationId();
  return {mutationDelete};
}
const useStylesWrapperTitle = makeStyles<Theme>(theme => ({
  root: {
    '& h2': {
      overflow: 'hidden',
      display: 'box',
      lineClamp: 2,
      boxOrient: 'vertical',
    },
  },
}));
export function WrapperTitle({children}: {children: React.ReactNode}) {
  const classes = useStylesWrapperTitle();
  return <Box className={classes.root}>{children}</Box>;
}
export function ItemRead() {
  const {toDefault, toUpdate} = useRedirect();
  const {data: item, status} = useItemDataRead();
  const {mutationDelete} = useDeleteMutation();
  const [isOpenDialog, setOpenDialog] = React.useState(false);
  const queryClient = useQueryClient();
  const topActions: TopAction[] = [];

  if (item.type === ReservationType.Maintenance) {
    if (isMaintenanceUpdatable(item.updatePermissions)) {
      topActions.push({
        icon: 'edit',
        onClick: () => toUpdate(item.reservationId),
      });
    }
    if (isMaintenanceDeletable(item.deletePermissions)) {
      topActions.push({
        icon: 'delete',
        onClick: () => {
          setOpenDialog(!isOpenDialog);
        },
      });
    }
  } else {
    if (isReservationUpdatable(item.updatePermissions)) {
      topActions.push({
        icon: 'edit',
        onClick: () => {
          void queryClient
            .invalidateQueries([
              'reservationsReservationIdGet',
              item.reservationId,
            ])
            .then(() => {
              toUpdate(item.reservationId);
            });
        },
      });
    }
    if (isReservationDeletable(item.deletePermissions)) {
      topActions.push({
        icon: 'delete',
        onClick: () => {
          setOpenDialog(!isOpenDialog);
        },
      });
    }
  }

  return (
    <>
      <Panel status={status} onErrorRender={ErrorNotFoundItem}>
        {mutationDelete.isLoading ? (
          <LoadingScreen />
        ) : (
          <>
            <WrapperTitle>
              <Title
                onCloseClick={toDefault}
                isPreviousPageLinkVisible={false}
                titleTranslationKey={
                  isReservationTypeContent(item.type)
                    ? 'scheduling:headers.reservation'
                    : ''
                }
                titleSuffix={
                  isReservationTypeContent(item.type)
                    ? ''
                    : item.displayName ?? ''
                }
                onPreviousPageLinkClick={() => {}}
              />
            </WrapperTitle>
            <TopActions actions={topActions} />
            <ItemContentLayout>
              <ContextPanelContentSection
                headerTranslationKey={`scheduling:headers.${
                  item.isReservation ? 'reservationInfo' : 'maintenanceInfo'
                }`}
              >
                {item.isReservation ? (
                  <ContentReservation />
                ) : (
                  <ContentMaintenance />
                )}
              </ContextPanelContentSection>
            </ItemContentLayout>
            <DeleteConfirmDialog
              isOpen={isOpenDialog}
              onResult={confirmed => {
                if (confirmed) {
                  mutationDelete.mutate(item.reservationId);
                  setOpenDialog(false);
                } else {
                  setOpenDialog(!isOpenDialog);
                }
              }}
            />
          </>
        )}
      </Panel>
    </>
  );
}
function formatToRead(date: Date) {
  return format(date, 'dd.MM.y HH:mm');
}

const useStylesSurrogates = makeStyles<Theme>(theme => ({
  root: {
    '& p': {
      color: colors.black,
    },
  },
}));

export function ContentReservation({
  surrogates,
}: {
  surrogates?: {label: string; value: React.ReactNode}[];
}) {
  const {data: item} = useItemDataRead();
  const classes = useStylesSurrogates();
  const {t} = useTranslation();

  const modifiedByEmail =
    item?.createdByEmail === item?.modifiedByEmail
      ? null // Only show if different user
      : item?.modifiedByEmail;

  const data = item
    ? [
        {
          label: 'scheduling:fields.site',
          value: item?.site?.displayName,
        },
        {
          label: 'scheduling:fields.product',
          value: item.productDisplayName,
        },
        {
          label: 'scheduling:fields.type',
          value: getTextByType(item.type),
        },
        {
          label: 'scheduling:fields.loadingPlace',
          value: item?.equipment?.displayName,
        },
        {
          label: 'scheduling:fields.customer',
          value: item?.customer?.displayName,
        },
        {
          label: 'scheduling:fields.logisticsOperator',
          value: item.logisticOperator?.displayName,
        },
        {
          label: 'scheduling:fields.truckAsset',
          value: item.truckAssetLicensePlate,
          hideIfEmpty: true,
        },
        {
          label: 'scheduling:fields.reservationTimeStart',
          value: formatToRead(item.startStamp),
        },
        {
          label: 'scheduling:fields.reservationTimeEnd',
          value: formatToRead(item.endStamp),
        },
        {
          label: 'scheduling:fields.orderNo',
          value: item?.orderNo,
        },
        {
          label: 'scheduling:fields.referenceOrderNumber',
          value: item?.referenceOrderNumber,
          hideIfEmpty: true,
        },
        {
          label: 'scheduling:fields.destination',
          value: item?.destination ? getDestinationText(item.destination) : '',
        },
        {
          label: 'scheduling:fields.amount',
          value: `${item?.amount || ''} ${t('units.kg')}`,
        },
        {
          label: 'scheduling:fields.comments',
          value: item?.comments,
        },
        {
          label: 'scheduling:fields.completed',
          value: item?.isCompleted
            ? t('scheduling:headers.yes')
            : t('scheduling:headers.no'),
        },
        {
          label: 'scheduling:fields.createdBy',
          value: item?.createdByEmail,
          hideIfEmpty: true,
        },
        {
          label: 'scheduling:fields.modifiedBy',
          value: modifiedByEmail,
          hideIfEmpty: true,
        },
      ]
    : [];
  return (
    <Wrapper>
      {data.map(({label, value, hideIfEmpty}, index) => {
        if (hideIfEmpty && !value) {
          return <React.Fragment key={index}></React.Fragment>;
        }

        const surrogate = surrogates?.find(surrogate => {
          return surrogate.label === label;
        });
        if (surrogate) {
          return (
            <Box key={index} mb={2} className={classes.root}>
              {surrogate.value}
            </Box>
          );
        }
        return (
          <ContextPanelDataDisplay
            key={index}
            labelTranslationKey={label}
            value={`${value ?? ''}`}
          />
        );
      })}
    </Wrapper>
  );
}

export function ContentMaintenance() {
  const {data: item} = useItemDataRead();

  const modifiedByEmail =
    item?.createdByEmail === item?.modifiedByEmail
      ? null // Only show if different user
      : item?.modifiedByEmail;

  const data = item
    ? [
        {
          label: 'scheduling:fields.site',
          value: item?.site?.displayName,
        },
        {
          label: 'scheduling:fields.loadingPlace',
          value: item?.equipment?.displayName,
        },
        {
          label: 'scheduling:fields.maintenanceTimeStart',
          value: formatToRead(item.startStamp),
        },
        {
          label: 'scheduling:fields.maintenanceTimeEnd',
          value: formatToRead(item.endStamp),
        },
        {
          label: 'scheduling:fields.comments',
          value: item?.comments,
        },
        {
          label: 'scheduling:fields.createdBy',
          value: item?.createdByEmail,
          hideIfEmpty: true,
        },
        {
          label: 'scheduling:fields.modifiedBy',
          value: modifiedByEmail,
          hideIfEmpty: true,
        },
      ]
    : [];
  return (
    <Wrapper>
      {data.map(({label, value, hideIfEmpty}, index) => {
        if (hideIfEmpty && !value) {
          return <React.Fragment key={index}></React.Fragment>;
        }
        return (
          <ContextPanelDataDisplay
            key={index}
            labelTranslationKey={label}
            value={`${value ?? ''}`}
          />
        );
      })}
    </Wrapper>
  );
}
const useStyles = makeStyles<Theme>(theme => ({
  root: {
    '& p': {
      maxWidth: '200px',
      overflowWrap: 'break-word',
    },
  },
}));
function Wrapper(props: {children: React.ReactNode}) {
  const classes = useStyles();
  return <div className={classes.root}>{props.children}</div>;
}
