import * as React from 'react';
import {useTheme} from '@material-ui/core';
import {Calendar, dateFnsLocalizer} from 'react-big-calendar';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import {zhTW, fi} from 'date-fns/locale';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import {useCalendar} from 'components/Pages/Scheduling/context/calendar-context';
import {useRedirect} from 'components/Common/Entity/context/redirect-context';
import {useCalendarStyles, getEventStyles} from './styles';
import {
  DateCellWrapper,
  MonthDateHeader,
  TimeGutterHeader,
  TimeSlotWrapper,
  Toolbar,
  WeekHeader,
} from './Parts';
import {
  getTextByType,
  RenderMode,
} from 'components/Pages/Scheduling/hooks/useListReservations';
import {ReservationType} from 'api/generated/iop';
import {
  add,
  addDays,
  endOfDay,
  isPast,
  isToday,
  set,
  startOfDay,
  sub,
} from 'date-fns';
import {groupBy, groupWith, prop} from 'ramda';
import {isSameDay} from 'date-fns/esm';
import {useTranslation} from 'react-i18next';
import {extractTime} from 'components/Pages/Scheduling/timeUtils';
import {isReservationTypeContent} from '../../ItemEdit';
import {isSelectable, LayoutView} from '../utils';
import {
  useCalendarTimelineReservations,
  isReservationNotVisible,
  makeTitleMaintenanceItem,
  makeTitleReservationItem,
} from 'components/Pages/Scheduling/hooks/useCalendarTimelineReservations';
import {LoadingScreen} from '@valmet-iop/ui-common';

const locales = {
  'zh-TW': zhTW,
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: (date: number | Date) => {
    return startOfWeek(date, {locale: fi});
  },
  getDay,
  locales,
});

const formats = {
  timeGutterFormat: 'HH',
  eventTimeRangeFormat: () => '',
};

const groupByType = groupBy<{type: ReservationType}>(prop('type'));
const groupByEquipmentId = groupBy<{equipmentId: string}>(prop('equipmentId'));
export function CalendarView() {
  const {
    setSelectedSlot,
    selectedSlot,
    setSelectedView,
    selectedView,
    screenDate,
    setScreenDate,
    isSelectedViewMonth,
  } = useCalendar();
  const {toRead, toCreate} = useRedirect();
  const {t} = useTranslation();
  // apply global styles
  useCalendarStyles({isWeekView: selectedView === 'week'});
  const theme = useTheme();
  const {normalizedFormatCalendar, status} =
    useCalendarTimelineReservations(screenDate);
  const groupsMonth = groupWith((a, b) => {
    return isSameDay(a.start, b.start) && isSameDay(a.end, b.end);
  }, normalizedFormatCalendar);
  const makeMonthEvent = React.useCallback(
    (event: typeof normalizedFormatCalendar) => {
      const [{start, end, ...other}] = event;
      const result = [];

      const adjustEndDate = (end: Date) => {
        // Subtract 1 second to make reservations ending on
        // 00:00 not fill the entire next day.
        // For example, start Jan 1st 22:00, end Jan 2nd 00:00
        // => fill only Jan 1st.
        return sub(new Date(end), {seconds: 1});
      };

      if (event.length > 1) {
        const groupedByEquipment = groupByEquipmentId(event);
        const bays = Object.values(
          groupedByEquipment,
        ) as typeof normalizedFormatCalendar[];
        bays.forEach(bay => {
          const [{start, end, ...other}] = bay;
          if (bay.length > 1) {
            result.push({
              ...other,
              renderMode: RenderMode.Group,
              amount: bay.length,
              start: startOfDay(new Date(start)),
              end: endOfDay(adjustEndDate(end)),
            });
          } else {
            result.push({
              ...other,
              renderMode: RenderMode.Item,
              amount: bay.length,
              start: startOfDay(new Date(start)),
              end: endOfDay(adjustEndDate(end)),
            });
          }
        });
      } else {
        result.push({
          ...other,
          renderMode: RenderMode.Item,
          amount: event.length,
          start: startOfDay(new Date(start)),
          end: endOfDay(adjustEndDate(end)),
        });
      }
      return result;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [normalizedFormatCalendar],
  );

  const monthEvents = groupsMonth.reduce((acc, group) => {
    const groupedByTyped = groupByType(group as {type: ReservationType}[]) as {
      [key in ReservationType]?: typeof normalizedFormatCalendar;
    };
    let result = [] as typeof normalizedFormatCalendar[];
    if (groupedByTyped.Maintenance) {
      result = [
        ...result,
        ...makeMonthEvent(groupedByTyped.Maintenance),
      ] as typeof normalizedFormatCalendar[];
    }
    if (
      groupedByTyped.ReservationLoading ||
      groupedByTyped.ReservationUnloading
    ) {
      const events = [
        ...(groupedByTyped.ReservationLoading ?? []),
        ...(groupedByTyped.ReservationUnloading ?? []),
      ];

      result = [
        ...result,
        ...makeMonthEvent(events),
      ] as typeof normalizedFormatCalendar[];
    }
    // we don't have type for `reserved` items in ReservationType
    // those are grouped by undefined key, the best choice is to have the same outputDTO for apiReservationsGetNotVisibleReservationsPost
    // as ReservationOutputDTO, but we don't have, thus we have 2 options: 1) create on FE special type for `reserved` items extend ReservationType,
    // and infect all code with that manual extending. 2) do some weird check on undefined key in one place to understand reserved group ==> let's choose (2)
    const reservedGroup = Object.keys(groupedByTyped).reduce((acc, key) => {
      if (
        key !== 'Maintenance' &&
        key !== 'ReservationLoading' &&
        key !== 'ReservationUnloading'
      ) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
        return (groupedByTyped as any)[key];
      }
      return acc;
    }, []);
    if (reservedGroup.length) {
      result = [
        ...result,
        ...makeMonthEvent(reservedGroup),
      ] as typeof normalizedFormatCalendar[];
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return [...acc, ...result] as any;
  }, []);
  const [currentWeek, setCurrentWeek] = React.useState<number>(
    +`${format(new Date(), 'I')}`,
  );
  React.useEffect(() => {
    setCurrentWeek(+`${format(screenDate, 'I')}`);
  }, [screenDate]);

  const handleDblClickOnSlot = React.useCallback(
    (e: Event) => {
      if (isPast(new Date(selectedSlot))) {
        if (!isToday(new Date(selectedSlot))) {
          return;
        }
      }
      const target = e.target as HTMLDivElement;
      if (
        target &&
        (target.closest('.rbc-month-row') || target.closest('.rbc-day-slot')) &&
        !target.closest('.rbc-event')
      ) {
        toCreate();
      }
    },
    [selectedSlot, toCreate],
  );
  React.useEffect(() => {
    // eslint-disable-next-line scanjs-rules/call_addEventListener
    document.addEventListener('dblclick', handleDblClickOnSlot);
    return () => {
      document.removeEventListener('dblclick', handleDblClickOnSlot);
    };
  }, [selectedSlot, isSelectedViewMonth, handleDblClickOnSlot]);

  React.useEffect(() => {
    // Week and Day view modes put `:` symbol before the tooltip content, still don't now why(`Manga bay2`-->`:Manga bay2`),
    // it looks like this is the inner react-big-calendar implementation, we can't effect on that via API settings.
    // month view doesn't have that kind of problem, no idea why...
    // this is only one possibility to fix that problem via DOM manipulation
    const rbcEvent = document.querySelectorAll('.rbc-event');
    rbcEvent.forEach(event => {
      const tooltip = event.getAttribute('title') as string;
      if (tooltip && tooltip.startsWith(':')) {
        event.setAttribute('title', tooltip.slice(1));
      }
    });
  }, [selectedView, screenDate, normalizedFormatCalendar]);

  interface CalendarEvent {
    id: string;
    title: string | undefined;
    start: Date;
    end: Date;
    type: ReservationType | undefined;
    comments: string | null | undefined;
    equipmentId: string;
    equipmentName: string | null | undefined;
    amount: number;
    renderMode: RenderMode;
    isCompleted: boolean;
  }

  const multiDayEventSeparator = (events: CalendarEvent[]) => {
    const newEventList: CalendarEvent[] = [];
    events.forEach(event => {
      if (isSameDay(event.start, event.end)) {
        newEventList.push(event);
        return;
      }
      let start = event.start;
      for (start; !isSameDay(start, event.end); start = addDays(start, 1)) {
        newEventList.push({
          ...event,
          start: start,
          end: set(start, {hours: 24, minutes: 0}),
        });
        start = set(start, {hours: 0, minutes: 0});
      }
      newEventList.push({...event, start: start, end: event.end});
    });
    return newEventList;
  };

  const dayWeekViewsToolTipTimeRange = (start: Date, end: Date) => {
    // extractTime translates HH=24 into 00
    const endTime =
      extractTime(end) !== '23:59'
        ? extractTime(add(new Date(end), {minutes: 1}))
        : '24:00';
    return `${extractTime(start)} - ${endTime} : `;
  };

  return (
    <>
      {status === 'loading' ? (
        <LoadingScreen />
      ) : (
        <LayoutView>
          <Calendar
            // culture={'zh-TW'}
            messages={{showMore: () => t('scheduling:buttons.plusMore')}}
            defaultView={selectedView}
            view={selectedView}
            defaultDate={screenDate}
            selectable
            timeslots={1}
            step={60}
            date={new Date(screenDate)}
            onNavigate={(newDate, view) => {
              setScreenDate(new Date(newDate));
              setSelectedSlot(isSelectable(newDate) ? newDate : new Date());
            }}
            onSelectEvent={(selectedEvent: any) => {
              const sE = selectedEvent as {
                id: string;
                renderMode: RenderMode;
                start: Date;
              };
              if (isSelectedViewMonth && sE.renderMode === RenderMode.Group) {
                setScreenDate(new Date(sE.start));
                setSelectedSlot(new Date(sE.start));
                setSelectedView('day');
                return;
              }
              if (isReservationNotVisible({reservationId: sE.id})) {
                return;
              }

              toRead(sE.id);
            }}
            tooltipAccessor={({
              comments,
              type,
              title,
              renderMode,
              equipmentName,
              id,
              end,
              start,
            }) => {
              const isGroupMonth = !!(
                isSelectedViewMonth && renderMode === RenderMode.Group
              );
              // we need to use `start` and `end` from `normalizedFormatCalendar`
              // as `arguments from tooltipAccessor "start" and "end"` contain clipped dates(to see those gaps between events and days/hours.. )
              const event = normalizedFormatCalendar.find(
                event => event.id === id,
              );
              const timeRange =
                event && !isGroupMonth
                  ? isSelectedViewMonth
                    ? `${extractTime(event.start)} - ${extractTime(
                        event.end,
                      )} : `
                    : dayWeekViewsToolTipTimeRange(start, end) //)${extractTime(start)} - ${extractTime(end)} : `
                  : // dayViewToolTipTimeRange(event)
                    '';
              const typeText = getTextByType(type) || '';
              const prefix = isGroupMonth
                ? timeRange
                : `${timeRange}${typeText ? `${typeText} : ` : ''}`;
              if (isReservationTypeContent(type)) {
                if (renderMode === RenderMode.Item) {
                  const title = !!comments
                    ? `${equipmentName || ''} - ${comments}`
                    : `${equipmentName || ''}`;
                  return `${prefix}${title}`;
                }
              }
              return `${prefix}${title}`;
            }}
            eventPropGetter={(event: CalendarEvent) => {
              const eventStyles = getEventStyles(theme);
              const isGroupMonth =
                isSelectedViewMonth && event.renderMode === RenderMode.Group;
              if (isReservationNotVisible({reservationId: event.id})) {
                return {
                  style: {
                    ...eventStyles.notVisible,
                    cursor: isGroupMonth ? 'pointer' : 'not-allowed',
                  },
                  className: 'rbc-reservation',
                };
              }
              if (event.type === ReservationType.Maintenance) {
                return {
                  style: {...eventStyles.maintenance},
                  className: 'rbc-maintenance',
                };
              }
              if (isGroupMonth) {
                return {
                  style: {...eventStyles.reservation},
                  className: 'rbc-reservation',
                };
              }
              return {
                style: event.isCompleted
                  ? {...eventStyles.completed}
                  : {...eventStyles.reservation},
                className: 'rbc-reservation',
              };
            }}
            components={{
              toolbar: Toolbar,
              timeGutterHeader: props => {
                return (
                  <TimeGutterHeader {...props} currentWeek={currentWeek} />
                );
              },
              week: {
                header: WeekHeader,
              },
              month: {
                dateHeader: MonthDateHeader,
              },
              dateCellWrapper: props => (
                <DateCellWrapper
                  {...props}
                  selectedSlot={selectedSlot}
                  selectedView={selectedView}
                />
              ),
              timeSlotWrapper: props => (
                <TimeSlotWrapper
                  {...props}
                  selectedSlot={selectedSlot}
                  selectedView={selectedView}
                />
              ),
            }}
            views={['day', 'week', 'month']}
            localizer={localizer}
            events={(isSelectedViewMonth
              ? monthEvents
              : multiDayEventSeparator(normalizedFormatCalendar)
            ).map(event => {
              const isReservationType = isReservationTypeContent(event.type);
              const isReservedTyped = event.type === undefined;
              if (
                isSelectedViewMonth &&
                event.renderMode === RenderMode.Group
              ) {
                const title = isReservationType
                  ? 'scheduling:headers.reservations'
                  : isReservedTyped
                  ? 'scheduling:headers.reserved'
                  : 'scheduling:headers.maintenances';
                return {
                  ...event,
                  title: `${event.equipmentName || ''}: ${event.amount} ${t(
                    title,
                  ).toLowerCase()}`,
                };
              } else {
                const title = isReservationType
                  ? makeTitleReservationItem({
                      comments: event.comments,
                      isMonthView: isSelectedViewMonth,
                      equipmentName: event.equipmentName,
                    })
                  : makeTitleMaintenanceItem({
                      title: event.title || '',
                      equipmentName: event.equipmentName,
                    });

                return {
                  ...event,
                  end: sub(new Date(event.end), {minutes: 1}), // Need that offset cause otherwise the event is considered as 'full day' And displayed at the top of the calendar
                  title: title,
                };
              }
            })}
            onSelectSlot={props => {
              setScreenDate(new Date(props.slots[0]));

              setSelectedSlot(
                isPast(new Date(props.slots[0])) ? new Date() : props.slots[0],
              );
            }}
            onSelecting={() => false}
            formats={formats}
            onView={view => {
              setSelectedView(view);
            }}
          />
        </LayoutView>
      )}
    </>
  );
}
