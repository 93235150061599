import React from 'react';
import {generatePathById, IRouterMap} from 'utils/router';
import {Modes} from 'components/Common/Entity/context/item-context';
import queryString from 'query-string';

const SchedulingPage = React.lazy(() => import('./'));

const ROUTE_SCHEDULING = '/scheduling';
const ROUTE_SCHEDULING_CREATE = `${ROUTE_SCHEDULING}/create`;
const ROUTE_SCHEDULING_VIEW = `${ROUTE_SCHEDULING}/view/:id`;
const ROUTE_SCHEDULING_EDIT = `${ROUTE_SCHEDULING}/edit/:id`;

export const generateSchedulingPath = (): string => {
  return ROUTE_SCHEDULING;
};

export const generateSchedulingCreatePath = (): string => {
  return ROUTE_SCHEDULING_CREATE;
};

export const generateSchedulingViewPath = (id: string): string => {
  return generatePathById(`${ROUTE_SCHEDULING_VIEW}`, id);
};

export const generateSchedulingEditPath = (id: string): string => {
  return generatePathById(`${ROUTE_SCHEDULING_EDIT}`, id);
};

export const routes: IRouterMap[] = [
  {
    comp: SchedulingPage,
    path: ROUTE_SCHEDULING,
  },
  {
    comp: SchedulingPage,
    path: ROUTE_SCHEDULING_CREATE,
    mode: Modes.CREATE,
  },
  {
    comp: SchedulingPage,
    path: ROUTE_SCHEDULING_VIEW,
    mode: Modes.READ,
  },
  {
    comp: SchedulingPage,
    path: ROUTE_SCHEDULING_EDIT,
    mode: Modes.UPDATE,
  },
];

export enum QsEvents {
  MAINTENANCE = 'maintenance',
  RESERVATION = 'reservation',
}

export function generateEventQs(event: QsEvents) {
  return {
    event,
  };
}

export function isReservationQsEventRoute(search: string) {
  const params = queryString.parse(search);
  return params['event'] === QsEvents.RESERVATION;
}

export function isMaintenanceQsEventRoute(search: string) {
  const params = queryString.parse(search);
  return params['event'] === QsEvents.MAINTENANCE;
}
