import {colors} from '@valmet-iop/ui-common';
const {grey9, grey14} = colors;
export const scrollBarStyle = {
  '&::-webkit-scrollbar': {
    height: '8px',
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: `${grey14}`,
  },
  '&::-webkit-scrollbar-thumb': {
    background: `${grey9}`,
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: `${grey9}`,
  },
};
