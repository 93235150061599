const translations = {
  notifications: {
    intervalExists:
      'The selected time interval already contains at least one reservation. Are you sure you want to schedule this maintenance break?',
    minutes: '{{minutes}} minute(s).',
    hours: '{{hours}} hour(s) {{minutes}} minute(s).',
    days: '{{days}} day(s) {{hours}} hour(s) {{minutes}} minute(s).',
    scopesNotFound: 'No scopes were found to access Scheduling feature.',
    lastRefreshed: 'There may be changes to this page, refreshed last',
    confirmCompleteFutureReservation:
      'Are you sure you want to mark the reservation as completed before its start time?',
  },
  actions: {
    delete: 'Delete',
    edit: 'Edit',
  },
  create: {
    success: '{{type}} successfully created.',
    errors: {
      serverError: 'server error',
    },
  },
  update: {
    success: '{{type}} successfully updated.',
    errors: {
      serverError: 'server error',
    },
  },
  delete: {
    success: '{{type}} successfully deleted.',
    errors: {
      serverError: 'server error',
    },
  },
  titleNew: 'New event: select event type',
  titleNewReservation: 'New reservation',
  titleNewMainenance: 'New maintenance',
  titleWeek: 'Week {{num}}',
  weekPrefix: 'W{{num}}',
  headers: {
    reservationInfo: 'Reservation information',
    maintenanceInfo: 'Maintenance information',
    site: 'Site',
    title: 'Title',
    loadingPlace: 'Loading place',
    orderNo: 'Order No.',
    license: 'License',
    destination: 'Destination',
    allSites: 'All sites',
    siteBays: '{{siteName}} bays',
    bayAlias: 'Bay{{alias}}',
    reservation: 'Reservation',
    reservations: 'Reservations',
    maintenance: 'Maintenance',
    maintenances: 'Maintenances',
    reserved: 'Reserved',
    product: 'Product',
    noSelectedLoadingPlaces: 'No selected loading places',
    company: 'Company',
    customer: 'Customer',
    startTime: 'Start time',
    logisticsOperator: 'Logistics operator',
    completed: 'Completed',
    type: 'Type',
    active: 'Active',
    maintenanceBreak: 'Maintenance break',
    yes: 'Yes',
    no: 'No',
  },
  buttons: {
    create: 'New reservation',
    maintenance: 'Maintenance',
    reservation: 'Reservation',
    day: 'Day',
    week: 'Week',
    month: 'Month',
    today: 'Today',
    refreshNow: 'Refresh now',
    plusMore: '+ Show more',
  },
  columns: {
    site: 'Site',
    loadingPlace: 'Loading place',
    product: 'Product',
    type: 'Type',
    startTime: 'Start time',
    customer: 'Customer',
    logisticsOperator: 'Logistics operator',
    orderNo: 'Order No.',
    license: 'License',
    destination: 'Destination',
  },
  title: 'Scheduling',
  modes: {
    list: 'List view',
    calendar: 'Calendar view',
    timeline: 'Timeline view',
  },
  fields: {
    timeInterval: 'Time interval',
    title: 'Title',
    type: 'Type',
    loadingPlace: 'Loading place',
    startTime: 'Start time',
    orderNo: 'Order No.',
    license: 'License',
    destination: 'Destination',
    maintenanceTime: 'Maintenance time',
    maintenanceTimeStart: 'Maintenance time start',
    maintenanceTimeEnd: 'Maintenance time end',
    reservationTime: 'Reservation time',
    reservationTimeStart: 'Reservation time start',
    reservationTimeEnd: 'Reservation time end',
    comments: 'Comments',
    customer: 'Customer',
    logisticsOperator: 'Logistics operator',
    amount: 'Amount',
    site: 'Site',
    completed: 'Completed',
    createdBy: 'Created by',
    modifiedBy: 'Modified by',
    product: 'Product',
    truckAsset: 'Truck',
    referenceOrderNumber: 'Reference order No.',
  },
  reservationTypes: {
    Reservation: 'Reservation',
    Maintenance: 'Maintenance',
    ReservationLoading: 'Reservation (loading)',
    ReservationUnloading: 'Reservation (unloading)',
  },
};
export default translations;
