import * as React from 'react';
import {useTranslation} from 'react-i18next';
import DatePicker from 'react-datepicker';
import CheckboxTree, {Node} from 'react-checkbox-tree';
import {colors} from '@valmet-iop/ui-common';
import {Box, Button, Fade, Grid, ThemeProvider} from '@material-ui/core';
import {enUS} from 'date-fns/locale';
import {useCalendar} from '../../context/calendar-context';
import {
  applyDatePickerArrowStyles,
  calendarTheme,
  useTreeStyles,
} from './styles';
import {useTree} from '../../context/tree-context';
import {
  differenceInMinutes,
  format,
  isFuture,
  isToday,
  setYear,
} from 'date-fns';
import {setMonth} from 'date-fns/esm';
import {Alert} from '@material-ui/lab';
import {useHistory} from 'react-router-dom';
import {useBanner} from '../../context/banner-context';
import {usePageView} from '../../context/pageView-context';

const REFRESH_TIME_LS_KEY = 'refreshTime';
function refreshInterval() {
  localStorage.setItem(REFRESH_TIME_LS_KEY, new Date().toString());
}
export function LastRefreshBanner() {
  const history = useHistory();
  const {t} = useTranslation();
  const minutes = 15;
  const {isOpened, setIsOpened, mountedTime, setMountedTime} = useBanner();
  React.useEffect(() => {
    refreshInterval();
    if (!mountedTime) {
      setMountedTime(new Date());
    }
    const interval = setInterval(() => {
      const refreshTime = localStorage.getItem(REFRESH_TIME_LS_KEY) as string;
      if (
        !isOpened &&
        differenceInMinutes(new Date(), new Date(refreshTime)) > minutes - 1
      ) {
        setIsOpened(true);
      }
    }, 1000);
    return () => {
      localStorage.removeItem(REFRESH_TIME_LS_KEY);
      clearInterval(interval);
    };
  }, [isOpened, mountedTime, setIsOpened, setMountedTime]);
  return (
    <Fade in={isOpened}>
      <div style={{position: 'absolute', top: '10px', left: '30px'}}>
        <Alert
          onClose={() => {
            refreshInterval();
            setIsOpened(false);
          }}
          severity="info"
        >
          <Box mr={1} component="span">
            {t('scheduling:notifications.lastRefreshed')}
            <Box component="span" ml={1}>
              {mountedTime ? format(mountedTime, 'HH:mm, dd.MM.y') : ''}
            </Box>
          </Box>
          <Button
            color="inherit"
            size="small"
            onClick={() => {
              history.go(0);
            }}
          >
            <Box component="span" fontWeight="bold">
              {t('scheduling:buttons.refreshNow').toUpperCase()}
            </Box>
          </Button>
        </Alert>
      </div>
    </Fade>
  );
}

interface IThemeLayout {
  children: React.ReactNode;
}
export function ThemeLayout({children}: IThemeLayout) {
  return <ThemeProvider theme={calendarTheme}>{children}</ThemeProvider>;
}

export function isSelectable(date: Date) {
  return isToday(date) || isFuture(date);
}
export const LayoutView = (props: {children: React.ReactNode}) => {
  const {setSelectedSlot, setScreenDate, selectedSlot} = useCalendar();
  React.useEffect(applyDatePickerArrowStyles, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={3} md={12} sm={12} xl={3}>
        <Grid container spacing={3}>
          <Grid
            item
            xs={6}
            lg={12}
            md={6}
            sm={6}
            xl={12}
            className="scheduling_layout"
          >
            <DatePicker
              showWeekNumbers
              locale={{
                ...enUS,
                options: {
                  weekStartsOn: 1,
                },
              }}
              selected={
                isSelectable(new Date(selectedSlot))
                  ? new Date(selectedSlot)
                  : new Date()
              }
              onMonthChange={date => {
                // `date arg` points on `selected day number` from DatePicker but not from CalendarView,
                // however month point  is right, so we have to get current selectedSlot,and set month for that from `date arg`
                const newDate = setMonth(
                  setYear(new Date(selectedSlot as Date), date.getFullYear()),
                  date.getMonth(),
                );
                setScreenDate(newDate);
                setSelectedSlot(isSelectable(newDate) ? newDate : new Date());
              }}
              onChange={date => {
                setScreenDate(date as Date);

                setSelectedSlot(
                  isSelectable(new Date(date as Date))
                    ? (date as Date)
                    : new Date(),
                );
              }}
              inline
            />
          </Grid>
          <Grid item xs={6} lg={12} md={6} sm={6} xl={12}>
            <Box mt={2}>
              <Tree />
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} lg={9} md={12} sm={12} xl={9}>
        {props.children}
        <Legend />
      </Grid>
    </Grid>
  );
};
function Legend() {
  const {t} = useTranslation();
  return (
    <Box display="flex" justifyContent="flex-end" mt={1} style={{gap: '10px'}}>
      <LegendItem color={colors.green1}>
        {t('scheduling:headers.completed')}
      </LegendItem>

      <LegendItem color={colors.blue2}>
        {t('scheduling:headers.active')}
      </LegendItem>

      <LegendItem color={colors.yellow1}>
        {t('scheduling:headers.maintenanceBreak')}
      </LegendItem>

      <LegendItem color={colors.grey5}>
        {t('scheduling:headers.reserved')}
      </LegendItem>
    </Box>
  );
}
function LegendItem({
  children,
  color,
}: {
  children: React.ReactNode;
  color: string;
}) {
  return (
    <Box display="flex" alignItems="center">
      <Box
        width="14px"
        height="14px"
        borderRadius="7px"
        style={{background: color}}
      ></Box>
      <Box ml={1}>{children}</Box>
    </Box>
  );
}

export function Tree() {
  const classes = useTreeStyles();
  const {data, expanded, setChecked, checked, setExpanded} = useTree();
  const {isSelectedPageViewCalendar} = usePageView();

  return (
    <CheckboxTree
      nodes={[{...data, showCheckbox: !isSelectedPageViewCalendar}]}
      checked={checked}
      expanded={expanded}
      onCheck={checked => {
        setChecked((prev: string[]) => {
          if (isSelectedPageViewCalendar) {
            const prevParentIds =
              data?.children?.reduce((acc: string[], curr: Node) => {
                const isParent = curr.children?.some((item: Node) => {
                  return prev.includes(item.value);
                });
                if (isParent) {
                  return [...acc, curr.value];
                }
                return acc;
              }, []) || [];
            const newCurrentId = checked.find(id => {
              return !prev.includes(id);
            });
            if (!newCurrentId) {
              return checked;
            }
            const newCurrentParent = data?.children?.find(groupBays => {
              return groupBays.children?.some(
                bay => bay.value === newCurrentId,
              );
            });
            if (
              newCurrentParent &&
              !prevParentIds.includes(newCurrentParent.value)
            ) {
              return checked.filter(id => {
                return !prev.includes(id);
              });
            }
          }

          return checked;
        });
      }}
      onExpand={expanded => {
        setExpanded(expanded);
      }}
      icons={{
        check: <i className={`icon-externally-valid ${classes.root}`} />,
        uncheck: <i className={`icon-installation-package ${classes.root}`} />,
        halfCheck: (
          <i className={`icon-installation-package ${classes.root}`} />
        ),
        expandClose: <i className="icon-add" />,
        expandOpen: <i className="icon-zoom-out2" />,
        expandAll: <></>,
        collapseAll: <></>,
        parentClose: <></>,
        parentOpen: <></>,
        leaf: <></>,
      }}
    />
  );
}
