import {IOption} from '@valmet-iop/ui-common';
import orderBy from 'lodash.orderby';
interface IGenerateCellsNames<T> {
  fieldName: string;
  rowIndex: number;
  row: T;
}
export function generateCellsNames<T>({
  fieldName,
  rowIndex,
  row,
}: IGenerateCellsNames<T>): (columnIndex: number) => string {
  const cellNames = Object.keys(row);
  return (columnIndex: number) =>
    `${fieldName}[${rowIndex}].${cellNames[columnIndex]}`;
}

type IOrderMode = 'asc' | 'desc';
export function orderOptionsBy(data: IOption[], mode: IOrderMode = 'asc') {
  return orderBy(data, [option => option.text.toLowerCase()], mode);
}
