import {i18n} from 'i18next';
import endpointMonitoringEn from '../EndpointMonitoring/i18n/translations.en';
import schedulingEn from '../Scheduling/i18n/translations.en';

export const addResources = (i18next: i18n) => {
  i18next.addResourceBundle(
    'en-US',
    'endpointMonitoring',
    endpointMonitoringEn,
  );
  i18next.addResourceBundle('en-US', 'scheduling', schedulingEn);
};
