import React from 'react';
import {Card, CardContent, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

const UserNotFound = () => {
  const {t} = useTranslation();

  return (
    <Card>
      <CardContent>
        <Typography variant="h2" gutterBottom>
          {t('userNotFound.header')}
        </Typography>
        <Typography gutterBottom>{t('userNotFound.body')}</Typography>
      </CardContent>
    </Card>
  );
};

export default UserNotFound;
