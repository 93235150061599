import * as React from 'react';
import {
  ReservationDeletePermissions,
  ReservationType,
  ReservationUpdatePermissions,
} from 'api/generated/iop';
import {
  useReservations,
  useReservationsSites,
  useReservationsLoadingPlaces,
  usePostReservationsGetNotVisible,
} from 'components/Hooks/queries/useReservations';
import {
  addWeeks,
  lastDayOfMonth,
  setDate,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
  subWeeks,
} from 'date-fns';
import {useTree} from '../context/tree-context';
import {mergedStatus} from './useItemData';
import {ReservationOutputDTO} from 'api/generated/iop';
import {useTranslation} from 'react-i18next';
import {isReservationTypeContent} from '../components/ItemEdit';
import {getTextByType} from './useListReservations';
export const NOT_VISIBLE_RESERVATION = 'NOT_VISIBLE_RESERVATION';

export enum RenderMode {
  Group,
  Item,
  Link,
}

export function useCalendarTimelineReservations(screenDate: Date) {
  const {t} = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const startEndTimes = React.useMemo(() => {
    const activeMonthFirstDay = setMilliseconds(
      setSeconds(setMinutes(setHours(setDate(screenDate, 1), 0), 0), 0),
      0,
    );
    // Displaying reservations of active month +/- 2 weeks
    const activeFirstDay = subWeeks(activeMonthFirstDay, 2);
    const activeLastDay = addWeeks(lastDayOfMonth(activeMonthFirstDay), 2);
    return {
      start: activeFirstDay.toISOString(),
      end: activeLastDay.toISOString(),
    };
  }, [screenDate]);

  const {
    status: reservationsStatus,
    data: reservations,
    isLoading: reservationsIsLoading,
    error: reservationsError,
  } = useReservations(startEndTimes.start, startEndTimes.end);

  const {checked} = useTree();

  const {data: reservationsNotVisible, status: reservationsNotVisibleStatus} =
    usePostReservationsGetNotVisible();

  const dataTreeBinded =
    reservations?.filter(({equipmentId}) => {
      return checked.some(id => equipmentId === id);
    }) || [];

  const dataTreeNotVisibleBinded: ReservationOutputDTO[] =
    reservationsNotVisible
      ?.filter(({bayId}) => {
        return checked.some(id => bayId === id);
      })
      ?.map(
        (
          {
            bayId,
            siteId,
            siteName,
            startStamp,
            endStamp,
            bayName,
            logisticsOperatorCode,
          },
          index,
        ) => {
          return {
            reservationId: generateNotVisibleReservationId(index),
            companyId: '',
            equipmentId: bayId as string,
            siteId: siteId as string,
            siteName: siteName,
            startStamp: startStamp as string,
            equipmentName: bayName,
            endStamp: endStamp as string,
            displayName:
              logisticsOperatorCode ?? t('scheduling:headers.reserved'),
            isCompleted: false,
            updatePermissions: ReservationUpdatePermissions.None,
            deletePermissions: ReservationDeletePermissions.None,
          };
        },
      ) || [];

  return {
    status: mergedStatus([reservationsStatus, reservationsNotVisibleStatus]),
    normalizedFormatTimeline: [
      ...dataTreeBinded,
      ...dataTreeNotVisibleBinded,
    ].map(
      ({
        reservationId,
        type,
        startStamp,
        endStamp,
        equipmentId,
        siteId,
        displayName,
        comments,
        isCompleted,
        siteName,
      }) => {
        const isReservationType = isReservationTypeContent(type);
        const isMaintenanceType = type === ReservationType.Maintenance;

        let title = displayName as string;
        if (isReservationType) {
          title = makeTimelineTitleReservationItem({
            comments,
            type: type as string,
          });
        }
        if (isMaintenanceType) {
          title = makeTimelineTitleMaintenanceItem({
            title: displayName ?? '',
            type: type as string,
          });
        }
        return {
          id: reservationId,
          group: generateTimelineGroupId({
            siteId,
            equipmentId,
            siteName: siteName as string,
          }),
          title,
          // eslint-disable-next-line @typescript-eslint/camelcase
          start_time: new Date(startStamp),
          // eslint-disable-next-line @typescript-eslint/camelcase
          end_time: new Date(endStamp),
          canMove: false,
          type,
          isCompleted,
        };
      },
    ),
    normalizedFormatCalendar: [
      ...dataTreeBinded,
      ...dataTreeNotVisibleBinded,
    ].map(
      ({
        reservationId,
        type,
        startStamp,
        endStamp,
        displayName,
        comments,
        equipmentId,
        equipmentName,
        isCompleted,
      }) => {
        return {
          id: reservationId,
          title: isReservationTypeContent(type) ? type : displayName ?? '',
          start: new Date(startStamp),
          end: new Date(endStamp),
          type,
          comments,
          equipmentId,
          equipmentName,
          amount: 1,
          renderMode: RenderMode.Item,
          isCompleted,
        };
      },
    ),
    isLoading: reservationsIsLoading,
    error: reservationsError,
  };
}
export enum TimelineGroupType {
  Site,
  Bay,
}
export function useTimelineGroups() {
  const {data: equipments} = useReservationsLoadingPlaces();
  const {data: reservationsSites} = useReservationsSites();
  const {checked} = useTree();
  const equipmentsTreeBinded = equipments?.filter(({equipmentId}) => {
    return checked.some(id => equipmentId === id);
  });

  const data =
    reservationsSites?.reduce(
      (acc: {id: string; title: string; type?: TimelineGroupType}[], curr) => {
        const siteId = curr.siteId as string;
        const siteName = curr.displayName as string;
        const groupsForSite =
          equipmentsTreeBinded
            ?.filter(eq => {
              return eq.siteId === siteId;
            })
            .map(eq => {
              return {
                id: generateTimelineGroupId({
                  siteId,
                  equipmentId: eq.equipmentId,
                  siteName,
                }),
                title: eq.displayName ?? '',
                type: TimelineGroupType.Bay,
              };
            }) || [];
        const groupSiteMark = {
          id: generateTimelineGroupId({
            siteId,
            equipmentId: '',
            siteName: '',
          }),
          title: siteName,
          type: TimelineGroupType.Site,
        };
        return groupsForSite.length
          ? [...acc, groupSiteMark, ...groupsForSite]
          : [...acc, ...groupsForSite];
      },
      [],
    ) || [];
  return {data};
}

function generateTimelineGroupId({
  siteId,
  equipmentId,
  siteName,
}: {
  siteId?: string;
  equipmentId?: string;
  siteName?: string;
}) {
  return JSON.stringify({siteId, equipmentId, siteName});
}

function truncate(str: string, max: number) {
  const msg = str.replace(/\s+/g, ' ').trim();
  const ellipsis = msg.length > max ? '...' : '';

  return msg.slice(0, max).trim() + ellipsis;
}

export function makeTimelineTitleReservationItem({
  comments,
  type,
}: {
  comments?: string | null;
  type: string;
}) {
  return !!comments
    ? `${getTextByType(type) || ''} - ${comments}`
    : `${getTextByType(type) || ''}`;
}
export function makeTimelineTitleMaintenanceItem({
  title,
  type,
}: {
  title: string;
  type?: string | null;
}) {
  return !!title ? `${type || ''} - ${title}` : `${title}`;
}

export function makeTitleReservationItem({
  comments,
  isMonthView,
  equipmentName,
}: {
  comments?: string | null;
  equipmentName?: string | null;
  isMonthView: boolean;
}) {
  return !!comments
    ? `${equipmentName || ''} - ${truncate(comments, isMonthView ? 10 : 20)}`
    : `${equipmentName || ''}`;
}
export function makeTitleMaintenanceItem({
  title,
  equipmentName,
}: {
  title: string;
  equipmentName?: string | null;
}) {
  return !!title
    ? `${equipmentName || ''} - ${title}`
    : `${equipmentName || ''}`;
}

export function parseTimelineGroup(timelineGroup: string): {
  siteId: string;
  equipmentId: string;
} {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const {siteId, equipmentId}: {siteId: string; equipmentId: string} =
    JSON.parse(timelineGroup);
  return {siteId: siteId ?? '', equipmentId: equipmentId ?? ''};
}

function generateNotVisibleReservationId(index: number) {
  return `${NOT_VISIBLE_RESERVATION}-${index}`;
}
export function isReservationNotVisible(reservation: {
  reservationId: string;
}): boolean {
  return reservation.reservationId.includes(NOT_VISIBLE_RESERVATION);
}
