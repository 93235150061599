import {
  addDays,
  addMonths,
  addWeeks,
  areIntervalsOverlapping,
  differenceInMilliseconds,
  endOfDay,
  endOfHour,
  endOfMonth,
  endOfWeek,
  isAfter,
  isBefore,
  isEqual,
  isPast,
  startOfDay,
  startOfHour,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import {Location} from 'history';
import React from 'react';
import {View} from 'react-big-calendar';
import {TimelineItemBase} from 'react-calendar-timeline';
import {generateSchedulingPath} from '../routes';
import {fi} from 'date-fns/locale';
import {useLocation} from 'react-router-dom';
import {LocationState} from 'components/Layout/SideNav';
import {
  getDefaultEndFromStart,
  getInitialStartForNewReservationFromSelectedCalendarSlot,
} from '../timeUtils';
import {EPageView, usePageView} from './pageView-context';

const DEFAULT_SELECTED_SLOT = new Date();
const DEFAULT_SELECTED_VIEW = 'month';
const DEFAULT_GENERATED_RANGE = {start: '', end: ''};
const DEFAULT_SCREEN_DATE = new Date();
export type ISlot = Date | string;
interface IRange {
  start: ISlot;
  end: ISlot;
}

interface IContext {
  selectedSlot: Date | string;
  setSelectedSlot: (date: Date | string) => void;
  setSelectedView: (view: View) => void;
  selectedView: View;
  generatedRange: IRange;
  screenDate: Date;
  setScreenDate: (date: Date) => void;
  isSelectedViewDay: boolean;
  isSelectedViewWeek: boolean;
  isSelectedViewMonth: boolean;
  setTimelineSelectedSlot: (item: TimelineItemBase<any> | null) => void;
  timelineSelectedSlot: TimelineItemBase<any> | null;
}

export function useCalendar(): IContext {
  const context = React.useContext<IContext>(CalendarContext);
  if (!context) {
    throw new Error(`useCalendar must be used within a CalendarProvider`);
  }
  return context;
}

const CalendarContext = React.createContext<IContext>({
  screenDate: DEFAULT_SCREEN_DATE,
  setScreenDate: (date: Date) => {},
  isSelectedViewDay: false,
  isSelectedViewWeek: false,
  isSelectedViewMonth: false,
  selectedSlot: DEFAULT_SELECTED_SLOT,
  setSelectedSlot: (date: Date | string) => {},
  selectedView: DEFAULT_SELECTED_VIEW,
  setSelectedView: (view: View) => {},
  generatedRange: DEFAULT_GENERATED_RANGE,
  setTimelineSelectedSlot: (item: TimelineItemBase<any> | null) => {},
  timelineSelectedSlot: null,
});

interface IProvider {
  children: React.ReactNode;
  location: Location<LocationState>;
}
export function Provider(props: IProvider) {
  const {
    location: {pathname},
    ...other
  } = props;
  const [selectedSlot, setSelectedSlot] = React.useState<ISlot>(
    DEFAULT_SELECTED_SLOT,
  );
  const [selectedView, setSelectedView] = React.useState<View>(
    DEFAULT_SELECTED_VIEW,
  );
  const [screenDate, setScreenDate] = React.useState<Date>(DEFAULT_SCREEN_DATE);
  const [generatedRange, setGeneratedRange] = React.useState<IRange>(
    DEFAULT_GENERATED_RANGE,
  );
  const [timelineSelectedSlot, setTimelineSelectedSlot] =
    React.useState<TimelineItemBase<any> | null>(null);

  function reset() {
    setSelectedSlot(DEFAULT_SELECTED_SLOT);
    setSelectedView(DEFAULT_SELECTED_VIEW);
    setGeneratedRange(DEFAULT_GENERATED_RANGE);
    setScreenDate(DEFAULT_SCREEN_DATE);
  }
  React.useEffect(() => {
    if (!pathname.includes(generateSchedulingPath())) {
      reset();
    }
  }, [pathname]);

  const generateRange = React.useCallback(
    (start: Date) => {
      const definedStart = (() => {
        return getInitialStartForNewReservationFromSelectedCalendarSlot(start);
      })();
      setGeneratedRange({
        start: definedStart,
        end: getDefaultEndFromStart(definedStart),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname],
  );

  React.useEffect(() => {
    if (selectedSlot) {
      generateRange(new Date(selectedSlot));
    }
  }, [selectedSlot, selectedView, generateRange]);

  React.useEffect(() => {
    if (timelineSelectedSlot) {
      const startTime = timelineSelectedSlot.start_time as Date;
      generateRange(startTime);
    }
  }, [timelineSelectedSlot, generateRange]);

  const {pageView} = usePageView();
  React.useEffect(() => {
    if (pageView === EPageView.PageViewList) {
      setSelectedSlot(DEFAULT_SELECTED_SLOT);
      setTimelineSelectedSlot(null);
      setScreenDate(DEFAULT_SCREEN_DATE);
    } else if (pageView === EPageView.PageViewCalendar) {
      setTimelineSelectedSlot(null);
    }
  }, [pageView]);

  const value = React.useMemo(() => {
    return {
      selectedSlot,
      setSelectedSlot,
      selectedView,
      setSelectedView,
      generatedRange,
      screenDate,
      setScreenDate,
      timelineSelectedSlot,
      setTimelineSelectedSlot,
      isSelectedViewDay: selectedView === 'day',
      isSelectedViewMonth: selectedView === 'month',
      isSelectedViewWeek: selectedView === 'week',
    };
  }, [
    selectedSlot,
    setSelectedSlot,
    selectedView,
    setSelectedView,
    generatedRange,
    screenDate,
    setScreenDate,
    timelineSelectedSlot,
    setTimelineSelectedSlot,
  ]);

  return <CalendarContext.Provider value={value} {...other} />;
}
export function CalendarProvider(props: {children: React.ReactNode}) {
  const location = useLocation<LocationState>();
  return <Provider {...props} location={location} />;
}

export const SLOT_ID = 'selectedSlot';
interface IGenerateItemByTime {
  time: number;
  groupId: any;
  startTime?: Date;
  endTime?: Date;
}

interface IDefaultTimeRange {
  start: Date;
  end: Date;
}
const yearMs = (year: number) => {
  return year * 365.24 * 86400 * 1000;
};
export function useTimeline() {
  const {
    setSelectedView,
    selectedView,
    screenDate,
    setScreenDate,
    isSelectedViewDay,
    isSelectedViewWeek,
    isSelectedViewMonth,
    setTimelineSelectedSlot,
    timelineSelectedSlot,
    selectedSlot,
    setSelectedSlot,
  } = useCalendar();
  const getDefaultTimeRange = React.useCallback(() => {
    if (isSelectedViewMonth) {
      return {
        start: startOfMonth(screenDate),
        end: endOfMonth(screenDate),
      };
    }
    if (isSelectedViewWeek) {
      return {
        start: startOfWeek(screenDate, {locale: fi}),
        end: endOfWeek(screenDate, {locale: fi}),
      };
    }
    return {
      start: new Date(startOfDay(screenDate)),
      end: new Date(endOfDay(screenDate)),
    };
  }, [screenDate, isSelectedViewMonth, isSelectedViewWeek]);

  const [maxZoom, setMaxZoom] = React.useState<number>(yearMs(1));
  const [minZoom, setMinZoom] = React.useState<number>(yearMs(1));

  const [defaultTimeRange, setDefaultTimeRange] =
    React.useState<IDefaultTimeRange>(getDefaultTimeRange());

  React.useEffect(() => {
    setMinZoom(
      differenceInMilliseconds(defaultTimeRange.end, defaultTimeRange.start),
    );
    setMaxZoom(
      differenceInMilliseconds(defaultTimeRange.end, defaultTimeRange.start),
    );
  }, [defaultTimeRange]);

  React.useEffect(() => {
    setDefaultTimeRange(getDefaultTimeRange());
  }, [selectedView, screenDate, getDefaultTimeRange]);

  const overlapped = (
    items: IItem[],
    item: IItem,
    isStrict = false,
    customCheck?: (ev: IItem) => boolean,
  ) => {
    return items.filter(event => {
      const isStartedFromPrevDay =
        isBefore(new Date(event.start_time), new Date(item.start_time)) ||
        isEqual(new Date(event.start_time), new Date(item.start_time));
      const isEndNextDay =
        isAfter(new Date(event.end_time), new Date(item.end_time)) ||
        isEqual(new Date(event.end_time), new Date(item.end_time));

      const isCustomChecked = !customCheck ? true : customCheck(event);
      const result =
        isCustomChecked &&
        event.group === item.group &&
        areIntervalsOverlapping(
          {start: event.start_time, end: event.end_time},
          {
            start: item.start_time,
            end: item.end_time,
          },
        );
      return isStrict ? result && isStartedFromPrevDay && isEndNextDay : result;
    });
  };
  return {
    defaultTimeRange,
    setDefaultTimeRange,
    moveToToday: () => {
      setSelectedSlot(new Date());
      setScreenDate(new Date());
    },
    moveToNext: () => {
      const {start} = defaultTimeRange;
      if (isSelectedViewMonth) {
        const slot = addMonths(new Date(selectedSlot), 1);
        const todayScreen = addMonths(startOfMonth(new Date()), 1);
        const screenDate = addMonths(startOfMonth(new Date(start)), 1);
        if (todayScreen.getTime() <= screenDate.getTime()) {
          setSelectedSlot(slot);
        }
        setScreenDate(screenDate);
      } else if (isSelectedViewWeek) {
        const slot = addWeeks(new Date(selectedSlot), 1);
        const todayScreen = addWeeks(startOfWeek(new Date(), {locale: fi}), 1);
        const screenDate = addWeeks(
          startOfWeek(new Date(start), {locale: fi}),
          1,
        );
        if (todayScreen.getTime() <= screenDate.getTime()) {
          setSelectedSlot(slot);
        }

        setScreenDate(screenDate);
      } else {
        const slot = addDays(new Date(selectedSlot), 1);
        const todayScreen = addDays(startOfDay(new Date()), 1);
        const screenDate = addDays(startOfDay(new Date(start)), 1);
        if (todayScreen.getTime() <= screenDate.getTime()) {
          setSelectedSlot(slot);
        }

        setScreenDate(screenDate);
      }
    },
    moveToPrev: () => {
      const {start} = defaultTimeRange;
      if (isSelectedViewMonth) {
        const slot = addMonths(new Date(selectedSlot), -1);
        if (isPast(slot)) {
          setSelectedSlot(new Date());
        } else {
          setSelectedSlot(slot);
        }
        setScreenDate(addMonths(startOfMonth(new Date(start)), -1));
      } else if (isSelectedViewWeek) {
        const slot = addWeeks(new Date(selectedSlot), -1);
        if (isPast(slot)) {
          setSelectedSlot(new Date());
        } else {
          setSelectedSlot(slot);
        }
        setScreenDate(addWeeks(startOfWeek(new Date(start), {locale: fi}), -1));
      } else {
        const slot = addDays(new Date(selectedSlot), -1);
        if (isPast(slot)) {
          setSelectedSlot(new Date());
        } else {
          setSelectedSlot(slot);
        }
        setScreenDate(addDays(startOfDay(new Date(start)), -1));
      }
    },
    maxZoom,
    minZoom,
    zoomInToDayView: (item: {
      id: string;
      group: any;
      title: string;
      start_time: Date;
      end_time: Date;
      canResize: boolean;
      canMove: boolean;
    }) => {
      setSelectedView('day');
      setDefaultTimeRange({
        start: new Date(item.start_time),
        end: new Date(item.end_time),
      });
    },
    generateSlotByTime: ({
      time,
      groupId,
      startTime,
      endTime,
    }: IGenerateItemByTime) => {
      return {
        id: SLOT_ID,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        group: groupId,
        title: '',
        // eslint-disable-next-line @typescript-eslint/camelcase
        start_time:
          startTime ||
          (isSelectedViewDay ? startOfHour(time) : startOfDay(time)),
        // eslint-disable-next-line @typescript-eslint/camelcase
        end_time:
          endTime || (isSelectedViewDay ? endOfHour(time) : endOfDay(time)),
        canResize: false,
        canMove: false,
      };
    },
    overlapped,
    isOverlapped: function (items: IItem[], item: IItem) {
      if (isSelectedViewDay) {
        return !!overlapped(items, item, true).length;
      } else {
        return !!overlapped(items, item).length;
      }
    },
    setSelectedSlot: setTimelineSelectedSlot,
    selectedSlot: timelineSelectedSlot,
  };
}

export interface IItem {
  id: any;
  group: any;
  title: string;
  start_time: Date;
  end_time: Date;
}
