import {useLocation, useParams} from 'react-router-dom';
import * as React from 'react';
import {
  OrderByType,
  PaginatedReservationsQueryDTO,
  ReservationAvailableProductEquipmentOutputDTO,
  ReservationAvailableProductOutputDTO,
  ReservationAvailableProductsOutputDTO,
  ReservationDeletePermissions,
  ReservationInputDTO,
  ReservationOutputDTO,
  ReservationSortColumnName,
  ReservationType,
  ReservationUpdatePermissions,
  ReservationUpdateRestrictedInputDTO,
} from 'api/generated/iop';
import {useItem} from 'components/Common/Entity/context/item-context';
import {
  useReservationsLoadingPlaces,
  useReservations,
  useReservationsCustomers,
  useReservationsReservationIdGet,
  useReservationsSites,
  useReservationsDeliveryDestinations,
} from 'components/Hooks/queries/useReservations';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  intervalToDuration,
  isValid,
  setMilliseconds,
} from 'date-fns';
import i18n from 'i18n';
import {QueryStatus} from 'react-query';
import {orderOptionsBy} from 'utils/form';
import {ISlot, useCalendar, useTimeline} from '../context/calendar-context';
import {useTree} from '../context/tree-context';
import {getTextByType} from './useListReservations';
import {isReservationQsEventRoute} from '../routes';
import {getDefaultEndFromStart} from '../timeUtils';
import {IOption} from '@valmet-iop/ui-common';
import {useTranslation} from 'react-i18next';
import {uniq} from 'ramda';
import {isReservationTypeContent} from '../components/ItemEdit';
import {usePageView} from '../context/pageView-context';
import {
  parseTimelineGroup,
  useTimelineGroups,
} from './useCalendarTimelineReservations';

function getAllowedEquipmentIds(
  productEquipments: ReservationAvailableProductEquipmentOutputDTO[],
  filterType?: ReservationType,
) {
  let allowedEquipments = [...productEquipments];
  // If reservation type is Loading, equipment must have CanProvide == true
  if (filterType === ReservationType.ReservationLoading) {
    allowedEquipments = allowedEquipments?.filter(eq => eq.canProvide);
  } else if (filterType === ReservationType.ReservationUnloading) {
    // Same for Unloading and CanReceive
    allowedEquipments = allowedEquipments?.filter(eq => eq.canReceive);
  }

  return allowedEquipments?.map(e => e.equipmentId) ?? [];
}

export function useItemHelper() {
  const {data: reservationsSites} = useReservationsSites();
  const {site, getCustomerCompaniesBySiteId, getLoadingPlacesBySiteId} =
    useFieldsOptions();

  //TODO move under useFieldsOptions
  const getFilteredLoadingPlaces = React.useCallback(
    (
      productsDto: ReservationAvailableProductsOutputDTO | undefined,
      filters: {
        siteId: string;
        productId: string;
        type?: ReservationType;
      },
    ) => {
      const loadingPlaces = getLoadingPlacesBySiteId(filters.siteId);
      if (productsDto === undefined || !productsDto.products) {
        return [];
      }

      if (!filters.productId) {
        // Return loading places that are allowed by any product
        const allowedLoadingPlaces: IOption[] = [];

        for (const loadingPlace of loadingPlaces) {
          for (const product of productsDto.products) {
            const allowedEquipmentIds = getAllowedEquipmentIds(
              product?.allowedEquipments ?? [],
              filters.type,
            );
            if (allowedEquipmentIds.includes(loadingPlace.value)) {
              allowedLoadingPlaces.push(loadingPlace);
              break;
            }
          }
        }

        return allowedLoadingPlaces;
      }

      const productAllowedEquipmentIds = getAllowedEquipmentIds(
        productsDto.products?.find(p => p.productId === filters.productId)
          ?.allowedEquipments ?? [],
        filters.type,
      );

      return loadingPlaces.filter(x =>
        productAllowedEquipmentIds.includes(x.value),
      );
    },
    [getLoadingPlacesBySiteId],
  );
  const getProviderCompanyIdBySiteId = React.useCallback(
    (siteId: string) => {
      return reservationsSites?.find(s => s.siteId === siteId)?.companyId;
    },
    [reservationsSites],
  );
  const getProviderCompanyIdsBySiteIds = React.useCallback(
    (siteIds: string[]) => {
      const result = siteIds.reduce((acc: string[], siteId: string) => {
        const companyId = reservationsSites?.find(
          s => s.siteId === siteId,
        )?.companyId;
        if (companyId) {
          return [...acc, companyId];
        }
        return acc;
      }, []);
      return uniq(result);
    },
    [reservationsSites],
  );
  const getFilteredSites = React.useCallback(
    (
      productsDtosByProviderCompanyId: Record<
        string,
        ReservationAvailableProductsOutputDTO | undefined
      >,
      filters: {productId: string},
    ) => {
      const result: IOption[] = [];
      for (const siteOption of site) {
        const providerCompanyId = getProviderCompanyIdBySiteId(
          siteOption.value,
        );
        if (providerCompanyId === undefined) {
          continue;
        }

        const productsDto = productsDtosByProviderCompanyId[providerCompanyId];
        const availableLoadingPlaces = getFilteredLoadingPlaces(productsDto, {
          siteId: siteOption.value,
          productId: filters.productId,
        });
        if (availableLoadingPlaces.length > 0) {
          // Only sites where we will have loading places available
          // should be selectable.
          result.push(siteOption);
        }
      }

      return result;
    },
    [site, getFilteredLoadingPlaces, getProviderCompanyIdBySiteId],
  );
  const getFilteredCustomerCompanies = React.useCallback(
    (
      productsDto: ReservationAvailableProductsOutputDTO | undefined,
      filters: {
        siteId: string;
        logisticsOperatorId: string;
        productId: string;
      },
    ) => {
      const result = getCustomerCompaniesBySiteId(filters.siteId);
      if (!filters.productId) {
        return result;
      }

      const productAllowedCustomerIds =
        productsDto?.products?.find(p => p.productId === filters.productId)
          ?.allowedCustomerCompanyIds ?? [];

      return result.filter(x => productAllowedCustomerIds.includes(x.value));
    },
    [getCustomerCompaniesBySiteId],
  );
  const filterLogisticsOperators = React.useCallback(
    (
      logisticsOperators: IOption[],
      productsDto: ReservationAvailableProductsOutputDTO | undefined,
      filters: {
        productId: string;
      },
    ) => {
      if (!filters.productId) {
        return logisticsOperators;
      }

      const productAllowedLogisticsOperatorIds =
        productsDto?.products?.find(p => p.productId === filters.productId)
          ?.allowedLogisticsOperatorCompanyIds ?? [];

      return logisticsOperators.filter(x =>
        productAllowedLogisticsOperatorIds.includes(x.value),
      );
    },
    [],
  );

  return {
    getFilteredLoadingPlaces,
    filterLogisticsOperators,
    getFilteredCustomerCompanies,
    getFilteredSites,
    getProviderCompanyIdBySiteId,
    getProviderCompanyIdsBySiteIds,
  };
}
interface Option {
  text: string;
  value: string;
}
interface InitialValues {
  reservationId: string | null | undefined;
  site: Option | null;
  displayName: string;
  loadingPlace: Option | null;
  logisticOperator: Option | null;
  truckAsset: Option | null;
  loadingTimeStart: ISlot;
  loadingTimeEnd: ISlot;
  orderNo: string;
  referenceOrderNumber: string;
  destination: Option | null;
  amount: string;
  type: Option | null;
  customer: Option | null;
  product: Option | null;
  comments: string;
  isCompleted: boolean;
  updatePermissions: ReservationUpdatePermissions | undefined;
  deletePermissions: ReservationDeletePermissions | undefined;
  isOverlapOnMutate: boolean;
  siteReservationDurationLookup: Record<
    string,
    {
      minimumLoadingMinutes: number | null;
      defaultLoadingMinutes: number | null;
      minimumUnloadingMinutes: number | null;
      defaultUnloadingMinutes: number | null;
    }
  >;
  equipmentReservationDurationLookup: Record<
    string,
    {
      minimumLoadingMinutes: number | null;
      defaultLoadingMinutes: number | null;
      minimumUnloadingMinutes: number | null;
      defaultUnloadingMinutes: number | null;
    }
  >;
}

export function useItemDataEdit() {
  const {isCreate} = useItem();
  const {
    generatedRange: {start},
  } = useCalendar();
  const {data: reservationsSites} = useReservationsSites();
  const {search} = useLocation();
  const {id} = useParams<{id: string}>();
  const {data: reservation, status: reservationStatus} =
    useReservationsReservationIdGet(id);
  const {status: reservationsStatus, data: reservations} = useReservations();
  const {data: equipments} = useReservationsLoadingPlaces();

  const {
    isSelectedPageViewList,
    isSelectedPageViewTimeline,
    isSelectedPageViewCalendar,
  } = usePageView();
  const {selectedSlot} = useTimeline();
  const {checkedEquipmentsSites, checkedEquipments} = useTree();
  const {
    site,
    status,
    getDestinationsByCustomerCompanyId,
    getFilteredProducts,
    getReservationTypes,
  } = useFieldsOptions();
  const {
    getFilteredLoadingPlaces,
    filterLogisticsOperators,
    getFilteredCustomerCompanies,
    getFilteredSites,
    getProviderCompanyIdBySiteId,
  } = useItemHelper();
  useTimelineGroups();
  const selectedSlotOnTimeLine = (() => {
    if (selectedSlot && isSelectedPageViewTimeline) {
      return parseTimelineGroup(selectedSlot.group as string);
    }
    return null;
  })();
  const siteOnCreate = (() => {
    if (isSelectedPageViewList) {
      if (reservationsSites?.length === 1) {
        return reservationsSites[0];
      }
    } else {
      if (selectedSlotOnTimeLine) {
        const {siteId} = selectedSlotOnTimeLine;
        return reservationsSites?.find(reservationSite => {
          return siteId === reservationSite.siteId;
        });
      }
      if (checkedEquipmentsSites.length === 1) {
        return reservationsSites?.find(({siteId}) => {
          return checkedEquipmentsSites[0] === siteId;
        });
      }
    }
    return undefined;
  })();
  const extra = {
    isOverlapOnMutate: false,
  };
  const getDefaultEqById = React.useCallback(
    (equipmentId: string) => {
      const defaultEq = equipments?.find(eq => eq.equipmentId === equipmentId);
      return !!defaultEq
        ? {
            text: defaultEq.displayName ?? '',
            value: defaultEq.equipmentId ?? '',
          }
        : null;
    },
    [equipments],
  );

  const siteReservationDurationLookup = React.useMemo(() => {
    if (reservationsSites === undefined) {
      return {};
    }

    const result: InitialValues['siteReservationDurationLookup'] = {};

    for (const site of reservationsSites) {
      if (site.siteId === undefined) {
        continue;
      }

      result[site.siteId] = {
        minimumLoadingMinutes: site.minimumLoadingTimeIntervalMinutes ?? null,
        defaultLoadingMinutes: site.defaultLoadingTimeIntervalMinutes ?? null,
        minimumUnloadingMinutes:
          site.minimumUnloadingTimeIntervalMinutes ?? null,
        defaultUnloadingMinutes:
          site.defaultUnloadingTimeIntervalMinutes ?? null,
      };
    }

    return result;
  }, [reservationsSites]);

  const equipmentReservationDurationLookup = React.useMemo(() => {
    if (equipments === undefined) {
      return {};
    }

    const result: InitialValues['equipmentReservationDurationLookup'] = {};

    for (const equipment of equipments) {
      if (equipment.equipmentId === undefined) {
        continue;
      }

      result[equipment.equipmentId] = {
        minimumLoadingMinutes:
          equipment.minimumLoadingTimeIntervalMinutes ?? null,
        defaultLoadingMinutes:
          equipment.defaultLoadingTimeIntervalMinutes ?? null,
        minimumUnloadingMinutes:
          equipment.minimumUnloadingTimeIntervalMinutes ?? null,
        defaultUnloadingMinutes:
          equipment.defaultUnloadingTimeIntervalMinutes ?? null,
      };
    }

    return result;
  }, [equipments]);

  const initialValues: InitialValues = isCreate
    ? {
        reservationId: null,
        site: siteOnCreate
          ? {
              text: siteOnCreate.displayName ?? '',
              value: siteOnCreate.siteId ?? '',
            }
          : null,
        displayName: '',
        loadingPlace: (() => {
          if (selectedSlotOnTimeLine) {
            const {equipmentId} = selectedSlotOnTimeLine;
            return getDefaultEqById(equipmentId);
          } else if (
            isSelectedPageViewCalendar &&
            checkedEquipments.length === 1
          ) {
            const equipmentId = checkedEquipments[0];
            return getDefaultEqById(equipmentId);
          }
          return null;
        })(),
        logisticOperator: null,
        truckAsset: null,
        loadingTimeStart: isCreate ? start : '',
        loadingTimeEnd: (() => {
          return isCreate ? getDefaultEndFromStart(start as Date) : '';
        })(),
        orderNo: '',
        referenceOrderNumber: '',
        destination: null,
        amount: '',
        type: null,
        customer: null,
        product: null,
        comments: '',
        isCompleted: false,
        updatePermissions: isReservationQsEventRoute(search)
          ? ReservationUpdatePermissions.ReservationUpdateAllFields
          : ReservationUpdatePermissions.MaintenanceUpdate,
        deletePermissions: isReservationQsEventRoute(search)
          ? ReservationDeletePermissions.ReservationDelete
          : ReservationDeletePermissions.MaintenanceDelete,
        siteReservationDurationLookup,
        equipmentReservationDurationLookup,
        ...extra,
      }
    : {
        reservationId: reservation?.reservationId,
        site:
          !!reservation?.siteId && !!reservation.siteName
            ? {
                text: reservation.siteName ?? '',
                value: reservation.siteId ?? '',
              }
            : null,
        displayName: reservation?.displayName ?? '',
        loadingPlace:
          !!reservation?.equipmentId && !!reservation.equipmentName
            ? {
                text: reservation.equipmentName ?? '',
                value: reservation.equipmentId ?? '',
              }
            : null,
        logisticOperator:
          !!reservation?.logisticsOperatorName &&
          !!reservation?.logisticsOperatorCompanyId
            ? {
                text: reservation.logisticsOperatorName,
                value: reservation.logisticsOperatorCompanyId,
              }
            : null,
        truckAsset:
          !!reservation?.truckAssetLicensePlate && !!reservation?.truckAssetId
            ? {
                text: reservation.truckAssetLicensePlate,
                value: reservation.truckAssetId,
              }
            : null,
        loadingTimeStart: reservation ? new Date(reservation.startStamp) : '',
        loadingTimeEnd: reservation ? new Date(reservation.endStamp) : '',
        orderNo: reservation?.orderNo?.toString() ?? '',
        referenceOrderNumber: reservation?.referenceOrderNumber ?? '',
        destination:
          !!reservation?.deliveryDestinationId &&
          !!reservation?.deliveryDestinationName
            ? {
                text: getDestinationText({
                  code: reservation.deliveryDestinationCode,
                  address: reservation.deliveryDestinationAddress,
                  displayName: reservation.deliveryDestinationName,
                }),
                value: reservation.deliveryDestinationId ?? '',
              }
            : null,
        amount: reservation?.amount?.toString() ?? '',
        type: !!reservation
          ? {
              text: getTextByType(reservation.type) ?? '',
              value: reservation.type as ReservationType,
            }
          : null,
        customer:
          !!reservation?.customerCompanyId && !!reservation?.customerCompanyName
            ? {
                text: reservation.customerCompanyName,
                value: reservation.customerCompanyId,
              }
            : null,
        product:
          !!reservation?.productDisplayName && !!reservation?.productId
            ? {
                text: reservation.productDisplayName,
                value: reservation.productId,
              }
            : null,
        comments: reservation?.comments ?? '',
        updatePermissions: reservation?.updatePermissions,
        deletePermissions: reservation?.deletePermissions,
        isCompleted: reservation?.isCompleted ?? false,
        siteReservationDurationLookup,
        equipmentReservationDurationLookup,
        ...extra,
      };

  return {
    status: mergedStatus([reservationsStatus, reservationStatus, status]),
    initialValues,
    options: {
      reservation,
      site,
      getDestinationsByCustomerCompanyId,
      getFilteredCustomerCompanies,
      getFilteredLoadingPlaces,
      getProviderCompanyIdBySiteId,
      getFilteredProducts,
      filterLogisticsOperators,
      getFilteredSites,
      getReservationTypes,
    },
    getDefaultReservationTimeIntervalMinutes: (
      siteId: string | undefined,
      equipmentId: string | undefined,
      reservationType: ReservationType | undefined,
    ) => {
      let min: number | null | undefined;

      if (equipmentId) {
        const equipment = equipments?.find(e => {
          return e.equipmentId === equipmentId;
        });
        if (reservationType === ReservationType.ReservationLoading) {
          min = equipment?.defaultLoadingTimeIntervalMinutes;
        } else if (reservationType === ReservationType.ReservationUnloading) {
          min = equipment?.defaultUnloadingTimeIntervalMinutes;
        }
        if (min) {
          return min;
        }
      }

      if (siteId) {
        const site = reservationsSites?.find(s => {
          return s.siteId === siteId;
        });

        if (reservationType === ReservationType.ReservationLoading) {
          min = site?.defaultLoadingTimeIntervalMinutes;
        } else if (reservationType === ReservationType.ReservationUnloading) {
          min = site?.defaultUnloadingTimeIntervalMinutes;
        }
        if (min) {
          return min;
        }
      }

      return null;
    },
    normalizeValuesToPostSearchOverlapping: (
      values: typeof initialValues,
      pageOptions?: {
        pageSize?: number;
        pageNumber?: number;
        orderByType?: OrderByType;
        sortByColumn?: ReservationSortColumnName;
      },
    ): PaginatedReservationsQueryDTO => {
      const {loadingTimeStart, loadingTimeEnd, loadingPlace} = values;
      const startStamp = setMilliseconds(
        new Date(loadingTimeStart as string),
        0,
      ).toISOString();
      const endStamp = setMilliseconds(
        new Date(loadingTimeEnd as string),
        0,
      ).toISOString();

      return {
        startStamp,
        endStamp,
        pageNumber: pageOptions?.pageNumber ?? 1,
        pageSize: pageOptions?.pageSize ?? (reservations?.length || 1),
        orderByType: pageOptions?.orderByType,
        sortByColumn: pageOptions?.sortByColumn,
        equipmentId: loadingPlace?.value ?? '',
      };
    },
    normalizeValuesToPutRestricted: (
      values: typeof initialValues,
    ): ReservationUpdateRestrictedInputDTO => {
      const {destination, loadingTimeStart, loadingTimeEnd} = values;
      const startStamp = setMilliseconds(
        new Date(loadingTimeStart as string),
        0,
      ).toISOString();
      const endStamp = setMilliseconds(
        new Date(loadingTimeEnd as string),
        0,
      ).toISOString();
      return {
        deliveryDestinationId: destination?.value ?? '',
        startStamp,
        endStamp,
      };
    },
    normalizeValuesToPostPut: (
      values: typeof initialValues,
    ): ReservationInputDTO => {
      const {
        site,
        destination,
        loadingPlace,
        loadingTimeStart,
        loadingTimeEnd,
        orderNo,
        amount,
        displayName,
        type,
        customer,
        logisticOperator,
        comments,
        product,
        truckAsset,
        referenceOrderNumber,
      } = values;
      const reservationSite = reservationsSites?.find(({siteId}) => {
        return site?.value === siteId;
      });
      const startStamp = setMilliseconds(
        new Date(loadingTimeStart as string),
        0,
      ).toISOString();
      const endStamp = setMilliseconds(
        new Date(loadingTimeEnd as string),
        0,
      ).toISOString();
      if (type?.value === ReservationType.Maintenance) {
        return {
          companyId: reservationSite?.companyId as string,
          siteId: site?.value ?? '',
          equipmentId: loadingPlace?.value ?? '',
          startStamp,
          endStamp,
          deliveryDestinationId: null,
          customerCompanyId: null,
          logisticsOperatorCompanyId: null,
          orderNo: null,
          amount: null,
          type: type.value,
          displayName: displayName.trim(),
          comments,
          truckAssetId: null,
          referenceOrderNumber: null,
        };
      }

      return {
        companyId: reservationSite?.companyId as string,
        siteId: site?.value ?? '',
        equipmentId: loadingPlace?.value ?? '',
        startStamp,
        endStamp,
        deliveryDestinationId: destination?.value ?? '',
        customerCompanyId: customer?.value ?? '',
        logisticsOperatorCompanyId: logisticOperator?.value ?? '',
        orderNo: orderNo.length > 0 ? Number(orderNo) : null,
        amount: Number(amount),
        type: type?.value as ReservationType,
        displayName: null,
        comments,
        productId: product?.value ?? '',
        truckAssetId: !truckAsset?.value ? null : truckAsset.value,
        referenceOrderNumber: referenceOrderNumber?.trim(),
      };
    },
  };
}
export function useFieldsOptions() {
  const {status: reservationsSitesStatus, data: reservationsSites} =
    useReservationsSites();
  const {status: destinationsStatus, data: destinations} =
    useReservationsDeliveryDestinations();
  const {getPlainMarks} = useReservationType();
  const {status: reservationsCustomersStatus, data: reservationsCustomers} =
    useReservationsCustomers();
  const {data: equipments, status: equipmentsStatus} =
    useReservationsLoadingPlaces();
  const {t} = useTranslation();
  return {
    status: mergedStatus([
      reservationsSitesStatus,
      destinationsStatus,
      reservationsCustomersStatus,
      equipmentsStatus,
    ]),
    site: orderOptionsBy(
      reservationsSites?.map(({displayName, siteId}) => {
        return {text: displayName ?? '', value: siteId ?? ''};
      }) || [],
    ),
    getReservationTypes: (
      availableProducts?: ReservationAvailableProductOutputDTO[],
      selectedProductId?: string,
      selectedLoadingPlaceId?: string,
    ): IOption[] => {
      const {canProvide, canReceive} = getPlainMarks(
        selectedLoadingPlaceId,
        availableProducts,
        selectedProductId,
      );
      const reservationLoadingOption = {
        text: t('scheduling:reservationTypes.ReservationLoading'),
        value: ReservationType.ReservationLoading,
      };

      const reservationUnloadingOption = {
        text: t('scheduling:reservationTypes.ReservationUnloading'),
        value: ReservationType.ReservationUnloading,
      };
      if (canProvide && canReceive) {
        return [reservationLoadingOption, reservationUnloadingOption];
      } else if (canProvide) {
        return [reservationLoadingOption];
      } else if (canReceive) {
        return [reservationUnloadingOption];
      }
      return [];
    },
    getDestinationsByCustomerCompanyId: (selectedCustomerCompanyId: string) => {
      return orderOptionsBy(
        destinations
          ?.filter(({companyId}) => {
            return companyId === selectedCustomerCompanyId;
          })
          .map(destination => {
            const {deliveryDestinationId} = destination;
            return {
              text: destination ? getDestinationText(destination) : '',
              value: deliveryDestinationId ?? '',
            };
          }) || [],
      );
    },
    getCustomerCompaniesBySiteId: (selectedSiteId: string) => {
      return orderOptionsBy(
        reservationsCustomers
          ?.filter(({sitesIds}) => {
            return sitesIds?.some(siteId => siteId === selectedSiteId);
          })
          .map(({companyId, displayName}) => {
            return {
              text: displayName ?? '',
              value: companyId ?? '',
            };
          }) || [],
      );
    },
    getLoadingPlacesBySiteId: (selectedSiteId: string) => {
      return orderOptionsBy(
        equipments
          ?.filter(({siteId}) => {
            return selectedSiteId === siteId;
          })
          .map(({equipmentId, displayName}) => {
            return {text: displayName ?? '', value: equipmentId ?? ''};
          }) || [],
      );
    },
    getFilteredProducts: (
      productsDto: ReservationAvailableProductsOutputDTO | undefined,
      filters: {
        siteId: string;
        loadingPlaceId: string;
        customerId: string;
        logisticsOperatorId: string;
        truckAssetId: string;
      },
    ) => {
      const result: IOption[] = [];
      if (
        productsDto === undefined ||
        productsDto.products === null ||
        productsDto.products === undefined ||
        !filters.siteId
      ) {
        return result;
      }

      for (const product of productsDto.products) {
        const allowedSiteIds =
          product.allowedEquipments?.map(e => e.siteId) ?? [];
        const allowedEquipmentIds =
          product.allowedEquipments?.map(e => e.equipmentId) ?? [];
        const allowedCustomerIds = product.allowedCustomerCompanyIds ?? [];
        const allowedLogisticsOperatorIds =
          product.allowedLogisticsOperatorCompanyIds ?? [];
        const allowedAssetIds = product.allowedAssetIds ?? [];
        if (!allowedSiteIds.includes(filters.siteId)) {
          continue;
        }
        if (
          !!filters.loadingPlaceId &&
          !allowedEquipmentIds.includes(filters.loadingPlaceId)
        ) {
          continue;
        }
        if (
          !!filters.customerId &&
          !allowedCustomerIds.includes(filters.customerId)
        ) {
          continue;
        }
        if (
          !!filters.logisticsOperatorId &&
          !allowedLogisticsOperatorIds.includes(filters.logisticsOperatorId)
        ) {
          continue;
        }
        if (
          !!filters.truckAssetId &&
          !allowedAssetIds.includes(filters.truckAssetId)
        ) {
          continue;
        }

        result.push({
          text: product.productDisplayName ?? '',
          value: product.productId ?? '',
        });
      }
      return result;
    },
  };
}

export interface IReservationTypeMarks {
  canReceive: boolean;
  canProvide: boolean;
}
export function useReservationType() {
  function getReservationTypeMarksByProduct(
    product: ReservationAvailableProductOutputDTO,
    selectedLoadingPlaceId: string | null | undefined,
  ): IReservationTypeMarks {
    const defaultMarks = {canReceive: false, canProvide: false};

    if (!!selectedLoadingPlaceId) {
      const allowedEquipment = product.allowedEquipments?.find(
        e => e.equipmentId === selectedLoadingPlaceId,
      );
      if (!allowedEquipment) {
        return defaultMarks;
      }

      return {
        canReceive: allowedEquipment.canReceive ?? false,
        canProvide: allowedEquipment.canProvide ?? false,
      };
    }

    return (
      product.allowedEquipments?.reduce(
        (acc: {canReceive: boolean; canProvide: boolean}, curr) => {
          const result = {...acc};
          if (curr.canProvide && !result.canProvide) {
            result.canProvide = curr.canProvide;
          }
          if (curr.canReceive && !result.canReceive) {
            result.canReceive = curr.canReceive;
          }
          return result;
        },
        defaultMarks,
      ) ?? defaultMarks
    );
  }
  const canProvideAndReceiveByProduct = (
    product: ReservationAvailableProductOutputDTO,
    selectedLoadingPlaceId: string | null | undefined,
  ) => {
    return Object.values(
      getReservationTypeMarksByProduct(product, selectedLoadingPlaceId),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    ).every(can => can);
  };
  const canProvideByProduct = (
    product: ReservationAvailableProductOutputDTO,
    selectedLoadingPlaceId: string | null | undefined,
  ) => {
    const {canProvide} = getReservationTypeMarksByProduct(
      product,
      selectedLoadingPlaceId,
    );
    return canProvide;
  };
  const canReceiveByProduct = (
    product: ReservationAvailableProductOutputDTO,
    selectedLoadingPlaceId: string | null | undefined,
  ) => {
    const {canReceive} = getReservationTypeMarksByProduct(
      product,
      selectedLoadingPlaceId,
    );
    return canReceive;
  };
  const canOnlyProvideByProduct = (
    product: ReservationAvailableProductOutputDTO,
    selectedLoadingPlaceId: string | null | undefined,
  ) => {
    const {canProvide, canReceive} = getReservationTypeMarksByProduct(
      product,
      selectedLoadingPlaceId,
    );
    return canProvide && !canReceive;
  };
  const canOnlyReceiveByProduct = (
    product: ReservationAvailableProductOutputDTO,
    selectedLoadingPlaceId: string | null | undefined,
  ) => {
    const {canProvide, canReceive} = getReservationTypeMarksByProduct(
      product,
      selectedLoadingPlaceId,
    );
    return canReceive && !canProvide;
  };
  function getPlainMarks(
    selectedLoadingPlaceId: string | null | undefined,
    availableProducts?: ReservationAvailableProductOutputDTO[],
    selectedProductId?: string,
  ): IReservationTypeMarks {
    const defaultMarks = {canReceive: false, canProvide: false};
    const isProductsListExists = !!availableProducts?.length;
    if (!isProductsListExists) {
      return defaultMarks;
    }
    // if no product selected, define marks  by all availableProducts
    if (!selectedProductId) {
      const provideOnly =
        availableProducts?.some(product =>
          canProvideByProduct(product, selectedLoadingPlaceId),
        ) ?? false;
      const receiveOnly =
        availableProducts?.some(product =>
          canReceiveByProduct(product, selectedLoadingPlaceId),
        ) ?? false;
      return {
        canProvide: provideOnly,
        canReceive: receiveOnly,
      };
    }

    const selectedProduct = availableProducts?.find(
      product => product.productId === selectedProductId,
    );
    // if selected product is defined
    return selectedProduct
      ? (() => {
          const provideAndReceive = canProvideAndReceiveByProduct(
            selectedProduct,
            selectedLoadingPlaceId,
          );
          const provideOnly = canOnlyProvideByProduct(
            selectedProduct,
            selectedLoadingPlaceId,
          );
          const receiveOnly = canOnlyReceiveByProduct(
            selectedProduct,
            selectedLoadingPlaceId,
          );
          return {
            canProvide: provideAndReceive || provideOnly,
            canReceive: provideAndReceive || receiveOnly,
          };
        })()
      : defaultMarks;
  }
  return {
    getPlainMarks,
    isDisabled: (
      availableProducts?: ReservationAvailableProductsOutputDTO,
    ): boolean => {
      const isProductsListExists = !!availableProducts?.products?.length;
      const disabled = true;
      if (!isProductsListExists) {
        return disabled;
      }
      // Check if there is a product available that can be loaded (provide) or unloaded (receive)
      // We only enable the field when there are both available
      const anyProductCanProvide = availableProducts.products?.some(product =>
        canProvideByProduct(product, undefined),
      );
      const anyProductCanReceive = availableProducts.products?.some(product =>
        canReceiveByProduct(product, undefined),
      );
      return !anyProductCanProvide || !anyProductCanReceive;
    },
    filterOptionsByMarkKey: (
      markKey: 'canProvide' | 'canReceive',
      originalOptions: IOption[],
      selectedLoadingPlaceId: string | null | undefined,
      productsByFilteredOptions?: ReservationAvailableProductOutputDTO[],
    ) => {
      const ids = productsByFilteredOptions
        ?.filter(product => {
          const marks = getPlainMarks(
            selectedLoadingPlaceId,
            productsByFilteredOptions,
            product.productId,
          );
          return marks[markKey];
        })
        .map(({productId}) => productId);
      return originalOptions.filter(({value}) => ids?.includes(value));
    },
  };
}

export function mergedStatus(statuses: QueryStatus[]): QueryStatus {
  if (statuses.some(status => status === 'loading')) {
    return 'loading';
  }
  if (statuses.some(status => status === 'error')) {
    return 'error';
  }
  if (statuses.every(status => status === 'success')) {
    return 'success';
  }
  return 'idle';
}
export function useItemDataRead() {
  const {id} = useParams<{id: string}>();

  const {data: reservation, status: reservationStatus} =
    useReservationsReservationIdGet(id);
  return {
    status: reservationStatus,

    data: {
      ...(reservation as ReservationOutputDTO),
      equipment: reservation?.equipmentId
        ? {
            id: reservation.equipmentId,
            displayName: reservation.equipmentName,
          }
        : null,
      logisticOperator: {
        companyId: reservation?.logisticsOperatorCompanyId,
        displayName: reservation?.logisticsOperatorName,
      },
      startStamp:
        reservation !== undefined
          ? new Date(reservation.startStamp)
          : new Date(),
      endStamp:
        reservation !== undefined ? new Date(reservation.endStamp) : new Date(),
      customer: reservation?.customerCompanyId
        ? {
            id: reservation.customerCompanyId,
            displayName: reservation.customerCompanyName,
          }
        : null,
      site: reservation?.siteId
        ? {
            id: reservation.siteId,
            displayName: reservation.siteName,
          }
        : null,

      destination: reservation?.deliveryDestinationId
        ? {
            id: reservation.deliveryDestinationId,
            displayName: reservation.deliveryDestinationName,
            code: reservation.deliveryDestinationCode,
            address: reservation.deliveryDestinationAddress,
          }
        : null,
      isReservation: isReservationTypeContent(reservation?.type),
      loadingInterval: (() => {
        if (reservation) {
          return getIntervalText({
            start: new Date(reservation.startStamp),
            end: new Date(reservation.endStamp),
          });
        }
        return '';
      })(),
    },
  };
}
export function getDestinationText(destination: {
  code?: string | null;
  displayName?: string | null;
  address?: string | null;
}) {
  const {code, displayName, address} = destination;
  return `${code ? `${code} - ` : ''}${displayName ?? ''}${
    address ? ` (${address})` : ''
  }`;
}

export function getIntervalText(interval: {start: Date; end: Date}) {
  const {start, end} = interval;
  if (!isValid(start) || !isValid(end)) {
    return '-';
  }
  const {hours, minutes} = intervalToDuration(interval);
  if (differenceInMinutes(end, start) < 60) {
    return i18n.t('scheduling:notifications.minutes', {minutes});
  } else if (differenceInHours(end, start) < 24) {
    return i18n.t('scheduling:notifications.hours', {hours, minutes});
  } else {
    return i18n.t('scheduling:notifications.days', {
      days: differenceInDays(end, start),
      hours,
      minutes,
    });
  }
}
