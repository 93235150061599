import {ValmetTextInput} from '@valmet-iop/ui-common';
import {getIn, useFormikContext} from 'formik';
import React from 'react';
interface Props {
  /**
   * Id of the element, used to connect the label
   */
  id: string;
  /**
   * Translation key for the label
   */
  labelTranslationKey: string;
  /**
   * Translation key for the help text below the field
   */
  helpTextTranslationKey?: string;
  /**
   * If specified, the error message is shown.
   * Don't use with validator.
   */
  errorMessageTranslationKey?: string;
  /**
   * If true, displays the field as required (does not do validation, that you need to do in validator)
   */
  isRequired?: boolean;
  /**
   * Adds an (optional) text after the label text.
   * Don't use with isRequired.
   */
  isOptional?: boolean;
  isDisabled?: boolean;
  /**
   * The current value; if undefined the field will be uncontrolled
   */
  value?: string;
  width?: 'default' | 'medium' | 'small' | 'full';
  /**
   * Validation function for the current value, called at mount and every time value changes.
   * Must return either true to indicate valid data or a translation key for an error message.
   */
  validator?: (value: string) => true | string;
  onChange: (value: string) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}
export const TextInput = (
  props: {formProperty: string} & Omit<
    Props,
    'errorMessageTranslationKey' | 'value' | 'validator' | 'onChange' | 'onBlur'
  >,
) => {
  const {formProperty, ...rest} = props;
  const {
    touched,
    errors,
    values,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<any>();
  const fieldTouched = getIn(touched, formProperty) as boolean | undefined;
  const fieldError = getIn(errors, formProperty) as string | undefined;
  const fieldValue = getIn(values, formProperty) as string;
  const onChange = (val: string) => {
    setFieldValue(formProperty, val);
  };
  const onBlur = () => {
    setFieldTouched(formProperty);
  };

  return (
    <ValmetTextInput
      {...rest}
      value={fieldValue}
      errorMessageTranslationKey={fieldTouched ? fieldError : undefined}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};
