const resources = {
  en: {
    translation: {
      logout: 'Log out',
      table: {
        multiLineText: {
          andMore: '+ {N} more',
          showMore: 'Show more',
        },
        tooltips: {
          sortAscending: 'Sort by ascending',
          sortDescending: 'Sort by descending',
          resetSort: 'Reset sort',
          columnPreferences: 'Column preferences',
          rowActions: 'Row actions',
          rowActionsNoPermissions: 'No permissions to perform actions',
        },
        noResultsFound: 'No data available.',
      },
      inputs: {
        optional: 'optional',
      },
      forms: {
        cancelConfirmDialog: {
          header: 'Are you sure you want to close without saving?',
          cancel: 'Cancel',
          closeWithoutSaving: 'Close without saving',
        },
        deleteConfirmDialog: {
          header: 'Are you sure you want to delete item?',
          cancel: 'Cancel',
          apply: 'Delete',
        },
        confirmDialog: {
          no: 'No',
          yes: 'Yes',
        },
      },
      menus: {
        multiSelect: {
          selectAll: 'Select all',
          clear: 'Clear',
          filterResults: 'Filter results',
        },
      },
      backToListView: 'Back to list view',
      errors: {
        requestFailed: 'Request failed',
        notFound: 'Not found',
      },
      fieldErrors: {
        required: 'Required',
        digitsOnly: 'Digits only',
        numericOnly: 'Numbers only',
        minMax: 'Min is {{min}}, Max is {{max}}',
        maxLength: 'Max length is {{length}}',
        maxVal: 'Max value is {{max}}',
        noPast: 'Past is not valid',
        beforeStart: 'Date is before start',
        nullIntervalLength: 'Interval length is 0 minutes',
        tooShortInterval: 'Minimum interval length is {{length}} minutes',
        afterEnd: 'Date is after end',
        invalidDate: 'Invalid date format',
      },
      navigation: {
        operationsManagement: 'Operations management',
        distributionManagement: 'Distribution management',
        endpointMonitoring: 'Site monitoring',
        endpointList: 'Site list',
        logisticsManagement: 'Logistics management',
        scheduling: 'Scheduling',
        assetTracking: 'Asset tracking',
        assetTrackingMap: 'Map',
        forecast: 'Forecast',
        endpointMap: 'Site map',
      },
      pagination: {
        pageIndicatorTemplate: '{{start}}-{{end}} of {{results}} results',
        perPage: '/ page',
        previous: 'Previous',
        next: 'Next',
      },
      filter: {
        placeholder: 'Filter...',
        placeholderStart: 'From',
        placeholderEnd: 'To',
      },
      select: {
        open: 'Open',
        close: 'Close',
        clear: 'Clear',
        noOptions: 'No options found',
        loading: 'Loading...',
      },
      login: {
        header: 'Authentication required',
        body: 'To continue to the application, authentication is required.',
        loggingOut: 'Logging out...',
        loginInProgress: 'Login in progress...',
        login: 'Login',
        failedErrorMessage: 'Something went wrong when logging you in.',
      },
      userNotFound: {
        header: 'Unable to load user data',
        body: 'Something went wrong connecting to the server.',
      },
      accessDenied: {
        header: 'Access denied',
        body: 'Your account does not have permission to access this application.',
      },
      buttons: {
        add: 'Add',
        cancel: 'Cancel',
        update: 'Update',
        reset: 'Reset',
        revert: 'Revert',
        clear: 'Clear',
      },
      tooltips: {
        delete: 'Delete',
      },
      units: {
        kg: 'kg',
      },
    },
  },
};

export default resources;
