import * as React from 'react';
import {makeStyles, Theme} from '@material-ui/core';
import {BottomActions, colors} from '@valmet-iop/ui-common';
import {useRedirect} from '../context/redirect-context';
import {useFormikContext} from 'formik';
import {useItem} from '../context/item-context';

const useActionStyles = makeStyles<Theme>(theme => ({
  root: {
    width: '100%',
    position: 'absolute',
    bottom: `-${theme.spacing(9.75)}px`,
    borderTop: `1px solid ${colors.grey12}`,
  },
}));

interface IProps {
  disabled?: boolean;
  onDirtyCancel: () => void;
}
export function Actions({disabled, onDirtyCancel}: IProps) {
  const classes = useActionStyles();
  const {toDefault} = useRedirect();
  const {submitForm, isValid, dirty} = useFormikContext<any>();
  const {isCreate} = useItem();
  return (
    <div className={classes.root}>
      <BottomActions
        actions={[
          {
            translationKey: isCreate ? 'buttons.add' : 'buttons.update',
            style: 'primary',
            isDisabled: (!isValid && dirty) || !dirty || disabled,
            onClick: () => {
              void submitForm();
            },
          },
          {
            translationKey: 'buttons.cancel',
            style: 'ghost',
            isDisabled: disabled,
            onClick: () => {
              dirty ? onDirtyCancel() : toDefault();
            },
          },
        ]}
      />
    </div>
  );
}
