import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {
  Theme,
  makeStyles,
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
} from '@material-ui/core';
import {QueryStatus} from 'react-query';

import {PrimaryButton} from '@valmet-iop/ui-common';

const useStyles = makeStyles<Theme>(theme => ({
  titleAndCreateButton: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '28px',
  },
  title: {
    lineHeight: '26px',
    marginTop: '6px',
  },
  filter: {
    marginBottom: '20px',
  },
  table: {
    marginBottom: '70px',
  },
  pagination: {},
}));

export interface IListPageLayout {
  createTitle: string;
  title: string;
  children?: React.ReactNode;
  status: QueryStatus;
  onCreate: () => void;
  extraButtons?: React.ReactNode;
  titleButtons?: React.ReactNode;
  isCreateDisabled?: boolean;
  preTitle?: React.ReactNode;
}

export function ListPageLayout({
  title,
  createTitle,
  children,
  status,
  onCreate,
  extraButtons,
  titleButtons,
  isCreateDisabled,
  preTitle,
}: IListPageLayout) {
  const classes = useStyles();
  const {t} = useTranslation();
  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={9} lg={9} md={9} sm={9} xl={7}>
            <Box display="flex">
              {preTitle}
              <Typography variant="h2" className={classes.title}>
                {t(title)}
              </Typography>
              {titleButtons}
            </Box>
          </Grid>
          <Grid item xs={3} lg={3} md={3} sm={3} xl={3}>
            <Box display="flex" justifyContent="flex-end">
              {extraButtons}
              <Box marginLeft={3}>
                <PrimaryButton
                  icon="add"
                  isDisabled={isCreateDisabled}
                  text={t(createTitle)}
                  onClick={onCreate}
                  disableMinimumWidth
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={10}>
            {children}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

interface IListLayout {
  filter?: React.ReactNode;
  children: React.ReactNode;
  pagination?: React.ReactNode;
}
export function ListLayout({filter, children, pagination}: IListLayout) {
  const classes = useStyles();
  return (
    <>
      {filter && <div className={classes.filter}>{filter}</div>}
      <div className={classes.table}>{children}</div>
      {pagination && <div className={classes.pagination}>{pagination}</div>}
    </>
  );
}
