import store from '../../redux/store';
import React from 'react';
import {Provider} from 'react-redux';

const withProvider = (WrappedComponent: any) => (props: any) =>
  (
    <Provider store={store}>
      <WrappedComponent {...props} />
    </Provider>
  );

export default withProvider;
